import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import Loader from '../../../App/Loader';
import apiCalls from '../../../../config/apiCalls'
import fetchMethodRequest from '../../../../config/service';
import { Button} from 'reactstrap';

// config file
export default class MemoryCache extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Memory Cache',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
  }



  //user Task label options
  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
         width: 80,
        field: 'screen',
        "mobile": true,
        header: 'Screen',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
         width: 40,
        field: 'memoryCacheName',
        // "fieldType": 'number',
        "mobile": true,
        header: "Memory Cache Name",
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
         width: 40,
        field: 'description',
        // "fieldType": 'number',
        "mobile": true,
        header: "Description",
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 40,
        "fieldType": 'custumChangeFieldValues',
        "field": "",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  custumChangeFieldValues=(item)=>{
    return (
      <span style={{ textTransform: "capitalize" }}>
        <Button
          className="mr-1 mb-0"
          color="primary"
          outline
          onClick={() => this.resetCacheApi(item)}>
          {"Refresh"}
        </Button>
      </span>
    );
  }


  getFormFields = () => {
    return ([
    ]);
  }

  resetCacheApi = (item) => {
    this.datatableref.getisLoading(true)
    fetchMethodRequest(
      "GET",
      `${apiCalls.resetCacheApi}?tableName=${item.memoryCacheName}`
    ).then(async (response) => {
      if (response && response["respMessage"]) {
        this.datatableref.getErrorMessage(response["respMessage"], "", "Success")
      } else if (response && response["errorMessage"]) {
        this.datatableref.getErrorMessage(response["respMessage"], "", "error")
      }
      this.datatableref.getisLoading("false")
    });
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"screen"}
          sortDirection={"asc"}
          dontShowTitle={true}
          addRequried={false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          custumChangeFieldValues={this.custumChangeFieldValues}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          isClearFilters={true}
          type='Memory Cache'
          displayright={true}
          icon='user'
          routeTo='adminSettings'
          displayViewOfForm='modal'
          apiResponseKey='memoryCacheConfigurations'
          apiUrl={apiCalls.MemoryCache}
          entityType='employee'
        />
      </span>
    );
  }
}