import React from 'react';
import Table from 'react-bootstrap/Table';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { InputText } from 'primereact/inputtext';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import config from '../../../config/config';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
//Loader
import Loader from '../../App/Loader';
import fetchMethodRequest from '../../../config/service';
// config file
export default class ViewTextModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount = async () => {
    }

    toggleModal = () => {
        this.props.updateViewTextModal("", "")

    };


    render() {
        const { value, viewTextFieldName } = this.props;
        const isPreformatted = value.includes('\n') || value.includes('\t'); // Example condition
        return (
            <div>
                <Modal isOpen={true} fade={false} 
                    style={{ width: 1500 }}
                    className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width leftAlign'
                >

                    <ModalHeader className={"modal__header kanbanScreen d-flex"}>
                        <div className='d-flex'> <span style={{ fontWeight: "bolder", fontSize: "medium" }}> {viewTextFieldName}
                            <button className="lnr lnr-cross modal__close-btn" style={{ color: "black", fontSize: "medium", right: 40, marginTop: "24px" }}
                                onClick={this.toggleModal} />
                        </span>
                        </div>
                    </ModalHeader>
                    <ModalBody className='deleteModalBody' style={{ padding: 10,whiteSpace:  'pre-wrap'  }}>
                        <div style={{ marginBottom: 10 }} className='leftAlign' >{value}</div>

                    </ModalBody>
                </Modal>
            </div>
        );
    }
}