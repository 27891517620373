import React from 'react';
import { StaticGrid } from '../../../../shared/StaticGrid';
import fetchMethodRequest from '../../../../config/service';
interface props {
    documents:Array<any>,
    getDocumentsData:Function,
    showModal:any,
    closeModal:Function,
    loadedDocuments:any
}


export const AddressEvid = ({documents,getDocumentsData,showModal,closeModal,loadedDocuments}:props) => {

    /**
     * Redirecting to Pdf editor on click file name.
     * @param id {*} 
     */
    const redirectToPDFEditor = (id:any) => {
        fetchMethodRequest('GET', `getDocumentById?Id=${id}`,'','','','').then(async (res) => {
            let data = res && res.respCode && res.outResponse && res.outResponse.details ? res.outResponse.details : null;
            if (data) {                
                localStorage.setItem('pdfDocDetails',JSON.stringify(data));
                let url = `/pdfEditor?details=loadDoc`;
                window.open(url, '_blank', 'noopener=true');
            }            
        })
    }

    const fileNameBody = (row:any) => {
        return <><a onClick={() => redirectToPDFEditor(row._id)} className='text-info' style={{ cursor: 'pointer' }}>{row.fileName}</a>
            {loadedDocuments[row.documentNumber] && <div>(Document already loaded for {loadedDocuments[row.documentNumber]})</div>}</>
    }

    // Table columns of address evidence
    const columns = [
        {
            field: 'documentTypeName',
            header: 'Document Type',
            filter: false,
            filterPlaceHolder: '',
            sortable: false,
            style:{textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsAddress',
            header: 'Address',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsCity',
            header: 'City',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsState',
            header: 'State',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{padding:'revert-layer'}
        },
        {
            field: 'fileName',
            header: 'File Name',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: false,
            style:{width:'40%',textAlign:'left',padding:'revert-layer'},
            formatter:fileNameBody
        }

    ]
    
    return (      
        <>{ showModal &&
            <StaticGrid
                data={documents}
                columns={columns}
                pagination={true}
                rows={10}
                removableSort={true}
                sortMode='multiple'
                rowsPerPageOption={[10,15,20,30, 50, 100,250]}
                emptyMessage='No Documents Found'
                getDocumentsData={getDocumentsData}
                closeModal={closeModal}
                loadedDocuments={loadedDocuments}
                
            />}
        </>
        
    )
}