import React, { Component } from 'react';
import apiCalls from '../../../../config/apiCalls';
import fetchMethodRequest from '../../../../config/service';
import { FileUpload } from 'primereact/fileupload';
import { AutoComplete } from 'primereact/autocomplete'
import { Button, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from "primereact/dropdown"
//import configMessages from '../../../../config/configMessages';

//const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);

const largeFontStyle = { fontSize: '1.2em' };
const modalStyle = { maxWidth: '800px' };
const errorStyle = { fontSize: '1.2em', color: "red" }
const alignRight = { textAlign: "right" }
class ReportBugNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: props.openReportBugNewModel,
            formData: {
                type: 'Feature',
                page: "",
                description: '',
                dealName: '',
                priority: 'Medium', // Set default priority to Medium
                files: [],
                fileNames: [],
                displayError: false
            },
            isSubmitted: false,
            dropDownFields: ["issueType", "opra2Page", "businessPriority", "issueStatus"],
            issueType: [{ 'label': "Feature", "value": "Feature" },
            { 'label': "Bug", "value": "Bug" }],
            opra2Page: [{ 'label': "Property Editor", "value": "Property Editor" },
            { 'label': "Claims Manager", "value": "Claims Manager" },
            { 'label': "Deal Management", "value": "Deal Management" },
            { 'label': "Other", "value": "Other" }],
            businessPriority: [{ 'label': "Critical", "value": "Critical" },
            { 'label': "High", "value": "High" },
            { 'label': "Medium", "value": "Medium" },
            { 'label': "Low", "value": "Low" }],
            deals: [],
            showFilesError: false,
            fileNames: [],
            filesSelected: false,
            userName: "",
        };
    }

    componentDidMount = async () => {
        //   this.fetchDeals();
        let data = document.getElementsByClassName("p-fileupload-buttonbar");
        if (data) {
            data[0].children[1].style.display = "none"
        }
        let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
        if (loginCredentials && loginCredentials.displayName) {
            this.setState({ userName: loginCredentials.displayName })
        }


        await this.getDropDownValues()
        let rolePermissions = JSON.parse(localStorage.getItem("rolePermissions"));
        let opra2Page = []
        if (rolePermissions) {
            Object.entries(rolePermissions).forEach(([key, value]) => {
                if (value === "Edit") {
                    opra2Page.push({ label: key, value: key })
                } // key and corresponding value
            });
            const sorted = opra2Page.sort((a, b) => {
                let fa = a.label.toLowerCase(),
                    fb = b.label.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            this.setState({ opra2Page: sorted })
        }
    }

    getDropDownValues = () => {
        let url = apiCalls.userRequestConfiguration;
        if (url != null) {
            url += '?filter={"sortfield":"sequence","direction":"asc","criteria":[{"key":"Screen","value":"UserRequests","type":"eq"}]}'
            fetchMethodRequest('GET', url, {}, null)
                .then(async (response) => {
                    if (response && response.userRequestConfigurations && response.userRequestConfigurations.length) {
                        for (let obj of response.userRequestConfigurations) {
                            if (obj.key != null && obj.key != "opra2Page" && this.state.dropDownFields.includes(obj.key)) {
                                await this.setState({
                                    [obj.key]: obj.options
                                })
                            }
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }
    toggleModal = () => {
        this.props.handleReportBugNewModel(false)
        this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));
    };

    editorTemplateForDeal(option) {
        return (`${option.DealType} - ${option.SellerNickName} (${option && option.APADate ? new Date(option.APADate).getFullYear() : ""})`)
    }

    EstateItems = (event) => {
        let filterCriteria = { limit: 30, sortfield: "SellerNickName" };
        filterCriteria["criteria"] = [{
            key: "SellerNickName",
            value: event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : "",
            type: "sw"
        }]
        let apiUrl = `seller?filter=${JSON.stringify(filterCriteria)}`
        return fetchMethodRequest('GET', apiUrl)
            .then(async (response) => {
                if (response) {
                    let dropdownData = [];
                    if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
                        dropdownData = response["sellers"]
                    }
                    else if (response && response.errorMessage) {
                        // this.props.getErrorResponseFromServer(response)
                    }
                    if (dropdownData && dropdownData.length === 0) {
                        this.setState({
                            filteredSuggestions: [],
                            noData: true
                        });
                    } else {
                        let dropDopdownValues = dropdownData;
                        dropdownData = dropDopdownValues
                    }
                    await this.setState({
                        filteredSuggestions: dropdownData
                    });
                }
            }).catch((err) => {
                return err;
            });
    }

    handleChange = (e) => {
        const { name, value } = (e && e.target) ? e.target : e;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            },
            isPage:name==="page"&&value.length>0?false:this.state.isPage,
            isDescription:name==="description"&&value.length>0?false:this.state.isDescription

        }));
    };

    handleFileChange = (e) => {
        const filesArray = Array.from(e.target.files).slice(0, 5);
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                files: filesArray
            }
        }));
    };

    // upload images and save the user request
    handleUpload = async () => {
        if (document.getElementsByClassName("pi-upload p-c") && document.getElementsByClassName("pi-upload p-c")[0]) {
            // will trigger uploadMultipleFiles() and upload the files if any selected
            await document.getElementsByClassName("pi-upload p-c")[0].click()
            // if no files selected will save the details 
            if (this.state.fileNames && this.state.fileNames.length == 0 && !this.state.showFilesError && (!document.getElementsByClassName("p-fileupload-row") || document.getElementsByClassName("p-fileupload-row")).length == 0) {
                this.handleData()
            }
        }
    }

    handleData = async () => {
        const { formData } = this.state;
        console.log("jhg",formData)
        if (formData.description.length === 0 || formData.description === undefined|| formData.page.length === 0 || formData.page === undefined) {
            if (formData.description.length === 0 || formData.description === undefined) {
                await this.setState({ isDescription: true })
            }
            if ( formData.page.length === 0 || formData.page === undefined) {
                await this.setState({ isPage: true })
            }
        } else {
            await this.setState({ isSubmitted: true })
            this.handleSubmit()
        }
    }
    // Save the details in reportbugs
    handleSubmit = async () => {
        const { formData } = this.state;

        let days = 35;
        this.state.businessPriority.map(u => {
            if (formData.priority == u.value) {
                days = u.val
            }
        })
        let arr = []
        if (formData.notes && formData.notes.length > 0) {
            arr.push({ note: formData.notes })
        }
        let dealNameValue
        dealNameValue = formData && formData.dealName ? `${formData.dealName.DealType} - ${formData.dealName.SellerNickName} (${formData.dealName.APADate ? new Date(formData.dealName.APADate).getFullYear() : ""})` : ""

        const body = {
            issueType: formData.type,
            opra2Page: formData.page,
            issueNumber: 1,
            issueDescription: formData.description,
            businessPriority: formData.priority,
            developmentPriority: formData.priority,
            issueStatus: "New",
            days: days,
            issueOwner: this.state.userName,
            issueRelatedDealName: dealNameValue,
            issueFixDevelopedBy: "",
            issueTestedBy: "",
            sprint: "",
            notes: arr,
            fileNames: this.state.fileNames,
            referanceLink: formData.referanceLink
        }
        await this.setState({ isLoading: true })
        try {
            fetchMethodRequest('POST', apiCalls.ReportNewBug, body)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        this.props.getErrorMessage(response.respMessage, "", "green")
                        this.toggleModal();
                        this.props.getDataFromServer(this.props.filterCriteria, "refresh")
                    } else if (response && response.errorMessage) {
                        await this.setState({ isSubmitted: false })
                        this.props.getErrorMessage(response.errorMessage, "", "error")
                    }
                    await this.setState({ isLoading: false })

                    if (!response.ok) {
                        throw new Error('Failed to submit the bug report');
                    }
                    this.setState({
                        formData: {
                            type: 'Feature',
                            page: 'Property Editor',
                            description: '',
                            dealName: '',
                            priority: 'Medium', // Reset default priority to Medium
                            files: []
                        }
                    });


                }).catch((err) => {
                    return err;
                });


        } catch (error) {
            console.error('Error submitting bug report:', error.message);
        }
    };

    //extract the file names from the response and setting state and using in record.
    getFileName = async (responses, type) => {
        var responsesdocuments = [];
        for (var file of responses) {
            responsesdocuments.push(file.fileName)
        }
        if (type == "success") {
            await this.setState({
                fileNames: responsesdocuments
            });
            this.handleSubmit()
        }
    }
    // Upload Image API to save files in Server
    uploadMultipleFiles = async (event) => {
        if (event && event.files && event.files.length) {
            if (event.files.length > this.props.fileUploadLimit) {
                await this.setState({
                    showFilesError: true
                })
            } else {
                await this.setState({
                    showFilesError: false
                })
                event.files = Array.from(event.files).slice(0, this.props.fileUploadLimit ? this.props.fileUploadLimit : 5);
                fetchMethodRequest('POST', apiCalls.reportBugUpload, event, 'upload')
                    .then(async (response) => {
                        if (response && response.fileResponses && response.respMessage) {
                            this.getFileName(response.fileResponses, 'success')
                            await this.setState({ displayError: false });
                        } else if (response && response.errorMessage) {
                            this.getFileName(response, 'failure')
                            await this.setState({ displayError: true });
                        }
                    }).catch((err) => {
                        return err;
                    });
            }
        }
    }

    // fetchDeals = async () => {
    //     try {
    //         const response = await fetch('your-backend-api-url');
    //         if (!response.ok) {
    //             throw new Error('Failed to fetch deals');
    //         }
    //         const data = await response.json();
    //         this.setState({ deals: data });
    //     } catch (error) {
    //         console.error('Error fetching deals:', error.message);
    //     }
    // };

    render() {
        const { modalOpen, formData } = this.state;

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Modal isOpen={modalOpen} fade={false} style={modalStyle}>
                        <ModalHeader className={"d-flex"} style={{ justifyContent: 'center' }}>
                            <div className='d-flex'> <span style={{ fontWeight: "bolder", fontSize: "large" }}> Submit User Request </span>
                                <button className="lnr lnr-cross modal__close-btn" style={{ color: "black", fontWeight: "bolder", right: 40, marginTop: "24px" }}
                                    onClick={this.toggleModal} />
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            {this.displayError && <Label style={errorStyle}>Failed to Upload</Label>}
                            <FormGroup>
                                <Row className="d-flex ">
                                    <Col md={3} style={alignRight}>
                                        <Label for="type" style={largeFontStyle}>Request Type</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Dropdown value={formData.type} style={{ width: "100%", height: 34, textAlign: "left"  }} name="type"onChange={this.handleChange} options={this.state.issueType} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="d-flex ">
                                    <Col md={3} style={alignRight}>
                                        <Label for="page" style={largeFontStyle}>OPRA2 Page <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /></Label>
                                    </Col>
                                    <Col md={9}>
                                        <Dropdown value={formData.page} style={{ width: "100%", height: 34, textAlign: "left" }} name="page" filter={true} onChange={this.handleChange} options={this.state.opra2Page} />
                                    </Col>
                                    {this.state.isPage ? <p className='col-sm-12 my-0 text-danger'>Please fill the field</p> : null}

                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="d-flex align-items-center">
                                    <Col md={3} style={alignRight}>
                                        <Label for="description" style={largeFontStyle}>Description <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /></Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input type="textarea" name="description" rows="10" id="description" value={formData.description} onChange={this.handleChange} style={largeFontStyle} />
                                    </Col>
                                    {this.state.isDescription ? <p className='col-sm-12 my-0 text-danger'>Please fill the field</p> : null}
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="d-flex align-items-center">
                                    <Col md={3} style={alignRight}>
                                        <Label for="notes" style={largeFontStyle}>Notes</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input type="textarea" name="notes" id="notes" rows="5" value={formData.notes} onChange={this.handleChange} style={largeFontStyle} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="d-flex align-items-center">
                                    <Col md={3} style={alignRight}>
                                        <Label for="dealName" style={largeFontStyle}>Deal Name</Label>

                                    </Col>
                                    <Col md={9}>
                                        <AutoComplete
                                            name="dealName"
                                            id="dealName"
                                            value={formData.dealName.SellerNickName ? `${formData.dealName.DealType} - ${formData.dealName.SellerNickName} (${formData.dealName.APADate ? new Date(formData.dealName.APADate).getFullYear() : ""})` : formData.dealName}
                                            suggestions={this.state.filteredSuggestions ? this.state.filteredSuggestions : []}
                                            completeMethod={this.EstateItems}
                                            minLength={1}
                                            field={"EntityName"}
                                            placeholder={"Deal Name"}
                                            style={{ width: "100%" }}
                                            inputStyle={{ width: "92%" }}
                                            itemTemplate={this.editorTemplateForDeal}
                                            dropdown={true}
                                            onChange={this.handleChange}
                                            appendTo={document.body} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="d-flex ">
                                    <Col md={3} style={alignRight}>
                                        <Label for="priority" style={largeFontStyle}>Priority</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Dropdown name="priority" id="priority" style={{ width: "100%", height: 34 , textAlign: "left"}} value={formData.priority} filter={true} onChange={this.handleChange} options={this.state.businessPriority} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="d-flex align-items-center">
                                    <Col md={3} style={alignRight}>
                                        <Label for="referanceLink" style={largeFontStyle}>Recording Link</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input type="text" name="referanceLink" id="referanceLink" value={formData.referanceLink} onChange={this.handleChange} style={largeFontStyle} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="d-flex align-items-center">
                                    <Col md={3} style={alignRight}>
                                        <Label for="file" style={largeFontStyle}>Attach Files (up to 5)</Label>
                                    </Col>
                                    <div className='ml-2 pl-2'>
                                        <FileUpload
                                            name="file"
                                            url={apiCalls.reportBugUpload}
                                            onUpload={this.uploadMultipleFiles}
                                            multiple={true}
                                            accept="image/*"
                                            onSelect={this.checkSelection}
                                            //disabled={this.state.displayError}
                                            maxFileSize={1000000}
                                            uploadHandler={(event) => this.uploadMultipleFiles(event)}
                                            customUpload={this.uploadMultipleFiles}
                                        />
                                        {this.state.showFilesError && <span style={{ ...errorStyle, ...alignRight }}>{"max 5 files can be selected"}</span>}
                                    </div>
                                </Row>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" outline onClick={this.toggleModal}>Cancel</Button>{' '}
                            <Button color="primary" outline onClick={this.handleUpload} disabled={this.state.isSubmitted}> Submit</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}

export default ReportBugNew;
