import React, { useEffect, useState } from "react";
import { DocumentLibraryFilters } from "./DocumentLibraryFilters";
import DataTables from '../CommonDataTable/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlusSquare,faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import fields from './DocumentLibraryFields.json';
import { useRef } from 'react';
import * as documentsApis from "../../../shared/documentsApis";
import Loader from '../../App/Loader';


interface props{
    dealId:string,
    names:Array<any>,
    relSellerNames:Array<any>,
    setDocumentsDropdownsData:Function
}

interface searchValues {
    fileName:string,
    subCategoryName:Array<any>,
    documentSourceTypeName:Array<any>,
    relevanceTypeName:Array<any>,
    createdByUser:Array<any>,
    updatedByUser:Array<any>,
    state:Array<any>
}

const DocumentLibrary = ({dealId,names,relSellerNames,setDocumentsDropdownsData}:props) => {
    const [docLibraryTopFilters,setDocLibraryTopFilters] = useState <Array<any>>([]);
    const [removeFilterField,setRemoveFilterField] = useState<any>();
    const [namesDropDownData,setNamesDropDownData] = useState <Array<any>>([]);
    const [relSellerDropDownData,setRelSellerDropDownData] = useState <Array<any>>([]);
    const [isLoading,setIsLoading] = useState <boolean>(false);
    const [loadTable,setLoadTable] = useState <boolean>(false);
    const [addressDropDownData,setAddressDropDownData] = useState <Array<any>>([]);
    const [doSearch,setDoSearch] = useState<number>(0);    
    const dataTableRef = useRef<any>();
    
    useEffect(() => {
        getDropdownFilterData();
    },[dealId])

    /**
     * Get multiselect filter options in data table for Mapped names, Rel seller names & Addresses
     */
    const getDropdownFilterData = async () => {
        setIsLoading(true);
        if (names && names.length === 0) {
            const namesData = await documentsApis.getNames('sellerRelevanceName', dealId);
            setDocumentsDropdownsData('names', namesData);
            setNamesDropDownData(namesData.map((e: any) => { return { label: e.CombinedName, value: e.BusinessNamesID } }));
        } else {
            setNamesDropDownData(names.map((e: any) => { return { label: e.CombinedName, value: e.BusinessNamesID } }));
        }
        if (relSellerNames && relSellerNames.length === 0) {
            const relSellerNamesData = await documentsApis.getNames('mappedName', dealId);
            setDocumentsDropdownsData('relSellerNames', relSellerNamesData);
            setRelSellerDropDownData(relSellerNamesData.map((e: any) => { return { label: e.CombinedName, value: e.BusinessNamesID.toString() } }));
        } else {
            setRelSellerDropDownData(relSellerNames.map((e: any) => { return { label: e.CombinedName, value: e.BusinessNamesID.toString() } }));
        }
        const adressData = await documentsApis.getAddresses(dealId);
        setAddressDropDownData(adressData.map((e: any) => { return { label: `${e.Line1}, ${e.City}, ${e.StateProvince}`, value: e.BusinessLocationID } }))
        setTimeout(() => {
            setIsLoading(false);
            setLoadTable(true);
        }, 2000);

    }

    const getCustomActions = (col:any) => {
        return <>
            {(col.subCategoryName === documentsApis.CLAIM_PKT || col.subCategoryName === documentsApis.OPP_CLMNT_INFO || col.documentTypeName === documentsApis.CLAIM_PKT) ? null :
                <div className="d-flex w-100 justify-content-center">
                    <div data-toggle="tooltip" data-placement="left" title={'Edit Document Attributes'}>
                        <FontAwesomeIcon icon={faPencilAlt} className="cursor-pointer" onClick={() => redirectToUpload(col, 'edit')} />
                    </div>
                    <div className="ml-2" data-toggle="tooltip" data-placement="left" title={'Add A New Document'}>
                        <FontAwesomeIcon icon={faPlusSquare} className="cursor-pointer" onClick={() => redirectToUpload(col, 'add')} />
                    </div>
                </div>
            }
        </>
    }

    const redirectToUpload = async (rowData:any, type?:any) => {       
        let url = `/sellersEdit/documentUpload/${rowData.dealId}?dealId=${rowData.dealId}&mappedNameId=${rowData.mappedNameID}&relSellerNameId=${rowData.businessNameID}&stateCode=${rowData.docState}&stateClaimId=${rowData.docStateClaimID}&OPPClaimID=${rowData.docOPPClaimID}&rowData=${encodeURIComponent(JSON.stringify(rowData))}`;
        if (type === 'edit') {
            url = `${url}&type=${type}`
        }
        window.open(url, '_blank', 'noopener=true');
    }

    const redirectToPDFEditor = (col:any) => {
        return <div className="cursor-pointer text-info" onClick={() => documentsApis.redirectToPDFEditor(col, '', col._id)} data-toggle="tooltip" data-placement="left" title={col.fileName}>
            {col.isFileCommonForMultiSubCategories ? <span className="multiRepFile">{col.fileName}</span> : <span>{col.fileName}</span>}
        </div>
    }
    const getTableFields = () => { 
        let tableFields = JSON.parse(JSON.stringify(fields));
        //Setting multi select dropdown filter options in table
        for (let i = 0; i < tableFields.length; i++) {
            if (tableFields[i].field === 'mappedName') {
                tableFields[i].filterOptions = namesDropDownData;
            } else if (tableFields[i].field === 'businessName') {
                tableFields[i].filterOptions = relSellerDropDownData;
            } else if (tableFields[i].field === 'bsAddress') {
                tableFields[i].filterOptions = addressDropDownData;
            }
        }
        
        tableFields.unshift({
            "show": true,
            "textAlign": "center",
            "width": 120,
            "field": "customActions",           
            "mobile": true,
            "header": "Actions",
            "displayInSettings": false,
            "fieldType": "customActions",                   
            "filter": false,
            "sortable": false,
            "getCustomActions":getCustomActions
        },{
            "show": true,
            "textAlign": "left",
            "width": 500,
            "field": "fileName",
            "mobile": true,
            "header": "Document File Name",
            "displayInSettings": true,
            "filter": true,
            "sortable": true,
            "fieldType": "customActions",
            "getCustomActions":redirectToPDFEditor 
        });       
        return tableFields;
    }
    
    const getTopFilters = (topFilters:searchValues | undefined) => {
        let topFilterCriteria:Array<any> = [];
        const mappingObj: any = {
            subCategoryName: "subCategoryName",
            documentSourceTypeName: "sourceType",
            relevanceTypeName: "name",
            createdByUser: "display_name",
            updatedByUser: "display_name",
            state:"shortCode"
        }
        if (topFilters) {
            for (let field in topFilters) {
                const key = field as keyof searchValues;
                if (topFilters[key]) {
                    if (key === 'fileName') {
                        topFilterCriteria.push({ "key": key, "value": topFilters[key], "type": "regexOr", "add_Filter": true });
                    } else if(topFilters[key] && topFilters[key].length > 0){
                        topFilterCriteria.push({ "key": key, "value": topFilters[key].map((elem: any) => elem[mappingObj[key]]), "type": "in", "add_Filter": true });
                    }
                }
            }
        }      
        setDocLibraryTopFilters(topFilterCriteria);
        setDoSearch(prevDoSearch => prevDoSearch + 1);
    }

    const removeTopFilter = (field:string) => {
        setRemoveFilterField(field);        
        setDocLibraryTopFilters(prevDocFilters => prevDocFilters.filter((e) => e.key !== field));
    }
    return <div className="mt-3 px-2 document-library">
        {/* Hiding top filters for now */}
        {/* <DocumentLibraryFilters
            getTopFilters={getTopFilters} 
            removeFilterField={removeFilterField}           
        />         */}
        <div >
            {loadTable && // Loading table after getting dropdown options in filter from server.
                <DataTables
                    //   onRef={(ref) => (this.datatableref = ref)}
                    ref={dataTableRef}
                    getTableFields={getTableFields}
                    formFields={getTableFields}
                    exportRequried={false}
                    printRequried={false}
                    addRequried={false}
                    editRequired={true}
                    sortField={"createdDateUTC"}
                    deleteRequired={false}
                    viewRequired={true}
                    settingsRequired={false}
                    filterRequired={true}
                    gridRequried={false}
                    // isShownnFieldType={true}
                    sample={false}
                    scrollHeight={"580px"}
                    datakey={"_id"}
                    // isSelectMode={"multiple"}
                    globalSearch={'Search'}
                    type={"Document Library"}
                    displayright={true}
                    icon='faCity'
                    // settingsData={this.state.settingsData}
                    fontAwesome={true}
                    // routeTo='propertyEditor'
                    displayViewOfForm='modal'
                    hideHeader={true}
                    // apiResponseKey='ns_scored_propertys'
                    className={true}
                    apiUrl={"postListDocuments"}
                    entityType='employee'
                    selDealId={dealId}
                    docLibraryTopFilters={docLibraryTopFilters}
                    hideProgress={true}
                    removeTopFilter={removeTopFilter}
                    doSearch={doSearch}
                    paginatorPosition={'top'}
                    style={{ marginTop: 10 }}
                    donotIncludeParamsInFilter={true}
                />
            }
        </div>
        <Loader loader={isLoading} />
    </div>
}

export default DocumentLibrary;