import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


// config file
class confirmationForRedirection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  deleteSelectedRow = (type) => {
    if (type) {
      this.props.closeConfirmationModal(this.props.setFlagforredirection, type)
    } else {
      this.props.closePopUpScreen()
    }
  }

  closePopUpScreen = () => {
  }

  render() {
    const { t } = this.props
    return (
      <div className="">
        <Modal isOpen={this.props.openConfirmationModal} fade={false} className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width redirection_prop' >
          <div className="d-flex "style={{justifyContent:"flex-end"}}>
            <FontAwesomeIcon icon={faTimes}  style={{ color: "black" }}
              onClick={() => this.deleteSelectedRow()} />
          </div>
          <ModalBody className='deleteModalBody'>
            <h4 style={{ marginBottom: 10 }}> {"Pick the Property Editor version:"}</h4>
            <Button color="primary" style={{ width: 200 }} onClick={() => this.deleteSelectedRow("nsproperties")} className='deleteModalBtn mr-0'>{t(' Property Editor V1')}</Button>
            <Button color="primary" style={{ width: 200 }} onClick={() => this.deleteSelectedRow("propertyEditorV2")}
              className='deleteModalBtn'>{t('Property Editor V2')}</Button>
          </ModalBody>
        </Modal>
      </div >
    );
  }
}
export default withTranslation('common')(confirmationForRedirection);
