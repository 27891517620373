import React from 'react';
import { Button } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDownload,faSave } from "@fortawesome/free-solid-svg-icons";
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment-timezone';
import Loader from '../../../App/Loader';
import fetchMethod from '../../../../config/service';
import CommonPdfEdiitor from '../../../../shared/pdf/CommonPdfEdiitor';
import DocumentSelection from './DocumentSelection';
import { ChainOfTitle } from './ChainOfTitle';
import { ProgressResp } from './ProgressResp';
import { TransmittalLetter } from './TransmittalLetter';
import config from '../../../../config/config';
import '../../../../scss/component/claims-packet.scss';
import * as documentsAPIs from '../../../../shared/documentsApis';
import * as fs from 'file-saver';
import { AddressEvid } from '../../DocumentUpload/components/AddressEvid';
import { RadioButton } from 'primereact/radiobutton';
import dateFormats from '../../../UI/FormatDate/formatDate';
import Alerts from '../../DocumentUpload/components/Alerts';


const params = new URLSearchParams(window.location.search);
const COMPANY_ID = params.get('companyID') ? params.get('companyID') : '';
const STATE_CLAIM_ID = params.get('stateClaimID') && params.get('stateClaimID') !== 'null' ? params.get('stateClaimID') : '';
const STATE = params.get('state') ? params.get('state') : '';
const BUSINESSID = params.get('relevantSellerBusinessNameID') ? params.get('relevantSellerBusinessNameID') : '';
const MAPPED_ID = params.get('mappedNameID') ? params.get('mappedNameID') : '';
const MAPPED_NAME = params.get('mappedName') ? params.get('mappedName').trim() : '';
const REL_SELLER_NAME = params.get('releveantSellerName') ?  decodeURIComponent(params.get('releveantSellerName')) : '';
const CLAIM_VALUE = params.get('claimAmount') ? params.get('claimAmount') : '';
const OPP_CLAIM_ID = params.get('OPPClaimID') ? params.get('OPPClaimID') : '';
const CLAIM_PKT_SOURCE = 'OPP';

export default class ClaimsPacket extends React.Component {
    constructor(props) {
        super(props);
        // Document types with chevron buttons to show on top. for order change or renaming etc, make changes in this array.
        this.documentTypes = [
            { name: 'Transmittal Letter', delOption: true, category: 'Transmittal Letter', seq: 1 },
            { name: 'Claim Form', delOption: true, category: 'Claim Form', seq: 2 },
            { name: 'OPP APA', delOption: true, category: 'OPP APA', seq: 3 },
            { name: 'OPP Docs', delOption: true, category: 'OPP Docs', seq: 4 },
            { name: 'Chain Of Title', delOption: true, category: 'Chain Of Title', seq: 5 },
            { name: 'Seller Docs', delOption: true, category: 'Seller Docs', seq: 6 },
            { name: 'Review & Finalize', delOption: false, seq: 999 },
        ];

        this.state = {
            documentUrl: null,
            loading: false,
            allPdfLists: [],
            showModal: false,
            selectedDocType: '',
            selectedPage: 0,
            fileNamesToShow: [],
            mergePdf: '',
            mergePdfBlobUrl: '',
            getMultipleFiles: false,
            pageNumbers: {},
            activePageType: null,
            lastUpdated: '-',
            claimMasterSeq: {},
            mandatoryDocs: {},
            guidelines: '',
            delToggle: false,
            delType: null,
            delPages: false,
            selRelTypes: [],
            seqDocumentTypes: [],
            stateAttributes: [],
            totalPages: 0,
            dealDetails: {},
            showNameOptDialog: false,
            fileName: '',
            showRespDialog: false,
            autoSave: true,
            selectedClaimPkt: '',
            showClaimPktDocs: false,
            isUpdate: false,
            currFileName: null,
            overWriteFile: false,
            fileWithSameNameExist: false,
            selectedClaimId: null,
            disableLoadButton: false,
            respMsgType: null,
            draftNameExist: false,
            delMessage: '',
            showChainOfTitle: false,
            chainOfTitleData: [],
            showProgressBar: false,
            loadingCompleted: false,
            progressType: 'Search',
            disableCOTAdd: false,
            cotHirCount: 0,
            topButtonSeq: [],
            showTLetterEditor: false,
            cheveronWidth: '100px',
            selDocTypes: [],
            COTFiles:[],
            noCOTFound:false,
            loadedDocuments:{},
            showFileNameRestrictedCharError:false,
            response: { type: '', message: '' },
            sectionLoaded:false,
            prevLoadedSections:{}            
        }
        this.relevanceTypes = [];
        this.documentTypes = [];
        this.categories = [];
        this.claimPktRelId = null;
        this.claimPktDocTypeId = null;
        this.saveStatus = 'notSaved';
        this.documentId = '';
        this.documentNumber = '';
        this.document_id = '';
        this.pdfEditorRef = React.createRef();
        this.loginCredentials = localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials")) : null;
        this.mandatoryRelTypes = [];
        this.existingClaimDocs = [];
        this.chainOfTitleHir = [];
        this.prevActivePage = '';
        this.sellerDocs = {};
        this.queryParams = new URLSearchParams(window.location.search);
        this.forTransmittalLetter = false;
        this.pdfBeforePageNum = null;
        this.docLoadingOrder=[];
        this.selCOTFiles=[];
        this.recentlyLoadedDocs = [];
        this.addInProgress = false;
        this.updateInProgress = false;
        this.clmPktSubCatTypeId = ''; 
        this.stopAutoUpdate=false; 
        this.loadedClaimPktDetails = {};
        this.claimPktStateAttr = {}; 
        this.doReplace = false;
        this.mandatoryTypes = {};            
    }

    componentDidMount() {
        this.getDeal();
        this.getCategories();
        if (document.getElementsByClassName("layout layout--collapse").length === 0) {
            document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
        } window.addEventListener('navbarToggle', this.toggle);
        window.addEventListener('beforeunload', (e) => {
            // e.preventDefault();
           
        })

    }


    /**
     * Get deal details
     */
    getDeal = () => {
        let filter = { "limit": 20, "page": 1, "criteria": [], "sortfield": "created", "direction": "desc" };
        filter.criteria.push({ "key": "DealID", "value": parseInt(this.queryParams.get('companyID')), "type": "eq" });
        fetchMethod('GET', `seller?filter=${JSON.stringify(filter)}`).then(async (res) => {
            if (res && res.sellers && res.sellers.length > 0) {                
                await this.setState({ dealDetails: res.sellers[0], fileName: documentsAPIs.replaceRestrictedCharFromFileName(`${res.sellers[0].SellerNickName.trim()}-${STATE}-${REL_SELLER_NAME.trim()}${STATE_CLAIM_ID ? '-' + STATE_CLAIM_ID : ''}-CLM PCKT-DRAFT`) });
                this.getStateAttributes();
            } else {
                this.setState({ dealDetails: {} });
            }
        });

    }

    /**
     * Get state attribute data for sequence
     */
    getStateAttributes = () => {
        const filter = { "criteria": [{ "key": "state", "value": STATE, "type": "eq", "addFilter": true }, { "key": "Category", "value": "Claim Packet", "type": "eq", "addFilter": true }, { "key": "activeIndicator", "value": true, "type": "eq", "addFilter": true }, { "key": "dealType", "value": this.state.dealDetails.DealType, "type": "eq", "addFilter": true }], limit: 100 }
        fetchMethod('GET', `StateUpFiling?type=exportToCsv&filter=${JSON.stringify(filter)}`).then(async (res) => {
            if (res && res.stateUpFilings && res.stateUpFilings.length > 0) {
                let seq = [];
                let guide = {};
                res.stateUpFilings.forEach((elem) => {
                    if (elem.SubCategory && elem.SubCategory !== documentsAPIs.CLAIM_PKT) {
                        const index = seq.findIndex((e) => e.name === elem.SubCategory);                        
                        if (index > -1) {
                            seq[index].seq = parseInt(elem.ClaimBuilderChevronSequence);
                        } else {
                            seq.push({ name: elem.SubCategory, delOption: true, category: elem.SubCategory, seq: parseInt(elem.ClaimBuilderChevronSequence) });
                        }

                    }else{
                        this.claimPktStateAttr = elem;
                    }
                    if (!guide[elem.SubCategory]) {
                        guide[elem.SubCategory] = [];
                    }
                    if (elem.IsMandatory) {
                        this.mandatoryRelTypes.push({ docTypeName: elem.DocumentTypeName, docType: elem.SubCategory, isLoaded: false });
                        this.mandatoryTypes[elem.DocumentTypeName] = [];
                    }
                    guide[elem.SubCategory].push(`${elem.SubCategory} (${elem.attributeValue})`)
                });

                seq.push({ name: "Review & Finalize", delOption: false, category: "Review & Finalize", seq: 99 });
                let chWidth = 0;
                for (let i = 0; i < seq.length; i++) {
                    if (seq[i].name) {
                        seq[i].chWidth = ((seq[i].name.length * 10) + 42) > 200 ? `${(seq[i].name.length * 10) + 42}px` : `200px`;
                    }
                }
                seq.sort((a, b) => a.seq - b.seq);                
                await this.setState({ pageNumbers: this.getPageNumbersObj(seq), seqDocumentTypes: seq, guidelines: guide, stateAttributes: res.stateUpFilings, topButtonSeq: seq.map((e) => e.name), activePageType: seq[0].name, cheveronWidth: `${chWidth + 32}px` });
                this.setPageNumbers();
                // this.onclickDocumentType(this.documentTypes[0].name, this.documentTypes[0].category);                
                const fileDetails = this.queryParams.get('sharePointDetails') ? JSON.parse(this.queryParams.get('sharePointDetails')) : null;
                if (fileDetails && fileDetails.length > 0) {
                    this.loadClaimPktFromDocMaint(fileDetails)
                } else {
                    this.getDocumentList(documentsAPIs.CLAIM_PKT, documentsAPIs.CLAIM_PKT);
                }


            }
        });
    }

    loadClaimPktFromDocMaint = (fileDetails) => {
        this.saveStatus = 'update';
        this.setState({ selectedDocType: documentsAPIs.CLAIM_PKT, isUpdate: true, selectedClaimId: fileDetails.id });
        this.getDocumentsData([{ sharePointDetails: fileDetails, fileName:  this.getFileNameWithoutOPPId(fileDetails[0].fileLeafRef)}]);
        
    }

    /**
     * Get document cateegories list
     */
    getCategories = async () => {
        this.categories = await documentsAPIs.getSubCategories();
        this.documentTypes = await documentsAPIs.getDocTypes();
        this.relevanceTypes = await documentsAPIs.getRelevenceTypes();
        const clmPktRel = this.relevanceTypes.find((e) => e.name === documentsAPIs.CLAIM_PKT);
        if (clmPktRel && clmPktRel._id) {
            this.claimPktRelId = clmPktRel._id;
        }
        const clmPktDocTyp = this.documentTypes.find((e) => e.name === documentsAPIs.CLAIM_PKT);
        if (clmPktDocTyp && clmPktDocTyp._id) {
            this.claimPktDocTypeId = clmPktDocTyp._id;
        } 
        const clmPktSubCatType = this.categories.find((e) => e.subCategoryName === documentsAPIs.CLAIM_PKT);
        if(clmPktSubCatType && clmPktSubCatType._id){
            this.clmPktSubCatTypeId = clmPktSubCatType._id;
        }       
    }



    getBodyBasedOnScope = (scope) => {       
        let body = {};
        if (scope.includes(documentsAPIs.DOC_SCOPE_DEAL)) {
            body.DealId = COMPANY_ID;
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_STATE)) {
            body.State = STATE;
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_CLAIMID)) {
            body.StateClaimID = STATE_CLAIM_ID;
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME)) {
            body.MappedNameID = MAPPED_ID;
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_REL_SELLER)) {
            body.BusinessNameId = BUSINESSID;
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_OPP_CLAIMID)) {
            body.OPPClaimID = OPP_CLAIM_ID;
        }
        return body;

    }

    /**
     * Getting available documents list.
     * @param {String} docType 
     * @param {String} relType 
     */
    getDocumentList = async (docType, relType, sellerDocType) => {
        this.prevActivePage = this.state.activePageType;
        const selectedCategory = this.categories.find((e) => e.subCategoryName === docType);
        if (!selectedCategory) {
            this.setState({ fileNamesToShow: [] });
            return;
        }
        const scope = selectedCategory && selectedCategory.documentScopeName ? selectedCategory.documentScopeName : []
        this.setState({ selectedDocType: docType, getMultipleFiles: false, progressType: 'Search', showProgressBar: true, loadingCompleted: false, activePageType: docType,sectionLoaded:false });
        const body = this.getBodyBasedOnScope(scope);
        const stateAttributes = this.state.stateAttributes.filter((e) => e.SubCategory === selectedCategory.subCategoryName);
        let result = await documentsAPIs.getAvailableDocuments(body, JSON.parse(JSON.stringify(stateAttributes)), [selectedCategory], 'claimBuilder', docType,null,MAPPED_NAME);
        let docIds = {};
        result = result.filter((record) => record.sharePointDetails && record.sharePointDetails.length > 0 );
        result.sort((a,b) => {                
            return (new Date(b.createdDateUTC).getTime() - new Date(a.createdDateUTC).getTime())
        });
        if (docType === documentsAPIs.OPP_CLMNT_INFO) {
            if (result && result.length > 0) {
                this.getDocumentsData(result);
                return;
            } else {
                this.setState({ loading: false, showModal: true, showProgressBar: false, fileNamesToShow: result.filter((e) => e.sharePointDetails && e.sharePointDetails.length > 0)  });

            }
        }        
        if(docType === documentsAPIs.CLAIM_PKT){
            this.existingClaimDocs = result;
            let filesWithData = result.filter((e) => e.sharePointDetails && e.sharePointDetails.length > 0) ;             
            for (let i = 0; i < filesWithData.length; i++) {
                filesWithData[i].latestDate = filesWithData[i].updatedDateUTC ? filesWithData[i].updatedDateUTC : filesWithData[i].createdDateUTC;                               
            }
            filesWithData.sort((a,b) => {                
                return (new Date(b.latestDate).getTime() - new Date(a.latestDate).getTime())
            });            
            this.setState({ loading: false, showClaimPktDocs: true, showProgressBar: false, fileNamesToShow: filesWithData ,selectedClaimPkt: filesWithData.length > 0 ? 0 : null});           
            return;
        }
        this.setState({ loading: false, showModal: true, showProgressBar: false, fileNamesToShow: result });          
        this.checkGotAllMandatoryFilesForSubCat(this.state.selectedDocType, result);
    }    

    setSellerDocs = (docArray, type) => {
        this.sellerDocs[type] = docArray;
        if (this.sellerDocs['Mapped Name ID'] && this.sellerDocs['Relevant Seller Name ID']) {
            const res = [...this.sellerDocs['Mapped Name ID'], ...this.sellerDocs['Relevant Seller Name ID']];
            this.setState({ fileNamesToShow: res, loading: false, showModal: true, showProgressBar: false });
            this.checkGotAllMandatoryFiles(this.state.selectedDocType, res);
        }
    }

    getChainOfTitle = (fromTL) => {
        this.forTransmittalLetter = fromTL ? true : false;
        this.setState({ loading: true, noCOTFound:false });
        const url = `getCOTNamesHierarchy?DealID=${this.state.dealDetails.DealID}&BusinessNameID=${MAPPED_ID}`;
        // const url = `getCOTNamesHierarchy?DealID=3&BusinessNameID=21510228`;
        fetchMethod('POST', url).then(async (res) => {
            if (res && res.outResponse && res.outResponse.details && res.outResponse.details.length > 0) {
                this.chainOfTitleHir = res.outResponse.details;
                const selectedCategory = this.categories.find((e) => e.subCategoryName === this.state.selectedDocType);
                if (!selectedCategory) {
                    this.setState({ fileNamesToShow: [] });
                    return;
                }
                const scope = selectedCategory && selectedCategory.documentScopeName ? selectedCategory.documentScopeName : [];
                const body = this.getBodyBasedOnScope(scope);
                body.MappedNameID = this.chainOfTitleHir.map((elem) => elem.businessNamesID).join(',');
                const result = await documentsAPIs.getAvailableDocuments(body, JSON.parse(JSON.stringify(this.state.stateAttributes)), [selectedCategory], 'chainOfTitle');
                const filteredResult = result.filter((e) => e.sharePointDetails && e.sharePointDetails.length > 0)
                this.formatChainOfTitleData(filteredResult);
                this.setState({ setCOTCount: res.outResponse.details.length, COTResult: filteredResult });
            } else {
                this.chainOfTitleHir = [];
                this.setState({ loading: false, chainOfTitleData: [], showProgressBar: false,noCOTFound:true });
                if (this.forTransmittalLetter) {
                    this.setState({ chainOfTitleData: [] });
                }
            }
        });
    }

    formatChainOfTitleData = (fileData) => {
        let cotData = [];
        let disableCOTAdd = false;
        this.chainOfTitleHir.forEach((hir, i) => {
            const files = fileData.filter((elem) => (elem.mappedNameID.toString() === hir.businessNamesID.toString() && elem.sharePointDetails && elem.sharePointDetails.length > 0));
            if (files.length > 0) {
                const fileNamesArr = files.map((file) => file.sharePointDetails && file.sharePointDetails.length > 0 ? file.fileName : null);
                if (fileNamesArr.length === 0) {
                    disableCOTAdd = true;
                }
                files.forEach((file, j) => {
                    cotData.push({
                        ...hir,
                        fileName: fileNamesArr[j],
                        year: file.eventDateYear,
                        description: file.documentRelevanceNotes,
                        hideHir: (j > 0 ? true : false),
                        rowSpanCount: files.length,
                        colorCode: i,
                        docId: file.id,
                        docNumber:file.documentNumber
                    });

                })

            } else {
                disableCOTAdd = true;
                cotData.push({ ...hir, colorCode: i });
            }
        });

        if (this.forTransmittalLetter) {
            this.setState({ chainOfTitleData: cotData, loading: false });
        } else {
            this.setState({ chainOfTitleData: cotData, loading: false, cotHirCount: this.chainOfTitleHir.length, showChainOfTitle: true, disableCOTAdd: disableCOTAdd, disableLoadButton: true });
        }

    }

    loadClaimPacket = (selectedFiles) => {
        let selDocIds = {};
        selectedFiles.forEach((elem) => {
            selDocIds[elem.docId] = true;
        });
        const fileList = this.state.fileNamesToShow.filter((doc) => (doc.sharePointDetails && doc.sharePointDetails.length > 0 && selDocIds[doc.id]));
        this.getDocumentsData(fileList);
        this.showHideChainOfTitle(false);
    }

    /**
     * Creating page number object based on 'documentTypes'
     * @returns {Object} tPageNumbers 
     */
    getPageNumbersObj = (docSeqArray) => {
        let tPageNumbers = {};
        docSeqArray.forEach((type) => {
            if (type && type.name) {
                tPageNumbers[type.name] = { from: null, to: null };
            }
        })
        return tPageNumbers;
    }

    /**
     * Making API call to get file data from server.
     * @param {Array} pdfList 
     */
    getDocumentsData = (pdfList) => {            
        if (this.state.selectedDocType === documentsAPIs.CLAIM_PKT) {
            this.documentId = pdfList[0].sharePointDetails && pdfList[0].sharePointDetails[0] && pdfList[0].sharePointDetails[0].documentID ? pdfList[0].sharePointDetails[0].documentID : '';           
            this.document_id = pdfList[0] && pdfList[0]._id ? pdfList[0]._id : '';
            this.setState({ currFileName: pdfList[0].fileName, fileName: this.getFileNameWithoutOPPId(pdfList[0].fileName) });
            
        }
        let sortedList = [];
        this.docLoadingOrder.forEach((rel) => {
            if (rel === documentsAPIs.CHAIN_OF_TITLE_DOC_TYPE && this.selCOTFiles.length > 0) {
                sortedList.push(this.selCOTFiles);
            } else {
                const docs = pdfList.filter((d) => d.documentTypeName === rel);
                if (docs.length > 0) {
                    sortedList.push(docs);
                }
            }
        });
        sortedList = sortedList.length > 0 ? sortedList.flat() : pdfList;
        const uniqDocs = this.getUniqueDocuments(sortedList);
        const docsWithData = sortedList.filter((e) => e.sharePointDetails && e.sharePointDetails.length > 0 && !this.state.loadedDocuments[e.documentNumber]);
        const pdfArray = uniqDocs.filter((e) => e.sharePointDetails && e.sharePointDetails.length > 0 && !this.state.loadedDocuments[e.documentNumber]).map((elem) => elem.sharePointDetails[0]);
        let data = {
            details: pdfArray
        }   
        this.setState({ showClaimPktDocs: false, progressType: 'load', showProgressBar: true, loadingCompleted: false, showModal: false });            
        fetchMethod('POST', `GetFileContent`, data).then(async (res) => {
            if (res && res.details) {              
                if (res.details.length > 0) {
                    this.setFiles(res.details);
                    this.recentlyLoadedDocs = sortedList;
                }
                pdfList.forEach((file) => {                                      
                    const index = this.mandatoryRelTypes.findIndex((elem) => elem.docTypeName === file.documentTypeName)                
                    if (index > -1) {
                        this.mandatoryRelTypes[index].isLoaded = true;
                    }
                    if (this.state.selectedDocType === documentsAPIs.OPP_CLMNT_INFO) {
                        for (let i = 0; i < this.mandatoryRelTypes.length; i++) {
                            if(this.mandatoryRelTypes[i].docType === documentsAPIs.OPP_CLMNT_INFO){
                                this.mandatoryRelTypes[i].isLoaded = true;
                                
                            }
                        }
                    }                    
                });
                docsWithData.forEach((file) => {
                    if(this.state.selectedDocType === documentsAPIs.OPP_CLMNT_INFO){
                        this.state.selDocTypes.forEach((type) => {
                            if(this.mandatoryTypes[type]){
                                this.mandatoryTypes[type].push(file.documentNumber);
                            }
                        });
                        return;
                    }
                    if (this.mandatoryTypes.hasOwnProperty(file.documentTypeName)) {
                        this.mandatoryTypes[file.documentTypeName].push(file.documentNumber);
                    }
                });
            } else {
                this.setState({ loading: false });
            }
        });
    }
    
    /**
     * To remove duplicate documents
     * @param {Array} docList 
     * @returns {Array} 
     */
    getUniqueDocuments = (docList) => {
        let uniqDocs = [];
        let docNumObj = {};
        docList.forEach((e) => {
            if(!docNumObj[e.documentNumber]){
                uniqDocs.push(e);
                docNumObj[e.documentNumber] = true;
            }
        });        
        return uniqDocs;
    }

    /**
     * Triggering initialising/merging of data into PDF.
     * @param {Array} files 
     */
    setFiles = async (files) => {
        this.setState({ loading: true });
        if (files && files.length > 0) {
            if (!this.state.documentUrl) {
                const url = await this.createBlobFromBase64(files[0].fileContent, true);
                await this.setState({ documentUrl: url, loading: false });                
                if (files.length > 1) {
                    setTimeout(() => {
                        this.setFiles(files.slice(1));
                    }, 5000);
                }

            } else {
                let fileArray = [];
                files.forEach((file) => {
                    const blobData = this.createBlobFromBase64(file.fileContent, false);
                    fileArray.push(blobData);
                });
                await this.setState({ mergePdfBlobUrl: fileArray, loading: false });
                const index = this.getInsertPageIndex(this.state.selectedDocType);
                if (this.pdfEditorRef.current) {
                    this.pdfEditorRef.current.mergePdf(index);
                }
            }
        }
    }

    /**
     * Determine where the new pages should load
     * @param {String} docType 
     * @returns {Number} 
     */
    getInsertPageIndex = (docType) => {
        const index = this.state.seqDocumentTypes.findIndex((elem) => elem.name === docType);
        if (index > -1) {
            if (this.state.pageNumbers[this.state.seqDocumentTypes[index]] && this.state.pageNumbers[this.state.seqDocumentTypes[index]].name && this.state.pageNumbers[this.state.seqDocumentTypes[index]].name.to) {
                return this.state.pageNumbers[this.state.seqDocumentTypes[index]].name.to;
            }
            for (let i = index - 1; i >= 0; i--) {
                if (this.state.seqDocumentTypes[i] && this.state.seqDocumentTypes[i].name &&
                    this.state.pageNumbers[this.state.seqDocumentTypes[i].name] &&
                    this.state.pageNumbers[this.state.seqDocumentTypes[i].name].to) {
                    return this.state.pageNumbers[this.state.seqDocumentTypes[i].name].to;
                }
            }
        }
        return 0;
    }

    toggleLoader = (showLoader) => {
        this.setState({ loading: showLoader });
    }


    /**
     * converting baseString to blob
     * @param {BinaryData} base64String 
     * @param {string} url 
     * @returns Object URL or BLOB
     */
    createBlobFromBase64 = (base64String, url) => {
        try {
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            if (url) {
                const tUrl = URL.createObjectURL(blob);
                return tUrl;
            } else {
                return blob;
            }
        } catch(err){
            this.setState({showProgressBar:false,respMsgType : 'invalidFileData',showRespDialog:true,});
            console.error(err);
        }
    }


    /**
     * show file names or navigating to the specific type page in document on click type(chevron buttons)
     * @param {string} type 
     * @param {string} category
     */
    onclickDocumentType = (type, category) => {
        this.selCOTFiles = [];
        this.setState({ AddedCOT: false, disableLoadButton: false });
        if (this.documentSelectionRef && this.documentSelectionRef.setCOTCount) {
            this.documentSelectionRef.setCOTCount(0, false)
        }
        if (type === "Review & Finalize") {
            const isMandatoryFilesLoaded = this.checkMandatoryFilesLoaded();
            if (isMandatoryFilesLoaded) {
                let pageNumbers = this.state.pageNumbers;
                if (this.state.totalPages > 0) {
                    pageNumbers[type].from = 1;
                    pageNumbers[type].to = this.state.totalPages;
                }
                this.setState({ selectedPage: 1, pageNumbers: pageNumbers, selectedDocType: type });
            } else {
                this.setState({ respMsgType: 'loadMandatory', showRespDialog: true });
            }
            return;
        }
        if (!this.state.pageNumbers[type].from) {
            if (type === 'Chain Of Title') {
                this.getChainOfTitle();
            } else {
                let filteredSubCat = this.state.stateAttributes.filter((cat) => (cat.SubCategory === category && cat.SubCategory)).sort((a, b) => a.DocSequenceWithinChevron - b.DocSequenceWithinChevron);
                const relevanceNames = (filteredSubCat).map((elem) => elem.RelevanceTypeName);
                this.docLoadingOrder = (this.state.stateAttributes.filter((cat) => (cat.SubCategory === category && cat.SubCategory)).sort((a, b) => a.ValueSequence - b.ValueSequence)).map((e) => e.DocumentTypeName);
                let mandatory = {};
                filteredSubCat.forEach((elem) => {
                    mandatory[elem.DocumentTypeName] = elem.IsMandatory;
                });  
                this.setState({ selRelTypes: relevanceNames, selectedDocType: type, mandatoryDocs: mandatory, selDocTypes: filteredSubCat.map((elem) => elem.DocumentTypeName), SubCatDesc: filteredSubCat.map((elem) => elem.DocumentDescription) });
                if (type !== 'Seller Docs') {
                    this.getDocumentList(type, relevanceNames.join(','));
                } else {
                    this.sellerDocs = {};
                    const mapRelNames = filteredSubCat.filter((elem) => elem.claimPacketDocsSubGroup === 'Mapped Name ID').map((e) => e.SubCategory);
                    const RelSellerRelNames = filteredSubCat.filter((elem) => elem.claimPacketDocsSubGroup === 'Relevant Seller Name ID').map((e) => e.SubCategory);
                    this.getDocumentList(type, mapRelNames.join(','), 'Mapped Name ID');
                    this.getDocumentList(type, RelSellerRelNames.join(','), 'Relevant Seller Name ID');
                }
            }
        } else {
            this.setState({ selectedPage: this.state.pageNumbers[type].from, selectedDocType: type });
        }
    }


    /**
     * Trigger change of page on click page numbers which are bellow Types(chevron buttons)
     * @param {Event} e     
     * @param {String} pageNumber 
     */
    onClickPageNumber = (e, pageNumber) => {
        e.stopPropagation();
        this.setState({ selectedPage: parseInt(pageNumber) });
    }

    /**
     * Trigger delete functonality
     * @param {Event} e 
     * @param {sting} delType 
     */
    onClickDelete = (e, delType) => {
        e.stopPropagation();
        this.setState({ delType: delType, delPages: true, loading: true });
    }
    /**
     * Setting section sequence from annotations
     * @param {Array} sequence 
     * @param {Array} pageNumArray 
     */
    setSequenceFromAnnotations = async (sequence, pageNumArray) => {
        let docTypes = JSON.parse(JSON.stringify(this.state.seqDocumentTypes));
        sequence.forEach((docType, i) => {
            const index = docTypes.findIndex((e) => e.name === docType);           
            if (index > -1) {
                docTypes[index].annSeq = docType === 'Review & Finalize' ? 99 : i + 1;
            } else {
                docTypes[index].annSeq = 98;
            }            
        });
        for (let i = 0; i < docTypes.length; i++) {
            if(!docTypes[i].annSeq &&  docTypes[i].annSeq !== 0){
                docTypes[i].annSeq = docTypes.length;
            }
        }
        docTypes.sort((a, b) => {return (a.annSeq - b.annSeq)});       
        await this.setState({ seqDocumentTypes: docTypes.sort((a, b) => {return (a.annSeq - b.annSeq)}), topButtonSeq: sequence });
        this.setPageNumbersFromBookmarks(pageNumArray);
    }


    /**
     * save page numbers into state on successfully loading new pages into PDF
     * @param {string} type 
     * @param {Number} from 
     * @param {Number} to 
     */
    setPageNumbers = async (type, from, addedPageCount) => {
        const index = this.state.seqDocumentTypes.findIndex((elem) => elem.name === type);
        let pageNumbers = this.state.pageNumbers;
        if (pageNumbers[type]) {
            if (!pageNumbers[type].from) {
                pageNumbers[type].from = from;
            }
            if (!pageNumbers[type].to) {
                pageNumbers[type].to = from + addedPageCount - 1;
            } else {
                pageNumbers[type].to = pageNumbers[type].to + addedPageCount;
            }
        }
        for (let i = index + 1; i < this.state.seqDocumentTypes.length; i++) {
            if (pageNumbers[this.state.seqDocumentTypes[i].name].from) {
                pageNumbers[this.state.seqDocumentTypes[i].name].from = pageNumbers[this.state.seqDocumentTypes[i].name].from + addedPageCount;
                pageNumbers[this.state.seqDocumentTypes[i].name].to = pageNumbers[this.state.seqDocumentTypes[i].name].to + addedPageCount;
            }
        }
        this.updateMandatryRelTypes(pageNumbers);
        await this.setState({ pageNumbers: pageNumbers, showModal: false, activePageType: type },this.setLoadedDocuments('add'));
    }

    /**
     * Set page numbers whan loading claim packet.
     * @param {Array} pageNumArray 
     */
    setPageNumbersFromBookmarks = (pageNumArray) => {  
        pageNumArray = pageNumArray.map((elem) => {
            const index = this.state.seqDocumentTypes.findIndex((e) => e.name === elem.docType);
            return { ...elem, seq: index }
        });
        pageNumArray = pageNumArray.sort((a, b) => a.seq - b.seq);                
        let pageNumbers = this.state.pageNumbers;
        for (let i = 0; i < pageNumArray.length; i++) {
            if (pageNumbers[pageNumArray[i].docType]) {
                pageNumbers[pageNumArray[i].docType].from = pageNumArray[i].pNum + 1;
                if (pageNumArray[i + 1]) {
                    pageNumbers[pageNumArray[i].docType].to = pageNumArray[i + 1].pNum;
                } else {
                    pageNumbers[pageNumArray[i].docType].to = this.state.totalPages;
                }
            }
        }
        this.updateMandatryRelTypes(pageNumbers);
        this.setState({ pageNumbers: pageNumbers, selectedDocType: this.state.seqDocumentTypes[0].name, activePageType: this.state.seqDocumentTypes[0].name });
    }

    /**
     * Adjusting page numbers of other types after delete
     * @param {String} delType 
     * @param {Number} noOfDelPages 
     */
    setPageNumbersAfterDel = async (delType, noOfDelPages) => {
        let typeFound = false;
        let pageNumbers = this.state.pageNumbers;
        let redirectType = null;
        let lastFoundDataIndex = -1;
        this.state.seqDocumentTypes.forEach((type, i) => {
            if (type && type.name && type.name === delType) {
                typeFound = true;
                pageNumbers[type.name] = { from: null, to: null };
            } else if (typeFound && pageNumbers[type.name].from) {
                let from = pageNumbers[type.name].from - noOfDelPages;
                let to = pageNumbers[type.name].to - noOfDelPages;
                if(type.name === 'Review & Finalize'){
                    from = null;
                    to = null;
                } else {
                    redirectType = type.name;
                }
                pageNumbers[type.name] = { from: from, to: to };
                
            } else if (pageNumbers[type.name].from) {
                lastFoundDataIndex = i
            }
        });

        if (!redirectType && lastFoundDataIndex > -1) {
            redirectType = this.documentTypes[lastFoundDataIndex].name;
        }
        this.updateMandatryRelTypes(pageNumbers);        
        await this.setState({ pageNumbers: pageNumbers, 
            delPages: false, 
            mergePdf: null, 
            selectedPage: pageNumbers[redirectType].from, 
            selectedDocType: redirectType,
            delMessage: `${delType} section deleted successfully.`,
            respMsgType: 'delSuccess',
            loading: false,
            showRespDialog: true,
            delType: null },this.setLoadedDocuments('delete',delType));
        //Checking how many sections loaded in builder. if only one section loaded hide the delete option.
        const count = this.getLoadedSecCount(pageNumbers);
        if (count <= 1) {
            this.setState({ delToggle: false });
        }
    }

    /**
     * Calculate the number of sections loaded in builder.
     * @param {Object} pageNumbers 
     * @returns {Number} number of sections loaded.
     */
    getLoadedSecCount = (pageNumbers) => {
        let count = 0;
        this.state.seqDocumentTypes.forEach((type) => {
            if (pageNumbers[type.name].from) {
                count++;
            }
        });
        return count;
    }


    /**
     * Function to trigger api calls to get files data from server on user click 'Yes' to load.
     */
    onClickLoadYes = () => {
        if (this.state.fileNamesToShow.length === 1) {
            this.getDocumentsData(this.state.fileNamesToShow);
        } else if (this.state.fileNamesToShow.length > 1) {
            this.setState({ getMultipleFiles: true });
            // this.getDocumentsData(this.state.fileNamesToShow);
        }

    }


    /**
     * Setting active page type into state to highlight the active Type button(chevron button)
     * @param {Number} pageIndex 
     */
    getActivePageType = (pageIndex) => {
        const pageNum = pageIndex + 1;
        for (const type in this.state.pageNumbers) {
            if (type !== 'Review & Finalize') {
                if (pageNum >= this.state.pageNumbers[type].from && pageNum <= this.state.pageNumbers[type].to) {
                    this.setState({ activePageType: type });
                }
            }
        }
    }

    closeDialog = () => {
        this.setState({ showModal: false })
    }

    /**
     * Show file name editing dialog on click Finalize 
     */
    onClickFinalize = async () => {
        let fileName = this.state.fileName;
        fileName = documentsAPIs.replaceRestrictedCharFromFileName(`${this.state.dealDetails.SellerNickName.trim()}-${STATE}-${REL_SELLER_NAME.trim()}${STATE_CLAIM_ID ? '-' + STATE_CLAIM_ID : '' }-CLM PCKT`);
        this.saveStatus = 'finalize-rename';
        this.updateInProgress = false;
        this.stopAutoUpdate = true;
        await this.setState({ showFileNameRestrictedCharError: false, showNameOptDialog: true, fileName: fileName, overWriteFile: false, fileWithSameNameExist: false });
    }

    saveClaimPacket = () => {
        if (this.pdfEditorRef.current) {            
            this.setState({ loading: true, showNameOptDialog: false });
            this.pdfEditorRef.current.saveToServer();
        }
    }

    uploadPdfDocument = async (file) => {
        //Checking draft with same name already exist or not       
        if (this.saveStatus === 'notSaved' && this.existingClaimDocs.length > 0) {
            const fileName = (this.state.fileName.trim().replaceAll(" ", "_")).replaceAll("/","_");            
            const index = this.existingClaimDocs.findIndex((elem) => elem.fileName === fileName);            
            if (index > -1) {
                this.setState({ fileWithSameNameExist: true, draftNameExist: true });
                this.documentId = this.existingClaimDocs[index] && this.existingClaimDocs[index].documentNumber ? this.existingClaimDocs[index].documentNumber : '';
                this.document_id = this.existingClaimDocs[index] && this.existingClaimDocs[index]._id ? this.existingClaimDocs[index]._id : '';
                return;
            }
        }
        await this.setState({ fileWithSameNameExist: false, draftNameExist: false });
        if (this.saveStatus === 'notSaved' || this.saveStatus === 'finalize' ) {
            this.addDocument(file);            
        } else if (this.saveStatus === 'auto' || this.saveStatus === 'update' || this.saveStatus === 'draftOverwrite' || this.saveStatus === 'finalize-replace' || this.saveStatus === 'finalize-rename') {            
            this.updateDocument(file, this.saveStatus === 'finalize-rename' ? true : false);
        }

    }

    addDocument = async (file) => {
        if(this.addInProgress){
            return;
        }
        this.addInProgress = true;        
        const fileName = documentsAPIs.formatteFileName(this.state.fileName,true);
        const resp = await documentsAPIs.saveDocumentDetails(this.getDataForDocument(fileName));
        if (resp && resp.outResponse) { 
            this.documentId = resp.outResponse.documentNumber ? resp.outResponse.documentNumber : null;
            this.document_id = resp.outResponse.documentId ? resp.outResponse.documentId : null;        
            this.saveStatus = this.saveStatus === 'notSaved' ? 'auto' : this.saveStatus;
            const encodedFileName = documentsAPIs.formatteFileName(this.state.fileName);
            this.uploadClaimPDF(file,resp,encodedFileName);            
        }else {
            this.addInProgress = false; 
        }

    }

    updateDocument = async (file,finalizeUpdate) => {
        
        if(this.stopAutoUpdate && !finalizeUpdate){            
            return;
        }
        if(this.updateInProgress){
            setTimeout(() => {
                this.updateDocument(file);
            }, 1000);
            return;
        }       
        this.updateInProgress = true;      
        const fileName = documentsAPIs.formatteFileName(this.state.fileName,true);
        const data = this.getDataForDocument(fileName);
        const resp = await documentsAPIs.updateDocumentRecord({ id: this.document_id, DocumentNumber: this.documentId, ...data });        
        if (resp && resp.outResponse) {
            const encodedFileName = documentsAPIs.formatteFileName(this.state.fileName);
            this.uploadClaimPDF(file,resp,encodedFileName);
        }else {
            this.updateInProgress = false; 
        }
        
    }

    uploadClaimPDF = async (file,resp, fileName) => {
        if (resp && resp.outResponse) {            
            const res = await documentsAPIs.uploadDocument(file, fileName, this.documentId, this.document_id, this.state.dealDetails);
            this.addInProgress = false;  
            this.updateInProgress = false;          
            if (res && res.respCode && res.respCode === config.successRespCode) {
                if(this.saveStatus === 'finalize-rename'){
                    this.updateFileNameSharepoint();
                }else {
                    this.onUploadSuccess();
                }
            }
        }

    }

updateFileNameSharepoint = () => {   
    fetchMethod('POST', `UpdatePDFDocument?FileName=${this.state.fileName}&recordId=${this.loadedClaimPktDetails._id}&OldFileName=${this.loadedClaimPktDetails.fileName}&FileId=${this.loadedClaimPktDetails.sharePointrecordID}&DocumentId=${this.loadedClaimPktDetails.documentNumber}`).then(async (res) => {
        if (res && res.resultCode && res.resultCode === config.successRespCode) {
            this.onUploadSuccess();            
        } else {
            this.showErrorMessage(res.errorMessage ? res.errorMessage : '');
        }
    });
}

    onUploadSuccess = () => {
        this.setState({ lastUpdated: moment(new Date()).tz(moment.tz.guess()).format("MM/DD/YY hh:mm A z") ,  loading: false});
        if (this.saveStatus === 'finalize' || this.saveStatus === 'finalize-replace' || this.saveStatus === 'finalize-rename') {
            this.saveStatus = 'auto';
            this.stopAutoUpdate = false;
            this.setState({ respMsgType: 'upSuccess', showRespDialog: true, loading: false, autoSave: true });
        }
       
       

    }    

    getDataForDocument = (fileName) => {
        return {
            "BusinessNameID": BUSINESSID,
            "DocumentTypeId": null,
            "MappedNameID": MAPPED_ID,
            "DealId": this.state.dealDetails.DealID,
            "DocumentSource": CLAIM_PKT_SOURCE,
            "FileName": documentsAPIs.formatteFileName(fileName,true,OPP_CLAIM_ID),
            "DocState": STATE,
            "DocStateClaimID":STATE_CLAIM_ID,
            "DocOPPClaimID":OPP_CLAIM_ID,
            "SharepointLocation": this.state.dealDetails.SellerNickName,
            "DealType": this.state.dealDetails.DealType,
            "DocumentRelevances": [
                {
                    "RelevanceTypeName": documentsAPIs.CLAIM_PKT,
                    "DocumentTypeName": documentsAPIs.CLAIM_PKT,
                    "RelevanceTypeId": this.claimPktRelId,
                    "DocumentTypeId": this.claimPktDocTypeId,
                    "StateClaimID": STATE_CLAIM_ID,
                    "State": STATE,
                    "OPPClaimID": OPP_CLAIM_ID,
                    "SubCategoryName":documentsAPIs.CLAIM_PKT,
                    "SubCategoryID": this.clmPktSubCatTypeId,
                    "StateUpFilingId":this.claimPktStateAttr._id
                }
            ]
        }
    }    

    toggle = () => {
        const loadedSecCount = this.getLoadedSecCount(this.state.pageNumbers);
        this.setState({ delToggle: loadedSecCount > 1 ? !this.state.delToggle : false });
        if (loadedSecCount === 1) {
            this.setState({ respMsgType: 'deleteDisabled', showRespDialog: true });
        }
    }

    /**
     * settting PDF total pages into state.
     * @param {Number} pages 
     */
    getTotalPages = (pages) => {
        this.setState({ totalPages: pages });
    }

    onSelectClaimPkt = async (i) => {
        this.saveStatus = 'update';
        await this.setState({ isUpdate: true, selectedClaimId: this.state.fileNamesToShow[i].id });
        this.loadedClaimPktDetails = this.state.fileNamesToShow[i];
        const lastUpdate = this.loadedClaimPktDetails.updatedDateUTC ? this.loadedClaimPktDetails.updatedDateUTC : this.loadedClaimPktDetails.createdDateUTC;
        if(lastUpdate){
            this.setState({lastUpdated: moment(new Date(lastUpdate)).tz(moment.tz.guess()).format("MM/DD/YY hh:mm A z")});
        }
        this.getDocumentsData([this.state.fileNamesToShow[i]]);        
    }

    /**
     * trigger replace file functionality on click yes to replace
     */
    onReplaceFile = () => {
        this.setState({ overWriteFile: true, loading: true, fileWithSameNameExist: false });
        if (this.saveStatus === 'finalize') {
            this.saveStatus = 'finalize-replace';
        }
        this.saveClaimPacket();
    }

    /**
     * Check if all mandatory files available fromserver
     * @param {String} docType 
     * @param {Array} respArry 
     */
    checkGotAllMandatoryFiles = (docType, respArry) => {        
        const reqMan = this.mandatoryRelTypes.filter((elem) => elem.docTypeName === docType);
        let notFound = false;
        if (respArry.length > 0) {
            reqMan.forEach((file) => {
                const index = respArry.findIndex((elem) => elem.relevanceType === file.relType);
                if (index === -1) {
                    notFound = true;
                }
            });
        } else {
            notFound = true;
        }
        if(!notFound && respArry.length > 0){
            this.checkDocSelectedOtherThanLoadedDocs(respArry);
            this.checkTheSectionDocsLoaded(respArry,docType);
        }
        this.setState({ disableLoadButton: notFound });
    }
    /**
   * Check if all mandatory files available fromserver for all sub categories
   * @param {String} docType 
   * @param {Array} respArry 
   */
    checkGotAllMandatoryFilesForSubCat = (docType, respArry) => {        
        let reqMan = []
        for (let man in this.state.mandatoryDocs) {
            if (this.state.mandatoryDocs[man]) {
                reqMan.push(man)
            }
        }
        let notFound = false;
        if (respArry && respArry.length > 0) {
            for (let reqMa of reqMan) {
                if (reqMa === documentsAPIs.CHAIN_OF_TITLE_DOC_TYPE) {
                    if (this.selCOTFiles.length === 0) {
                        notFound = true;
                    }
                } else {
                    const index = respArry.findIndex((elem) => elem.documentTypeName === reqMa && elem.sharePointDetails && elem.sharePointDetails.length > 0);
                    if (index === -1) {
                        notFound = true;
                    }
                }

            }
        } else {
            notFound = true;
        }
        if(!notFound && respArry.length > 0){
            this.checkDocSelectedOtherThanLoadedDocs(respArry);
            this.checkTheSectionDocsLoaded(respArry,docType);
        }
        this.setState({ disableLoadButton: notFound });
    }

    /**
     * Disabling/Enabling load button 
     * @param {Array} selDocuments 
     */
    checkDocSelectedOtherThanLoadedDocs = (selDocuments) => {
        let disableButton = true;        
        selDocuments.forEach((doc) => {
            if(doc && doc.documentNumber && !this.state.loadedDocuments[doc.documentNumber]){
                disableButton = false;
            }
        });        
        this.setState({ disableLoadButton: disableButton });
    }

    checkTheSectionDocsLoaded = (documents) => {        
        //Pushing all the loaded document ids into an array.
        let loadedDocIds = [];
        for(let prop in this.mandatoryTypes){  
            if (this.mandatoryTypes[prop] && this.mandatoryTypes[prop].length > 0) {
                loadedDocIds.push(this.mandatoryTypes[prop]);
                if(prop === documentsAPIs.CHAIN_OF_TITLE_DOC_TYPE){
                    loadedDocIds.push(this.state.COTResult.map((e) => e.documentNumber));
                }
            }
        }
        loadedDocIds = loadedDocIds.flat();

        //Check all the available documents already loaded or not.
        let isAllDocsLoaded = true;
        documents.forEach((record) => {
            if (loadedDocIds.includes(record.documentNumber)) {
                if (this.mandatoryTypes[record.documentTypeName]) {
                    this.mandatoryTypes[record.documentTypeName].push(record.documentNumber);
                }
            } else {
                isAllDocsLoaded = false;
            }
        });
        this.setState({ sectionLoaded: isAllDocsLoaded });
    }
   

    /**
     * Check whether all mandatory files loaded or not
     * @returns true or false
     */
    checkMandatoryFilesLoaded = () => {
        let allLoaded = true;
        for (const docType in this.mandatoryTypes) {
            if (this.mandatoryTypes[docType].length === 0) {
                allLoaded = false;
            }
        }
        return allLoaded;
    }

    /**
     * Updates mandatory status when loading existing claim packet. 
     * @param {Object} pageNum 
     */
    updateMandatryRelTypes = (pageNum) => {
        for (let i = 0; i < this.mandatoryRelTypes.length; i++) {
            const docType = this.mandatoryRelTypes[i].docType
            if (pageNum && pageNum[docType] && (pageNum[docType].from || pageNum[docType].from === 0)) {
                this.mandatoryRelTypes[i].isLoaded = true;
            } else {
                this.mandatoryRelTypes[i].isLoaded = false;
            }
        }
    }
    
    /**
     * Replacing the existing document
     */
    onClickReplace = () => {
        // if (this.state.draftNameExist) {
        //     this.saveStatus = 'draftOverwrite';
        //     this.setState({ fileWithSameNameExist: false });
        //     this.pdfEditorRef.current.saveToServer();
        // } else {
        //     this.onReplaceFile();
        // }
        this.doReplace = true;
        this.saveClaimPacket();
    }

    showHideChainOfTitle = (show, files) => {
        this.setState({ showChainOfTitle: show });
        if (files && files.length > 0) {
            this.selCOTFiles = this.state.COTResult.filter((res) => files.findIndex((e) => e.fileName === res.fileName) > -1);           
            this.setState({AddedCOT: true });
        }
        this.checkGotAllMandatoryFilesForSubCat(this.state.selDocTypes,this.state.fileNamesToShow);        
        this.documentSelectionRef.setCOTCount(this.selCOTFiles.length , this.state.AddedCOT);        
    }

    showHideProgressBar = (show) => {
        this.setState({ showProgressBar: show });
    }

    setLoadingStatus = (value) => {
        this.setState({ loadingCompleted: value });
    }

    /**
     * To check atleast one file selected for a connection. Enable Add button if selected or Disable Add button if not.
     * @param {Array} selectedFiles 
     */
    checkCOTFileSelection = (selectedFiles) => {
        let disable = false;
        for (let i = 0; i < this.chainOfTitleHir.length; i++) {
            const index = selectedFiles.findIndex((elem) => elem.businessNamesID === this.chainOfTitleHir[i].businessNamesID);
            if (index == -1) {
                disable = true;
            }
        }
        if (this.state.disableCOTAdd !== disable) {
            this.setState({ disableCOTAdd: disable });
        }
    }

    onClickCreateTL = () => {
        const paramString = window.location.href.slice(window.location.href.indexOf('?') + 1);
        window.open(`/transmittalLetter?${paramString}`);
    }

    /**
     * Save the pdf data into a varaible before inserting page numbers.
     * @param {ArrayBuffer} arrayBuffer 
     */
    savePDFBeforePageNumRender = (arrayBuffer) => {
        this.pdfBeforePageNum = arrayBuffer;
    }
    /**
     * Download pdf with page numbers to local machine & loads the pdf data without page numbers into editor.
     * @param {Blob} blob 
     * @param {ArrayBuffer} arrayBuffer 
     */
    savePdfToLocal = (blob, arrayBuffer) => {
        fs.saveAs(blob, documentsAPIs.formatteFileName(this.state.fileName,true,OPP_CLAIM_ID));
        this.setState({ documentUrl: this.pdfBeforePageNum, loading: false });
    }
    /**
     * On click download icon, starts adding page numbers & download to local functionality.
     */
    addPageNumbers = () => {
        if (this.pdfEditorRef.current) {
            this.setState({ loading: true });
            this.pdfEditorRef.current.addPageNumbers();
        }
    }

    /**
     * setting loaded documents in state for claim packet
     * @param {String} type 
     * @param {String} [docType]    
     */
    setLoadedDocuments = (type, docType) => {               
        if (this.state.selectedDocType === documentsAPIs.CLAIM_PKT || !this.state.selectedDocType) {
            return;
        }
        let loadedDocs = JSON.parse(JSON.stringify(this.state.loadedDocuments));        
        if (type === 'add') {
            for (let i = 0; i < this.recentlyLoadedDocs.length; i++) {
                if (this.recentlyLoadedDocs[i].sharePointDetails && this.recentlyLoadedDocs[i].sharePointDetails.length > 0) {
                    if (!loadedDocs[this.recentlyLoadedDocs[i].documentNumber]) {
                        loadedDocs[this.recentlyLoadedDocs[i].documentNumber] = this.recentlyLoadedDocs[i].documentTypeName;
                    }
                }
            }
        } else if(type === 'delete' && docType){
            let docTypes = (this.state.stateAttributes.filter((cat) => (cat.SubCategory === docType && cat.SubCategory))).map((elem) => elem.DocumentTypeName);
            docTypes.forEach((docTypeName) => {
                for(let docNum in this.state.loadedDocuments){
                    if(this.state.loadedDocuments[docNum] === docTypeName){
                        delete loadedDocs[docNum];
                    }
                }
                if(this.mandatoryTypes[docTypeName]){
                    this.mandatoryTypes[docTypeName] = [];
                }
            })
        }           
        this.setState({ loadedDocuments: loadedDocs },() => {
            if(this.pdfEditorRef.current){                
                this.pdfEditorRef.current.addLoadedDocRelAnnotation(this.mandatoryTypes);
            }
        });
    }

    setLoadedDocsFromExistingDoc = (loadedDocs,loadedDocTypes) => {        
        this.setState({ loadedDocuments: loadedDocs });
        if (loadedDocTypes) {
            this.mandatoryTypes = loadedDocTypes;
        }
    }
        
    saveDocument = async (pdfFile, type) => {
        //Checking if file Name contains restricted special characters
        const restrictCharExist = documentsAPIs.checkFileNameForRestrictedChar(this.state.fileName);        
        if (restrictCharExist) {
            this.setState({ showFileNameRestrictedCharError: true,loading:false });
            return;
        }
        // Checking if a document exist with the same name for the deal in the system.
        const existedDocDetails = await documentsAPIs.checkDocumentExist(JSON.parse(JSON.stringify(this.state.fileName)), this.state.dealDetails.DealID, documentsAPIs.CLAIM_PKT, documentsAPIs.CLAIM_PKT, OPP_CLAIM_ID);
        if (existedDocDetails && (!this.loadedClaimPktDetails || (this.loadedClaimPktDetails._id !== existedDocDetails._id)) && !this.doReplace ) {
            this.setState({ fileWithSameNameExist: true ,loading:false}); // If it is not replace or update functionality show user that document with file name already exist
            return;
        } else {
            this.setState({ fileWithSameNameExist: false });            
        }
        // If user selected replace functionality save the current document '_id' so to do delete functionality after successful replace. 
        let deleteDocId = null ;
        if (this.doReplace) {
            deleteDocId = this.loadedClaimPktDetails && this.loadedClaimPktDetails._id ? this.loadedClaimPktDetails._id : null;
            this.loadedClaimPktDetails = existedDocDetails; // For replace functionality - loading the found or the document to be replace details as current document to do update. 
        }
        const data = this.getDataForDocument(this.state.fileName);
        let docsSaveResp = null;        
        const fileName = documentsAPIs.formatteFileName(this.state.fileName,false,OPP_CLAIM_ID);
        const OldFileName = this.loadedClaimPktDetails.fileName ?  this.loadedClaimPktDetails.fileName : fileName;
        const doSharePointFileNameUpdate = fileName == OldFileName ? false : true; // checking file name changed or not.
        if (this.loadedClaimPktDetails && this.loadedClaimPktDetails._id) { // update documents record functionality
            docsSaveResp = await documentsAPIs.updateDocumentRecord({ id: this.loadedClaimPktDetails._id, DocumentNumber: this.loadedClaimPktDetails.documentNumber, ...data });
        } else {
            docsSaveResp = await documentsAPIs.saveDocumentDetails(data);   // Create document record functionality                   
        }
        // Showing error message in case of failure
        if (docsSaveResp && ((docsSaveResp.respCode && docsSaveResp.respCode !== config.successRespCode) || !docsSaveResp.respCode)) {
            this.showRespMessage('danger', docsSaveResp.errorMessage ? docsSaveResp.errorMessage : documentsAPIs.STATIC_DOCS_ERROR_MSG);  
            return;
        } 
        if (docsSaveResp && docsSaveResp.outResponse && docsSaveResp.outResponse.response) {
            this.loadedClaimPktDetails = docsSaveResp.outResponse.response;
            if (!this.loadedClaimPktDetails._id) {
                this.loadedClaimPktDetails._id = this.loadedClaimPktDetails.id;
            }
        }
        //Upload document to the share point.         
        const sharePointUploadResp = await documentsAPIs.uploadDocument(pdfFile, fileName, this.loadedClaimPktDetails.documentNumber, this.loadedClaimPktDetails._id, this.state.dealDetails);
        if (sharePointUploadResp && sharePointUploadResp.respCode && sharePointUploadResp.respCode === config.successRespCode) {
            if(doSharePointFileNameUpdate){ // Update file name in share point if file name changes
                const sharePointNameUpdateResp = await documentsAPIs.updateFileNameSharepoint(fileName,OldFileName,this.loadedClaimPktDetails._id,this.loadedClaimPktDetails.sharePointrecordID,this.loadedClaimPktDetails.documentNumber);
                if (sharePointNameUpdateResp && (!sharePointNameUpdateResp.resultCode || sharePointNameUpdateResp.resultCode !== config.successRespCode) ) {
                    this.showRespMessage('danger', sharePointUploadResp.errorMessage ? sharePointUploadResp.errorMessage : documentsAPIs.STATIC_DOCS_ERROR_MSG);
                    return;
                }
            }
        } else {
            this.showRespMessage('danger', sharePointUploadResp.errorMessage ? sharePointUploadResp.errorMessage : documentsAPIs.STATIC_DOCS_ERROR_MSG);
        }        
        if(deleteDocId){ // Delete document details in case of replace functionality 
            const res = await documentsAPIs.deleteDocument(deleteDocId);
        }
        this.showRespMessage('success', `${this.state.fileName}__${OPP_CLAIM_ID}.pdf saved successfully.`);      
    }

    showRespMessage = (type, message) => { 
        this.doReplace = false;       
        this.setState({ response: { type: type, message: message }, loading: false });
        if(type === 'success'){
            this.setState({lastUpdated: moment(new Date()).tz(moment.tz.guess()).format("MM/DD/YY hh:mm A z")});
        }
        setTimeout(() => { this.hideRespMessage() }, 1000 * 60 * 10);
    }
    hideRespMessage = () => {
        this.setState({ response: { type: '', message: '' } })
    }

    getFileNameWithoutOPPId = (fileName) => {
        const splitFileNameArray = JSON.parse(JSON.stringify(fileName)).split('__');
        if (splitFileNameArray[splitFileNameArray.length - 1] && splitFileNameArray[splitFileNameArray.length - 1] === `${OPP_CLAIM_ID}.pdf`) {
            splitFileNameArray.pop();
            return splitFileNameArray.join('__');
        } else {
            return fileName;
        }
    }

    render() {

        return (
            <>
                <div className="claimPdf ">

                    {/* Top info row starts. */}
                    <div className='claimInfo'>
                        <div className='elem-border deal'>
                            <strong>Deal:</strong>&nbsp;&nbsp;
                            {this.state.dealDetails.SellerNickName && this.state.dealDetails.SellerNickName.length > 50 ?
                                `${this.state.dealDetails.SellerNickName.slice(0, 49)}...`
                                : this.state.dealDetails.SellerNickName}
                        </div>
                        <div className='elem-border rel-slr-name'>
                            <strong>Relevant Seller Name:</strong>&nbsp;&nbsp;
                            {REL_SELLER_NAME && REL_SELLER_NAME.length > 75 ? `${REL_SELLER_NAME.slice(0, 74)}...` : REL_SELLER_NAME}
                        </div>

                        <div className='elem-border state'>
                            <strong>State:</strong>&nbsp;&nbsp;
                            {STATE}
                        </div>
                        <div className='elem-border claim-id'>
                            <strong>St Claim ID:</strong>&nbsp;&nbsp;
                            {STATE_CLAIM_ID}
                        </div>
                        <div className='elem-border claim-value'>
                            <strong>Claim Value:</strong>&nbsp;&nbsp;
                            {CLAIM_VALUE && CLAIM_VALUE !== 'null' ? `$${(parseFloat(CLAIM_VALUE)).toLocaleString('en-US', { maximumFractionDigits: 2 })}` : ''}
                        </div>
                        <div className='elem-border last-saved'>
                            <strong>Last Saved:</strong>&nbsp;&nbsp;
                            {this.state.lastUpdated}
                        </div>
                        <div className='actions'>
                        <FontAwesomeIcon
                                icon={faSave}
                                className='mr-3'
                                color={'#354f6e'}
                                data-toggle="tool-tip"
                                id="savePDF"
                                title="Save"
                                onClick={() => this.saveClaimPacket()}
                                style={{ fontSize: '17px', cursor: 'pointer' }}
                            />
                             <FontAwesomeIcon
                                icon={faDownload}
                                className='mr-3'
                                color={'#354f6e'}
                                data-toggle="tool-tip"
                                id="downloadPdf"
                                title="Download PDF"
                                onClick={() => this.addPageNumbers()}
                                style={{ fontSize: '17px', cursor: 'pointer' }}
                            /> 



                            <span className={`topbar__link-icon lnr lnr-trash mr-0`} data-toggle="tool-tip" style={{ fontSize: "17px", color: "#354f6e", cursor: 'pointer' }}
                                onClick={() => {
                                    this.toggle();
                                }} />
                        </div>

                    </div>
                    {this.state.response.type && <Alerts type={this.state.response.type} message={this.state.response.message} changeStatus={this.hideRespMessage} />}
                    {/* Top info row ends. */}

                    {/* Document Type navigation/steps (chevron buttons) starts. */}
                    <div className='claimNav flex-wrap'>
                        {
                            this.state.seqDocumentTypes.map((type, i) => {
                                return <div key={`${type.name}-${i}`}
                                    className={`mb-1 mt-1 ${i === 0 ? 'claim-button-start' : 'claim-button'}`}
                                    style={{ backgroundColor: (this.state.pageNumbers[type.name].from) ? '#bbd9ff' : '#eeeeee', width: type.chWidth }}
                                    onClick={() => { this.onclickDocumentType(type.name, type.category) }}><div></div>

                                    <span style={{ fontSize: 'smaller' }}>{type.name === 'Review & Finalize' ? 'Review' : type.name}</span><br></br>
                                    <div className='claim-button-sub-wrap'>

                                        <div className='page-numbers'>(
                                            {this.state.pageNumbers[type.name].from === null ? 'None' :
                                                <span><a onClick={(e) => { this.onClickPageNumber(e, `${this.state.pageNumbers[type.name].from}`) }}>{this.state.pageNumbers[type.name].from}</a> - <a onClick={(e) => { this.onClickPageNumber(e, `${this.state.pageNumbers[type.name].to}`) }}>{this.state.pageNumbers[type.name].to}</a></span>
                                            })
                                        </div>
                                        <div className='trash-icon'>
                                            {(type.delOption && this.state.delToggle && this.state.pageNumbers[type.name].from) &&
                                                <FontAwesomeIcon
                                                    onClick={(e) => { this.onClickDelete(e, type.name) }}
                                                    icon={faTrash} />
                                            }
                                        </div>
                                    </div>

                                </div>


                            })
                        }

                    </div>

                    {/* Document Type navigation/steps (chevron buttons) ends. */}

                    {this.state.selectedDocType === 'Review & Finalize' ?
                        <div className='mt-3 text-center'>
                            <Button color='primary' onClick={() => this.onClickFinalize()} disabled={this.state.loading}>Finalize</Button>
                        </div> : null

                    }


                    {/* PDF Editor starts.  */}
                    <div className='pdf-viewer'>
                        {/* Guidelines ovelay starts.Hiding for now  */}
                        {/* <div className='left-overlay'>
                            <Guidelines
                                activePageType={this.state.activePageType}
                                guidelines={this.state.guidelines}
                            />
                        </div> */}
                        {/* {this.state.chainOfTitleData.length > 0 && this.state.activePageType === 'Transmittal Letter' &&
                            <div className='top-overlay'>
                                <ChainOfTitleGuide
                                    chainOfTitleData={this.state.chainOfTitleData}
                                />
                            </div>
                        } */}
                        {/* Guidelines ovelay ends  */}

                        {this.state.documentUrl &&
                            <CommonPdfEdiitor
                                ref={this.pdfEditorRef}
                                pdfDoc={this.state.documentUrl}
                                selectedPage={this.state.selectedPage}
                                setPageNumbers={this.setPageNumbers}
                                mergePdf={this.state.mergePdf}
                                mergePdfBlobUrl={this.state.mergePdfBlobUrl}
                                toggleLoader={this.toggleLoader}
                                getActivePageType={this.getActivePageType}
                                selectedDocType={this.state.selectedDocType}
                                uploadPdfDocument={this.saveDocument}
                                autoSave={false}
                                subscribeForPageNum={true}
                                fileName={this.state.fileName}
                                pageNumbers={this.state.pageNumbers}
                                delType={this.state.delType}
                                delPages={this.state.delPages}
                                setPageNumbersAfterDel={this.setPageNumbersAfterDel}
                                getTotalPages={this.getTotalPages}
                                isUpdate={this.state.isUpdate}
                                setPageNumbersFromBookmarks={this.setPageNumbersFromBookmarks}
                                createBookmarks={true}
                                setLoadingStatus={this.setLoadingStatus}
                                topButtonSeq={this.state.topButtonSeq}
                                setSequenceFromAnnotations={this.setSequenceFromAnnotations}
                                savePdfToLocal={this.savePdfToLocal}
                                savePDFBeforePageNumRender={this.savePDFBeforePageNumRender}
                                loadedDocuments={this.state.loadedDocuments}
                                setLoadedDocsFromExistingDoc={this.setLoadedDocsFromExistingDoc}
                                paramsDetails = {{state:STATE,stateClaimID:STATE_CLAIM_ID,mappedName:MAPPED_NAME}}
                                showPageNum={true}
                            />
                        }
                    </div>
                    {/* PDF Editor ends.  */}
                    {this.state.showTLetterEditor &&
                        <TransmittalLetter
                            getChainOfTitle={this.getChainOfTitle}
                            chainOfTitleData={this.state.chainOfTitleData} />

                    }
                </div>

                {/* Dialog/popup for File selecting, order arranging starts. */}
                <Dialog header={this.state.selectedDocType} visible={this.state.showModal} style={{ minWidth: '70vw', maxWidth: '95vw' }} modal={true} onHide={() => this.closeDialog()}>

                    <div className='text-center' style={{ maxHeight: '90vh', overflowY: 'auto' }}>

                        {
                            (this.state.fileNamesToShow.length === 0 && this.state.selDocTypes.length === 1) ?
                                <h6>No Files Found.</h6> :
                                ((this.state.fileNamesToShow.length === 1 && this.state.fileNamesToShow[0] && this.state.selDocTypes.length === 1 && this.state.selectedDocType !== documentsAPIs.ADDRESS_EVIDENCE)  ) ?
                                    <div>
                                        <h6>Load {this.state.selectedDocType} <span className='claim-file-color'>{this.state.fileNamesToShow[0].fileName}</span> ?</h6>
                                        {this.state.loadedDocuments[this.state.fileNamesToShow[0].documentNumber] && <div>(Document already loaded for {this.state.loadedDocuments[this.state.fileNamesToShow[0].documentNumber]})</div>}

                                    </div> :

                                    ((this.state.fileNamesToShow.length > 1 || this.state.selDocTypes.length > 1) && this.state.selectedDocType !== documentsAPIs.ADDRESS_EVIDENCE) ?
                                        
                                        <DocumentSelection
                                            onRef={(ref) => this.documentSelectionRef = ref}
                                            documentList={this.state.fileNamesToShow}
                                            getMultipleFiles={this.state.getMultipleFiles}
                                            getDocumentsData={this.getDocumentsData}
                                            selectedDocType={this.state.selectedDocType}
                                            selRelTypes={this.state.selRelTypes}
                                            selDocTypes={this.state.selDocTypes}
                                            SubCatDesc={this.state.SubCatDesc}
                                            mandatoryDocs={this.state.mandatoryDocs}
                                            checkGotAllMandatoryFiles={this.checkGotAllMandatoryFilesForSubCat}
                                            checkDocSelectedOtherThanLoadedDocs={this.checkDocSelectedOtherThanLoadedDocs}
                                            getChainOfTitle={this.getChainOfTitle}
                                            noCOTFound={this.state.noCOTFound}
                                            showModal={this.state.showModal}
                                            loadedDocuments={this.state.loadedDocuments}

                                        /> :
                                        (this.state.selectedDocType === documentsAPIs.ADDRESS_EVIDENCE) ? 
                                            <AddressEvid
                                                documents={this.state.fileNamesToShow}
                                                getDocumentsData={this.getDocumentsData}
                                                showModal={this.state.showModal}
                                                closeModal={() => this.setState({ showModal: false })}
                                                loadedDocuments={this.state.loadedDocuments}
                                            />
                                        :
                                        null

                        }
                        {this.state.sectionLoaded ?
                            <div className='mt-2'>
                                <h5>All documents needed for {this.state.selectedDocType} are already present in the Claim Packet</h5>
                                <Button color='primary' outline onClick={() => this.setState({ showModal: false })} >Ok</Button>
                            </div>
                            :
                            <>
                                {this.state.selectedDocType !== documentsAPIs.ADDRESS_EVIDENCE &&
                                    <div className='mt-4'>
                                        {this.state.fileNamesToShow.length > 0 && <Button color='primary' outline onClick={() => { this.onClickLoadYes() }} disabled={this.state.loading || this.state.disableLoadButton || (this.state.selectedDocType === "SLR CONNCTN EVID" && !this.state.AddedCOT)}>Load</Button>}
                                        {/* {(this.state.fileNamesToShow.length === 0 && this.state.selectedDocType === 'Transmittal Letter') && <Button color='primary' outline onClick={() => { this.onClickCreateTL() }} >Create</Button>} */}
                                        <Button color='primary' outline onClick={() => this.setState({ showModal: false })} disabled={this.state.loading}>{this.state.fileNamesToShow.length > 0 ? 'Cancel' : 'Close'}</Button>

                                    </div>
                                }
                                {(this.state.disableLoadButton && this.state.fileNamesToShow.length > 1) && <span>Please note that Load button will be enabled once all mandatory documents are available.</span>}
                                {(this.state.selectedDocType === "SLR CONNCTN EVID" && !this.state.disableCOTAdd) && <span>The Load button will remain disabled until all Chain Of Title documents are available.</span>}
                            </>
                        }
                    </div>
                    <Loader loader={this.state.loading} ></Loader>
                </Dialog>
                {/* Dialog/popup for File selecting, order arranging ends. */}
                {/* Showing Filename */}
                <Dialog header={'Claim Packet Name'} closable={false} closeOnEscape={false} visible={this.state.showNameOptDialog} style={{ minWidth: '40vw', maxWidth: '75vw' }} modal={true} onHide={() => this.setState({ showNameOptDialog: false })}>
                    <div className='p-2'>
                        <div className='d-flex'>
                            <div className="p-inputgroup w-100">
                                <InputText value={this.state.fileName} onChange={(e) => this.setState({ fileName: e.target.value })} style={{ width: '75%' }} />
                                <span className="p-inputgroup-addon">{`__${OPP_CLAIM_ID}.pdf`}</span>
                            </div>
                        </div>
                        {this.state.showFileNameRestrictedCharError && <strong className='text-danger'>{documentsAPIs.RESTRICT_FILE_NAME_CHAR_ERROR}</strong>}
                        <div className='text-center mt-4'>
                            <Button color='primary' outline onClick={() => this.saveClaimPacket()} >Save</Button>
                            <Button color='primary' outline onClick={() => this.setState({ showNameOptDialog: false })} >Close</Button>
                        </div>
                    </div>

                </Dialog>

                <Dialog header={'File name already existed'} closable={false} closeOnEscape={false} visible={this.state.fileWithSameNameExist} style={{ minWidth: '40vw', maxWidth: '55vw' }} modal={true} onHide={() => this.setState({ fileWithSameNameExist: false })}>
                    <div className='p-2'>
                        <div className='d-flex'>
                            {
                                this.state.draftNameExist ?
                                    <span>System trying to save the document with the file name &nbsp;<strong className='claim-file-color'>{this.state.fileName}</strong>&nbsp; which is already exist. Do you want to replace it or rename the file?</span> :
                                    <span>Document with the file name &nbsp;<strong>{`${this.state.fileName}__${OPP_CLAIM_ID}.pdf`}</strong>&nbsp; already exist.</span>
                            }

                        </div>
                        <div className='text-center mt-4'>
                            <Button color='primary' outline onClick={() => this.setState({ fileWithSameNameExist: false, showNameOptDialog: true })} >{ 'Rename' }</Button>
                            <Button color='primary' outline onClick={() => { this.onClickReplace() }} >{'Replace' }</Button>
                        </div>
                    </div>

                </Dialog>
                {/* Dialogue for showing simple responses that doesn't require any actions */}
                <Dialog header={'Claim Builder'}  visible={this.state.showRespDialog} style={{ minWidth: '40vw', maxWidth: '75vw' }} modal={true} onHide={() => this.setState({ showRespDialog: false })}>
                    <div className='p-2 '>
                        <div className='d-flex justify-content-center'>
                            {this.state.respMsgType === 'upSuccess' && <h5> Claim Packet <span className='claim-file-color'>{this.state.fileName}</span> uploaded sucessfully. </h5>}
                            {this.state.respMsgType === 'loadMandatory' && <h5> Please load all mandatory files before review and finalizing. </h5>}
                            {this.state.respMsgType === 'delSuccess' && <h5> {this.state.delMessage} </h5>}
                            {this.state.respMsgType === 'invalidFileData' && <h5> Error! Invalid File. Please contact the OPRA2 team. </h5>}
                            {this.state.respMsgType === 'deleteDisabled' && <h4> Delete is disabled as there is only one section loaded in claim builder. </h4>}
                        </div>
                        <div className='text-center mt-4'>
                            <Button color='primary' outline onClick={() => this.setState({ showRespDialog: false })} >Close</Button>
                        </div>

                    </div>

                </Dialog>
                <Dialog header={'Claim Builder'} visible={this.state.showClaimPktDocs} style={{ minWidth: '40vw', maxWidth: '75vw' }} modal={true} onHide={() => this.setState({ showClaimPktDocs: false })}>
                    <div className='p-2 ' style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                        <div >
                            
                            {(this.state.fileNamesToShow && this.state.fileNamesToShow.length > 0) ? <>
                                <table class="table table-bordered" >
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>File Name</th>
                                        <th>Updated</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.fileNamesToShow.map((doc, i) => {
                                    return <tr>
                                        <td>
                                            <RadioButton inputId={`${doc._id}${i}`} name="claimPacketDocs" value={i} onChange={(e) => this.setState({ selectedClaimPkt: i })} checked={this.state.selectedClaimPkt === i} />
                                        </td>
                                        <td>{doc.fileName}</td>
                                        <td>{dateFormats.formatDate(doc.updatedDateUTC, "MM/DD/YYYY hh:mm A", "nhg")}</td>
                                        <td>{dateFormats.formatDate(doc.createdDateUTC, "MM/DD/YYYY hh:mm A", "nhg") }</td>                                         
                                    </tr>
                                })}
                                   
                                </tbody>
                            </table>
                                
                            </>
                                : <div className='text-center'>There is no claim packet documents found.</div>}
                        </div>
                        <div className='text-center mt-4'>
                            {(this.state.fileNamesToShow && this.state.fileNamesToShow.length > 0) &&
                                <Button color='primary' outline onClick={() => this.onSelectClaimPkt(this.state.selectedClaimPkt)} >Load</Button>
                            }
                            <Button color='primary' outline onClick={() => this.setState({ showClaimPktDocs: false })} >Cancel</Button>
                        </div>

                    </div>

                </Dialog>

                <Dialog header={'Chain Of Title'} visible={this.state.showChainOfTitle} style={{ width: '90vw' }} modal={true} onHide={() => this.showHideChainOfTitle(false)}>
                    <ChainOfTitle
                        showChainOfTitle={this.state.showChainOfTitle}
                        chainOfTitleData={this.state.chainOfTitleData}
                        disableCOTAdd={this.state.disableCOTAdd}
                        loadClaimPacket={this.loadClaimPacket}
                        showHideChainOfTitle={this.showHideChainOfTitle}
                        cotHirCount={this.state.cotHirCount}
                        checkCOTFileSelection={this.checkCOTFileSelection}
                        loadedDocuments={this.state.loadedDocuments}
                    />                    
                </Dialog>

                <ProgressResp
                    showProgressBar={this.state.showProgressBar}
                    loadingCompleted={this.state.loadingCompleted}
                    selectedDocType={this.state.selectedDocType}
                    progressType={this.state.progressType}
                    showHideProgressBar={this.showHideProgressBar}
                />


                <Loader loader={this.state.loading} ></Loader>
            </>
        )
    }
}