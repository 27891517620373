// eslint-disable no-console, no-control-regex
import React from 'react';
import {
  Button,
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import classNames from 'classnames';
import { CSVLink } from "react-csv";
import { Calendar } from 'primereact/calendar';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import ShowHideColumnsModal from '../Cruds/CommonModals/ShowHideColumnsModal'
import { Column } from 'primereact/column';
import ExpiredSessionModal from '../Cruds/CommonModals/ExpiredSessionModal';
import { withTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import PaginatorComponent from '../Cruds/CommonDataTable/PaginatorComponent';
import ConfirmaionModels from '../Cruds/CommonModals/isApplyFilter';

import Checkbox from '../../shared/components/form/CheckBox';

//config
import config from '../../config/config';
import apiCalls from '../../config/apiCalls';
import configMessages from '../../config/configMessages';
import fetchMethodRequest from '../../config/service';

//components 

import dateFormats from '../UI/FormatDate/formatDate';
import validate from '../Validations/validate';

// Loader
import Loader from '../App/Loader';

import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
let timeout

// const ruleEngineIndex = require('../../rule-engine/js/ruleengine.build')

class FormModal extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      filterCriteria: {
        limit: 50, page: 1, criteria: [],
        sortfield: 'CompanyBuySideID',
        direction: 'desc'
      },
      selectFilterValue: "",
      sessionExpiryModal: false,
      sessionWarning: false,
      exportData: [],
      userName: '',
      expandAll: false,
      selectsearchFilterValue: "",
      holding: [],
      investorStatus: [],
      investorType: [],
      filterSelect: "",
      HoldingFilter: false,
      InvestorStatusFilter: false,
      InvestorTypeFilter: false,
      searchFilterCriteria: [],
      userData: '',
      activeTab: "1",
      activitiesData: [],
      totalRecordsLength: 0,
      first: 0,
      rows: 10,
      navTabs: ["Company", "Acquisition", "Affiliate/Subsidiary", "Investments", "Investors", "News", "Contacts", "Names/Address", "Pre APA"],
      // navTabs: ["Company", "Acquisition", , "Investments", "Investors", "Contacts", "Names/Address", "Pre APA"],
      statusFilterCriteria: {
        criteria: [{
          key: "activeIndicator",
          value: true,
          type: "eq"
        }]
      },
      dataChangeOptionswithFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' },

        // { "label": "Hide Column", "value": 'hide' },
        // { "label": "Show Column", "value": 'show' },
      ],
      dataChangeOptionswithoutFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        // { "label": "Hide Column", "value": 'hide' },
        // { "label": "Show Column", "value": 'show' },
      ],
      investorCompany: {}
    };
  }

  componentDidUpdate() {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
    }
    if (!this.state.errorResponse && localStorage.getItem('serverError') === "true") {
      localStorage.removeItem('serverError')
    }
    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }
  }

  componentWillReceiveProps = async (nextProps) => {
    // if (tabId[3] && this.state.activeTab !== tabId[3]) {
    //   await this.setState({
    //     activeTab: tabId[3],
    //     tabName: tabId[4] === "Affiliate" ? "Affiliate/Subsidiary"
    //       : tabId[4] === "Address" ? "Names/Address"
    //         : tabId[4]
    //   })
    //   console.log("route changed")
    //   this.getActivities()
    // }
    // else {
    //   await this.setState({
    //     activeTab: 1,
    //   })
    //   this.getchildCompanyDetails(tabId[2])
    // }
  }

  componentDidMount = async () => {
    let sessionexpired = await localStorage.getItem('sessionexpired')
    if (sessionexpired === "true") {
      await this.setState({ sessionExpiryModal: true })
    }
    // localStorage.setItem("SearchedCompany", this.state.selectFilterValue)
    if (localStorage.getItem("SearchedCompany") && localStorage.getItem("SearchedCompany").length > 0) {
      document.getElementById("globalSearchcompany").value = localStorage.getItem("SearchedCompany")
    }
    // await this.setState({ selectFilterValue: localStorage.getItem("SearchedCompany") ? localStorage.getItem("SearchedCompany") : "" })
    let tabId = window.location.pathname.split("/")
    // if (companyId) {
    //   userData.CompanyID = companyId
    //   this.setState({
    //     userData: userData
    //   })
    //   this.getchildCompanyDetails(companyId)
    // }
    if (tabId[3] && this.state.activeTab !== tabId[3]) {
      this.setState({
        activeTab: tabId[3],
        tabName: tabId[4] === "Affiliate" ? "Affiliate/Subsidiary"
          : tabId[4] === "Address" ? "Names/Address"
            : tabId[4]
      })
      if (!this.state.userIDval) {
        this.getchildCompanyDetails(tabId[2])
      }
      this.getActivities()
    } else {
      this.setState({
        activeTab: 1,
      })
      this.getchildCompanyDetails(tabId[2])
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
    this.unlisten = this.props.history.listen((location, action) => {
      let tabId = window.location.pathname.split("/")
      if (tabId[3] && this.state.activeTab !== tabId[3]) {
        this.setState({
          activeTab: tabId[3],
          tabName: tabId[4] === "Affiliate" ? "Affiliate/Subsidiary"
            : tabId[4] === "Address" ? "Names/Address"
              : tabId[4]
        })
        this.getActivities()
      }
      else {
        this.setState({
          activeTab: 1,
        })
        this.getchildCompanyDetails(tabId[2])
      }
    })
  }

  onPageChange = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      await this.setState({
        rows: event.rows,
        page: event.page,
        first: event.first,
        // expandAll: false,
        expandedRows: null

      })
    }
    this.getActivities('', this.state.filterCriteria)
  }

  getTableFields = () => {
    let companyType = localStorage.getItem("CompanyType")
    let data
    if ((this.props.type === "Company" && this.state.activeTab === "2") || this.state.shownTable === "Investor") {
      data = this.props.getAcquisitionFields()
    } else if (this.props.type === "Company" && this.state.activeTab === "3") {
      if (companyType === "Public") {
        data = this.props.getPublicAffiliateCompanies()
      } else {
        data = this.props.getAffiliatesFields()
      }
    } else if ((this.props.type === "Company" && (this.state.activeTab === "4" || this.state.activeTab === "5")) || this.props.type === "Investor") {
      data = this.getActivetab4Tablefields()
    } else if (this.props.type === "Company" && this.state.activeTab === "6") {
      data = this.props.getNewsFields()
    } else if (this.props.type === "Company" && this.state.activeTab === "7") {
      data = this.props.getContactsFields()
    } else if (this.props.type === "Company" && this.state.activeTab === "8") {
      data = this.props.getNamesAddressFields()
    } else if (this.props.type === "Company" && this.state.activeTab === "9") {
      if (companyType === "Public") {
        data = this.props.getPublicCompanies()
      } else {
        data = this.props.getPreAPAFields()
      }
    }
    return data;
  };
  getActivetab4Tablefields() {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'CompanyID',
        getField: "CompanyID",
        fieldType: (this.state.tabName && this.state.tabName === "Investments") || this.props.type === "Investor" ? 'ParentCompany' : "",
        header: 'Company ID',
        filter: true,
        sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'CompanyName',
        header: 'Company Name',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'InvestorID',
        getField: "InvestorID",
        fieldType: this.state.tabName && this.state.tabName === "Investors" ? 'ParentCompany' : "",
        header: 'Investor ID',
        filter: true,
        sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'InvestorName',
        header: 'Investor Name',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        filterType: "num",
        field: 'InvestorSince',
        fieldType: 'Date',
        "dateFormat": 'MM-DD-yyyy',
        type: 'date',
        header: 'Investor Since',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'Holding',
        header: 'Holding',
        filterElement: this.state.holding,
        filter: true,
        // filter: false,
        sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 165,
        field: 'InvestorStatus',
        header: 'Investor Status',
        filter: true,
        // filter: false,
        filterElement: this.state.investorStatus,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 165,
        field: 'InvestorType',
        header: 'Investor Type',
        // filterElement: [{ "label": "All", "value": null }, { "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
        filterElement: this.state.investorType,
        filter: true,
        // filter: false,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'InvestorWebsite',
        fieldType: 'website',
        header: 'Investor Website',
        filter: true,
        sortable: false
      },
    ];
    return data;
  }

  getPaginator() {
    return (
      <PaginatorComponent
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows ? this.state.rows : this.state.filterCriteria["limit"]}
        onPageChange={this.onPageChange}
        isWeb={true}
      />
    )
  }

  getlistApiCall = async (id, filterCriteria, forFilter) => {
    let CompanyType = localStorage.getItem("CompanyType")
    let url, dataObj, str, key, apiUrl, sortField;
    let tabId = window.location.pathname.split("/")
    str = tabId[2]
    if ((this.props.type === "Company" && this.state.activeTab === "2") || this.state.shownTable === "Investor") {
      let filterCriteria = this.state.filterCriteria
      filterCriteria['criteria'] = filterCriteria.criteria
      filterCriteria['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "CompanyBuySideID"
      url = `${apiCalls.ChildCompanies}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
      dataObj = "childCompanies"
      // }
    } else if ((this.props.type === "Company" && this.state.activeTab === "4") || this.props.type === "Investor") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "InvestorID"
      filter['criteria'] = filterCriteria && filterCriteria.criteria
        ? filterCriteria.criteria
        : [{ key: "InvestorID", value: str, type: 'eq', add_Filter: true }];
      if (forFilter || this.state.searchFilterCriteria.length > 0) {
        for (let searchCriteria of this.state.searchFilterCriteria) {
          if (searchCriteria.value.length > 0) {
            filter['criteria'].push({
              key: searchCriteria.key,
              value: searchCriteria.value,
              type: 'in'
            });
          }
        }
      }
      if (!forFilter) {
        await this.setState({ holding: [], investorStatus: [], investorType: [] })
      }
      await this.setState({ filterCriteria: filter })
      url = `${apiCalls.Investors}?filter=${JSON.stringify(this.state.filterCriteria)}`
      dataObj = "investors"
    } else if (this.props.type === "Company" && this.state.activeTab === "3") {
      let filter = this.state.filterCriteria
      if (CompanyType === "Public") {
        apiUrl = apiCalls.publicAffiliates;
        key = "ParentCik";
        dataObj = "publicAffiliates";
        sortField = "Name"
      } else {
        apiUrl = apiCalls.affiliates;
        key = "CI.CompanyID";
        dataObj = "affiliates";
        sortField = "CompanyID"
      }
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : sortField
      filter['criteria'] = filterCriteria && filterCriteria.criteria
        ? filterCriteria.criteria
        : [{ key: key, value: str, type: 'eq', add_Filter: true }]

      url = `${apiUrl}?filter=${JSON.stringify(this.state.filterCriteria)}`
    } else if (this.props.type === "Company" && this.state.activeTab === "5") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "CompanyID"
      filter['criteria'] = filterCriteria && filterCriteria.criteria
        ? filterCriteria.criteria
        : [{ key: "CI.CompanyID", value: str, type: 'eq', add_Filter: true }];
      if (forFilter || this.state.searchFilterCriteria.length > 0) {
        for (let searchCriteria of this.state.searchFilterCriteria) {
          if (searchCriteria.value.length > 0) {
            filter['criteria'].push({
              key: searchCriteria.key,
              value: searchCriteria.value,
              type: 'in'
            });
          }
        }
      }
      if (!forFilter) {
        await this.setState({ holding: [], investorStatus: [], investorType: [], HoldingFilter: false, InvestorStatusFilter: false, InvestorTypeFilter: false })
      }
      await this.setState({ filterCriteria: filter })
      url = `${apiCalls.Investors}?filter=${JSON.stringify(this.state.filterCriteria)}`
      dataObj = "investors"
    } else if (this.props.type === "Company" && this.state.activeTab === "6") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "PublishDate"
      filter['criteria'] = filterCriteria && filterCriteria.criteria ? filterCriteria.criteria : [];
      url = `${apiCalls.News}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
      dataObj = "news"
    } else if (this.props.type === "Company" && this.state.activeTab === "7") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "PersonName"
      filter['direction'] = filterCriteria && filterCriteria['direction'] ? filterCriteria['direction'] : "asc"
      filter['criteria'] = filterCriteria && filterCriteria.criteria ? filterCriteria.criteria : [];
      url = `${apiCalls.Leaders}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
      dataObj = "leaders"
    } else if (this.props.type === "Company" && this.state.activeTab === "8") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "CompanyID"
      filter['direction'] = filterCriteria && filterCriteria['direction'] ? filterCriteria['direction'] : "asc"
      filter['criteria'] = filterCriteria && filterCriteria.criteria ? filterCriteria.criteria : [];
      url = `${apiCalls.AddressNames}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}&fromNamesAddressSP=true`
      dataObj = "addressnames"
    } else if (this.props.type === "Company" && this.state.activeTab === "9") {
      let filter = this.state.filterCriteria
      if (CompanyType === "Public") {
        apiUrl = "businessNames/publicAPA";
        dataObj = "publicAPAs";
        sortField = "CIK"
      } else {
        apiUrl = apiCalls.PreAPA;
        dataObj = "apas";
        sortField = "CompanyName"
      }
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : sortField
      filter['direction'] = filterCriteria && filterCriteria['direction'] ? filterCriteria['direction'] : "asc"
      filter['criteria'] = filterCriteria && filterCriteria.criteria ? filterCriteria.criteria : [];
      url = `${apiUrl}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
      dataObj = dataObj
    }
    let obj = {
      url: url,
      dataObj: dataObj
    }
    return obj;
  }

  getActivities = async (id, filterCriteria, forFilter) => {
    let str;
    let tabId = window.location.pathname.split("/")
    // str = tabId[2]
    let dataUrl = await this.getlistApiCall(id, filterCriteria, forFilter);
    await this.setState({
      isLoading: true
    })
    this.handlePercentage('1')
    return fetchMethodRequest('GET', dataUrl.url).then(async (response) => {
      if (response && response[dataUrl.dataObj] && response[dataUrl.dataObj].length > 0) {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          this.setState({ sessionExpiryModal: true })
        }
        let responseData = '', totalRecordsLength = this.state.totalRecordsLength;
        let rows
        let totalGrandChildrenCount = this.state.totalGrandChildrenCount;
        if (response && response[dataUrl.dataObj] && response[dataUrl.dataObj].length && response[dataUrl.dataObj].length >= 0) {
          if ((this.state.activeTab === "4" || this.state.activeTab === "5") && !forFilter && this.state.filterCriteria.page === "1") {
            await localStorage.setItem("uniqueHoldings", JSON.stringify(response["uniqueHoldings"]["results"]))
            await localStorage.setItem("uniqueInvestorStatuses", JSON.stringify(response["uniqueInvestorStatuses"]["results"]))
            await localStorage.setItem("uniqueInvestorTypes", JSON.stringify(response["uniqueInvestorTypes"]["results"]))
          }

          if ((this.state.activeTab === "4" || this.state.activeTab === "5") && !forFilter) {
            await this.addingFiltersforInvestorsandInvestments()
          }
          if (response.pagination && response.pagination.totalCount) {
            totalRecordsLength = response.pagination.totalCount;
            rows = response.pagination.limit;
          }
          if (forFilter) {
            if (response.pagination) {
              totalRecordsLength = response.pagination.totalCount;
              rows = response.pagination.limit;
            }
          }
          if (response.totalGrandChildrenCount) {
            totalGrandChildrenCount = response.totalGrandChildrenCount ? response.totalGrandChildrenCount : 0;
          }
          responseData = this.updateDateFormat(response[dataUrl.dataObj], this.state.dateFormat);
        } else if (response && response[dataUrl.dataObj].length && response[dataUrl.dataObj].length >= 0) {
          if (response.pagination && response.pagination.totalCount) {
            totalRecordsLength = response.pagination.totalCount;
            rows = response.pagination.limit;
          }
          if (forFilter) {
            if (response.pagination) {
              totalRecordsLength = response.pagination.totalCount;
              rows = response.pagination.limit;
            }
          }
          responseData = this.updateDateFormat(response[dataUrl.dataObj], this.state.dateFormat);
        } else {
          if (response.pagination && response.pagination.totalCount) {
            totalRecordsLength = response.pagination.totalCount;
          }
          if (forFilter) {
            if (response.pagination) {
              totalRecordsLength = response.pagination.totalCount;
              rows = response.pagination.limit;
            }
          }
        }
        await this.setState({
          activitiesData: responseData,
          isLoading: false,
          totalRecordsLength: totalRecordsLength,
          totalGrandChildrenCountLength: totalGrandChildrenCount,
          rows: rows
        })
        if (this.state.activeTab === "2") {
          this.expandorCollapse()
        }
      } else {
        await this.getErrorResponses(response)
      }

    }).catch((err) => {
      return err
    })
  }

  getErrorResponses = async (response) => {
    if (response && response['errorMessage'] === configMessages.warningMessage) {
      await this.setState({
        sessionWarning: true,
      })
    } else if (response && response['errorMessage'] && (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired)) {
      this.getErrorMessage(response.errorMessage)
      await this.setState({
        sessionExpired: true,
      })
    } else {
      this.getErrorMessage(response.errorMessage)
    }
    await this.setState({
      activitiesData: [],
      isLoading: false,
      totalRecordsLength: 0,
      totalGrandChildrenCountLength: 0,
    })
  }

  addingFiltersforInvestorsandInvestments = async () => {
    let uniqueHoldings = localStorage.getItem("uniqueHoldings")
    let uniqueInvestorStatuses = localStorage.getItem("uniqueInvestorStatuses")
    let uniqueInvestorTypes = localStorage.getItem("uniqueInvestorTypes")
    let HoldingArray = [], InvestorStatus = [], InvestorType = []
    for (let uniqueHold of JSON.parse(uniqueHoldings)) {
      if (uniqueHold.Holding === "") {
        HoldingArray.push({ label: "Blank", value: uniqueHold.Holding })
      } else {
        HoldingArray.push({ label: uniqueHold.Holding, value: uniqueHold.Holding })
      }
    }
    for (let UniqueInvestorStatus of JSON.parse(uniqueInvestorStatuses)) {
      if (UniqueInvestorStatus.InvestorStatus === "") {
        InvestorStatus.push({ label: "Blank", value: UniqueInvestorStatus.InvestorStatus })
      } else {
        InvestorStatus.push({ label: UniqueInvestorStatus.InvestorStatus, value: UniqueInvestorStatus.InvestorStatus })
      }
    }
    for (let UniqueInvestorType of JSON.parse(uniqueInvestorTypes)) {
      if (UniqueInvestorType.InvestorType === "") {
        InvestorType.push({ label: "Blank", value: UniqueInvestorType.InvestorType })
      } else {
        InvestorType.push({ label: UniqueInvestorType.InvestorType, value: UniqueInvestorType.InvestorType })
      }
    }
    this.setState({ holding: HoldingArray, investorStatus: InvestorStatus, investorType: InvestorType })
  }

  getCompanyTableFields = (companyType) => {
    let type = localStorage.getItem("CompanyType")
    let data;
    if (type === "Public") {
      data = this.props.getPublicCompanies()
    } else {
      data = this.props.getTableFields()
    }
    return data;
  };

  getViewData = async (rowData, type, rowDataIndex, userData, _id, screen) => {
    await this.setState({
      rowData: rowData,
      editRowDataID: _id,
      formType: type,
      slno: rowDataIndex,
      userData: userData,
      // screen: "Company"
    });
    if (this.state.activeTab !== "1" || this.props.type === "Investor") {
      // userData.CompanyID = companyId[2];
      this.getActivities()
    }
  };

  //to get company Data from the id in investors and company activities Table
  getchildCompanyDetails = async (id, type, screen, field) => {

    let val;
    if (field) {
      val = id[field];

      let pathname = `/companyView/${val}`
      window.history.pushState(null, null, pathname);
    } else {

      val = id;
    }
    let filterCriteria = {}
    let data, apiUrl, responseKey;
    if (localStorage.getItem("CompanyType") === "Public") {
      filterCriteria['criteria'] = [{ key: "CIK", value: val, type: 'eq' }];
      apiUrl = apiCalls.publicCompanies
      responseKey = "publicCompanies"
    } else {
      filterCriteria['criteria'] = [{ key: "CompanyID", value: val, type: 'eq' }];
      apiUrl = apiCalls.Companies
      responseKey = "companies"
    }

    let tabId = window.location.pathname.split("/")
    fetchMethodRequest('GET', `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response[responseKey] && response[responseKey].length > 0) {
          data = response[responseKey][0];
          if (this.props.type === "Company") {
            await this.setState({
              activeTab: tabId[3] ? tabId[3] : "1",
              isLoading: false
            })
          }
          if (data) {
            this.handleViewDisplay(data, 'view')
            await this.setState({
              activeTab: tabId[3] ? tabId[3] : "1",
              isLoading: false,
              userIDval: data.Name ? data.Name : data.CompanyName,
            })
          }
        } else {
          await this.getErrorResponses(response)
          data = [];
          await this.setState({
            activeTab: tabId[3] ? tabId[3] : "1",
            isLoading: false
          })
          this.handleViewDisplay(data, 'view')
        }
      }).catch((err) => {
        return err;
      });
  }

  getTableFieldItem = async (field, screen) => {
    let tablefieldsToShow
    let type = localStorage.getItem("CompanyType")
    let addFields = [];
    tablefieldsToShow = this.getCompanyTableFields(screen);
    if (type === "Private") {
      addFields =
        [
          {
            show: true,
            mobile: true,
            textAlign: 'left',
            width: 150,
            field: 'CompanyAlsoKnownAs',
            header: 'Company Also Known As',
            filter: true,
            sortable: true
          }, {
            show: true,
            mobile: true,
            textAlign: 'left',
            width: 200,
            field: 'CompanyFormerName',
            header: 'Company Former Name',
            filter: true,
            sortable: true
          },
          {
            show: true,
            mobile: true,
            textAlign: 'left',
            width: 200,
            field: 'CompanyLegalName',
            header: 'Company Legal Name',
            filter: true,
            sortable: true
          },
          {
            show: true,
            mobile: true,
            textAlign: 'left',
            width: 200,
            field: 'CompanyNameAliases',
            header: 'Company Name Aliases',
            filter: true,
            sortable: true
          }
        ]
    } else {
      addFields =
        [
          {
            show: true,
            mobile: true,
            textAlign: 'left',
            width: 150,
            field: 'MostRecent10KFormUrl',
            header: 'Most Recent 10K Form Url',
            filter: true,
            sortable: true
          }, {
            show: true,
            mobile: true,
            textAlign: 'left',
            width: 200,
            field: 'DirectoryListingUrl',
            header: 'Directory Listing Url',
            filter: true,
            sortable: true
          },
        ]
    }
    tablefieldsToShow = [...tablefieldsToShow, ...addFields]
    // } else {
    //   tablefieldsToShow = this.getTableFields();
    // }
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field === field) {
        return tablefieldsToShow[i];
      }
    }
    return null;
  }

  handleViewDisplay = async (rowData, type, data) => {
    let _id = rowData['_id'] ? rowData['_id'] : "";
    let rowDataIndex;
    // let _id = ""
    if (rowData['_id']) {
      rowDataIndex = this.getUserData(rowData['_id'])
    } else {
      rowDataIndex = ""
    }
    let keys = Object.keys(rowData);
    let formFields = [];
    if (formFields) {
      if (this.props.type) {
        if (rowData) {
          let values, self = this, icon = false;
          // hari get all the labels from 
          // hari get all the labels from 

          keys.forEach(async function (key) {
            let labelKey;
            labelKey = await self.getTableFieldItem(key);
            if (labelKey === null) {
              labelKey = key;
            } else {
              let val
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              if (labelKey.field === 'LastUpdated') {
                let date = dateFormats.addDaysToDate(rowData[key], 1);
                date = dateFormats.formatDate(date, config.dateDBFormat);
                values = {
                  label: labelKey.header,
                  value: date
                }
                val = date
              } else if (labelKey.field === "Revenue") {
                if (rowData[key] % 1) {
                  val = rowData[key].toFixed(2)
                } else {
                  val = rowData[key]
                }
              } else if (labelKey.field === "HQCity") {
                val = await self.getCityStateCOncateField(rowData)
                // val = rowData['HQCity'] + "," + rowData["HQState_Province"] ? rowData["HQState_Province"] : ""

              } else {
                val = rowData[key] === "null" ? "" : rowData[key];
              }
              values = {
                label: labelKey.header,
                value: val ? val : ""
              }
              formFields.push(values);
              icon = false;
            }

          });
        }
      }

      await this.setState({
        formType: 'view',
        userData: rowData,
        activitiesData: [],
        totalRecordsLength: 0,
        totalGrandChildrenCountLength: 0,
        displayBreadCrumbValue: rowData[this.state.displayBreadCrumbField],
        userStatus: rowData['status']
      });
      await this.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
    }
  }
  getCityStateCOncateField(rowData) {
    let val;
    if (!rowData['HQCity'] && rowData["HQState_Province"]) {
      val = rowData["HQState_Province"]
    } else if (!rowData['HQState_Province'] && rowData["HQCity"]) {
      val = rowData["HQCity"]
    } else if (rowData['HQCity'] && rowData['HQState_Province']) {
      val = rowData['HQCity'].concat(' ,', rowData["HQState_Province"]);
    } else {
      val = ""
    }
    return val;
  }

  //after selecting type 
  onSeletFilterType(value, type) {
    value = this.state.activeTab ? this.state.activeTab.toString() + value : value
    let selectedcolumn = document.getElementsByClassName(`p-column-filter-${value}`)
    if (!type) {
      selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].focus()
    } else {
      selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].blur()
    }
  }


  getUserData(_id) {
    let data = this.props.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  updateDateFormat(itemdata) {
    let modifiedData = [];
    let tablefieldsToShow = this.getTableFields();
    if (this.state.activeTab !== "2") {
      for (let i = 0; i < itemdata.length; i++) {
        for (let k = 0; k < tablefieldsToShow.length; k++) {
          if ("Date" === tablefieldsToShow[k]['fieldType']) {
            itemdata[i][tablefieldsToShow[k]['field']] =
              // dateFormats.formatDate(
              //   itemdata[i][tablefieldsToShow[k]['field']],
              //   tablefieldsToShow[k]['dateFormat']);
              dateFormats.formatDate(
                itemdata[i][tablefieldsToShow[k]['field']],
                tablefieldsToShow[k]['dateFormat'], "NpS");
          }
        }
        modifiedData.push(itemdata[i])
      }
    }
    if (this.state.activeTab === "2") {
      modifiedData = this.handleGrandChildDataDateformat(itemdata)
    }
    return modifiedData;
  }

  handleGrandChildDataDateformat(itemdata) {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      if (itemdata[i].DealDate) {
        itemdata[i].DealDate = dateFormats.formatDate(
          itemdata[i].DealDate,
          "MM-DD-YYYY", "nhnh");
      }
      // field = "DWDateInserted" || "DWDateLastModified" || "LastUpdated"
      // if (itemdata[i]["DWDateInserted"] || itemdata[i]["DWDateLastModified"] || itemdata[i]["LastUpdated"]) {
      //   itemdata[i]["DWDateInserted"] = dateFormats.formatDate(
      //     itemdata[i]["DWDateInserted"],
      //     "MM-DD-YYYY", "nhnh");
      //   itemdata[i]["DWDateLastModified"] = dateFormats.formatDate(
      //     itemdata[i]["DWDateLastModified"],
      //     "MM-DD-YYYY", "nhnh");
      //   itemdata[i]["LastUpdated"] = dateFormats.formatDate(
      //     itemdata[i]["LastUpdated"],
      //     "MM-DD-YYYY", "nhnh");
      // }
      if (itemdata[i].GrandChildCompanies && itemdata[i].GrandChildCompanies.length > 0) {
        this.updateDateFormat(itemdata[i].GrandChildCompanies, 'MM-DD-YYYY')
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]|\\/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }

  getActivtiesTableFieldItem = (field) => {
    let tablefieldsToShow = this.getTableFields()
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field === field) {
        return tablefieldsToShow[i];
      }
    }
    return null;
  }

  //Settings Modal open
  openShowHideColumnsModal = () => {
    this.setState({
      isOpenShowHideColumnsModal: true,
    })
  }

  getOpenShowHideColumnsModal() {
    return (
      <ShowHideColumnsModal
        isOpenShowHideColumnsModal={this.state.isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={this.closeShowHideColumnsModal}
        tableFields={this.state.originalTableFields}
        type={this.props.type}
        viewBy={this.state.selectedViewType}
        isShownnFieldType={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ||
          this.props.type === "Deal Sizing-Properties" || this.props.type === "Properties" ? false : true}

      />
    )
  }


  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];

      this.setState({
        isOpenShowHideColumnsModal: false,
      })

      fields.map(async item => {
        Object.entries(columnFields).forEach(async ([key, value]) => {
          if (item.field === key) {
            return item.show = value;
          }
        });
      })

      fields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            width: col.width
          }
        )
      });
      await this.sendColumnDataToServer(updatedOrder, "PUT", columnFields);
      await this.sendColumnDataToServer("", "GET");
      // localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
      await this.getTableFieldsOrder(type, this.state.originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];
      this.setState({
        isOpenShowHideColumnsModal: false,
      })
      await this.sendColumnDataToServer(fields, "PUT", columnFields);
      await this.sendColumnDataToServer("", "GET");
      // localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(fields));
      await this.getTableFieldsOrder(type, this.state.originalTableFields, "isLoading");
    } else {
      this.setState({
        isOpenShowHideColumnsModal: false,
        // isOpenConfirmColumnModel: true
      })
    }
  }


  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = self.getActivtiesTableFieldItem(column.field);
    // if (tableItem.fieldType === "Date") {
    //   let val = dateFormats.formatDate(item[column.field], "MM-DD-YYYY");
    //   return <span style={tableItem.style} title={val}>
    //     {val}
    //   </span>
    // } else
    if (tableItem.fieldType === "Link" && this.props.type === "Investor") {
      return <div className='textElipses'>
        <span
          onClick={() => this.getchildCompanyDetails(item.TargetCompanyID, 'view', 'Company')}>
          {item[column.field]}</span>
      </div >
    } else if (tableItem.fieldType === "ParentCompany") {
      return <div className='textElipses'>
        <span
          onClick={() => this.getchildCompanyDetails(item, 'view', 'Investor', tableItem.getField)}>
          {item[column.field]}</span>
      </div >
    } else if (tableItem.fieldType === "website") {
      return <span>
        <span>
          <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`${item[column.field]}`}>{item[column.field]}</a>
        </span>
      </span>
    } else if (tableItem.fieldType === "url") {
      return <span>
        <span>
          <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`${item['Url']}`}>{item[column.field]}</a>
        </span>
      </span>
    } else if (tableItem.field === "Name" && this.state.activeTab === "9") {
      return <span style={{ textTransform: "capitalize" }}>
        {item[column.field]}
      </span>
    }
    else {
      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        return item[column.field];
      }
    }
  }

  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }

  getchildcomp(exportData, mainData) {
    for (let child of exportData) {
      mainData.push(child)
      if (child.ChildCount > 0) {
        mainData = this.getchildcomp(child.GrandChildCompanies, mainData)
      }
    }
    return mainData;
  }

  //Get all data of current screen with filters applied from server to export to CSV
  //Get all data of current screen with filters applied from server to export to CSV
  getDataToExport = async () => {
    this.setState({ isLoading: true })
    this.handlePercentage('1')
    let url, apiDataarr;
    let apiUrl, responseObj; let mainData = []
    let CompanyType = localStorage.getItem("CompanyType")
    delete this.state.filterCriteria['limit'];
    delete this.state.filterCriteria['sortfield'];
    this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
    if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
      delete this.state.filterCriteria.globalSearch
    }
    let tabId = window.location.pathname.split("/")
    let str = tabId[2];
    let getUrl = this.getUrlfromData(str)

    return fetchMethodRequest('GET', getUrl.apiUrl)
      .then(async (response) => {
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          await this.setState({ sessionExpiryModal: true })
        }
        if (response && response[getUrl.url]) {
          if (getUrl.url === "childCompanies") {
            let updatedatedata = this.updateDateFormat(response[getUrl.url])
            if (!this.state.expandAll) {
              responseObj = updatedatedata
            } else {
              responseObj = this.getchildcomp(updatedatedata, mainData)
            }
            for (var item of responseObj) {
              let keys = Object.keys(item);
              keys.forEach(async function (key) {
                if (key.includes("DW")) {
                  delete item[key]
                }
              })
              delete item.GrandChildCompanies
            }
          } else {
            responseObj = this.updateDateFormat(response[getUrl.url])
          }
          responseObj.forEach((item) => {
            for (let key in item) {
              if (item[key] && item[key].name) {
                item[key] = item[key].name;
              }
            }
          });
          let filterCriteria = this.state.filterCriteria
          filterCriteria.limit = 50
          this.setState({
            exportData: responseObj,
            limit: 50,
            filterCriteria: filterCriteria,
            isLoading: false
          }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLinkRef.link.click();
          });
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          this.setState({
            sessionWarning: true
          })
        } else if (response && (response['errorMessage'] === config.tokenMessage || response['errorMessage'] === config.sessionExpired)) {
          this.setState({
            sessionexpired: true
          })
        } else {
          this.getErrorMessage(response['errorMessage'])
        }
      }).catch((err) => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  }


  getUrlfromData(str) {
    let url, apiDataarr;
    let apiUrl, responseObj; let mainData = []
    let CompanyType = localStorage.getItem("CompanyType")
    if ((this.props.type === "Company" || this.state.shownTable === "Company") && this.state.activeTab !== "3" && this.state.activeTab !== "4" && this.state.activeTab !== "5" && this.state.activeTab !== "6" && this.state.activeTab !== "7" && this.state.activeTab !== "8" && this.state.activeTab !== "9") {
      apiUrl = `${apiCalls.ChildCompanies}?str=${str}&type=exportToCsv`;
      url = "childCompanies"
    } else if (this.props.type === "Investor" || this.state.activeTab === "4") {
      this.state.filterCriteria['criteria'] = [{ key: "InvestorID", value: str, type: 'eq' }];
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      apiUrl = `${apiCalls.Investors}?filter=${JSON.stringify(this.state.filterCriteria)}&type=exportToCsv`;
      url = "investors"
    } else if (this.state.activeTab === "3") {
      if (CompanyType === "Public") {
        this.state.filterCriteria['criteria'] = [{ key: "ParentCik", value: str, type: 'eq' }];
        apiDataarr = "businessnames/publicAffiliates"
        url = "publicAffiliates"
      } else {
        this.state.filterCriteria['criteria'] = [{ key: "CI.CompanyID", value: str, type: 'eq' }];
        apiDataarr = apiCalls.affiliates
        url = "affiliates"
      }
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      apiUrl = `${apiDataarr}?filter=${JSON.stringify(this.state.filterCriteria)}&type=exportToCsv`;
    } else if (this.state.activeTab === "5") {
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      this.state.filterCriteria['criteria'] = [{ key: "CI.CompanyID", value: str, type: 'eq' }];
      apiUrl = `${apiCalls.Investors}?filter=${JSON.stringify(this.state.filterCriteria)}&type=exportToCsv`
      url = "investors"
    } else if (this.state.activeTab === "6") {
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      this.state.filterCriteria['criteria'] = [];
      apiUrl = `${apiCalls.News}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}&type=exportToCsv`
      url = "news"
    } else if (this.state.activeTab === "7") {
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      this.state.filterCriteria['criteria'] = [];
      apiUrl = `${apiCalls.Leaders}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}&type=exportToCsv`
      url = "leaders"
    } else if (this.state.activeTab === "8") {
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      this.state.filterCriteria['criteria'] = [];
      apiUrl = `${apiCalls.AddressNames}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}&fromNamesAddressSP=true&type=exportToCsv`
      url = "addressnames"
    } else if (this.state.activeTab === "9") {
      let filter = this.state.filterCriteria
      filter['criteria'] = [];
      let sortField;
      if (CompanyType === "Public") {
        // this.state.filterCriteria['criteria'] = [{ key: "ParentCik", value: this.state.userData.CIK, type: 'eq' }];
        apiDataarr = "businessnames/publicAPA";
        url = "publicAPAs";
        sortField = "CIK"
      } else {
        // this.state.filterCriteria['criteria'] = [{ key: "CI.CompanyID", value: this.state.userData.CompanyID, type: 'eq' }];
        apiDataarr = apiCalls.PreAPA;
        url = "apas";
        sortField = "CompanyName"
      }
      filter['sortfield'] = sortField;
      this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      apiUrl = `${apiDataarr}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}&type=exportToCsv`
      url = url
    }
    let data = {
      apiUrl: apiUrl,
      url: url
    }

    return data;
  }

  filteredValues = async (e, type) => {
    let filterCriteria = this.state.filterCriteria
    if (e && e.persist) {
      e.persist();
    }
    if (e.key === "Enter") {
      if (e && e.target && e.target.value.length > 1) {
        filterCriteria.globalSearch = {
          value: encodeURIComponent(e.target.value),
          type: "user"
        }
      } else {
        delete filterCriteria.globalSearch
      }
      filterCriteria.page = 1
      this.setState({
        first: 0
      })
      this.getActivities('', filterCriteria)
    }

  }

  handleExpandChange = async (value) => {
    await this.setState({ expandAll: !this.state.expandAll ? true : false });
    this.expandorCollapse()
  }

  expandorCollapse = async () => {
    if (this.state.expandAll) {
      this.expandAll(this.state.activitiesData)
    } else {
      this.collapseAll()
    }
  }

  getEditButton() {
    const { t } = this.props;
    return (
      <div className='d-flex'>
        <div className='col-12 pl-0'>
          {(this.props.type === "Company" && this.state.activeTab === "2") || this.state.shownTable === "Investor" ?
            <div className="float-left ml-2 row ">
              <div className="mt-3">
                <input
                  label='Expand All'
                  type='checkbox'
                  component={Checkbox}
                  name={'expand'}
                  className="analystCheckbox mb-0 mr-2"
                  checked={this.state.expandAll}
                  onChange={() => this.handleExpandChange('expandAll')}
                />
                <label>Expand All</label>
              </div>
              <span style={{ marginTop: 15, marginLeft: 5 }}>({this.state.activitiesData.length})</span>
              <span style={{ marginTop: 15, marginLeft: 5 }}>({this.state.totalGrandChildrenCountLength})</span>
            </div> : null}
          <span className='row float-right mb-3'>
            <Button color="primary"
              outline
              size="sm"
              style={{ height: 30 }}
              className="p-1 ml-2 mt-3 mb-1"
              onClick={() => this.getremoveFilters("type")}>Clear Column Filters
            </Button>
            <span className='' style={{ marginTop: "16px" }}>
              <Button color="primary" outline
                className='ml-auto mb-0 mr-0 form_exportbtn'
                size={'sm'} onClick={this.getDataToExport}>
                <FontAwesomeIcon
                  icon='download'
                  data-toggle="tool-tip" title={t("Export To CSV")}
                  className='pl-1' size='lg' />
              </Button>
              <CSVLink
                data={this.state.exportData}
                filename={this.state.userIDval ? `${this.state.userIDval}-${this.state.tabName}.csv` : `${this.state.tabName}.csv`}
                className="hidden text-white"
                ref={(r) => this.csvLinkRef = r}
                target="_blank" >
              </CSVLink>
            </span>

            <div
              className="p-inputgroup searchField mt-3 mr-2"
            >
              <span className="p-input-icon-left">
                <i className="pi pi-search" style={{ display: "block", fontSize: "14px" }} />
                <span className="p-input-icon-right">

                  <InputText
                    type="search"
                    name='globalSearch'
                    id='globalSearch'
                    // value={this.state.selectsearchFilterValue}
                    style={{ height: 37, paddingLeft: "2.5rem" }}
                    onKeyPress={(e) => this.filteredValues(e)}
                    onChange={(e) => this.filteredValues(e)}
                    placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                    size={25}
                  //  disabled={true}
                  />
                </span>
              </span>
            </div>
          </span>
        </div>
      </div>
    )
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getActivities}

      />
    )
  }
  //getScreenBody

  getErrorMessage = (errorMessage) => {
    this.setState({
      errorMessage: errorMessage,
      errorResponse: true
    })
  }

  getViewBody() {
    const { t } = this.props
    return (<div>
      <div className='row'>
        <div className='col-sm-4 pb-2'>
          {this.props.actionsTypes && this.props.actionsTypes.length > 0 ? <Dropdown
            style={{ minWidth: '10%', lineHeight: 1.3, }}
            className='mr-3'
            // appendTo={document.body}
            name='actions'
            value={this.state.actions}
            options={this.props.actionsTypes}
            placeholder={t('Actions')}
            onChange={(e) => this.onActionsChange(e, 'dropdownFilter')}
          /> : null}
        </div>
        <div className='col-sm-8 text-lg-right'>
          {this.state.originalTableFields && this.state.originalTableFields.length > 0 ? this.state.originalTableFields.map((item, index) => {
            return item.fieldType === 'Badge' && item.options && item.options.length > 0 ? item.options.map((optionsItem, optionsIndex) => {
              return (
                <Button key={optionsIndex} color='primary' onClick={() => this.saveDataToServer({ "status": optionsItem.value })} disabled={this.state.userStatus === optionsItem.value ? true : false}>{optionsItem.label}</Button>
              )
            }) : null
          }) : null}
        </div>
      </div>
      {this.state.rowData && this.state.rowData.length > 0 ?
        <div className="row form" >
          {this.state.rowData.map((item, i) => {

            return (
              item.label !== 'Sno' ?
                <div className="col-sm-12 col-12" key={i}>
                  <div className="row" style={{ margin: "auto" }}>
                    <div
                      className="col-3 paddingRowDataCol"
                    >
                      <span className='fontWeight'>
                        {t(item.label)}
                      </span>
                    </div>

                    <div className="col-8 paddingOfRowData"
                    >
                      {item.label === "Parent Company" ?
                        <a href="#" style={{ color: '#0e4768', cursor: 'pointer' }} className="pl-0" onClick={() => this.getchildCompanyDetails(this.state.userData, '', 'Investor', 'ParentCompanyID')}>{item.value}</a> :
                        item.label === "Website" ?
                          <a style={{ color: '#0e4768', cursor: 'pointer' }} className="pl-0" target="_blank" href={`http://${item.value}`}>{item.value}</a>
                          : item.label === "Most Recent 10K Form Url" || item.label === 'Directory Listing Url' ?
                            <a style={{ color: '#0e4768', cursor: 'pointer' }} className="pl-0" target="_blank" href={`${item.value}`}>{item.value}</a>
                            : <span>{item.value}</span>
                      }
                    </div>
                  </div>
                </div> : null
            );
          })}
        </div>
        : <div style={{
          textAlign: "center", fontWeight: 800
        }}><span style={{ textAlign: "center" }}>

            No data is found for this Company
          </span></div>
      }
    </div>
    )
  }

  //sorting fields
  sortChange = async (event) => {
    let data = this.props.type === "Labels" || this.props.type === "Purposes" || this.props.type === "Company" || this.props.type === "Investor" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ?
      'asc'
      : this.props.sortDirection ? this.props.sortDirection : "desc";
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = {
        direction: event.selectedOption === "unsort" ? data : event.selectedOption,
        sortfield: event.selectedOption === "unsort" ? this.props.sortField ? this.props.sortField : "created" : sortField,
        limit: this.state.filterCriteria.limit,
        page: this.state.filterCriteria.page,
        criteria: this.state.filterCriteria['criteria']
      }
      if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
        filterCriteria.globalSearch = this.state.filterCriteria.globalSearch
      }
      await this.setState({
        sortCount: sortCount,
        isLoading: true,
        filterCriteria: filterCriteria
      });
      this.handlePercentage('1')
      await this.getActivities("", filterCriteria);
    }
  }

  expandAll(t) {
    let rowDataValues = this.expandDataAll(t)
    this.setState({
      expandedRows: rowDataValues
    });
  }


  expandDataAll(t) {
    let expandedRows = {};
    t.forEach(element => {
      if (element.ChildCount != 0) {
        expandedRows[`${element.DealID}`] = true;
        expandedRows = Object.assign(expandedRows, this.expandDataAll(element.GrandChildCompanies))
      }
    });
    return expandedRows;
  }

  collapseAll = async () => {
    this.setState({
      expandedRows: null
    });
  }

  // to show filter element dropdown only when clicked on filter icon
  searchPerform = async (field) => {
    if (field) {
      if (field === "Holding") {
        this.setState({ HoldingFilter: this.state.HoldingFilter ? false : true })
      }
      else if (field === "InvestorStatus") {
        this.setState({ InvestorStatusFilter: this.state.InvestorStatusFilter ? false : true })
      }
      else if (field === "InvestorType") {
        this.setState({ InvestorTypeFilter: this.state.InvestorTypeFilter ? false : true })
      }
    }

  }
  onFilterChangeDropdown = async (event, type) => {
    let searchFilterCriteria = this.state.searchFilterCriteria
    let index = searchFilterCriteria.findIndex((element) => element.key === event.target.name);
    if (index > -1) {
      searchFilterCriteria.splice(index, 1);
    }
    searchFilterCriteria.push({ key: event.target.name, value: event.target.value })
    await this.setState({ searchFilterCriteria: searchFilterCriteria })
    this.setState({
      isLoading: true,
      selectedRows: '',
      filterSelect: event.target.value
    })
    if (type === 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value
      })
    }
    let fieldName = '', filterCriteria = this.state.filterCriteria,
      selectedFilterValue, selecterFilterType;
    if (event) {
      fieldName = event.target.name;
      selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
      if (type === 'dropdownFilter') {
        selecterFilterType = 'eq'
      }
      if ((selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        filterCriteria['criteria'].push({
          key: fieldName,
          value: selectedFilterValue,
          type: 'in'
        });
      }
      this.state.filterCriteria.page = "1"
      this.setState({ first: 0 })
      await this.getActivities("", this.state.filterCriteria, { key: fieldName, value: selectedFilterValue })
    }
  }

  handleDataChangingOption = async (e, field) => {
    if (e && e.selectedOption) {
      if (e.selectedOption === "unsort" || e.selectedOption === "asc" || e.selectedOption === "desc") {
        await this.sortChange(e)
      } else if (e.selectedOption === "hide") {
        await this.columnHandlingfromHeader(e.selectedOption, e.sortField)
      } else if (e.selectedOption === "show") {
        this.openShowHideColumnsModal()
      } else if (e.selectedOption === "filter") {
        await this.handleFilterInputField(e.sortField)
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "clear_filter") {
        await this.handleFilterInputField(e.sortField, "clear")
        // this.AddingFilterInput(e.selectedOption, field)
      }
    }
  }

  // on search get data from server
  // on search get data from server
  onFilterChange = async (event, type, screen) => {
    if (event && event.persist) {
      event.persist();
    }
    this.setState({
      isLoading: true,
      selectedRows: '',
      selectedKey: event.key === "Enter" || event.filters ? true : false,
      isDisabledTab: true,
      // isChangeHit: true
    })
    // await this.handlePercentage('1')
    if (type === 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value
      })
    }
    let fieldName = '', filterCriteria = this.state.filterCriteria,
      selectedFilterValue, selectedFilter, selecterFilterType, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        selecterFilterType = document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0] ? document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0].value : "eq"
        selectedFilterValue = selectedFilter && selectedFilter.value ? encodeURIComponent(selectedFilter.value) : selectedFilter ? selectedFilter : null;
        if (selecterFilterType === "nin" || selecterFilterType === "in") {
          selectedFilterValue = "nexistes"
        }
        if (event && event.filters && event.filters[fieldName].matchMode === "clear") {//for clearing column filter menu
          selectedFilterValue = null
        }
        if (fieldName === 'date' || fieldName === 'LastUpdated' || fieldName === 'created' || fieldName === 'updated' || fieldName.includes("Date") || fieldName.includes("date")) {
          if (selecterFilterType !== "nin" && selecterFilterType !== "in") {
            selecterFilterType = "date" + selecterFilterType
          }
        }
        if (this.props.isSql === "yes" && this.props.isSql !== undefined) {
          if (selecterFilterType === "in") {
            selecterFilterType = "nexists"
          } else if (selecterFilterType === "nin") {
            selecterFilterType = "exists"
          }
        }
      } else {
        fieldName = event && event.target && event.target.name ? event.target.name : "globalSearch";
        selectedFilterValue = event && event.target && event.target.value ? event.target.value : event;
        type = "eq"
      }
      if (selectedFilterValue === null) {
        let index
        index = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.type === selecterFilterType)
        filterCriteria.criteria.splice(index, 1);
        filterCriteria.page = "1"
        await this.setState({
          filterCriteria: filterCriteria,
          first: 0,
          page: 1,
          // selectFilterValue: "",
        });

        if (this.state.selectedKey) {
          this.getActivities(this.state.filterCriteria)
        }
      } else if ((selectedFilterValue && selectedFilterValue.length && ((fieldName === "globalSearch" && selectedFilterValue.length > 0) || (fieldName !== "globalSearch" && selectedFilterValue.length >= 1)))) {

        let valdat
        if (this.props.isSql === "yes" && this.props.isSql !== undefined && isDateFilter) {
          valdat = ["", null, 0]
        } else if (this.props.isSql === undefined) {
          valdat = ["", null]
        }
        filterCriteria.page = 1;
        if (filterCriteria['criteria'].length === 0) {
          filterCriteria['criteria'].push({
            key: fieldName,
            value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexistes" ? parseInt(selectedFilterValue) : selectedFilterValue === "nexistes" ? valdat : selectedFilterValue,
            type: selecterFilterType,
            add_Filter: false
          });
        } else {
          let obj, index;
          index = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
          if (index !== -1) {
            filterCriteria['criteria'].splice(index, 1)
          }
          filterCriteria['criteria'].push({
            key: fieldName,
            value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexistes" ? parseInt(selectedFilterValue) : selectedFilterValue === "nexistes" ? valdat : selectedFilterValue,
            type: selecterFilterType,
            add_Filter: false
          });

          // }
        }

        await this.setState({
          filterCriteria: filterCriteria,
          page: 1,
          first: 0
        });

        if (event && event.persist) {
          event.persist();
        }
        if (this.state.selectedKey) {
          this.getActivities("", this.state.filterCriteria)
          if (screen === "DetailView") {
            this.formModalRef.closeFormModal();
          }
        }
      } else {
        if (selectedFilterValue.length === undefined && !isDateFilter) {
          let obj = filterCriteria.criteria.find(x => x.key === fieldName && x.add_Filter === false);
          let index = filterCriteria.criteria.indexOf(obj);
          filterCriteria.criteria.splice(index, 1);
          // }

          filterCriteria.page = "1"
          if (fieldName === 'globalSearch') {
            filterCriteria.globalSearch = {}
            delete filterCriteria.globalSearch
          }
          await this.setState({
            filterCriteria: filterCriteria,
            page: "1",
            first: 0,
            // selectFilterValue: ""
          });
          if (this.state.selectedKey) {
            await this.getActivities(this.state.filterCriteria)
          }
        }
      }
      this.setState({
        isLoading: false,
        progress: 0
      })
    }

  }


  isConfirmFilter = async (val, type) => {
    if (val && val.filters) {
      let fieldName = Object.keys(val.filters)[0];
      if (val.filters[fieldName].value.length === 0 || val.filters[fieldName].value.length === undefined) {
        await this.setState({ isApplyFilter: true, filteredCol: val })
        this.getConfirmaionModel()
      } else {
        await this.onFilterChange(val)
      }
    } else {
      this.setState({ isLoading: false, isApplyFilter: false, })
      if (type) {
        this.onSeletFilterType(val, type)
        //  this.handleFilterInputField(val, "clear")
      }
    }
  }

  getColumns(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    let tablefieldsToShow = this.getTableFields();
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column bodyClassName="expandable_Table" key={item.field + i}
            expander={item.field === "expander" ? true : false}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: item.field === "expander" ? 'none' : 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field === 'status' || item.field === 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            header={t(item.header)}
            body={self.changeFieldValues}
            headerStyle={{
              padding: "6px 15px", fontWeight: 500, width: item.width, fontSize: 13,
              color: config.blackColor, backgroundColor: config.templateColor, textAlign: 'left'
            }}
            // filter={item.filter && (item.field === "Holding" || item.field === "InvestorStatus" || item.field === "InvestorType") ? item.filter : false}
            // filterElement={

            //   item.filterElement && (item.field === "Holding" || item.field === "InvestorStatus" || item.field === "InvestorType") ?
            //     <div >
            //       < FontAwesomeIcon
            //         className='genderIconAlignment'
            //         icon={faFilter}
            //         data-toggle="tool-tip"
            //         title="search"
            //         style={{ color: 'black', width: '14', marginTop: "-15px", marginLeft: 7, cursor: 'pointer', float: "right" }}
            //         onClick={() => this.searchPerform(item.field)} />

            //       <div style={this.state[item.field + "Filter"] ? { height: 26, display: "block" } : { height: 26, display: "none" }} >
            //         <MultiSelect
            //           style={{ minWidth: '10%', lineHeight: 1.1 }}
            //           appendTo={document.body}
            //           name={item.field}
            //           value={this.state[item.field]}
            //           options={item.field === "Holding" ? this.state.holding : item.field === "InvestorStatus" ? this.state.investorStatus : item.field === "InvestorType" ? this.state.investorType : null}
            //           onChange={(e) => this.onFilterChangeDropdown(e, 'dropdownFilter')} />
            //       </div>
            //     </div>
            //     : item.filterElement ?
            //       <div style={{ height: 26 }} >
            //         <MultiSelect
            //           style={{ minWidth: '10%', lineHeight: 1.1 }}
            //           appendTo={document.body}
            //           name={item.field}
            //           value={this.state[item.field]}
            //           options={item.field === "Holding" ? this.state.holding : item.field === "InvestorStatus" ? this.state.investorStatus : item.field === "InvestorType" ? this.state.investorType : null}
            //           onChange={(e) => this.onFilterChangeDropdown(e, 'dropdownFilter')} />
            //       </div> : null

            // }
            shownField={this.state.shownFieldTab}
            activeTab={this.state.activeTab}
            dataChangeOptions={item.filter ? this.state.dataChangeOptionswithFilter : this.state.dataChangeOptionswithoutFilter}
            filterMatchMode={item.filterType === "num" ? "num" : "string"}
            filter={item.filter ? item.filter : false}
            filterElementnonText={item.fieldType === 'Date' ? this.CalenderComponent(item) : null}
            filterElement={item.filter && item.filterType === "num" ?
              <select className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} name="viewroom" id="viewroom" onChange={(e) => this.onSeletFilterType(item.field)}>
                <option value="eq">Equals</option>
                <option value="lt">Less Than</option>
                <option value="gt">Greater Than</option>
                <option value="lte">Less Than or Equals</option>
                <option value="gte">Greater Than or Equals</option>
                {/* <option value="nin">Exists</option>
                <option value="in">Not Exists</option> */}
              </select> : item.filter && item.filterType !== "num" ?
                <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                  <option value="regexOr">Includes</option>
                  <option value="eq">Equals</option>
                  <option value="sw">Starts With</option>
                  <option value="ew">Ends With</option>
                  <option value="nregexOr">Exclude</option>
                  {/* <option value="nin">Exists</option>
                  <option value="in">Not Exists</option> */}
                </select>
                : null
            }
            isAllowInt={item.allowInt ? true : false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
            selectionMode={item.selectionMode}
          />
        )
        return column;
      })
    }
  }
  //Custum Date Column Filter Onchange
  onFilterdateChange = async (e, field) => {
    let value = e.value.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    let filters = {}, newFil = {}, filteredObj = {};
    newFil[field] = {
      value: value,
      matchMode: "string"
    };
    filters[field] = newFil[field]
    filteredObj.filters = filters
    await this.setState({
      [field]: e.value
    })
    this.onFilterChange(filteredObj)
  }

  CalenderComponent(item) {
    return (
      < >
        <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
          appendTo={document.body}
          monthNavigator={true}
          yearNavigator={true}
          yearRange="1940:2530"
          inputClassName={`p-column-filter p-column-${item.field}`}
          name={item.field}
          dateFormat="mm/dd/yy"
          value={this.state[item.field]}
          onSelect={(e) => this.onFilterdateChange(e, item.field)} />
      </>
    )
  }



  getConfirmaionModel() {
    return (
      <ConfirmaionModels
        openConfirmationModal={this.state.isApplyFilter}
        closeConfirmationModal={this.isConfirmFilter}
        onFilterChange={this.onFilterChange}
        filterInput={this.state.filteredCol}
        handleFilterInputField={this.handleFilterInputField}

      />
    )
  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }

  handleFilterInputField(val, type) {
    let filters = {}, newFil = {}, filteredObj = {};
    if (type) {
      newFil[val] = {
        value: "",
        matchMode: "clear"
      };
      filters[val] = newFil[val]
      filteredObj.filters = filters
      let value = this.state.activeTab ? this.state.activeTab + val : val
      let data = document.getElementsByClassName(`p-column-filter-${value}`)
      data[0].getElementsByClassName(`p-column-filter`)[0].value = ""
      let getName = data[0].getElementsByClassName(`p-column-filter`)[0].name
      this.setState({
        [getName]: ""
      })
      document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
      this.onFilterChange(filteredObj)
    } else {
      let value = this.state.activeTab ? (this.state.activeTab + val) : val
      if ((document.getElementsByClassName(`p-column-filter-${value}`) && document.getElementsByClassName(`p-column-filter-${value}`)[0]) !== undefined) {
        if (document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display === "none") {
          document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "block"
        } else {
          document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
        }
      }

    }
  }

  //to remove datatable all Filters 
  getremoveFilters = async (type) => {
    await this.setState({
      clearFilters: true,
      page: 1,
      first: 0
    })
    let filteredNo = document.getElementsByClassName("p-column-filter").length
    for (let i = 0; i <= filteredNo; i++) {
      if (document.getElementsByClassName("p-column-filter")[i] && document.getElementsByClassName("p-column-filter")[i].value)
        if (document.getElementsByClassName("p-column-filter")[i].value !== "") {
          document.getElementsByClassName("p-column-filter")[i].value = ""
          let getName = document.getElementsByClassName("p-column-filter")[i].name
          this.setState({
            [getName]: ""
          })
        }
      if (document.getElementsByClassName("p-column-filterMode")[i]) {
        document.getElementsByClassName("p-column-filterMode")[i].selectedIndex = 0
      }
      if (document.getElementsByClassName("p-column-filterComponent")[i] && document.getElementsByClassName("p-column-filterComponent")[i].style.display === "block") {
        document.getElementsByClassName("p-column-filterComponent")[i].style.display = "none"
      }
    }
    //removing inline filters
    let filterCriteria = this.state.filterCriteria
    var newArr = filterCriteria["criteria"].filter(object => {
      return object.add_Filter == true;
    });
    filterCriteria["criteria"] = newArr
    filterCriteria["page"] = 1
    await this.setState({
      filterCriteria: filterCriteria,
      page: 1,
      first: 0
    })
    if (type) {
      this.getActivities(this.state.filterCriteria)
    }
  }

  //getRow Expand collapse
  rowExpansionTemplate = (data) => {
    return (
      <div className="" style={{ padding: '0px' }}>
        {data.GrandChildCompanies && data.GrandChildCompanies.length > 0 ?
          <div className="child_Companie">
            <DataTable value={data.GrandChildCompanies}
              scrollable
              scrollHeight="200px"
              responsiveLayout="scroll"
              expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
              rowExpansionTemplate={this.rowExpansionTemplate} dataKey={!this.state.expandAll ? "" : "DealID"}>
              {/* <Column bodyClassName="expandable_Table" expander bodyStyle={{ width: '60px' }}></Column>
              <Column bodyClassName="expandable_Table" field="AffiliateID" bodyStyle={{ width: '130px' }}></Column>
              <Column bodyClassName="expandable_Table" field="AffiliateName" bodyStyle={{ width: '180px' }}></Column>
              <Column bodyClassName="expandable_Table" field="AffiliateNameAliases" bodyStyle={{ width: '200px' }}></Column>
              <Column bodyClassName="expandable_Table" field="AffiliateType" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="BottomFlag" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="DealID" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="BridgeDupData" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="BusinessIDImmediateParentCompany" bodyStyle={{ width: '150px' }}></Column>
              <Column bodyClassName="expandable_Table" field="BusinessIDRootCompany" bodyStyle={{ width: '110px' }}></Column>
              <Column bodyClassName="expandable_Table" field="DWDelete" bodyStyle={{ width: '170px' }}></Column>
              <Column bodyClassName="expandable_Table" field="DWLastModifiedBy" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="ImmediateParentCompanyID" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="Industry" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="Location" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="RootCompanyAliases" bodyStyle={{ width: '150px' }}></Column>
              <Column bodyClassName="expandable_Table" field="RootCompanyID" bodyStyle={{ width: '110px' }}></Column>
              <Column bodyClassName="expandable_Table" field="DWDelete" bodyStyle={{ width: '170px' }}></Column>
              <Column bodyClassName="expandable_Table" field="TopFlag" bodyStyle={{ width: '120px' }}></Column>
              <Column bodyClassName="expandable_Table" field="DWDateInserted" bodyStyle={{ width: '130px' }}></Column>
              <Column bodyClassName="expandable_Table" field="DWDateLastModified" bodyStyle={{ width: '150px' }}></Column>
              <Column bodyClassName="expandable_Table" field="LastUpdated" bodyStyle={{ width: '110px' }}></Column> */}
              <Column expander bodyStyle={{ width: '60px' }}></Column>
              <Column field="CompanyID" bodyStyle={{ width: '130px' }}></Column>
              <Column field="TargetCompanyID" bodyStyle={{ width: '180px' }}></Column>
              <Column field="TargetCompanyName" bodyStyle={{ width: '200px' }}></Column>
              <Column field="DealID" bodyStyle={{ width: '120px' }}></Column>
              <Column field="DealDate" bodyStyle={{ width: '120px' }}></Column>
              <Column field="DealType" bodyStyle={{ width: '120px' }}></Column>
              <Column field="DealSize" bodyStyle={{ width: '120px' }}></Column>
              <Column field="CompanyStage" bodyStyle={{ width: '150px' }}></Column>
              <Column field="Industry" bodyStyle={{ width: '110px' }}></Column>
              <Column field="LeadPartner" bodyStyle={{ width: '170px' }}></Column>
            </DataTable>
          </div>
          :
          // <div className="p-md-10" >Child Companies are not available under this Company.</div>
          <></>
        }
      </div>

    );
  }

  getDataTable() {
    let self = this;
    return (
      <DataTable
        ref={(el) => this.dt = el}
        value={this.state.activitiesData}
        totalRecords={this.state.totalRecordsLength}
        expandedRows={this.state.expandedRows}
        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
        rowExpansionTemplate={this.rowExpansionTemplate}
        paginator={false}
        lazy={true}
        dataKey={!this.state.expandAll ? "" : "DealID"}
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={this.handleDataChangingOption}
        globalFilter={this.state.globalFilter}
        onFilter={this.isConfirmFilter}
        scrollable={true}
        selection={false}
        scrollHeight='1000px'
        emptyMessage={configMessages.noRecords}
        sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        // selectionMode={'multiple'}
        metaKeySelection={false}
        loading={this.state.isLoading}
        style={this.state.activitiesData && this.state.activitiesData.length === 0 ?
          { textAlign: 'center', marginTop: 0 }
          : { marginTop: 0 }}
      >
        {self.getColumns()}
      </DataTable>
    )
  }

  getActivitiesHeader() {
    // const { t } = this.props
    return (
      <div className='col-12  pb-1'>
        <span className='float-right pt-2'>
          {this.state.totalRecordsLength > 50 ?
            this.getPaginator()
            : null}
        </span>
      </div>
    )
  }
  //getScreenView
  getScreenView() {
    const { handleSubmit, } = this.props;
    return (
      <div>
        {this.props.openFormModal ? <div
        >
          <div className='row'>
            <div className='col-sm-12 pb-2'>
              <span className='float-left'>
                {this.getHeader()}
              </span>
            </div>
          </div>
          {this.state.formType !== 'view' ? this.getScreenBody(handleSubmit) : this.getViewBody()}
          {this.state.formType === 'view' ? this.getEditButton() : null}
          {this.state.formType !== 'add' && this.props.type !== "Rules" ?
            < div >
              <div className='row'>
                {/* {this.getActivitiesHeader()} */}
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  {this.getDataTable()}
                  {/* {this.getDataTable2()} */}
                </div>
              </div>
              <div className='row'>
                {this.getActivitiesHeader()}
              </div>
            </div> : null
          }
        </div> : null
        }
      </div>
    );
  }

  toggle = async (tab, tabName) => {
    await this.getremoveFilters()
    let filterCriteria = this.state.filterCriteria
    let CompanyType = localStorage.getItem("CompanyType")
    filterCriteria.page = '1';
    filterCriteria.limit = '50';
    filterCriteria.direction = 'desc';
    filterCriteria['sortfield'] = ""
    filterCriteria['criteria'] = []
    if (document.getElementById('globalSearch'))
      document.getElementById('globalSearch').value = ""
    if (filterCriteria && filterCriteria.globalSearch) {
      delete filterCriteria.globalSearch
    }
    if (tab === "2") {
      await this.setState({
        activitiesUrl: "ChildCompanies",
        dataurl: "childCompanies",
        defSortField: "CompanyBuySideID",
        criteriakey: ""

      })
    } else if (tab === "3") {
      if (CompanyType === "Public") {
        await this.setState({
          activitiesUrl: apiCalls.publicAffiliates,
          criteriakey: "ParentCik",
          dataurl: "publicAffiliates",
          defSortField: "Name"
        })
      } else {
        await this.setState({
          activitiesUrl: apiCalls.affiliates,
          key: "CI.CompanyID",
          dataurl: "affiliates",
          defSortField: "CompanyID",
        })
      }
    } else if (tab === "4" || tab === "5") {
      await this.setState({
        activitiesUrl: "Investors",
        dataurl: "investors",
        defSortField: tab === "4" ? "InvestorID" : "CompanyID",
        criteriakey: tab === "4" ? "InvestorID" : "CI.CompanyID"
      })
    } else if (tab === "6") {
      await this.setState({
        activitiesUrl: "News",
        dataurl: "news",
        defSortField: "PublishDate",
        criteriakey: ""
      })
    } else if (tab === "7") {
      await this.setState({
        activitiesUrl: "Leaders",
        dataurl: "leaders",
        defSortField: "PersonName",
        criteriakey: ""
      })
    } else if (tab === "8") {
      await this.setState({
        activitiesUrl: "AddressNames",
        dataurl: "addressnames",
        defSortField: "BusinessName",
        criteriakey: ""
      })
    } else if (tab === "9") {
      if (CompanyType === "Public") {
        await this.setState({
          activitiesUrl: "businessNames/publicAPA",
          dataurl: "publicAPAs",
          defSortField: "CIK"
        })
      } else {
        await this.setState({
          activitiesUrl: apiCalls.PreAPA,
          dataurl: "apas",
          defSortField: "CompanyName"
        })
      }

    }
    let companyId = window.location.pathname.split("/")
    let userData = {}
    userData.CompanyID = companyId[2];
    if (this.state.activeTab !== tab) {
      await this.setState({
        userData: userData,
        activitiesData: [],
        activeTab: tab,
        tabName: tabName,
        filterCriteria: filterCriteria,
        first: 0,
        Holding: "",
        searchFilterCriteria: [],
        InvestorStatus: "",
        InvestorType: "",
        selectsearchFilterValue: "",
        totalRecordsLength: 0,
        expandAll: false,
      });
    }
    if (tab !== "1") {
      // if (!this.state.isback) {
      let pathnameVal = this.state.userData.CompanyID ? this.state.userData.CompanyID : this.state.userData.CIK
      let pathname = `/companyView/${pathnameVal}/${this.state.activeTab}/${this.state.tabName}`
      window.history.pushState(null, null, pathname);
      // this.props.history.push(`/companytabView/${pathnameVal}/${this.state.activeTab}/${this.state.tabName}`);
      // }
      this.getActivities();
    } else {
      let pathnameVal = this.state.userData.CompanyID ? this.state.userData.CompanyID : this.state.userData.CIK
      let pathname = `/companyView/${pathnameVal}`;
      // this.getchildCompanyDetails(pathnameVal)//commented because redirecting to previous tab
      window.history.pushState(null, null, pathname);
    }
    localStorage.setItem("SearchedCompany", this.state.selectFilterValue)
    this.expandorCollapse();
  }

  getTabView = () => {
    let CompanyType = localStorage.getItem("CompanyType")
    return (
      <div style={{ width: "100%" }}>
        <Loader loading={this.state.loading} progress={this.state.progress} className="screen-loader" />
        <Nav tabs >
          {this.state.navTabs && this.state.navTabs.length > 0 ? this.state.navTabs.map((item, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={classNames({ active: this.state.activeTab === (index + 1).toString() })}
                  onClick={() => { this.toggle((index + 1).toString(), item); }}
                >
                  {item}
                  {/* {item === "Acquisition" ? "Affiliate/Subsidiary" : item} */}
                </NavLink>
              </NavItem>
            )
          }) : null}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {this.state.activeTab === "1" ?
            <TabPane tabId="1">
              {this.getViewBody()}
            </TabPane>
            :
            <TabPane tabId={this.state.activeTab}>
              {this.getEditButton()}
              {this.getDataTable()}
              {this.getActivitiesHeader()}
            </TabPane>
          }
        </TabContent>
      </div >
    )
  }


  redirectToCompany = async (val, type) => {
    if (type === "results") {
      localStorage.setItem("CompanyType", val)
      await this.setState({
        isRedirectTo: true
      })
    } else {
      if (val && val.persist) {
        val.persist();
      }
      if (val.key === "Enter") {
        localStorage.setItem("SearchedCompany", val.target.value)
        await this.setState({
          isRedirectTo: true
        })

      }

    }
  }

  getScreenHeader() {
    const { t } = this.props;
    let CompanyType = localStorage.getItem("CompanyType")
    return (
      <div>
        <div className='row cardForListMargin'>
          <div className='col-sm-12 '>
            <h4><span className='postionRelative pt-2'>
              {/* <span className='pr-2'>
                <FontAwesomeIcon
                  color='blue'
                  style={{ cursor: "pointer" }}
                  icon={faArrowLeft}
                  data-toggle="tool-tip"
                  onClick={() => this.goBack(this.state.backToid ? -1 : -2)}
                /></span> */}
              <span className={`tableheadericon lnr lnr-${this.props.icon}`} />
              <b
              ><Link to='/company' className="tableType" >
                  {t(this.props.type)}
                </Link>{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
              </b>
            </span>
              <span className="formError pt-2 pl-4" style={{ color: "red", fontSize: 14 }}>{CompanyType === "Public" && this.state.tabName === "Names/Address" ? "Data for this screen is not available for now.Currently we are working on this." : this.state.errorResponse ? this.state.errorMessage : null}</span>
              <span className='float-right pl-3 ' style={{ display: "inline-flex" }}>
                <div>
                  <div
                    className="p-inputgroup searchField"
                  >
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" style={{ display: "block", fontSize: "14px" }} />
                      <span className="p-input-icon-right">
                        < InputText
                          type="search"
                          name='globalSearch'
                          id='globalSearchcompany'
                          style={{ height: 37, paddingLeft: "2.5rem" }}
                          onKeyPress={(e) => this.redirectToCompany(e)}
                          onChange={(e) => this.redirectToCompany(e)}
                          placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                          size={25}
                        //  disabled={true}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </span>
            </h4>
            {this.props.type === "Company" ? this.chooseCompanyType() : null}
          </div >

        </div >

      </div >
    )
  }

  chooseCompanyType = () => {
    let type = localStorage.getItem("CompanyType")
    return (
      <div className='row mr-5 float-right'>
        <div className="field-radiobutton mr-2">
          <RadioButton inputId="city1" className="ml-2 mr-2" name="city" value="Private" onChange={() => this.redirectToCompany("Private", 'results')} checked={type === 'Private'} />
          <label htmlFor="city1" className='mb-0'>Pitchbook</label>
        </div>
        <div className="field-radiobutton mr-2">
          <RadioButton inputId="city2" className="ml-2 mr-2" name="city" value="Public" onChange={() => this.redirectToCompany("Public", 'results')} checked={type === 'Public'} />
          <label htmlFor="city2" className='mb-0'>SEC</label>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className='tableCardBody card-body mt-3'>
          <Loader loader={this.state.isLoading} progress={this.state.progress} className="viewLoader"
          />
          {this.getScreenHeader()}
          {this.getTabView()}
        </div>
        {this.state.companyModal ? this.getCompanyModal() : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
        {this.state.isRedirectTo ? <Redirect to="/company" /> : null}
        {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}
        {this.state.isApplyFilter ? this.getConfirmaionModel() : null}
        {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
      </div>
    );

  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FormModal = reduxForm({
  form: "Common Form Modal", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(FormModal);

// You have to connect() to any reducers that you wish to connect to yourself
FormModal = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(FormModal);

export default withTranslation('common')(FormModal);