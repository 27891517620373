import React, { PureComponent } from 'react';
import {
  Card, CardBody, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Dropdown } from "primereact/dropdown";
import { connect } from 'react-redux';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
// file upload
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from '../App/Loader';
import fetch from '../../config/service'
import dateFormats from '../../containers/UI/FormatDate/formatDate';
// validate
// import validate from './validate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../redux/actions/userActions';
import { async } from '@firebase/util';
const bodyStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "left",
  verticalAlign: "inherit"
}
const bodyStyleRight = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "right",
  verticalAlign: "inherit"
}


const headerStyle = {
  width: 150,
  textAlign: "center",
}
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class PropertyLoaderFilters extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      click: true,
      originalname: "Original Name",
      message: "Message",
      filename: "File Name",
      uploadedByUsername: "Uploaded By Username",
      propertyQueueCollection: "Property Queue Collection",
      initialized: "Initialized",
      priority: "Priority",
      status: "Status",
      numFilesErrored: "Files Errored",
      dateAddedUtc: "Date Added Utc",
      filesParsed: "Files Parsed",
      numberOfPropertiesBeforeDedup: "Number Of Properties Before Dedup",
      numberOfDuplicateProperties: "Number Of Duplicate Properties",
      numberOfPropertiesAfterDedup: "Number Of Properties After Dedup",
      missingMoneyPropertyQueueCollectionName: "Missing Money Property Queue Collection Name",
      fileImportError: "File Import Error",
      hint: "Hint",
      fileWithError: "File With Error",
      errorMessage: "Error Message",
      priorityGroup: []
    };
  }
  getValidation = () => {
    this.setState({ validation: true })
    this.fileUploadRef.clearFile();
  }
  onSelect = (e) => {
    if (!e.target.files[0].name.endsWith(".zip")) {
      this.setState({
        fileName: "",
        fileSize: null,
        uploadedBy: "",
        uploadedDate: "",
        selectedFile: null,
        success: true,
        error: false,
        validation: true
      })
    } else {
      let todaydate = dateFormats.formatDate(
        new Date(),
        "MM/DD/YYYY", "nhg")
      // dataTransfer ? event.dataTransfer.files : event.target.files;
      this.setState({
        fileName: e.target.files[0].name,
        fileSize: this.getSize(e.target.files[0].size),
        uploadedDate: todaydate,
        success: false,
        error: false,
        responseMessage: "",
        selectedFile: e.target.files[0]

      })
      // this.fileUpload.style.display = 'none';
    }
  }

  getSize = (bytes) => {
    if (bytes === 0) {
      return '0 B';
    }

    var k = 1000,
      dm = 3,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  getFileQueue = () => {
    fetch('GET', "fileImportQueue/getAll",)
      .then(async (response) => {
        this.setState({
          FileQueue: response
        })
      }).catch((err) => {
        return err;
      });
  }
  setErrorFilesBasedOnRowClick = async (item) => {
    this.setState({ isLoading: true, })
    fetch('GET', `fileImportQueue/getErrorsForFile?fileImportQueueDocId=${item._id}`,)
      .then(async (response) => {
        this.setState({
          erroredFiles: response,
          isLoading: false,
          missingMoneyObj: null,
          responseMessage: "",
          errorMessageName: "",
        })
      }).catch((err) => {
        return err;
      });
  }
  uploadFileDataToServer = () => {
    let customFormData = {}
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    customFormData.uploadedByUsername = userData.displayName
    customFormData.uploadedByUserId = userData.azureAdUserId
    customFormData.priority = this.state.priorityValue
    let missingMoneyObj = {}, errorMessageName = {}, erroredFiles
    this.setState({ isLoading: true, showValidationMessage: true })
    fetch('POST', "import/uploadMissingMoneyZip", this.state.selectedFile, 'upload', customFormData)
      .then(async (response) => {
        if (response && response.file) {
          missingMoneyObj = { ...missingMoneyObj, ...response.file, }
          missingMoneyObj.message = response.message
        }
        if (response && response.fileImportQueueDoc && response.fileImportQueueDoc.importResults) {
          missingMoneyObj = { ...missingMoneyObj, ...response.fileImportQueueDoc }
          missingMoneyObj.filesParsed = response.fileImportQueueDoc.importResults.filesParsed
          // missingMoneyObj.missingMoneyPropertyQueueCollectionName = response.importResults.missingMoneyPropertyQueueCollectionName
          missingMoneyObj.numberOfDuplicateProperties = response.fileImportQueueDoc.importResults.numberOfDuplicateProperties
          missingMoneyObj.numberOfPropertiesAfterDedup = response.fileImportQueueDoc.importResults.numberOfPropertiesAfterDedup
          missingMoneyObj.numberOfPropertiesBeforeDedup = response.fileImportQueueDoc.importResults.numberOfPropertiesBeforeDedup
        }
        if (response && response.fileImportQueueDoc && response.fileImportQueueDoc.importResults && response.fileImportQueueDoc.importResults.erroredFiles && response.fileImportQueueDoc.importResults.erroredFiles.length > 0) {
          errorMessageName = {
            "status": 500,
            "message": "Error occurred while uploading missing money zipped file.",
            "numFilesErrored": response.fileImportQueueDoc.importResults.erroredFiles.length
          }
          erroredFiles = response.fileImportQueueDoc.importResults.erroredFiles
        }
        if (response && response.errorMessage) {
          errorMessageName = response
          errorMessageName.numFilesErrored = response.errors.length
          erroredFiles = response.errors
        }
        this.setState({
          fileName: "",
          fileSize: null,
          uploadedBy: "",
          uploadedDate: "",
          selectedFile: null,
          sheets: response,
          success: true,
          error: false,
          showValidationMessage: false,
          response: true,
          priorityValue: null,
          erroredFiles: erroredFiles,
          isLoading: false,
          missingMoneyObj: missingMoneyObj,
          errorMessageName: errorMessageName
        })
        this.props.setDataCriteriaforOpportunity({
          criteria: [], limit: this.props.Limit ? this.props.Limit : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 15,
          page: 1,
        }, {})
      }).catch((err) => {
        return err;
      });
  }

  /**
    * 
    * @param {*} rowData 
    * @param {*} column 
    * @returns Using to assign the values to grid for seller signatories
    */
  hoverTemplate = (rowData, column) => {

    return <span className='' data-toggle="tool-tip"
      title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
      {rowData[column.field] ? rowData[column.field] : ""}
    </span >

  }
  removeErrorTable = async () => {
    await this.setState({ erroredFiles: [] })
  }
  handleChange = async (e) => {
    await this.setState({ priorityValue: e.value })
  }
  clearMessage = () => {
    this.setState({
      success: false,
      error: false,
      responseMessage: "",
      errorMessageName: "",
      erroredFiles: [],
      missingMoneyObj: null,
      validation: false
    })
    document.getElementById("myFile").value = null
  }

  InvalidCapture = () => {
    console.log("InvalidCapture")
  }
  uploadToServer = () => {
    this.setState({ click: true })
    this.fileUploadRef.UploadFileToServer();
  }
  getFileImportQueuePriorities = async () => {
    let priority = []
    fetch('GET', "fileImportQueue/getPriorityEnums",)
      .then(async (response) => {
        for (let resp in response) {
          if (!Number.isInteger(response[resp]))
            priority.push({ label: response[resp], value: resp })
        }
        await this.setState({ priorityGroup: priority })
      }).catch((err) => {
        return err;
      });
  }
  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    await this.setState({ loginCredentials: loginCredentials })
    await this.getFileQueue()
    await this.getFileImportQueuePriorities()
    // fileImportQueue/getPriorityEnums
  }
  render() {
    return (
      <Card className="p-0">
        {/* <CardHeader >
          Missing Money Results File Upload
        </CardHeader> */}
        <Loader loader={this.state.isLoading} />
        <CardBody className="p-0">
          <div className='row'>
            <div className='col-12 pl-0 pt-2 mb-3' style={{ display: "flex", justifyContent: "center" }}>
              <h4><b>{"Missing Money Results File Upload"}</b></h4>
            </div>
            <div className='col-sm-12' style={{ textAlign: "center" }}>
              <span >
                <Button className="p-fileupload-choose btn btn-primary" style={{ width: "20%" }} disabled={this.state.selectedFile ? true : false} >
                  <input
                    type="file"
                    id="myFile"
                    onChange={(e) => this.onSelect(e)}
                    accept={"zip/*"}
                    onInvalidCapture={this.InvalidCapture}
                    onInvalid={this.InvalidCapture}
                    onClick={this.clearMessage} />
                  Select Zip File
                </Button>
              </span >
              <FontAwesomeIcon icon={faArrowRight}
                className='pl-1 ml-2'
                color='black'
                data-toggle="tool-tip"
                // title="remove field"
                style={{ height: 20, width: 20 }}
              // onClick={() => this.closeOrderModal()}
              />
              <span style={{ display: "inline-grid", verticalAlign: "bottom" }}>
                <label style={{ fontWeight: "bold" }}>Priority</label>
                <Dropdown
                  options={this.state.priorityGroup}
                  type="text"
                  style={{ width: 30 }}
                  name="label"
                  disabled={this.state.fileName ? false : true}
                  value={this.state.priorityValue}
                  filter={true}
                  onChange={(e) => this.handleChange(e)}
                  className="form-control ml-2 mb-3"
                />
              </span>
              <FontAwesomeIcon icon={faArrowRight}
                className='pl-1 ml-2'
                color='black'
                data-toggle="tool-tip"
                // title="remove field"
                style={{ height: 20, width: 20 }}
              // onClick={() => this.closeOrderModal()}
              />
              {/* {this.state.fileName ?
                <h5 >{this.state.fileName}</h5>
                : ""} */}
              <span >
                <Button
                  style={{ width: "20%", height: 34, }}
                  color="primary"
                  className="import_upload ml-2 mt-1 mb-4 mt-1"
                  onClick={() => this.uploadFileDataToServer()}
                  disabled={this.state.fileName ? false : true}
                >Upload</Button>
              </span>
              {this.state.fileName ?
                <h5 style={{ fontWeight: "bold" }} >{`File Name: ${this.state.fileName}`}</h5>
                : ""}
              {this.state.showValidationMessage ?
                <h5 style={{ fontWeight: "bold", color: "green" }} >{`It may take a couple of minutes. Please do not close this window until then.`}</h5>
                : ""}
              {this.state.missingMoneyObj ?
                <div style={{ textAlign: "left" }}>
                  <div class=" mt-2 " style={{ borderBottom: "2px solid #aaa", fontWeight: "bold" }}> Success :</div>
                  {Object.entries(this.state.missingMoneyObj).map((t, k) =>
                    this.state[t[0]] ? <div className='row'>
                      <div class="mt-2 col-lg-2 font-weight-bold" >{this.state[t[0]]}</div>
                      <div class="mt-2" >{this.state[t[0]] ? ":" : null}</div>
                      <div class="mt-2 ml-3" style={{ color: "green" }} >{this.state[t[0]] ? t[1] : null}</div>
                    </div> : null
                  )}
                  {/* {Object.entries(this.state.responseFile).map((t, k) =>
                    <div className='row'>
                      <div class="mt-2 col-lg-2 font-weight-bold" >{this.state[t[0]]}</div>
                      <div class="mt-2" >{this.state[t[0]] ? ":" : null}</div>
                      <div class="mt-2 ml-3" style={{ color: "green" }} >{this.state[t[0]] ? t[1] : null}</div>
                    </div>)}
                  */}
                </div>
                : ""}
              {this.state.errorMessageName ?
                <div class="mt-2" style={{ textAlign: "left", borderTop: "2px solid #aaa" }}>
                  <div class="mt-2" style={{ borderBottom: "2px solid #aaa", fontWeight: "bold" }}> Errors :</div>
                  {Object.entries(this.state.errorMessageName).map((t, k) =>
                    <div className='row'>
                      <div class="mt-2 col-lg-2 font-weight-bold" >{this.state[t[0]]}</div>
                      <div class="mt-2" >{this.state[t[0]] ? ":" : null}</div>
                      <div class="mt-2 ml-3" style={{ color: "red" }} >{this.state[t[0]] ? t[1] : null}</div>
                    </div>)}

                </div>
                : ""}
            </div>
            {this.state.erroredFiles && this.state.erroredFiles.length > 0 ?
              <div>
                <FontAwesomeIcon
                  icon={faTimes}
                  className='Addbtn mt-2 mr-2' size='lg'
                  style={this.state.errorMessageName ? { position: "absolute", top: "600px", right: "0px" } : { position: "absolute", top: "90px", right: "0px" }}
                  color='red'
                  data-toggle="tool-tip" title={("Clear Table")}
                  onClick={(e) => this.removeErrorTable()}
                />



                <DataTable value={this.state.erroredFiles} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ marginTop: 15, marginLeft: 15 }}
                >
                  <Column field="fileWithError" header="File With Error" bodyStyle={bodyStyle} headerStyle={{
                    width: 120,
                    textAlign: "center",
                  }} body={this.hoverTemplate}
                  />
                  <Column field="hint" header="Hint" bodyStyle={bodyStyleRight} body={this.hoverTemplate} headerStyle={{
                    width: 250,
                    textAlign: "center",
                  }} />
                  <Column field="fileImportError" header="File Import Error" bodyStyle={bodyStyleRight} body={this.hoverTemplate} headerStyle={headerStyle} />
                </DataTable>  </div> : null}

            <div>
              {this.state.validation ?
                <i> <h5 >*Please select only Zip file.</h5></i>
                : ""}
            </div>
          </div>

        </CardBody>
      </Card >
    )
  }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PropertyLoaderFilters = reduxForm({
  form: "PropertyLoaderFilters", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(PropertyLoaderFilters);

// You have to connect() to any reducers that you wish to connect to yourself
PropertyLoaderFilters = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(PropertyLoaderFilters);

export default withTranslation('common')(PropertyLoaderFilters);