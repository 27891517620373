import React from 'react';
import { withTranslation } from 'react-i18next';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service'
import configMessages from '../../../../config/configMessages';
import statesList from '../../../../shared/states.json'
// config file
class AddressScreenProps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      namesArray: [],
      stateoptions:[...statesList.map((e) => { return { label: e.shortCode, value: e.shortCode } })],
    };
  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  //get Hierarchy Dropdown for
  getHierarchyData = async (dimSellerId) => {
    let namesArray = []
    let urlNames = `${apiCalls.HeirarchyCompany}?str=${dimSellerId}`
    fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.companyIDs) {
          for (let name of response.companyIDs) {
            namesArray.push({ label: name.CompanyName, value: name.CompanyID, BusinessID: name.BusinessID })
          }

          await this.setState({ namesArray: namesArray })
        } else {
          this.setState({ namesArray: [], })
        }

      }).catch((err) => {
        return err;
      });

    await this.getIssueNameCode()
    await this.getnameTypeArrayCode()
    await this.getLegacyConnectedName(dimSellerId)
    await this.getLegacyCompanyNameDBS(dimSellerId)
    return this.getaddressFields()

  }
  //Issue Names Dropdown
  getLegacyCompanyNameDBS = async (dimSellerId) => {
    var tablefieldsToShow = this.state.tableFieldsfromParentComponents
    let nameConnectionArray = [], fileForOptionsforDBS = []
    return fetch('GET', `${apiCalls.sellerRelevanceNames}?estateId=${dimSellerId}&nameType=sellerRelevanceName`)
      .then(async (response) => {
        if (response && response["sellerRelevanceNames"].length > 0) {
          let propertyReasons = response["sellerRelevanceNames"]
          for (let reason of propertyReasons) {
            if (this.state.fileForOptions) {
              let dataIndex = this.state.fileForOptions.findIndex((obj) => obj.label === reason.CombinedNameLabel)
              if (dataIndex === -1) {
                this.state.fileForOptions.push({  label: reason.CombinedNameLabel, value: reason.CombinedNameLabel, BusinessName: reason.BusinessName})
              }
            }
            nameConnectionArray.push({label: reason.CombinedNameLabel, value: reason.CombinedNameLabel, BusinessName: reason.BusinessName,BusinessNamesID: reason.BusinessNamesID,legacyConnectedName:reason.CombinedNameLabel });
            // mappedNames.push({ label: reason.CombinedName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
          }
          await this.setState({ fileForOptionsforDBS: nameConnectionArray, fileForOptions: this.state.fileForOptions });
        } else if (response && response['errorMessage'] === configMessages.sessionWarning) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getLegacyConnectedName = async (dimSellerId) => {
    var tablefieldsToShow = this.state.tableFieldsfromParentComponents
    let nameConnectionArray = [], fileForOptions = []
    return fetch('GET', `${apiCalls.sellerRelevanceNames}?estateId=${dimSellerId}&nameType=sellerRelevanceName&type=ConnectedName`)
      .then(async (response) => {
        if (response && response["sellerRelevanceNames"].length > 0) {
          let propertyReasons = response["sellerRelevanceNames"]
          for (let reason of propertyReasons) {
            nameConnectionArray.push({ label: reason.CombinedNameLabel, value: reason.CombinedNameLabel, BusinessName: reason.BusinessName,BusinessNamesID: reason.BusinessNamesID });

            // mappedNames.push({ label: reason.CombinedName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
          }
          await this.setState({ fileForOptions: nameConnectionArray });
        } else if (response && response['errorMessage'] ===configMessages.sessionWarning) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  //Issue Names Dropdown
  getIssueNameCode = async () => {
    let issueNameCodes = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }] }
    let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.businessNameIssueCodeConfigurations) {
          for (let name of response.businessNameIssueCodeConfigurations) {
            issueNameCodes.push({ label: name.BusinessNameIssueLabel, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
          }

          await this.setState({ issueNameOptions: issueNameCodes })
        } else {
          this.setState({ issueNameOptions: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  //get Name Type Array
  getnameTypeArrayCode = async () => {
    var tablefieldsToShow = this.state.tableFieldsfromParentComponents
    let nameTypeArray = []
    let url1 = `${apiCalls.distinctNameTypes}`;
    await fetch("GET", url1)
      .then(async (response) => {
        if (response && response.results) {
          for (var result of response.results) {
            nameTypeArray.push({ label: result.NameType, value: result.NameType })
          }
          //after getting api response for dropdown options sending table Fields to Datatable
          await this.setState({ nameTypeArray: nameTypeArray })
        } else {
          this.setState({ nameTypeArray: [] })
        }
      }).catch((err) => {
        return err;
      });
    // this.sendColumnDataToServer()
  }

  /*
  For Getting table Fields
  */
  getaddressFields() {
    var data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //     "show": true,
      //     "textAlign": "left",
      //     "width": 90,
      //     "fieldType": 'Actions',
      //     "field": "Actions",
      //     "header": "Actions",
      //     "filter": false,
      //     "displayInSettings": true,
      //     "sortable": false
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 200,
        filterType: "num",
        editField: "text",
        field: 'LegacyOPRAEstateID',
        keyfilter: "pint",
        isRequired: true,
        header: 'Estate ID',
        "filter": true,
        "sortable": true,
        // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'CompanyID',
        editField: "dropdown",
        options: this.state.namesArray,
        required: true,
        header: 'Company ID',
        isAdd: true,
        "filter": true,
        "sortable": true,
        // filter: false,
        // sortable: true,
      },
      // {
      //   show: true,
      //   mobile: true,
      //   textAlign: 'left',
      //   editField: "text",
      //   width: 130,
      //   field: 'CompanyID',
      //   getField: "EntityID",
      //   header: 'Company ID',
      //   fieldType: "EntityID",
      //   "filter": true,
      //   "sortable": true,                    // sortable: true,
      // },
      // {
      //   show: true,
      //   mobile: true,
      //   textAlign: 'left',
      //   editField: "text",
      //   width: 130,
      //   field: 'EntityName',
      //   getField: "EntityName",
      //   header: 'Entity Name',
      //   fieldType: "EntityName",
      //   "filter": true,
      //   "sortable": true,                    // sortable: true,
      // },
      // {
      //     show: true,
      //     mobile: true,
      //     textAlign: 'left',
      // editField: "text",
      //     width: 130,
      //     field: 'LocationName',
      //     getField: "LocationName",
      //     header: 'Location Name',
      //     fieldType: "LocationName",
      //     filter: false,
      //     // sortable: true,
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'LocationType',
        getField: "LocationType",
        header: 'Type',
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyAddressSubTypeID',
        getField: "LegacyAddressSubTypeID",
        header: 'Sub Type',
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'LegacyNameFormat',
        getField: "LegacyNameFormat",
        header: 'Name Format',
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        field: 'LegacyExternalList',
        getField: "LegacyExternalList",
        header: 'External List',
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'LegacyCodeDate',
        getField: "LegacyCodeDate",
        header: 'Code Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'LegacyDateAdded',
        getField: "LegacyDateAdded",
        header: 'Date Added',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "dropdown",
        options: this.state.fileForOptionsforDBS,
        dataKey:"BusinessName",
        width: 130,
        dataKey:"BusinessNamesID",
        field: 'LocationNameLabel',
        getField: "LocationNameLabel",
        header: 'Company Names/DBAs',
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'LegacyConnectedNameLabel',
        dataKey:"BusinessNamesID",
        getField: "LegacyConnectedNameLabel",
        header: 'Connected Name',
        editField: "dropdown",
        options: this.state.fileForOptions,
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'Store',
        getField: "Store",
        header: 'Store',
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'Attn',
        getField: "Attn",
        header: 'ATTN',
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'Line1',
        getField: "Line1",
        required: true,
        header: 'Line 1',
        //fieldType: "Line1",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'Line2',
        required: true,
        getField: "Line2",
        header: 'Line 2',
        //fieldType: "Line2",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'City',
        getField: "City",
        required: true,
        header: 'City',
        //fieldType: "City",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "dropdown",
        options: this.state.stateoptions,
        width: 130,
        field: 'StateProvince',
        required: true,
        header: 'State',
        //fieldType: "State",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'Zip',
        getField: "Zip",
        required: true,
        header: 'Zip',
        //fieldType: "Zip",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'Country',
        getField: "Country",
        header: 'Country',
        //fieldType: "Country",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'AddressSource',
        getField: "AddressSource",
        header: 'Source',
        //fieldType: "Source",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'AddressNote',
        getField: "AddressNote",
        header: 'Note',
        //fieldType: "Note",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'LegacyLandLordInfo',
        getField: "LegacyLandLordInfo",
        header: 'Landlord Info',
        //fieldType: "Landlord Info",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'LegacyBNCNotices',
        getField: "LegacyBNCNotices",
        header: 'BNC Notices',
        //fieldType: "BNC Notices",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'KeyAddress',
        // getField: "Keyaddress",
        header: 'Key Address',
        //fieldType: "Key Address",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'PortfolioKeyAddress',
        getField: "PortfolioKeyAddress",
        header: 'Portfolio Key Address',
        //fieldType: "Portfolio Key Address",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'AssociatedCaseNumber',
        getField: "AssociatedCaseNumber",
        header: 'Associated Case #',
        //fieldType: "Associated Case #",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'MailForwardReviewEstate',
        getField: "MailForwardReviewEstate",
        header: 'Mail Forward (Estate) - Review',
        //fieldType: "Mail Forward (Estate) - Review",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'MailForwardNotesEstateCreatedDateUTC',
        getField: "MailForwardNotesEstateCreatedDateUTC",
        header: 'Mail Forward (Estate) - Created Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'MailForwardNotesEstateUpdatedDateUTC',
        getField: "MailForwardNotesEstateUpdatedDateUTC",
        header: 'Mail Forward (Estate) - Updated Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'MailForwardNotesEstate',
        getField: "MailForwardNotesEstate",
        header: 'Mail Forward (Estate) - Notes',
        //fieldType: "Mail Forward (Estate) - Notes",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'MailForwardReviewAddress',
        getField: "MailForwardReviewAddress",
        header: 'Mail Forward (Address) - Review',
        //fieldType: "Mail Forward (Address) - Review",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'MailForwardNotesAddressCreatedDateUTC',
        getField: "MailForwardNotesAddressCreatedDateUTC",
        header: 'Mail Forward (Address) - Created Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'MailForwardNotesAddressUpdatedDateUTC',
        getField: "MailForwardNotesAddressUpdatedDateUTC",
        header: 'Mail Forward (Address) - Updated Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'MailForwardNotesAddress',
        getField: "MailForwardNotesAddress",
        header: 'Mail Forward (Address) - Notes',
        //fieldType: "Mail Forward (Address) - Notes",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'CourtCaseKey',
        getField: "CourtCaseKey",
        header: 'Court Case Key',
        //fieldType: "Court Case Key",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'LeadCourtCaseKey',
        getField: "LeadCourtCaseKey",
        header: 'Lead Court Case Key',
        //fieldType: "Lead Court Case Key",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 130,
        // keyfilter: "pint",
        filterType: "num",
        field: 'BusinessLocationID',
        getField: "BusinessLocationID",
        header: 'Business Location ID',
        //fieldType: "External List",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'LocationStatus',
        getField: "LocationStatus",
        header: 'Location Status',
        //fieldType: "Location Status",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'OfficePhone',
        getField: "OfficePhone",
        header: 'Office Phone',
        //fieldType: "Office Phone",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'OfficeFax',
        getField: "OfficeFax",
        header: 'Office Fax',
        //fieldType: "OfficeFax",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        editField: "dropdown",
        width: 200,
        options: [{ label: "Yes", value: true }, { label: "No", value: false }],
        field: 'IsKey',
        header: 'Is Key?',
        // "filter": true,
        "sortable": true,                    // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'LastUpdated',
        getField: "LastUpdated",
        header: 'Last Updated',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'BusinessID',
        getField: "BusinessID",
        header: 'Business ID',
        //fieldType: "BusinessID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        filterType: "num",
        keyfilter: "pint",
        field: 'DataSourceID',
        getField: "DataSourceID",
        header: 'Data Source ID',
        //fieldType: "DataSourceID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',

        // editField: "date",
        width: 130,
        field: 'DWDateInserted',
        getField: "DWDateInserted",
        header: 'DW Date Inserted',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "date",
        width: 130,
        field: 'DWDateLastModified',
        getField: "DWDateLastModified",
        header: 'DW Date Last Modified',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 130,
        field: 'DWLastOperation',
        getField: "DWLastOperation",
        header: 'DW Last Operation',
        //fieldType: "DWLastOperation",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 130,
        field: 'DWLastModifiedBy',
        getField: "DWLastModifiedBy",
        header: 'DW Last Modified By',
        //fieldType: "DWLastModifiedBy",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 130,
        field: 'DWDelete',
        getField: "DWDelete",
        header: 'DW Delete',
        //fieldType: "DWDelete",
        // "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        width: 130,
        field: 'DWRowIsCurrent',
        getField: "DWRowIsCurrent",
        header: 'DW Row Is Current',
        //fieldType: "DWRowIsCurrent",
        // "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        filterType: "num",
        keyfilter: "pint",
        field: 'LegacyOPRAEstateToAddressID',
        header: 'Legacy OPRA Estate To Address ID',
        //fieldType: "LegacyOPRAEstateToAddressID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyOPRAEstateNameID',
        getField: "LegacyOPRAEstateNameID",
        header: 'Legacy OPRA Estate Name ID',
        //fieldType: "LegacyOPRAEstateNameID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        keyfilter: "pint",
        width: 130,
        filterType: "num",
        field: 'LegacyOPRAAddressID',
        header: 'Legacy OPRA Address ID',
        //fieldType: "LegacyOPRAAddressID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyOriginalPropertyId',
        getField: "LegacyOriginalPropertyId",
        header: 'Legacy Original Property ID',
        //fieldType: "LegacyOriginalPropertyId",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        editField: "dropdown",
        width: 130,
        options: [{ label: "Yes", value: true }, { label: "No", value: false }],
        field: 'IsHeadquarters',
        header: 'Is Headquarters',
        // "filter": true,
        "sortable": true,                    // sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyOwnershipTypeId',
        getField: "LegacyOwnershipTypeId",
        header: 'Legacy Ownership Type ID',
        "filter": true,
        "sortable": true,
        //fieldType: "LegacyOwnershipTypeId",
      },
      //  {
      //   show: true,
      //   textAlign: 'left',
      //   editField: "text",
      //   width: 130,
      //   keyfilter: "pint",
      //   field: 'LegacyOwnershipSubtypeId',
      //   header: 'Legacy Ownership SubType ID',
      //   //fieldType: "LegacyOwnershipSubtypeId",
      //   "filter": true,
      //   "sortable": true,                    // sortable: true,
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'LegacyIssues',
        header: 'Legacy Issues',
        //fieldType: "LegacyIssues",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyEstateToAddressStatusID',
        getField: "LegacyEstateToAddressStatusID",
        header: 'Legacy Estate To Address Status ID',
        //fieldType: "LegacyEstateToAddressStatusID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'LegacyEstateToAddressCreatedDate',
        getField: "LegacyEstateToAddressCreatedDate",
        header: 'Legacy Estate To Address Created Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'LegacyEstateToAddressUpdatedDate',
        header: 'Legacy Estate To Address Updated Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint", filterType: "num",
        field: 'OPRAAddressId',
        getField: "OPRAAddressId",
        header: 'OPRA Address ID',
        //   fieldType: "OPRAAddressId",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'Line3',
        header: 'Line 3',
        //   fieldType: "Line3",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'County',
        getField: "County",
        header: 'County',
        //   fieldType: "County",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        editField: "dropdown",
        width: 130,
        options: [{ label: "Yes", value: true }, { label: "No", value: false }],
        field: 'LegacyIsComplete',
        header: 'Legacy Is Complete',
        // "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        editField: "dropdown",
        width: 130,
        options: [{ label: "Yes", value: true }, { label: "No", value: false }],
        field: 'LegacyIsUseless',
        header: 'Legacy Is Use less',
        // "filter": true,
        "sortable": true,                    // sortable: true
      },


      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'LegacyAddressCreatedDate',
        getField: "LegacyAddressCreatedDate",
        header: 'Legacy Address Created Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'LegacyAddressUpdatedDate',
        getField: "LegacyAddressUpdatedDate",
        header: 'Legacy Address Up dated Date',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        keyfilter: "pint",
        field: 'LegacySearchedAsPropertyDateUTC',
        getField: "LegacySearchedAsPropertyDateUTC",
        header: 'Legacy Searched As Property Date UTC',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        keyfilter: "pint",
        field: 'LegacySearchedAsEstateDateUTC',
        getField: "LegacySearchedAsEstateDateUTC",
        header: 'Legacy Searched As Estate Date UTC',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        // editField: "text",
        editField: "dropdown",
        width: 130,
        options: [{ label: "Yes", value: true }, { label: "No", value: false }],
        field: 'LegacyGroomingFlag',
        header: 'Legacy Grooming Flag',
        // "filter": true,
        "sortable": true,                    // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        filterType: "num",
        keyfilter: "pint",
        field: 'LegacyResidentialFlag',
        getField: "LegacyResidentialFlag",
        header: 'Legacy Residential Flag',
        //   fieldType: "LegacyResidentialFlag",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        // keyfilter: "pint",
        field: 'LegacySS_AddressLine1',
        getField: "LegacySS_AddressLine1",
        header: 'Legacy SS Address Line 1',
        //   fieldType: "LegacySS_AddressLine1",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        // keyfilter: "pint",
        field: 'LegacySS_AddressLine2',
        getField: "LegacySS_AddressLine2",
        header: 'Legacy SS Address Line 2',
        //   fieldType: "LegacySS_AddressLine2",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        // keyfilter: "pint",
        field: 'LegacySS_City',
        getField: "LegacySS_City",
        header: 'Legacy City',
        //   fieldType: "LegacySS_City",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        // keyfilter: "pint",
        field: 'LegacySS_State',
        getField: "LegacySS_State",
        header: 'Legacy State',
        //   fieldType: "LegacySS_State",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        // keyfilter: "pint",
        field: 'LegacySS_ZipCode',
        getField: "LegacySS_ZipCode",
        header: 'Legacy Zip Code',
        //   fieldType: "LegacySS_ZipCode",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pnum",
        field: 'LegacySS_Latitude',
        getField: "LegacySS_Latitude",
        header: 'Legacy Latitude',
        //   fieldType: "LegacySS_Latitude",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pnum",
        field: 'LegacySS_Longitude',
        getField: "LegacySS_Longitude",
        header: 'Legacy SS Longitude',
        //   fieldType: "LegacySS_Longitude",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyOwnershipSubtypeId',
        getField: "LegacyOwnershipSubtypeId",
        header: 'Legacy Ownership Subtype ID',
        //   fieldType: "LegacyOwnershipSubtypeId",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'LegacySS_LastRanDateUTC',
        getField: "LegacySS_LastRanDateUTC",
        header: 'Legacy SS Last Ran Date UTC',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyAddressDetailID',
        getField: "LegacyAddressDetailID",
        header: 'Legacy Address Detail ID',
        //   fieldType: "LegacyAddressDetailID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyAddressTypeID',
        getField: "LegacyAddressTypeID",
        header: 'Legacy Address Type ID',
        //   fieldType: "LegacyAddressTypeID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        keyfilter: "pint",
        filterType: "num",
        field: 'LegacyAddressDetailStatusID',
        getField: "LegacyAddressDetailStatusID",
        header: 'Legacy Address Detail Status ID',
        //   fieldType: "LegacyAddressDetailStatusID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'CompanyName',
        header: 'Company Name',
        //   fieldType: "CompanyName",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'Attention',
        getField: "Attention",
        header: 'Attention',
        //   fieldType: "Attention",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'SuiteUnit',
        getField: "SuiteUnit",
        header: 'Suite Unit',
        //   fieldType: "SuiteUnit",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'GeneralNotes',
        getField: "GeneralNotes",
        header: 'General Notes',
        //   fieldType: "GeneralNotes",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'GeneralNotesCreatedDateUTC',
        getField: "GeneralNotesCreatedDateUTC",
        header: 'General Notes Created Date UTC',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "date",
        width: 130,
        field: 'GeneralNotesUpdatedDateUTC',
        getField: "GeneralNotesUpdatedDateUTC",
        header: 'General Notes Updated Date UTC',
        fieldType: "dateTime",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
    ];
    return data;
  }

  /*
   For Getting required Fields to save or edit record
   */
  getrequiredFieldsforScreen() {
    let array = ["CompanyID"]
    return array
  }

  /*
 For Getting required Fields to save or edit record
 */
  getanyOnerequiredFieldsforScreen() {
    let array = ["Line1", "Line2", "City", "Zip", "StateProvince"]
    return array
  }


  /*
  For Adding new Record Setting Empty Obj
  */
  getnewRecord = () => {
    let newRec = {
      "BusinessLocationID": 0,
      "CompanyID": null,
      "LocationName": null,
      "LocationType": null,
      "LocationStatus": null,
      "Line1": null,
      "Line2": null,
      "City": null,
      "StateProvince": null,
      "Zip": null,
      "Country": null,
      "OfficePhone": null,
      "OfficeFax": null,
      "AddressSource": null,
      "AddressNote": null,
      "IsKey": false,
      "LastUpdated": null,
      "BusinessID": null,
      "DataSourceID": null,
      "DWDateInserted": null,
      "DWDateLastModified": null,
      "DWLastOperation": "I",
      "DWLastModifiedBy": null,
      "DWDelete": false,
      "DWRowIsCurrent": true,
      "LegacyOPRAEstateToAddressID": null,
      "LegacyOPRAEstateID": null,
      "LegacyOPRAEstateNameID": null,
      "LegacyOPRAAddressID": null,
      "LegacyOriginalPropertyId": null,
      "IsHeadquarters": null,
      "LegacyOwnershipTypeId": null,
      "LegacyOwnershipSubtypeId": null,
      "LegacyIssues": null,
      "LegacyEstateToAddressStatusID": null,
      "LegacyEstateToAddressCreatedDate": null,
      "LegacyEstateToAddressUpdatedDate": null,
      "OPRAAddressId": null,
      "Attn": null,
      "Line3": null,
      "County": null,
      "LegacyIsComplete": null,
      "LegacyIsUseless": null,
      "LegacyAddressCreatedDate": null,
      "LegacyAddressUpdatedDate": null,
      "LegacySearchedAsPropertyDateUTC": null,
      "LegacySearchedAsEstateDateUTC": null,
      "LegacyGroomingFlag": null,
      "LegacyResidentialFlag": null,
      "LegacySS_AddressLine1": null,
      "LegacySS_AddressLine2": null,
      "LegacySS_City": null,
      "LegacySS_State": null,
      "LegacySS_ZipCode": null,
      "LegacySS_Latitude": null,
      "LegacySS_Longitude": null,
      "LegacySS_LastRanDateUTC": null,
      "LegacyAddressDetailID": null,
      "LegacyNameFormat": null,
      "LegacyAddressTypeID": null,
      "LegacyAddressSubTypeID": null,
      "LegacyAddressDetailStatusID": null,
      "LegacyExternalList": null,
      "LegacyCodeDate": null,
      "LegacyDateAdded": null,
      "CompanyName": null,
      "Store": null,
      "Attention": null,
      "SuiteUnit": null,
      "GeneralNotes": null,
      "GeneralNotesCreatedDateUTC": null,
      "GeneralNotesUpdatedDateUTC": null,
      "LegacyLandLordInfo": null,
      "LegacyBNCNotices": null,
      "PortfolioKeyAddress": null,
      "AssociatedCaseNumber": null,
      "MailForwardNotesEstate": null,
      "MailForwardNotesEstateCreatedDateUTC": null,
      "MailForwardNotesEstateUpdatedDateUTC": null,
      "MailForwardReviewEstate": null,
      "MailForwardNotesAddress": null,
      "MailForwardNotesAddressCreatedDateUTC": null,
      "MailForwardNotesAddressUpdatedDateUTC": null,
      "MailForwardReviewAddress": null,
      "LegacyImportLogId": null,
      "MailForwardEstateDate": null,
      "MailForwardAddressDate": null,
      "LegacyConnectedName": null,
      "CourtCaseKey": null,
      "LeadCourtCaseKey": null,
      "KeyAddress": null,
      "EntityName": null
    }
    return newRec
  }



  render() {

    return (
      <></>
    );
  }
}
export default withTranslation('common')(AddressScreenProps);
