import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import fetchMethodRequest from '../../../../config/service';
import AvailableToFileV2Filters from '../components/AvailableToFileFilters';
import apiCalls from '../../../../config/apiCalls';
import { customisedRowHighlights } from '../../CommonDataTable/CustomStyling';
// config file
export default class AvailableToFileV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tabType: "",
      type: 'Ready To File V2',
      reportType: "ReadyToFile",
      apiUrl: apiCalls.AvailableFileV2,
      responseKey: "filingPlanningSummary",
      filter: { criteria: [] }
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }


  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.getActiveTabFilterCriteria("Ready To File")
    this.getSettings()
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], departmentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
      DepartmentArray.push(user.department)
      departmentIdArray.push(user.departmentId)
      await this.setState({ Department: DepartmentArray, departmentIdArray: departmentIdArray, currentscreenCriteria: "Ready To File" })
    }
  }

  //To get the filter criteria for the payload

  getActiveTabFilterCriteria = async (screen) => {
    let statusReasonArray = []
    let StatusArray = [];
    let filterCriteria = {}
    screen = screen.replace(" V2", "")
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'screen', value: screen, type: "eq" }];
    await fetchMethodRequest('GET', `${apiCalls.AvailableToFile}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["statusConfigurations"] && response["statusConfigurations"].length > 0) {
        let propertyStatuses = response["statusConfigurations"]
        for (let status of propertyStatuses) {
          StatusArray.push(status.status)
          if (status.reasons && status.reasons.length > 0) { statusReasonArray.push(status.reasons) }
        }
        await this.setState({ statusbasedonActiveTab: StatusArray, statusReasonArray: statusReasonArray, currentscreenCriteria: screen })
      }
    })
    // await this.additionalCriteria()
    return StatusArray;
  }


  getSettings = async () => {
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    this.setState({
      isLoading: true
    });
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchMethodRequest('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        this.setState({
          isLoading: false,
          settingsData: settingsData,
        });
      }
    })
  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  additionalCriteria = async () => {
    let statuses = await this.getActiveTabFilterCriteria(this.state.tabType ? this.state.tabType : "Ready To File V2")
    let filter = this.datatableref.getFiltercriteria()
    let obj = {}
    if (this.state.tabType === "Resource Needed V2") {
      obj['key'] = "PropertyStatus"
    } else {
      obj['key'] = "propertyStatus"
    }
    obj['value'] = statuses
    obj['type'] = 'in'
    obj["add_Filter"] = true
    if (this.state.tabType !== "Resource Needed V2") {
      var index = filter["criteria"].findIndex(obj => obj.key === "propertyStatus")
    } else {
      var index = filter["criteria"].findIndex(obj => obj.key === "PropertyStatus")
    }
    if (index !== -1) { filter["criteria"].splice(index, 1) }
    filter['criteria'].push(obj)
    await this.setState({ filter: filter })
    return filter
  }

  //get call with API 
  getBasicCall = (criteria, obj, clear) => {
    this.setState({ postBody: obj })
    if (this.datatableref) {
      this.datatableref.setDataCriteriaforOpportunity(criteria, obj, clear)
    }
  }

  //get criteria from datatable
  getFiltercriteria = () => {
    if (this.datatableref) {
      return this.datatableref.getFiltercriteria()
    }
  }

  onChangeDepartment = (e, type, departmentIdArray) => {
    if (this.datatableref) {
      return this.datatableref.onChangeDepartment(e, 'selectedDepartment', departmentIdArray)

    }
  }

  getTaskFilters = () => {
    return (
      <AvailableToFileV2Filters
        getFiltercriteria={this.getFiltercriteria}
        getBasicCall={this.getBasicCall}
        onChangeDepartment={this.onChangeDepartment}
        type={this.state.type}
      />
    )
  }

  getFormFields = () => {
    return ([
    ]);
  }

  //settingRedirectedUrl
  setRedirectUrl = async (item, clickedField, postBodyFile, screen) => {
    let url
    url = `${window.location.protocol}//${window.location.host}/${screen}?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"].CompanyID}&FileFor=${encodeURIComponent((item.FileFor === null || item.FileFor === undefined) ? null : item.FileFor)}${this.state.reportType ? `&reportType=${this.state.reportType}` : ""}&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}${postBodyFile && postBodyFile.ConfirmedOnsite ? `&confirmedOnsite=` + encodeURIComponent(postBodyFile.ConfirmedOnsite) : ""}&bucket=${"All"}${this.state.statusbasedonActiveTab.length > 0 ? `&selectedPropertyStatus=${encodeURIComponent(this.state.statusbasedonActiveTab)}` : ""}${this.state.statusReasonArray && this.state.statusReasonArray.length > 0 ? `&selectedPropertyReasons=${encodeURIComponent(this.state.statusReasonArray)}` : ""}${item && item.HolderName ? `&HolderName=` + encodeURIComponent(item.HolderName) : ""}`
    return url
  }


  getTableFields = (tabType) => {
    if (tabType) {
      let apiUrl = this.state.apiUrl; let reportType = ""
      let responseKey = this.state.responseKey;
      if (tabType === 'Resource Needed V2') {
        apiUrl = apiCalls.ResourceNeededV2;
        responseKey = "results"
      } else {
        apiUrl = apiCalls.AvailableFileV2;
        responseKey = "filingPlanningSummary"
      }

      if (tabType === 'On Hold V2') {
        reportType = "OnHold"
      } else if (tabType === 'Resource Needed V2') {
        reportType = ""
      } else if (tabType === "Seller Negative Notice V2") {
        reportType = "SellerNegativeNotice"
      } else if (tabType === "CA-N Properties V2") {
        reportType = "CA-N"
      } else {
        reportType = "ReadyToFile"
      }
      this.setState({
        tabType: tabType,
        apiUrl: apiUrl,
        reportType: reportType,
        type: tabType,
        responseKey: responseKey
      })
    }
    if (tabType && tabType !== this.state.currentscreenCriteria) {
      // this.getActiveTabFilterCriteria(tabType)
    }
    let data = [];
    if (tabType === 'Ready To File' || tabType === "Seller Negative Notice" || tabType === 'On Hold') {
      data = this.getOtherTabsTableFields();
    } else if (tabType === "CA-N Properties V2") {
      data = this.getCANFieldsV2();
    }
    else if (tabType === 'Resource Needed V2') {
      data = this.getResourceNeededTabTableFields();
    } else {
      data = this.getOtherTabsTableFields();
    }
    return data;
  };

  getResourceNeededTabTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "SourceState",
        "mobile": false,
        "header": "State",
        // "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "ADDR EVID",
        "mobile": false,
        "fieldType": 'number',
        "header": "ADDR EVID",
        // "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "APA EXCLSN",
        "mobile": false,
        "header": "APA EXCLSN",
        // "filter": true,
        "fieldType": 'number',
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "APA EXHIBIT REVSN",
        "mobile": false,
        "fieldType": 'number',
        "header": "APA EXHIBIT REVSN",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "CASHIER'S CHK",
        "mobile": false,
        "fieldType": 'number',
        "header": "CASHIER'S CHK",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "CHAIN OF TITLE",
        "mobile": false,
        "fieldType": 'number',
        "header": "CHAIN OF TITLE",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "CO-OWNER",
        "mobile": false,
        "fieldType": 'number',
        "header": "CO-OWNER",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "DATE RESTRICTION",
        "mobile": false,
        "fieldType": 'number',
        "header": "DATE RESTRICTION",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "ENTITY SOS STATUS",
        "mobile": false,
        "fieldType": 'number',
        "header": "ENTITY SOS STATUS",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "FEIN EVID",
        "mobile": false,
        "fieldType": 'number',
        "header": "FEIN EVID",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "HLDR CLM CERT",
        "mobile": false,
        "fieldType": 'number',
        "header": "HLDR CLM CERT",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "HLDR RLTNSHP EVID",
        "mobile": false,
        "fieldType": 'number',
        "header": "HLDR RLTNSHP EVID",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "HLDR VERFCTN",
        "mobile": false,
        "fieldType": 'number',
        "header": "HLDR VERFCTN",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "KNWN LOW VALUE",
        "fieldType": 'number',
        "mobile": false,
        "header": "KNWN LOW VALUE",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "NOT ON UP SITE",
        "fieldType": 'number',
        "mobile": false,
        "header": "NOT ON UP SITE",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "OWNRSHP EVID",
        "fieldType": 'number',
        "mobile": false,
        "header": "OWNRSHP EVID",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SIGNATORY EVID",
        "mobile": false,
        "fieldType": 'number',
        "header": "SIGNATORY EVID",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SLR CA AFFRM FRM",
        "mobile": false,
        "fieldType": 'number',
        "header": "SLR CA AFFRM FRM",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SLR SIGNATORY ID",
        "fieldType": 'number',
        "mobile": false,
        "header": "SLR SIGNATORY ID",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SLR UP DECLRTN",
        "mobile": false,
        "fieldType": 'number',
        "header": "SLR UP DECLRTN",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SOS Enitity Suspension",
        "mobile": false,
        "fieldType": 'number',
        "header": "SOS Enitity Suspension",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SOS SUSP-RPTD OWNR",
        "mobile": false,
        "fieldType": 'number',
        "header": "SOS SUSP-RPTD OWNR",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SOS SUSP-UPSTRM SLR",
        "mobile": false,
        "fieldType": 'number',
        "header": "SOS SUSP-UPSTRM SLR",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SPECIAL DOC NEEDED",
        "fieldType": 'number',
        "mobile": false,
        "header": "SPECIAL DOC NEEDED",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };

  getCANFieldsV2 = () => {
    let data = [

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": false,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "HolderName",
        "mobile": false,
        "header": "Holder Name",
        "filter": false,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "Properties",
        "mobile": false,
        "header": "Properties",
        "filter": false,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_10k",
        "mobile": false,
        "header": "Properties > $10K",
        "filter": false,
        "fieldType": 'number',
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_50k",
        "mobile": false,
        "fieldType": 'number',
        "header": "Properties > $50K",
        "filter": false,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "KnownValue",
        "mobile": false,
        "header": "Known Value",
        "filter": false,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstimatedPropertyValue",
        "mobile": false,
        "header": "Est. Property Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        "filter": false,
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };

  getOtherTabsTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "FileFor",
        "mobile": false,
        "header": "Mapped Name",
        "filterType": "text",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "SourceState",
        "mobile": false,
        "header": "State",
        "filterType": "text",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "Properties",
        "mobile": false,
        "header": "Properties",
        // "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_10k",
        "mobile": false,
        "header": "Properties > $10K",
        // "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_50k",
        "mobile": false,
        "header": "Properties > $50K",
        // "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "KnownValue",
        "mobile": false,
        "header": "Known Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstimatedPropertyValue",
        "mobile": false,
        "header": "Est. Property Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };

  /**
    * get number of rows to show in a table based on user settings.
    * @returns {Number} number of rows
    */
  getrowsPerPage = () => {
    const loginDetails = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials')) : null;
    const limit = (loginDetails && loginDetails.pageLimit) ? parseInt(loginDetails.pageLimit) : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10;
    return limit;
  }

  submit = async (item) => {
  }

  render() {
    return (
      <>
        <span>
          {/* <Loader loader={this.state.isLoading} /> */}
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            additionalFlag={this.state.reportType ? `&reportType=${this.state.reportType}` : null}
            exportRequried={false}
            excelDownload={true}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            deleteRequired={false}
            criteria={this.state.filter.criteria}
            isPostCall={this.state.tabType !== "Resource Needed V2" ? true : false}
            postbody={this.state.postBody}
            viewRequired={true}
            dontShowTitle={true}
            customisedExport={this.state.tabType !== "Resource Needed V2" ? true : false}
            settingsRequired={false}
            settingsData={this.state.settingsData}
            filterRequired={true}
            gridRequried={false}
            sample={false}
            gettaskFilters={this.getTaskFilters}
            additionalCriteria={this.additionalCriteria}
            isClearFilters={true}
            setRedirectUrl={this.setRedirectUrl}
            globalSearchFieldName='user'
            globalSearch={""}
            redirecteddUrl={true}
            type={this.state.type}
            displayright={true}
            icon='menu-circle'
            isSql={"yes"}
            sortField={this.state.tabType !== "Resource Needed V2" ? "KnownValue" : ""}
            activeTab={"Ready To File V2"}
            displayViewOfForm='modal'
            apiResponseKey={this.state.responseKey}
            apiUrl={this.state.apiUrl}
            className={true}
            custumisedRowHighlights={customisedRowHighlights}
            tabType={true}
            tabOptions={["Ready To File V2", "On Hold V2", "Seller Negative Notice V2", "CA-N Properties V2", "Resource Needed V2"]}
            routeTo='AvailableToFileV2'
            entityType='employee'
          />
        </span>
      </>
    );
  }
}