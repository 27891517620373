import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { Redirect } from 'react-router-dom';
// file upload
import { Row } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../../config/apiCalls';
import fetch from '../../../config/service'
// validate
// import validate from './validate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../../redux/actions/userActions';
import SessionWarningModal from '../CommonModals/SessionWarningModal';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete'
import { InputTextarea } from 'primereact/inputtextarea';
import ManualMatchTable from '../ManualMatchTable/index'
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class ManuallyLoadedProp extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      locationProps: '',
      isContinue: false,
      States: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],

    };
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.searchsellerItems()
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }


  searchsellerItems = async (event, criteria) => {
    let apiUrl
    if (event && event.query.length > 0) {
      apiUrl = `${apiCalls.propertysellers}?str=${event.query}`
    } else {
      apiUrl = `${apiCalls.propertysellers}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [], dropdownDataVal = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownDataVal = response["sellers"]
          } else if (response && response.errorMessage && (response.errorMessage === "Session expired please login again." || response.errorMessage === "Token not provided")) {
            this.setState({
              sessionExpiryModal: true
            });
          } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
            this.setState({
              SessionWarningModal: true
            })
          }
          if (dropdownDataVal && dropdownDataVal.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownDataVal;
          }
          await this.setState({
            filteredSuggestions: dropdownDataVal
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            // this.searchnameItems(criteria)
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  searchnameItems = async (event) => {
    let apiUrl
    if (this.state.selectedSeller && this.state.selectedSeller.EstateID) {
      apiUrl = `${apiCalls.propertynames}?searchCompany=${this.state.selectedSeller.EstateID} `
    }
    // }
    if (apiUrl) {
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let namedropdownData = [];
            if (response["sellernames"] && response["sellernames"].length && response["sellernames"].length > 0) {
              namedropdownData = response["sellernames"]
            }
            if (namedropdownData && namedropdownData.length === 0) {
              this.setState({
                filterednameSuggestions: [],
                noData: true
              });
            } else {
              let modifiedLabels = []
              for (let name of namedropdownData) {
                if (name.companyName !== null || name.companyName.length > 0 || name.nameID !== null || name.nameID.length > 0) {
                  modifiedLabels.push({
                    label: name.companyName,
                    value: name.nameID,

                  })
                }
              }
              namedropdownData = modifiedLabels;
            }
            await this.setState({
              filterednameSuggestions: namedropdownData
            });
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  handleChange = async (e) => {
    await this.setState({
      selectedSeller: e.value
    })
    // this.searchnameItems()
  }



  resetFilters = async () => {
    await this.setState({
      selectedSeller: "",
      SelectedNames: "",
      SelectedStates: "",
      todate: "",
      fromdate: ""
    })
    this.props.getErrorMessage("Please Select Seller", "removingSeller", "error")

  }
  ManuallyLoadedRefValues = async (url) => {
    let apiUrl = url;
    if (this.state.selectedSeller) {
      apiUrl = `${apiUrl}&sellerID=${this.state.selectedSeller.EstateID}`
    }
    if (this.state.SelectedNames) {
      apiUrl = `${apiUrl}&BusinessName=${this.state.SelectedNames}`
    }
    if (this.state.SelectedStates) {
      apiUrl = `${apiUrl}&state=${this.state.SelectedStates}`
    }
    if (this.state.todate) {
      let value = this.state.todate.toLocaleDateString('en-US', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })
      apiUrl = `${apiUrl}&toDate=${value}`
    }
    if (this.state.fromdate) {
      let value = this.state.fromdate.toLocaleDateString('en-US', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })
      apiUrl = `${apiUrl}&fromDate=${value}`
    }

    return apiUrl
  }
  submit = () => {
    if (!this.state.selectedSeller) {
      this.props.getErrorMessage("Please Select Seller", "removingSeller", "error")
    }
    // else if (!this.state.SelectedNames) {
    //   this.props.getErrorMessage("Please Select Business Name", "", "error")
    // } 
    // else if (!this.state.SelectedStates) {
    //   this.props.getErrorMessage("Please Select Source State", "", "error")
    // }
    else {
      this.props.getDataFromServer(this.props.filterCriteria)
    }
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }



  render() {
    const { handleSubmit } = this.props;

    return (
      <div>
        <Col md={12} lg={12} style={{ border: "1px solid lightgrey", marginBottom: 10 }}>
          <Card className="py-2 ">
            <CardBody className="p-0">
              <form className="form" onSubmit={handleSubmit(this.submit)}>
                <div className='w-100 property_ManuallyLoadedProp'>
                  <Row className="mt-1">
                    <div className="ml-3 col-sm-2 col-lg-2">
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>Deal/Estate
                        <FontAwesomeIcon icon={faStarOfLife}
                          className='pl-1'
                          color='red'
                          data-toggle="tool-tip"
                          title="Mandotary Field"
                          style={{ width: 11 }}
                        /></label>
                      <span className='d-flex' >
                        <AutoComplete
                          value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` : this.state.selectedSeller}
                          // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                          suggestions={this.state.filteredSuggestions}
                          completeMethod={this.searchsellerItems}
                          minLength={1}
                          itemTemplate={this.editorTemplateForDeal}
                          field={"EstateName"}
                          style={{ width: "100%", display: "flex" }}
                          dropdown={true}
                          onChange={(e) => this.handleChange(e, 'Seller')}
                          appendTo={document.body} />
                      </span>
                    </div>
                    {/* <div className="form__form-group  col-sm-2 col-lg-2 pl-0" >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>BusinessName<FontAwesomeIcon icon={faStarOfLife}
                        className='pl-1'
                        color='red'
                        data-toggle="tool-tip"
                        title="Mandotary Field"
                        style={{ width: 11 }}
                      /></label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"states"}
                          component={MultiSelect}
                          onChange={(e) => this.setState({ SelectedNames: e.value })}
                          type={"dropDown"}
                          value={this.state.SelectedNames}
                          style={{ width: "100%" }}
                          options={this.state.filterednameSuggestions}
                          placeholder={""}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div> */}
                    <div className="form__form-group  col-sm-2 col-lg-2 pl-0" >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>Source State
                        {/* <FontAwesomeIcon icon={faStarOfLife}
                        className='pl-1'
                        color='red'
                        data-toggle="tool-tip"
                        title="Mandotary Field"
                        style={{ width: 11 }}
                      /> */}
                      </label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"states"}
                          filter={true}
                          component={MultiSelect}
                          onChange={(e) => this.setState({ SelectedStates: e.value })} type={"dropDown"}
                          value={this.state.SelectedStates}
                          style={{ width: "100%" }}
                          options={this.state.States}
                          placeholder={""}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div>

                    {/* <div className="form__form-group  col-sm-2 col-lg-2 pl-0" >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>State Prop. ID</label>
                      <div className="form__form-group-field">
                        <InputTextarea
                          // id={item.id}
                          name={"selectedPropertyIds"}
                          component={MultiSelect}
                          onChange={(e) => this.setState({ selectedPropertyIds: e.value })} type={"dropDown"}
                          type={"text"}
                          value={this.state.selectedPropertyIds}
                          style={{ width: "100%" }}
                          // options={this.state.States}
                          placeholder={""}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div> */}
                    <div className='mr-2'>
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>From Date</label>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        // onChange={(e) => this.setState({ [item.field]: e.value })}
                        // inputClassName={`p-column-filter p-column-${item.field}`}
                        // name={item.field}
                        dateFormat="mm/dd/yy"
                        value={this.state.fromdate}
                        onChange={(e) => this.setState({ fromdate: e.value })}
                      // onSelect={(e) => this.onFilterdateChange(e, item.field)}
                      />
                    </div>
                    <div className='mr-2'>
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>To Date</label>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        // onChange={(e) => this.setState({ [item.field]: e.value })}
                        // inputClassName={`p-column-filter p-column-${item.field}`}
                        // name={item.field}
                        dateFormat="mm/dd/yy"
                        value={this.state.todate}
                        onChange={(e) => this.setState({ todate: e.value })}
                      // onSelect={(e) => this.onFilterdateChange(e, item.field)}
                      />
                    </div>

                    <div className='d-flex align-items-center'>
                      <Button color="primary" style={{ marginTop: "25px" }} outline type="submit">Go</Button></div>
                    <div className="d-flex align-items-center" >
                      <span className=" " style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{
                            width: 16,
                            height: 16,
                            margin: 5,
                            marginTop: 20,
                          }}
                          color="red"
                          onClick={() => this.resetFilters()}
                        />
                      </span>
                    </div>
                  </Row>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col >
        {/* <ManualMatchTable
          onRef={(ref) => (this.manualmatchref = ref)}
          selectedSeller={this.state.selectedSeller}
          isContinue={this.state.isContinue}
        /> */}
        {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
      </div>
    );
  }
}




// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ManuallyLoadedProp = reduxForm({
  form: "ManuallyLoadedPropForm", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(ManuallyLoadedProp);

// You have to connect() to any reducers that you wish to connect to yourself
ManuallyLoadedProp = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(ManuallyLoadedProp);

export default withTranslation('common')(ManuallyLoadedProp);