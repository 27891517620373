import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Card, CardBody } from 'reactstrap';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import apiCalls from '../../../config/apiCalls';
import fetch from '../../../config/service';
import config from '../../../config/config';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


class ReportBugPageNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            subLevels: [],
            mainLevels: [],
            issueStatus: "all",
            sprints: []
        };
        this.getSprints()
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }
 

    componentDidMount = async () => {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        let rolePermissions = JSON.parse(localStorage.getItem("rolePermissions"));
        let opra2Page = []
        if (rolePermissions) {
            Object.entries(rolePermissions).forEach(([key, value]) => {
                if (value === "Edit") {
                    opra2Page.push({ label: key, value: key })
                } // key and corresponding value
            });
        }
        const sorted = opra2Page.sort((a, b) => {
            let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        this.setState({ subMenuList: sorted })
    }


    /**
     * 
     * @returns get the list from the menus api
     */
    getSprints = async () => {
        let filterCriteria = {}, url;
        filterCriteria.sortfield = 'sequenceNo';
        filterCriteria.direction = 'asc';
        url = apiCalls.ReportNewBug;
        let sprints = []
        var sorted = []
        return fetch('GET', `${url}?type=exporttoCSV`)
            .then(async (res) => {
                if (res && res.reportedBugs && res.reportedBugs.length > 0) {
                    res.reportedBugs.forEach((item) => {
                        if (item.sprint !== "" && item.sprint !== null) {
                            sprints.push({ label: item.sprint, value: item.sprint })
                        }
                    })
                    const sorted = sprints.sort((a, b) => {
                        let fa = parseInt(a.label),
                            fb = parseInt(b.label)
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    this.setState({
                        sprints: sorted
                    })
                } else {
                    this.setState({
                        sprints: []
                    })
                }

            })
    }


    handleChange = async (key, value, criteriaKey) => {
        this.setState({ [key]: value })
        let loggedUser = JSON.parse(localStorage.getItem("loginCredentials"))

        if (criteriaKey) {
            let criteria
            criteria = await this.props.criteria()
            if (criteria && criteria.criteria.length > 0) {
                let index = criteria.criteria.findIndex((e) => e.key === "createdBy" && key === "issueStatus");
                if (index !== -1) { criteria.criteria.splice(index, 1) }
                let dateindex = criteria.criteria.findIndex((e) => e.key === "issueResolvedDate" && key === "issueStatus");
                if (dateindex !== -1) { criteria.criteria.splice(dateindex, 1) }
                let statusIndex = criteria.criteria.findIndex((e) => e.key === "issueStatus" && key === "issueStatus");
                if (statusIndex !== -1) { criteria.criteria.splice(dateindex, 1) }
                if (statusIndex !== -1) { criteria.criteria.splice(statusIndex, 1) }
            }
            if (value === "recentlyClosed" && key === "issueStatus") {
                let date = new Date()
                criteria.criteria.push({ key: "issueResolvedDate", value: moment(date.setDate(date.getDate() - 6)).format(config.dbOnlyDateFormat), type: "gte", add_Filter: true })
            } else if (value === "reportedByme" && key === "issueStatus") {
                criteria.criteria.push({ key: "createdBy", value: loggedUser.displayName, type: "eq", add_Filter: true })
            } else if (value !== "all") {
                if (criteria && criteria.criteria.length > 0) {

                    let index = criteria.criteria.findIndex((e) => e.key === key);
                    if (index !== -1) { criteria.criteria.splice(index, 1) }
                }
                if (value && value.length > 0) {
                    criteria.criteria.push({ key: key, value: value, type: "eq", add_Filter: true })
                }
            }
            this.props.getBasicCall(criteria)
        }

    }

    resetAllFilters = async () => {
        this.setState({ issueStatus: "all", opra2Page: "", sprint: "" })
        let criteria = await this.props.criteria()
        let gridFilters = criteria.criteria.filter(object => {
            return object.add_Filter !== true;
        });
        criteria.criteria = gridFilters
        this.props.getBasicCall(criteria)


    }

    render() {
        const { issueStatus } = this.state
        return (
            <div className="p-0 mt-4">
                <div className="form kanbanfilterDiv filterKanbandiv align-items-center ml-0 mr-0">
                    <Row className='my-2 mx-4 d-flex align-items-center'>
                        <div className=''>
                            <h5 className='mb-2'><span className='col-sm-1 '><b>Status</b></span></h5>
                            <span className='d-flex'>
                                <div className="field-radiobutton mr-2">
                                    <RadioButton inputId="all" className="ml-2 mr-2" name="all" value="all" onChange={() => this.handleChange("issueStatus", "all", "issueStatus")} checked={issueStatus === 'all'} />
                                    <label htmlFor="all" className='mb-0'>All</label>
                                </div>
                                <div className="field-radiobutton mr-2">
                                    <RadioButton inputId="New" className="ml-2 mr-2" name="New" value="New" onChange={() => this.handleChange("issueStatus", "New", "issueStatus")} checked={issueStatus === 'New'} />
                                    <label htmlFor="New" className='mb-0'>New</label>
                                </div>
                                <div className="field-radiobutton mr-2">
                                    <RadioButton inputId="recentlyClosed" className="ml-2 mr-2" name="recentlyClosed" value="recentlyClosed" onChange={() => this.handleChange("issueStatus", "recentlyClosed", "issueStatus")} checked={issueStatus === 'recentlyClosed'} />
                                    <label htmlFor="recentlyClosed" className='mb-0'>Recently Closed</label>
                                </div><div className="field-radiobutton mr-2">
                                    <RadioButton inputId="In Development" className="ml-2 mr-2" name="In Development" value="In Development" onChange={() => this.handleChange("issueStatus", "In Development", "issueStatus")} checked={issueStatus === 'In Development'} />
                                    <label htmlFor="In Development" className='mb-0'>In Development</label>
                                </div><div className="field-radiobutton mr-2">
                                    <RadioButton inputId="reportedByme" className="ml-2 mr-2" name="reportedByme" value="reportedByme" onChange={() => this.handleChange("issueStatus", "reportedByme", "issueStatus")} checked={issueStatus === 'reportedByme'} />
                                    <label htmlFor="reportedByme" className='mb-0'>Reported By Me</label>
                                </div>
                            </span>
                        </div>
                        <div className='my-2 mx-3 align-items-center'>
                            <h5><span className='col-sm-1 pl-0'><b>Page</b></span></h5>
                            <Dropdown
                                value={this.state.opra2Page}
                                filter={true}
                                appendTo={document.body}
                                style={{ width: 250 }}
                                className='mt-2 mb-2'
                                options={this.state.subMenuList}
                                onChange={(e) => this.handleChange("opra2Page", e.value, "opra2Page")}

                            />
                        </div>
                        <div className='my-2 align-items-center'>
                            <h5><span className='col-sm-1 mt-2 pl-0'><b>Sprint</b></span></h5>
                            <Dropdown
                                value={this.state.sprint}
                                filter={true}
                                appendTo={document.body}
                                style={{ width: 250 }}
                                className='mt-2 mb-2'
                                options={this.state.sprints}
                                onChange={(e) => this.handleChange("sprint", e.target.value, "sprint")}
                            />
                        </div>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className='ml-4'
                            color={"red"}
                            data-toggle="tool-tip"
                            title="Cler Filters"
                            onClick={() => this.resetAllFilters()} />
                    </Row>
                </div>

            </div>



        );
    }
}

ReportBugPageNew.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ReportBugPageNew);
