import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import TableModal from '../../CommonDataTable/TableModal';

import store from '../../../App/store';
//Loader
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import NewClaim from '../../CommonModals/ClaimCreationfromPE/NewClaim'
import ExistingClaim from '../../CommonModals/ClaimCreationfromPE/ExistingClaim'
import MatchPropertyModal from '../../CommonModals/MatchPropertyModal';
import configMessages from '../../../../config/configMessages';

export default class DealSizingPropertyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Property Editor',
      ReasonsArray: [],
      activeTab: "1",
      propertyFilterSortOptions: [
        { label: 'Property Name', value: "propertyName" },
        { label: 'Prop.Address', value: "propertyAddressLine1" },
        { label: 'Business Name', value: "businessName" },
        { label: 'Property Zip Code', value: "propertyZipCode" },
        { label: 'Property City', value: "propertyCity" },
        { label: 'Property State', value: "propertyState" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    await this.getSettingsData();
    this.getDataFromServer()
    await this.getReasonForEditor();
    await this.getNewStatusReasonsForEditor()
    await this.getNewStatusForEditor()
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // this.setState({ params: params })
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  /**
   * get SPStatus based on selectedSeller
   */
  getSPData = () => {
    if (this.state.selectedSeller) {
      let filterCriteria = {}
      filterCriteria['criteria'] = [{ key: 'sellerID', value: this.state.selectedSeller.EstateID, type: 'eq', add_Filter: true }, { key: 'scoringType', value: "RegularScoring", type: 'eq', add_Filter: true }];
      fetchMethodRequest('GET', `NSScoredSeller?filter=${JSON.stringify(filterCriteria)}`)
        .then(async (response) => {
          if (response && response["nsScoredSellers"] && response["nsScoredSellers"].length > 0) {
            let SPStatus = response["nsScoredSellers"][0].status
            this.setState({ SPStatus: SPStatus })
          }
        })
    }
  }

  /**
   * 
   * @returns settings Data
   */
  getSettingsData = async () => {
    let StatusArray = [];
    let filterCriteria = {}

    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      // { key: 'screenType', value: ["Property Editor"], type: 'in', add_Filter: true }
    ];
    fetchMethodRequest('GET', `PropertyStatus?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`).then(async (response) => {
      if (response && response["propertyStatuses"] && response["propertyStatuses"].length > 0) {
        let propertyStatuses = response["propertyStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        await this.setState({ settingsData: StatusArray })
      }
    })
    return this.state.settingsData;
  }
  /**
   * 
   * @returns get reasons 
   */
  getReasonForEditor = async () => {
    let ReasonsArray = [];
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
    return fetchMethodRequest('GET', `${apiCalls.PropertyReasons}?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`)
      .then(async (response) => {
        if (response && response["propertyReasons"].length > 0) {
          let propertyReasons = response["propertyReasons"]
          for (let reason of propertyReasons) {
            if (ReasonsArray.findIndex(obj => obj.value === reason.reason) === -1) {
              ReasonsArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ shownreasons: ReasonsArray })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  /**
   * 
   * @returns get reasons from propertyStatusandReasonField 
   */
  getNewStatusForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = [], defaultArray = []
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      // { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Deal Sizing Property Editor', type: 'eq', add_Filter: true }
    ]

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            let statusIndex = propertyStatusArray.findIndex(obj => obj.label === reason.status)
            if (statusIndex === -1) {
              if (reason.status.startsWith("MATCHING") || reason.status.startsWith("POST-FILING") || reason.status.startsWith("MAPPING")) {
                defaultArray.push(reason.status)
              }
              propertyStatusArray.push({ label: reason.status, value: reason.status })
            }
            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ propertyStatusArray: propertyStatusArray, propertyStatusReasonArray: propertyStatusReasonArray })

        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  /**
  * 
  * @returns get reasons from propertyStatusandReasonField 
  */
  getNewStatusReasonsForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = [], defaultArray = []
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Deal Sizing Property Editor', type: 'eq', add_Filter: true }
      // { key: 'screenType', value: ['Property Editor'], type: 'in', add_Filter: true }
    ]

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {

            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ propertyStatusReasonArray: propertyStatusReasonArray })

        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }


  /**
   * 
   * @param refresh SP Call
   */
  getSPUpdate = async => {
    this.setState({
      isLoading: true,
      blockSP: true
    })
    let url = `seller/updateSimilarProperties?ESTATEID=${this.state.selectedSeller.EstateID}&EstateName='${encodeURIComponent(this.state.selectedSeller.EstateName)}'&ScoringType=DS`
    this.setState({
      flag: true
    })
    fetchMethodRequest("GET", url)
      .then(async (response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            this.setState({
              flag: false
            })
            // this.datatableref.getErrorMessage(response.respMessage, "", 'success')
            await this.setState({
              data: response.details,
              isLoading: false,
              blockSP: false
            })
          } else if (response.errorMessage) {
            this.datatableref.getErrorMessage(response.errorMessage, "", 'error')
            this.setState({
              isLoading: false,
              blockSP: false
            })
          }
        }
      });

    setTimeout(() => {
      if (this.state.flag) {
        this.datatableref.getErrorMessage("Process Taking Too long, please click GO button after 5 minutes.", 'success')
        this.setState({
          isLoading: false
        })
      }
    }
      , 60000);
  }

  isOpenMatchModel = async () => {
    await this.setState({
      isOpenMatchModel: true
    })

  }
  // }
  isOpenMatchCreateModel = async () => {
    await this.setState({
      isOpenMatchCreateModel: true
    })

  }
  isCloseMatchModel = async () => {
    await this.setState({
      isOpenMatchModel: false
    })
    let criteria = await this.datatableref.getFiltercriteria()
    this.datatableref.getDataFromServer(criteria)
  }
  isCloseMatchCreateModel = async () => {
    await this.setState({
      isOpenMatchCreateModel: false
    })
  }
  getSellerName = async (seller) => {
    await this.setState({
      selectedSeller: seller
    })
    this.getSPData();
  }

  /**
   * 
   * @param {String} getErrorMessage 
   */
  getErrorMessage = (getErrorMessage) => {
    if (getErrorMessage.errorMessage || getErrorMessage.title) {
      if (getErrorMessage && getErrorMessage.title) {
        this.datatableref.getErrorMessage(getErrorMessage.title, "", "error")
      }
      if (getErrorMessage && getErrorMessage.errorMessage) {
        this.datatableref.getErrorMessage(getErrorMessage.errorMessage, "", "error")
      }
    } else {
      this.datatableref.getErrorMessage(getErrorMessage.respMessage, "", "success")

    }
  }

  /**
   * 
   * @returns Match Property Modal
   */
  getOpenPropertyMatchModel() {
    return (
      <MatchPropertyModal
        type={"Deal Sizing Property Editor"}
        isOpenMatchmodel={this.state.isOpenMatchModel}
        isCloseModal={this.isCloseMatchModel}
        isShowErrorMessage={this.getErrorMessage}
        getTableFields={this.getTableFields}
        selectedOpra={this.state.selectedOpra}
        seller={this.state.selectedSeller}
        businessNames={this.state.businessNames}
      />
    )
  }

  getFileforOptions = async (data) => {
    await this.setState({
      businessNames: data
    })
  }
  selectedOpra = async (val) => {
    await this.setState({ selectedOpra: val })
  }
  getModaltoCreateProperty() {
    return (
      <div className='ml-auto'>
        <a className='btn btn-outline-primary p-1 ml-2 mr-2 mt-2 mb-0' title="Click to refresh" style={this.state.SPStatus == "In Progress" || this.state.blockSP ? { backgroundColor: "lightgrey", pointerEvents: "none" } : {}} onClick={() => this.getSPUpdate()} href="#"> Refresh SP</a>
        <a className='btn btn-outline-primary p-1 ml-2 mr-2 mt-2 mb-0' onClick={() => this.isOpenMatchModel()} href="#"> Manually Match</a>
        <a className=' btn btn-outline-primary p-1 ml-2 mr-2 mt-2 mb-0' href={config.opraPropertyCreationUrl} target="blank">Create Property</a>

      </div>
    )
  }
  /**
   * 
   * @returns table fields to show in grid
   */
  getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        frozen: true,
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      {
        "show": false,
        "textAlign": "left",
        "width": 110,
        "field": "bucket",
        "mobile": true,
        "header": "Bucket",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "connectedTaskID",
      //   "mobile": true,
      //   fieldType: "Array",
      //   "header": "Connected TaskID",
      //   "displayInSettings": true,
      //   sorted: true,
      // "filter": false,
      //   "sortable": true,
      // }, {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "connectedClaimID",
      //   "mobile": true,
      //   "header": "Connected ClaimId",
      //   "displayInSettings": true,
      //   sorted: true,
      // "filter": false,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "businessName",
        isFuzzy: true,
        "mobile": true,
        "header": "Scored Name",
        "displayInSettings": true,
        // sorted: true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "statePropertyId",
        "allowInt": true,
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "St. Prop. ID",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "connectedClaimID",
        "mobile": true,
        // "filterType": "text",
        // "fieldType": "ShowData",
        "header": "OPP ClaimID",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "allowInt": true,
        "filterType": "num",
        "field": "propertyID",
        "mobile": true,
        "fieldType": "ShowData",
        "header": "OPP Prop. ID",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "alternateID",
        // "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Alternate ID",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "addressId",
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "Address ID",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameId",
        "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Name ID",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyName",
        // "isFuzzy": true,
        "mobile": true,
        "header": "Reported Owner Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "holderName",
        isFuzzy: true,
        "mobile": true,
        "header": "Holder Name",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyDescription",
        "mobile": true,
        "header": "Property Description",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "businessAddressLine1",
        "mobile": true,
        "header": "Business Add Line1",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyAddressLine1",
        "mobile": true,
        "header": "Prop. Address Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessCity",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Biz City",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 60,
        "field": "businessState",
        "mobile": true,
        "header": "Biz St",
        sorted: true,
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      }, {
        "show": true,
        "textAlign": "right",
        "width": 60,
        "field": "sourceState",
        "mobile": true,
        "header": "Src. St",
        sorted: true,
        "filter": false,
        // "isFuzzy": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "prospectiveStatus",
        "mobile": true,
        "header": "Prospective Status",
        sorted: true,
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "isFuzzy": true
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessZipCode",
        "mobile": true,
        "header": "Biz Zip",
        sorted: true,
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "createdByUser",
        "mobile": true,
        "header": "Created By User",
        sorted: true,
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "field": "propertyState",
        "mobile": true,
        "header": "Prop. St",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyCity",
        "mobile": true,
        "header": "Prop. City",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "propertyZipCode",
        "mobile": true,
        "header": "Prop. Zip",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "filterType": "num",
        "field": "scoreDate",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Score Dt",
        sorted: true,
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
      },

      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMinValueAmount",
        "allowInt": true,
        "mobile": true,
        "header": "Min Amt",
        "filterType": "num",
        "fieldType": "formatCurrencywithdec",
        sorted: true,
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMaxValueAmount",
        "allowInt": true,
        "fieldType": "formatCurrencywithdec",
        "filterType": "num",
        "mobile": true,
        "header": "Max Amt",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyDataSource",
        isFuzzy: true,
        "mobile": true,
        // "fieldType": "Percentage",
        "header": " Prop. Source",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameIssueCode",
        "mobile": true,
        // "filterType": "num",
        "fieldType": "nameIssue",
        "header": "Name Iss",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "newNameIssueCode",
        "mobile": true,
        // "filterType": "num",
        // "fieldType": "Percentage",
        "header": "New Name Iss",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameSource",
        "mobile": true,
        // "filterType": "num",
        // "fieldType": "Percentage",
        "header": "Name Src",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      //  {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 220,
      //   "field": "matchMatchStatus",
      //   "filterType": "multiSelect",
      //   isFuzzy: true,
      //   "mobile": true,
      //   "header": "Old Status",
      //   "filterOptions": this.state.settingsData ? this.state.settingsData : [],
      //   "displayInSettings": true,
      //   sorted: true,
      // "filter": false,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 215,
      //   "field": "matchStatusReason",
      //   "mobile": true,
      //   "filterType": "multiSelect",
      //   "filterOptions": this.state.shownreasons,
      //   isFuzzy: true,
      //   // "fieldType": "Link",
      //   "header": "Old Reason",
      //   "displayInSettings": true,
      //   sorted: true,
      // "filter": false,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "propertyStatus",
        "filterType": "multiSelect",
        isFuzzy: true,
        "mobile": true,
        "header": "Prop. Status",
        "filterOptions": this.state.propertyStatusArray ? this.state.propertyStatusArray : [],
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 215,
        "field": "propertyStatusReason",
        "mobile": true,
        "filterType": "multiSelect",
        "filterOptions": this.state.propertyStatusReasonArray ? this.state.propertyStatusReasonArray : [],
        isFuzzy: true,
        // "fieldType": "Link",
        "header": "Prop. Reason",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "fileFor",
        "mobile": true,
        "header": "Mapped Name",
        // // "isFuzzy": true,
        sorted: true,
        "filter": false,
        "displayInSettings": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "relevantSellerName",
        "mobile": true,
        "header": "Relevant Seller Name",
        // // "isFuzzy": true,
        sorted: true,
        "filter": false,
        "displayInSettings": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 360,
        "field": "matchStatusNotes",
        "mobile": true,
        "fieldType": "Array",
        sorted: true,
        "filter": false,
        "openNotes": true,
        // // "isFuzzy": true,
        "header": "Mapping Note",
        "displayInSettings": true,
        // sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "claimID",
        "mobile": true,
        // "fieldType": "Link",
        isFuzzy: true,
        "header": "St.Claim ID",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyAlternateId",
        "filterType": "num",
        "mobile": true,
        "header": "Prop. Alt Id",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "lastUpdatedBy",
        "mobile": true,
        "header": "Last Updated By",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedBy",
        "mobile": true,
        "header": "Validated By",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "filedBy",
        "mobile": true,
        "header": "Filed By",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Validated",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "createdDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Created",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateIndexedInElastic",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Indexed In Elastic",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "updatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Updated in OPRA2",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateOfLastContact",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Of Last Contact",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "nameMatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Name Score",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "opraAddedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Opra Added",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "escheatmentDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Escheatment",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "addressLine1MatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Line1 Score",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "allowInt": true,
        "filterType": "num",
        "field": "propertyResidentialFlag",
        "mobile": true,
        "header": "Prop. Residential Flag",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "num",
        "allowInt": true,
        "field": "cityStateZipScore",
        "mobile": true,
        "header": "CSZ Score",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyCoOwnerNames",
        // "allowInt": true,
        // "filterType": "num",
        "mobile": true,
        "header": "Co Owner Names",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "hasPropertyCoOwner",
        "fieldType": "hasPropertyCoOwner",
        "mobile": true,
        "header": "Co-Owner",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "confirmedOnsite",
        "fieldType": "confirmedOnSite",
        "mobile": true,
        "header": "Confirmed Onsite",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "confirmedOnsiteDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Confirmed Onsite Date",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "confirmedOnsiteBy",
        // "allowInt": true,
        // "filterType": "num",
        "mobile": true,
        "header": "Confirmed Onsite By",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,

        "field": "nameProof",
        "mobile": true,
        "header": "Name Proof",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,

        "field": "addressProof",
        "mobile": true,
        "header": "Address Proof",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,

        "field": "feinProof",
        "mobile": true,
        "header": "Fein Proof",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,

        "field": "holderProof",
        "mobile": true,
        "header": "Holder Proof",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "sellerProof",
        "mobile": true,
        "header": "Seller Proof",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "pendingConfirmation",
        "editField": "dropDown",
        options: [{ label: "Confirmed", value: "Confirmed" }, { label: "Missing", value: "Missing" }],
        "mobile": true,
        "header": "Pending Confirmation",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "reportedMissing",
        "fieldType": "reportedMissing",
        "mobile": true,
        "header": "Reported Missing",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "reportedMissingBy",
        "mobile": true,
        "header": "reported Missing By",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "reportedMissingDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Reported Missing Date",
        "displayInSettings": true,
        sorted: true,
        "filter": false,
        "sortable": true,
      },
    ]
    return data;
  };

  getFormFields = () => {

    return
  }

  submit = async (item) => {

  }

  //Get THreshold Values from SQL
  getDataFromServer = async () => {
    fetch('GET', `ruleConfiguration`)
      .then(async (response) => {
        if (response && response.ruleConfigurations) {
          await this.setState({
            RulesListArray: response.ruleConfigurations,
          });
          let labels = response.ruleConfigurations, modifiedLabels = []
          for (let i = 0; i < labels.length; i++) {
            modifiedLabels.push({
              label: labels[i].name,
              value: { flagName: labels[i].flagName, name: labels[i].name, description: labels[i].description },
            })
          }
          localStorage.setItem('ruleGroups', JSON.stringify(modifiedLabels))

        } else if (response && response.errorMessage && (response.errorMessage === config.tokenMessage || response.errorMessage === config.sessionExpired)) {
          await this.setState({
            sessionExpiryModal: true
          })
          localStorage.setItem('ruleGroups', JSON.stringify([]))
        } else if (response && response.errorMessage && response.errorMessage === config.warningMessage) {
          await this.setState({
            sessionWarningModal: true
          })
        }
        this.isShowLoader(false)
      }).catch((err) => {
        return err;
      });
  }
  /**
   * 
   * @returns Existing Claim Component calling
   */
  getExistingClaimfromPE = () => {
    return (
      <ExistingClaim
        isOpenMatchmodel={this.state.isExistingMatchClaim}
        isCloseModal={this.isCloseExistingMatchClaim}
        getTableFields={this.getTableFields}
        seller={this.state.selectedSeller}
        selectedRows={this.state.selectedRows}
      />
    )
  }


  getColorbasedonProp = (item) => {
    if (item.connectedClaimID && item.connectedClaimID.length > 0) {
      return "#72261a"
    } else if (item.connectedTaskID && item.connectedTaskID.length > 0) {
      return "#354f6e"
    }
  }

  getDefaultArray = async () => {
    await this.getNewStatusReasonsForEditor()
    return this.state.defaultstatusArray
  }

  /**
   * 
   * @returns New Claim Component calling
   */
  getNewClaimfromPE = () => {
    return (
      <NewClaim
        isOpenMatchmodel={this.state.isNewClaimCreation}
        isCloseModal={this.isCloseExistingMatchClaim}
        saveClaim={this.saveClaim}
        getTableFields={this.getTableFields}
        seller={this.state.selectedSeller}
        selectedRows={this.state.selectedRows}
      />
    )
  }

  /**
   * close the components 
   */
  isCloseExistingMatchClaim = (response) => {
    this.setState({
      isExistingMatchClaim: false,
      isNewClaimCreation: false
    })
    if (response) {
      this.getErrorMessage(response)
    }
    let criteria = this.datatableref.getFiltercriteria()
    this.datatableref.isCloseClaimfromPE()
    this.datatableref.getreturnSelectedRows("remove")
    this.datatableref.getDataFromServer(criteria)

  }


  /**
   * 
   * @param {Object} seller 
   * @param {Array||Object} selectedRows 
   * @param {String} Claimtype 
   * Get the values from the parent component
   */
  getClaimsTabDetails = (seller, selectedRows, Claimtype) => {
    if (Claimtype === "addToTask") {
      this.setState({
        isExistingMatchClaim: true,
        selectedRows: selectedRows,
        seller: seller
      })
    } else {
      this.setState({
        isNewClaimCreation: true,
        selectedRows: selectedRows,
        seller: seller
      })
    }
  }

  openTableModel = async (item, seller) => {
    await this.setState({
      connectedClaimID: item.connectedClaimID,
      selectedItemforTableShow: item,
      seller: seller,
      isOpenTable: true,
    })
  }


  cancelTable = async () => {
    await this.setState({
      isOpenTable: false
    })
    let criteria = await this.datatableref.getFiltercriteria()
    await this.datatableref.getDataFromServer(criteria)
  }

  getTableModal() {
    return (
      <TableModal
        onRef={(ref) => this.tableModalRef = ref}
        openTableModal={this.state.isOpenTable}
        cancelReset={this.cancelTable}
        rowData={this.state.selectedItemforTableShow}
        seller={this.state.seller}
        type={"Property Editor"}
        getClaimsTabDetails={this.getClaimsTabDetails}
        openFormModal={this.datatableref.openFormModal}
      />
    )
  }


  render() {
    let tabData = JSON.parse(localStorage.getItem("ruleGroups"))
    return (
      <div>
        <Loader loader={this.state.isLoading} />
        <span>
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            dontShowTitle={true}
            exportRequried={false}
            excelDownload={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            getClaimsDetails={this.getClaimsTabDetails}
            frozen={true}
            sortField={"BusinessName, PropertyState, NameMatchScore"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            getFileOptionsforMatching={this.getFileforOptions}
            filterRequired={true}
            gridRequried={false}
            sample={false}
            getCustumFilterFilelds={this.getDealSizingpropertyFilters}
            getModaltoCreateProperty={this.getModaltoCreateProperty()}
            propertyFilterSortOptions={this.state.propertyFilterSortOptions}
            globalSearchFieldName='user'
            globalSearch={''}
            type={"Deal Sizing Property Editor"}
            isSelectMode={"multiple"}
            tabDescriptionArray={tabData && tabData.length > 0 ? tabData : []}
            dataKey={"_id"}//for data key on selection
            displayright={true}
            selectedOpra={this.selectedOpra}
            sortDirection={"desc"}
            icon='faCity'
            resize={true}
            getDefaultStatusArray={this.getDefaultArray}
            scrollHeight={"580px"}
            settingsData={this.state.settingsData}
            propertyStatusArray={this.state.propertyStatusArray}
            propertyStatusReasonArray={this.state.propertyStatusReasonArray}
            shownreasons={this.state.shownreasons}
            fontAwesome={true}
            routeTo='dealproperties'
            customisedExport={true}
            getSellerName={this.getSellerName}
            displayViewOfForm='modal'
            activeTab={"Green"}
            apiResponseKey='ns_scored_propertys'
            className={true}
            getColorbasedonProp={this.getColorbasedonProp}
            openTableModel={this.openTableModel}
            // isShownnFieldType={true}
            apiUrl={"NsScoredProperties/dealSizingPostList"}
            entityType='employee'
          />
          {this.state.isOpenMatchModel ? this.getOpenPropertyMatchModel() : null
          }
          {this.state.isExistingMatchClaim ? this.getExistingClaimfromPE() : null
          }
          {this.state.isNewClaimCreation ? this.getNewClaimfromPE() : null
          }
          {this.state.isOpenTable ? this.getTableModal() : null}

        </span>
      </div>
    );
  }
}