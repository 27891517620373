var currencyFormatWithDecimal = '$#,##0.00';
var currencyFormatWithoutDecimal = '$#,##0'
var numberFormatWithComma = '#,##0';
var numberFormatWithoutComma = '0';


export const isCurrencyField = (header) => {
    var lowerHeader = header.toLowerCase();
    var keywords = ['amt', 'amount', 'value'];
    return keywords.some(keyword => lowerHeader.includes(keyword)) && !lowerHeader.includes('color') && !lowerHeader.includes('claim value type');
}

export const formatNumber = (value, header, isCurrency) => {
    if (header && (header.toLowerCase().includes("id") || header.toLowerCase().replaceAll(" ", "").includes("postcode") || header.toLowerCase().includes("zip") || header.toLowerCase().includes("number"))) {
        return { value: Number(value), numFmt: numberFormatWithoutComma };
    } else {
        if (isCurrency) {
            return { value: Number(value), numFmt: currencyFormatWithoutDecimal };
        } else {
            return { value: Number(value), numFmt: numberFormatWithComma };
        }
    }
}

export const formatDecimal = (value) => {
    if (value === "0.0") {
        return { value: 0.00, numFmt: currencyFormatWithDecimal }; // Format as decimal with two decimal places
    } else {
        return { value: parseFloat(value), numFmt: currencyFormatWithDecimal }; // Format as decimal with two decimal places
    }

}

export const isInt = (value) => {
    return Number(value) === value && value % 1 === 0;
}

export const isFloat = (value) => {
    return Number(value) === value && value % 1 !== 0;
}



export const isNumberField = (header) => {
    var lowerHeader = header.toLowerCase();
    var keywords = ['cnt', 'score', 'count', 'round', 'noof'];
    var normalizedHeader = lowerHeader.replaceAll(" ", "").replaceAll(".", "");
    return keywords.some(keyword => normalizedHeader.includes(keyword)) || lowerHeader.startsWith('#');
}

export const isStringField = (header) => {
    var lowerHeader = header.toLowerCase().replaceAll(" ", "");
    var keywords = ['claimid', 'name', 'zip', 'postcode', 'taskid'];

    return keywords.some(keyword => lowerHeader.includes(keyword));
}

export const isBooleanField = (header) => {
    var lowerHeader = header.toLowerCase().replaceAll(" ", "");
    var keywords = ["haspropertycoowner", 'confirmedonsite', 'reportedmissing'];
    const isExactMatch = keywords.some(keyword => lowerHeader === keyword);

    return isExactMatch;
}

export const getDefaultCurrencyValue = (header, value, IsReport) => {
    if (!IsReport && header && isCurrencyField(header)) {
        value = (!value || value == "") ? "0.0" : value
    } else if (!IsReport && header && isNumberField(header)) {
        value = (!value || value == "") ? 0 : value
    } else if (IsReport) {
        value = (!value || value == "") ? 0 : value
    } else {
        value = (value || value === 0) ? value : ""
    }
    return value;
}


export const formatBooleanField = (value, isbooleanheader) => {
    if (isbooleanheader) {
        value = value ? "Yes" : "No"
    } else {
        value = value ? value : false
    }
    return value;
}

