import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FollowUp from './components/FollowUp';

const followUp = ({ t }) => (
  <Container >
    <FollowUp />
  </Container>
);

followUp.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(followUp);
