import React from 'react';
import DataTables from '../../Cruds/CommonDataTable/DataTable';

// config file
export default class NameAddressImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Names Address Import',
      recordData: {}

    };
  }

  static getDerivedStateFromProps(props, state) {

  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }

  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  /**
   * 
   * @returns table fields in the grid
   * */
  getTableFields = () => {
    let data = [

      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'fileName',
        fieldType: 'fileName',
        "mobile": true,
        header: 'File Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'fileSize',
        "fieldType": 'fileSize',
        "mobile": true,
        header: 'File Size',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'totalRows',
        "fieldType": 'number',
        "mobile": true,
        header: 'Total # of Records in Upload',
        allowInt: true,
        filter: true,
        filterType: "num",
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'successRows',
        allowInt: true,
        filterType: "num",
        "fieldType": 'number',
        "mobile": true,
        header: '# successful Records',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'failedRows',
        allowInt: true,
        filterType: "num",
        "fieldType": 'number',
        "mobile": true,
        header: '# failed Records',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'uploadType',

        "fieldType": 'number',
        "mobile": true,
        header: 'Upload Type',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'created',
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": "MM/DD/YYYY",
        header: 'Upload Start Time',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'updatedAtUtc',
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": "MM/DD/YYYY",
        header: 'Upload End Time',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'createdBy',
        "mobile": true,
        header: 'Uploaded By',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 80,
        field: 'progress',
        fieldType: 'badgePill',
        "mobile": true,
        header: 'Upload Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: '',
        fieldType: 'progress',
        "mobile": true,
        header: 'Upload Progress',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 45,
        field: 'errorNamesFile',
        fieldType: 'fileDownlod',
        "mobile": true,
        header: 'Names - File with Error Details',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 45,
        field: 'reUploadNamesFile',
        fieldType: 'fileDownlod',
        "mobile": true,
        header: 'Names - File to be Fixed and Reimported',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 45,
        field: 'errorAddressesFile',
        fieldType: 'fileDownlod',
        "mobile": true,
        header: 'Address - File with Error Details',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 45,
        field: 'reUploadAddressesFile',
        fieldType: 'fileDownlod',
        "mobile": true,
        header: 'Address - File to be Fixed and Reimported',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
    ]
    return data;
  };

  /**
   * 
   * @returns formFields if add or edit is there
   */
  getFormFields = () => {
    return ([])
  }

  /**
   * 
   * @returns get counts based on the last week
   */
  getCountsforlastWeek = () => {
    let counts = this.datatableref ? this.datatableref.getCountstoreturn() : []
    return (
      <div className='d-flex justify-content-flex-start mb-2'>
        <div className='d-grid' >
          <span className='ml-2'><span>#Remaining : </span><span>{counts && Object.keys(counts).length > 0 && counts.remaining ? counts.remaining : ""}</span></span>
          <span className='ml-2 mr-2'><span>#Uploaded in last Week : </span><span>{counts && Object.keys(counts).length > 0 && counts.lastWeek ? counts.lastWeek : ""}</span></span>
          <span className=''><span>#Failed in last Week : </span><span>{counts && Object.keys(counts).length > 0 && counts.lastWeekFailed ? counts.lastWeekFailed : ""}</span></span>
        </div> </div>
    )

  }


  /**
 * 
 * @param {Number} bytes 
 * @returns selected file size 
 */

  getSize = (bytes) => {
    if (bytes === 0) {
      return '0 B';
    }

    var k = 1000,
      dm = 3,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }



  /**
  * 
  * @param {object} item 
  * 
  */
  openModaltodownloadFailedRecords = async (item, field) => {
    if (item) {
      await this.setState({
        recordData: item
      })
    }
    await this.getExceltoDownload(item, field)
  }

  getExceltoDownload(item, field) {
    if (item && item[field] !== null) {
      window.open(item[field], "_self")

    }
  }



  /**
   * refresh after file success uploads 
   */
  refreshtogetUdatedValues = () => {
    if (this.datatableref) {
      let criteria = this.datatableref.getFiltercriteria()
      this.datatableref.getDataFromServer(criteria, 'refresh')
    }
  }


  /**
   * 
   * @returns Calling Datatable
   */
  render() {
    var recordData = this.state.recordData
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          getHeaderforSchedule={this.getCountsforlastWeek}
          openModaltodownloadFailedRecords={this.openModaltodownloadFailedRecords}
          isCustomHeader={true}
          printRequried={false}
          sortField={"created"}
          addRequried={false}
          dontShowTitle={false}
          editRequired={false}
          isClearFilters={false}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          getSize={this.getSize}
          sample={false}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          // globalSearch={'Search'}
          type='Queue'
          displayright={true}
          icon='layers'
          routeTo='names-addressImport'
          displayViewOfForm='modal'
          apiResponseKey='importQueues'
          apiUrl={'importQueue'}
          entityType='employee'
        />
        {Object.keys(recordData).length > 0 ?
          <div id="download_files">
            <a href={recordData.errorNamesFile} id="names_error_file" download></a>
            <a href={recordData.errorAddressesFile} id="address_error_file" download></a>
          </div> : null}
      </span>

    );
  }
}