import React from 'react';
import config from '../../config/config';
// import PSPDFKit from 'pspdfkit';
import envConfig from "../../config/envConfig";
import mark from "../../assets/Thinkcheckmark.png";

const LOADE_DOCS_ANN_ID = "LOADE_DOCS_ANN"
export default class CommonPdfEdiitor extends React.Component {   
    
    constructor(props) {        
        super(props);
        this.containerRef = React.createRef();
        this.instance = React.createRef();
        this.saveInterval = React.createRef();
        this.pdfKit = React.createRef(); 
        this.stopUpload = false; 
        this.loadedDocuments = [];      
    }

    async componentDidMount() {
        this.pdfKit.current = await import("pspdfkit");
        this.initialisePdfViewer();
    }

    componentWillUnmount() {
        if (this.props.autoSave) {
            clearInterval(this.saveInterval.current);
        }
    }
    
    componentDidUpdate(prevProps,prevStat){
        // calling funtion for redirect to selected page        
        if(this.props.selectedPage && (prevProps.selectedPage !== this.props.selectedPage)){
            this.goToSelectedPage(this.props.selectedPage);
        }
        // calling function for adding/merging pages into PDF
        if(this.props.mergePdf && (prevProps.mergePdf !== this.props.mergePdf)){
            this.mergePdf();
        }
        //  calling function for initialise new pdf instance when selected complete new pdf - pdf editor
        if(this.props.enableNewDocLoad && (prevProps.pdfDoc != this.props.pdfDoc)){            
            this.initialisePdfViewer();
        }
        // calling function for creating blob from pdf instance when click on save - pdf editor
        if(this.props.saveToLocal && (prevProps.saveToLocal != this.props.saveToLocal) ) {
            this.createChangesPDFBlob();
        }
        // calling function for deleting pdf pages
        if(this.props.delPages && (prevProps.delType != this.props.delType)){
            this.deletePDFPages(this.props.delType);
        }
        if(this.props.pdfDoc && prevProps.pdfDoc && (prevProps.pdfDoc !== this.props.pdfDoc)){            
            this.initialisePdfViewer(true);
        }        
        
    }

    
    /**
     * Functionality to go to page on click pagnumber below the type(chevron buttons)
     * @param {Number} pageNumber 
     */
    goToSelectedPage = (pageNumber) => {        
        const inst = this.instance.current;       
        if(inst.totalPageCount >= pageNumber){            
            const newState = inst.viewState.set("currentPageIndex", pageNumber-1);
            inst.setViewState(newState);
        }
    }
    
    
    /**
     * Initialise and load PDF editor
     */
    initialisePdfViewer = async (reload) => {              
        const container = this.containerRef.current;
        const customRenderPageCallback = this.createPageNumbers(this.props.paramsDetails,this.getBatesNumbering);
        // let PSPDFKit = await import("pspdfkit");
        this.pdfKit.current.unload(container)

        this.instance.current = await this.pdfKit.current.load({
            // Container where PSPDFKit should be mounted.
            licenseKey: envConfig.REACT_APP_PSPDFKIT_LICENCE_KEY,
            container,
            enableClipboardActions: true,
            enableHistory: true,
            toolbarItems: [
                ...this.pdfKit.current.defaultToolbarItems.filter((elem) => (elem.type !== 'sidebar-bookmarks')),
                { type: "content-editor" },
                { type: 'undo' },
                { type: 'redo' },
            ],
            // The document to open.           
            document: this.props.pdfDoc,
            initialViewState: new this.pdfKit.current.ViewState({
                zoom: 1.50
              }),
              // Adding custom page numbers.
              renderPageCallback: customRenderPageCallback,
            // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
            baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/`
        });
        this.addCustomAnnotation();         
        if (this.props.selectedDocType && !reload) {
            this.props.setPageNumbers(this.props.selectedDocType, 1, this.instance.current.totalPageCount);
            this.props.toggleLoader(false);
        }      
        this.setTotalPages();
        if (this.props.isUpdate) {
            this.getBookmarks();            
        } else {
            if (this.props.createBookmarks) {
                this.createBookMark(this.props.selectedDocType, 0, this.instance.current.totalPageCount);
            }
        }
        if (this.props.autoSave) {            
            this.saveInterval.current = setInterval(() => {
                if (this.props.autoSave && this.stopUpload === false) {
                    this.saveToServer();
                }
            }, 120000);
        }
        if (this.props.subscribeForPageNum) {
            this.instance.current.addEventListener("viewState.currentPageIndex.change", page => {
                this.props.getActivePageType(page);
            });
        }  
        if (this.props.setLoadingStatus) {
            this.props.setLoadingStatus(true);
        }         
                   

    }
    
    
    /**
     * Functionality merge pages into PDF doc
     * @param {Number} afterIndex 
     */
    mergePdf = async (afterIndex) => {
        if (this.instance.current) {
            const prevPageCount = this.props.pageNumbers[this.props.selectedDocType].to && this.props.pageNumbers[this.props.selectedDocType].from ? this.props.pageNumbers[this.props.selectedDocType].to - this.props.pageNumbers[this.props.selectedDocType].from : 0;
            const inst = this.instance.current;
            this.props.toggleLoader(true);
            let insertIndex = afterIndex - 1;
            let beforeTotalPages = inst.totalPageCount;
            const beforeTotalPageCount = inst.totalPageCount;
            //'mergePdfBlobUrl' is array of Blob data.
            for (let i = 0; i < this.props.mergePdfBlobUrl.length; i++) {
                insertIndex = insertIndex + (inst.totalPageCount - beforeTotalPages);
                beforeTotalPages = inst.totalPageCount;
                const res = await inst.applyOperations([
                    {
                        type: "importDocument",
                        afterPageIndex: insertIndex,
                        treatImportedDocumentAsOnePage: true, // All the imported document type pdf will be treated as one section for specific operations.
                        document: this.props.mergePdfBlobUrl[i] // Document to import.
                    }
                ]);

            }
            const newState = inst.viewState.set("currentPageIndex", afterIndex);
            inst.setViewState(newState);            
            this.props.setPageNumbers(this.props.selectedDocType, afterIndex + 1, inst.totalPageCount - beforeTotalPageCount);
            this.props.toggleLoader(false);
            this.setTotalPages();
            if (this.props.createBookmarks) {
                this.createBookMark(this.props.selectedDocType, afterIndex, inst.totalPageCount - beforeTotalPageCount, prevPageCount);
            }
            if (this.props.setLoadingStatus) {
                this.props.setLoadingStatus(true);
            }
        } else {
            setTimeout(()=>{this.mergePdf(afterIndex)},1000);
        }     
    }

    /**
     * Deleting pages when on click delete (trash icon - claim packet)
     * @param {String} type 
     */
    deletePDFPages = async (type) => {
        if (this.props.pageNumbers[type].from && this.props.pageNumbers[type].to) {
            const inst = this.instance.current;
            const pageCount = inst.totalPageCount;
            let pageIndexesArray = [];
            for (let i = this.props.pageNumbers[type].from - 1; i < this.props.pageNumbers[type].to; i++) {
                pageIndexesArray.push(i);
            }
            await inst.applyOperations([
                {
                    type: "removePages",
                    pageIndexes: pageIndexesArray
                }
            ]);
            this.props.setPageNumbersAfterDel(type,(this.props.pageNumbers[type].to - this.props.pageNumbers[type].from) + 1);
            this.setTotalPages();
        }
        
    }

    
    /**
     * Uploading claim packet document to the server
     */
    saveToServer = async() => {
        const inst = this.instance.current;
		const arrayBuffer = await inst.exportPDF();
		const blob = await new Blob([arrayBuffer], { type: 'application/pdf' });		  
        this.props.uploadPdfDocument(blob); 
    }

    
    /**
     * Creating blob from pdf instance
     */
    createChangesPDFBlob = async () => {
        const inst = this.instance.current;
		const arrayBuffer = await inst.exportPDF();
		const blob = await new Blob([arrayBuffer], { type: 'application/pdf' });
        this.props.savePdfToLocal(blob,arrayBuffer);
        //Allow document upload
        setTimeout(() => {
            this.stopUpload = false;
        },2000)
    }

    /**
     * Setting total pages
     */
    setTotalPages = () => {
        if (this.props.getTotalPages) {
            this.props.getTotalPages(this.instance.current.totalPageCount)
        }        
    }

    /**  
     * Setting bookmarks - still in development.   
     * @param {String} name 
     * @param {Number} index 
     */
    createBookMark = async (name, index,totalSecPages,prevPageCount) => {        
        const inst = this.instance.current;
        const bookmarks = await inst.getBookmarks();
        let found = false;
        bookmarks.forEach(bookmark => {
            const obj = bookmark.toJS();
            if(obj.name === name){                
                found = true;
            }
        });
        if (!found && this.props.pageNumbers[name]) {
            const bookmark = new this.pdfKit.current.Bookmark({
                id: name,
                name: name,
                action: new this.pdfKit.current.Actions.GoToAction({ pageIndex: index }),
                readOnly:true                
            });            
            await inst.create(bookmark);            
        }       
       
        this.setSeqAnnotations(name, index,totalSecPages,prevPageCount);
    }

    /**
     * Creating annotation with notes that contains required data to maintain sequence
     * @param {String} name 
     * @param {Number} index 
     * @param {Number} totalSecPages 
     * @param {Number} prevPageCount 
     */
    setSeqAnnotations = async(name, index,totalSecPages,prevPageCount) => {
        const inst = this.instance.current;
        if(prevPageCount){
            totalSecPages = totalSecPages + prevPageCount;
        }
        for (let i = 0; i < totalSecPages; i++) {
            const annotation = new this.pdfKit.current.Annotations.RectangleAnnotation({
                note: JSON.stringify({ name: name, startPage: index, totalCount: totalSecPages, sequence: this.props.topButtonSeq }),
                pageIndex: parseInt(index + i),
                boundingBox: new this.pdfKit.current.Geometry.Rect({
                    left: 10,
                    top: 10,
                    width: 5,
                    height: 5
                }),
                hidden: true,
                readOnly: true,
                locked: true,
            });
            await inst.create(annotation);
        }
        if (this.props.autoSave) {
            this.saveToServer();
        }

    }

    /**
     * Getting bookmarks when loading claim packet file
     */
    getBookmarks = async() => {
        const inst = this.instance.current;
        const bookmarks = await inst.getBookmarks();
        let pageNum = [];             
        bookmarks.forEach(bookmark => {
            const obj = bookmark.toJS();
            if (this.props.pageNumbers[obj.name]) {
                pageNum.push({ docType: obj.name, pNum: obj.action.pageIndex });
            }
        });                
        this.getAllAnnotations(pageNum);
        this.getLoadedocsInfo();
    }
    
    /**
     * Adding custom stamp annotation.
     */
    addCustomAnnotation = async () => {
        if (this.instance.current) {            
            const inst = this.instance.current;
            const file = await fetch(mark).then(response => response.blob());
            const imageAttachmentId = await inst.createAttachment(file);            
            const imageAnnotation = new this.pdfKit.current.Annotations.ImageAnnotation({
                imageAttachmentId,
                contentType: "image/png",
                description: "Example",
                boundingBox: new this.pdfKit.current.Geometry.Rect({
                    width: 75,
                    height: 50,
                    top: 0,
                    left: 0
                })
            });
            inst.setStampAnnotationTemplates(stampAnnotationTemplates => [            
                imageAnnotation
            ]);

        } else {
            setTimeout(() => { this.addCustomAnnotation() }, 1000);
        }
    }

    /**
     * Getting all annotations to set sequence
     * @param {Array} pageNum      
     */
    getAllAnnotations = async (pageNum) => {
        const annotationsForEachPage = await Promise.all(
            Array.from(Array(this.instance.current.totalPageCount).keys()).map(this.instance.current.getAnnotations)
        );
        const allAnnotations = annotationsForEachPage.flat();       
        for (let i = 0; i < allAnnotations.length; i++) {
            const annotation = allAnnotations[i].toJS();
            if (annotation.length > 0) {                             
                for (let j = 0; j < annotation.length; j++) {
                    if(annotation[j].note){
                        const notes = this.getJSONValue(annotation[j].note);                       
                        if(notes && notes.sequence && notes.sequence.length > 0){                            
                            this.props.setSequenceFromAnnotations(notes.sequence,pageNum);                          
                            return;
                        }
                    }
                }
            }
        }
        this.props.setPageNumbersFromBookmarks(pageNum);
    }
    getJSONValue = (string) => {
        try{            
            return JSON.parse(string);
        }
        catch(err){
            return false;
        }
    }

    /**
     * To insert page numbers to pdf file when user download it into local machine
     */
    addPageNumbers = async () => {
        const color_blue = new this.pdfKit.current.Color({ r: 0, g: 0, b: 255 });       
        if(!this.instance.current){
            return;
        }
        this.stopUpload = true; // Stop auto upload when saving to local
        //Save pdf instance without page numbers
        const inst = this.instance.current;
        const arrayBuffer = await inst.exportPDF();        
        this.props.savePDFBeforePageNumRender(arrayBuffer);
        let pageNumberIDs = [];
        //Create page numbers with annotations
        for (let i = 0; i < inst.totalPageCount; i++) {
            const { width, height } = await inst.pageInfoForIndex(i);
            const pageNumber = i + 1;
            let page;
            if (pageNumber > 99) {
                page = pageNumber;
            } else if (pageNumber < 100 && pageNumber > 9) {
                page = '0' + pageNumber;
            } else {
                page = '00' + pageNumber;
            }
            var pageLabel = this.getBatesNumbering(pageNumber);
            const ID = this.pdfKit.current.generateInstantId();
            pageNumberIDs.push(ID); // Saving Id's into array to flatten the annotation
            const pageNumberAnnotation = new this.pdfKit.current.Annotations.TextAnnotation({
                id: ID,
                pageIndex: i,
                text: { format: "plain", value: pageLabel },
                font: "Arial",
                fontSize: 10,
                isBold: false,
                horizontalAlign: "center",
                boundingBox: new this.pdfKit.current.Geometry.Rect({
                    left: width - 8 * pageLabel.length,
                    top: height - 20,
                    width: 10 * pageLabel.length,
                    height: 40,
                }),
                fontColor: color_blue,
            });
            await inst.create(pageNumberAnnotation);
        } 
        //Flatten the annotation (removes annotation & makes page numbers as text)      
        await inst.applyOperations([
            {
                type: "flattenAnnotations",
                annotationIds: pageNumberIDs,
            },
        ]);        
        this.createChangesPDFBlob();
    }

    /**
     * Saving loaded document details as annotation functioality
     */
    addLoadedDocRelAnnotation = async (loadedDocTypes) => {       
        const inst = this.instance.current;
        const annotations = await inst.getAnnotations(0);
        const existing_ann = annotations.find((ann) => ann.id === LOADE_DOCS_ANN_ID);

        if (existing_ann) {            
            const updatedAnnotation = existing_ann.set("note", JSON.stringify({ loadedDocs: this.props.loadedDocuments }));
            await inst.update(updatedAnnotation);
        } else {            
            const annotation = new this.pdfKit.current.Annotations.RectangleAnnotation({
                note: JSON.stringify({ loadedDocs: this.props.loadedDocuments,loadedDocTypes: loadedDocTypes}),
                pageIndex: 0,
                boundingBox: new this.pdfKit.current.Geometry.Rect({
                    left: 20,
                    top: 20,
                    width: 3,
                    height: 3
                }),
                hidden: true,
                readOnly: true,
                locked: true,
                id: LOADE_DOCS_ANN_ID
            });
            await inst.create(annotation);
        }
        if (this.props.autoSave) {
            this.saveToServer();
        }
    }

    /**
     * Getting loaded docs info from saved claim packet annotation
     */
    getLoadedocsInfo = async () => {    
        const inst = this.instance.current;           
        const annotations = await inst.getAnnotations(0);
        const existing_ann = annotations.find((ann) => ann.id === LOADE_DOCS_ANN_ID);
        if (existing_ann && existing_ann.note) {           
            const notes = this.getJSONValue(existing_ann.note);           
            if (notes && notes.loadedDocs && this.props.setLoadedDocsFromExistingDoc) {
                this.props.setLoadedDocsFromExistingDoc(notes.loadedDocs,notes.loadedDocTypes);
            }
        }       
    }
    
    getBatesNumbering = (pageNum) => {
        let pageDocType,pageTypeName;               
        for (const docType in this.props.pageNumbers) {
            if (this.props.pageNumbers[docType] && this.props.pageNumbers[docType].from <= pageNum && this.props.pageNumbers[docType].to >= pageNum) {
                pageDocType = docType;
            }
        }
        if(pageDocType === 'TRANSMTTL LTR'){
            return ``;
        }
        if (['OPP STATE REQRD DOC', 'CLM FORM', 'OPP CLMNT INFO', 'OPP APA'].includes(pageDocType)) {
            pageTypeName = `Claimant’s Ownership Documentation`;
        } else {
            pageTypeName = `Chain of Title and Supporting Documentation`;
        } 
        let page;       
        if (pageNum > 99) {
            page = pageNum;
        } else if (pageNum < 100 && pageNum > 9) {
            page = '0' + pageNum;
        } else {
            page = '00' + pageNum;
        }
        if(!this.props.paramsDetails){
            return '';
        }
        return `${this.props.paramsDetails.state} - ${this.props.paramsDetails.stateClaimID ? this.props.paramsDetails.stateClaimID : this.props.paramsDetails.mappedName} - ${pageTypeName} - ${page}`;
    }   

    createPageNumbers = (params,getName) => {
        if(!this.props.showPageNum){
            return;
        }
        return function (ctx, pageIndex, pageSize) {
            ctx.font = "10px arial";
            ctx.fillStyle = "#0000FF";
            ctx.textAlign = "end";
            var pageNumber = pageIndex + 1;            
            var pageLabel = getName(pageNumber);
            ctx.fillText(pageLabel, pageSize.width - 20, pageSize.height - 20);
        }
    }

    render() {
        return (
            <div ref={this.containerRef} className='pdf-container' />
        )
    }
}