import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls';
import fetch from '../../../../config/service'
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';

// config file
export default class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: '',
      menuList: [],
      reportsArr: [
        {
          "icon": "grip-horizontal",
          "displayTitle": "Available to File",
          "title": "Report - Available to File",
          "route": "/taskView"
        },
        {
          "icon": "grip-horizontal",
          "displayTitle": "Report - Deal Sizing Report For Private Equity Portfolio",
          "title": "Report - Deal Sizing Report For Private Equity Portfolio",
          "route": "/taskView"
        }, {
          "icon": "grip-horizontal",
          "displayTitle": "Report - Deal Sizing Report For Companies",
          "title": "Report - Deal Sizing Report For Companies",
          "route": "/taskView"
        },
        {
          "icon": "grip-horizontal",
          "displayTitle": "Report - Seller Summary Report",
          "title": "Report - Seller Summary Report",
          "route": "/taskView"
        }, {
          "icon": "grip-horizontal",
          "displayTitle": "Report - Activity Details Report",
          "title": "Report - Activity Details Report",
          "route": "/taskView"
        }, {
          "icon": "grip-horizontal",
          "displayTitle": "Report - Utilization – Integrity",
          "title": "Report - Utilization – Integrity",
          "route": "/taskView"
        }, {
          "icon": "grip-horizontal",
          "displayTitle": "Report - Utilization – US Team",
          "title": "Report - Utilization – US Team",
          "route": "/taskView"
        },
        {
          "icon": "grip-horizontal",
          "displayTitle": "Report - Required Evidence",
          "title": "Report - Required Evidence",
          "route": "/reportsrequiredevidence"
        },
        {
          "icon": "grip-horizontal",
          "displayTitle": "Report - Available to Confirm Onsite",
          "title": "Report - Available to Confirm Onsite",
          "route": "/availabletoConfirmonSite"
        },
        {
          "icon": "grip-horizontal",
          "displayTitle": "Report List - All Owned Names and Addresses",
          "title": "Report List - All Owned Names and Addresses",
          "route": "/ownedNamesandAddresses"
        }, {
          "icon": "grip-horizontal",
          "displayTitle": "Change Report",
          "title": "Change Report",
          "route": "/namesReport"
        },
      ]

    };
  }
  componentDidMount = async () => {
    this.getMenuListFromServer();
  }

  /**
   * 
   * @returns get the list from the menus api
   */
  getMenuListFromServer = async () => {
    let filterCriteria = {}, url;
    filterCriteria.sortfield = 'sequenceNo';
    filterCriteria.direction = 'asc';
    url = `${apiCalls.topBarMenulists}?filter=${JSON.stringify(filterCriteria)}`;
    let neWmenuList = [];
    var sorted
    return fetch('GET', url)
      .then(async (res) => {
        if (res && res.topBarMenulists && res.topBarMenulists.length > 0) {
          if (res.topBarMenulists) {
            res.topBarMenulists.forEach((item) => {
              item.title = item.label
              if (item.label !== "Logout" && item.label !== "Profile") {
                if (item.items && item.items.length > 0) {
                  item.title = item.label
                  item.items.forEach(sitem => {
                    sitem.title = sitem.label
                    if (sitem.label !== "Logout" && sitem.label !== "Profile") {
                      neWmenuList.push(sitem);
                    }
                    if (sitem.items && sitem.items.length > 0) {
                      item.items.forEach(sitem2 => {
                        sitem2.title = sitem2.label
                        if (sitem2.label !== "Logout" && sitem2.label !== "Profile") {
                          neWmenuList.push(sitem2);
                        }
                      })
                    }

                  })
                } else {
                  // if (item && item.url) {
                  console.log("item", item)

                  neWmenuList.push(item);
                  // }
                }
              }
            });

            sorted = neWmenuList.sort((a, b) => {
              let fa = a.title,
                fb = b.title;
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            //pushing the the Reports tiles into the array
            for (var obj of this.state.reportsArr) {
              let index = neWmenuList.findIndex(obj => obj.title === obj.title)
              if (index === -1 ) {
                  neWmenuList.push(obj)
              }
            }
          }
          this.setState({
            menuList: sorted
          })
        } else {
          this.setState({
            menuList: []
          })
        }
      })
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  /**
   * 
   * @returns tableFIelds to show
   */
  getTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "left",
        "mobile": true,
        // "width": 50,
        "field": "role",
        "tableField": "role",
        "fieldType": 'Badge',
        options: [{ "value": "Active", "color": "success" }, { "value": "Inactive", "color": "danger" }, { "value": "Pending", "color": "danger", }],
        filterElement: [{ "label": "All", "value": null }, { "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
        "header": "Role",
        "filter": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        // "width": 30,
        "field": "Actions",
        "fieldType": "Actions",
        "header": "Actions",
        "filter": true, "sortable": false
      }
    ]
    return data;
  };

  /**
   * 
   * @returns formFields to show in the form
   */
  getFormFields = () => {
    return ([{ "value": "", "type": "text", "name": "role", "label": "Role", "id": "role", "placeholder": "Role", "required": true },
    {
      value: "",
      type: "radio",
      name: "isHold",
      label: "Hold",
      id: "isHold",
      options: config.biOptions,
      required: true,
    },
    { "value": "", "type": "permission", "name": "permission", "label": "Permission", "id": "permission", "placeholder": "permission", "required": true },
    ]);
  }

  render() {
    return (
      <DataTables
        onRef={(ref) => (this.datatableref = ref)}
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        exportRequried={false}
        printRequried={false}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={false}
        filterRequired={false}
        gridRequried={false}
        isClearFilters={true}
        sample={false}
        globalSearchFieldName='user'
        globalSearch={'Search'}
        type='Roles'
        icon='user'
        routeTo='roles'
        displayViewOfForm='modal'
        apiResponseKey='roles'
        apiUrl={apiCalls.Roles}
        entityType='employee'
        menuList={this.state.menuList}
      />
    );
  }
}