import React from "react";
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import statesList from '../../../../shared/states.json';
import MultiSelect from '../../../Form/components/MultiSelect';
import * as documentsAPIs from '../../../../shared/documentsApis';
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import DefaultTextArea from "../../../../shared/components/form/DefaultTextArea";
import DatePicker from "../../../../shared/components/form/DatePicker";
import Select from '../../../../shared/components/form/Select';
import { withTranslation } from 'react-i18next';
import validate from '../../../Validations/validate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import configMessages from "../../../../config/configMessages";
import { InputSwitch } from 'primereact/inputswitch';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
const multiSelectValidation = (value) => {
    let error = undefined;
    if (value && value.length > 0) {
        error = undefined
    } else {
        error = configMessages.fillField
    }
    return error

}


class StateAttributeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: null,
            regularCatFlag :false,
            comparisonDropDown:[{ label: ">=", value: ">=" },{ label: "==", value: "==" },{ label: "<=", value: "<=" },{ label: "!=", value: "!=" }],
            subCategories: props.subCategories.map((e) => { return { label: e.subCategoryName, value: e.subCategoryName } }),            
        }
        this.selectedDealTypes = [];
        this.selectedCategory = null;
        this.callInProgress= false;
    }
    componentDidMount = async () => {    
        //Populating form fields for edit functionality   
        if (this.props.rowData) {            
            this.setState({ selectedCategory: this.props.rowData.Category ,regularCatFlag: this.props.rowData.Category === 'Claim Packet' ? true : false});
            this.props.load({
                Category: this.props.rowData.Category,
                isMandatory: this.props.rowData.IsMandatory,
                activeIndicator: this.props.rowData.ActiveIndicator,
                attributeValue: this.props.rowData.AttributeValue,
                ComparisonIndicator: this.props.rowData.ComparisonIndicator,
                DocumentDescription: this.props.rowData.DocumentDescription,
                valueSequence: this.props.rowData.ValueSequence,
                DocumentTypeId: this.props.rowData.DocumentTypeName,
                effectiveFromDate: this.props.rowData.EffectiveFromDate,
                note: this.props.rowData.FilingRequirementNote,
                RelevanceTypeId: this.props.rowData.RelevanceTypeName,
                claimBuilderChevronSequence: this.props.rowData.ClaimBuilderChevronSequence,
                docSequenceWithinChevron: this.props.rowData.DocSequenceWithinChevron,
                SubCategory: this.props.rowData.SubCategory
            })
            this.onChangeSubCategory( this.props.rowData.SubCategory);
            this.onChangeRelevance( this.props.rowData.RelevanceTypeId);

        } else {
            this.props.load({});
        }
    }
    
    componentDidUpdate(prevProps,prevStat) {
        if(prevProps.apiCallInProgress !== this.props.apiCallInProgress){
            this.callInProgress = this.props.apiCallInProgress;
        }
    }

    submit = (values) => {
        if(this.callInProgress){
            return;
        }
        this.callInProgress = true;
        let data = JSON.parse(JSON.stringify(values));
        data.FilingRequirementNote = values.note;
        delete data.note;
        if (this.props.type === 'Edit' && this.props.rowData) {
            data.DocumentTypeId = data.DocumentTypeId === this.props.rowData.DocumentTypeName ? this.props.rowData.DocumentTypeId : values.DocumentTypeId;
            data.RelevanceTypeId = data.RelevanceTypeId === this.props.rowData.RelevanceTypeName ? this.props.rowData.RelevanceTypeId : values.RelevanceTypeId;
            data.DealType = this.props.rowData.DealType;
            data.State = this.props.rowData.State;
            data._id = this.props.rowData._id;
        }
        this.props.saveStateAttributesToServer(data);
    }

    setSubCategoryOptions = (field, value) => {        
        if (field === 'dealTypes') {
            this.selectedDealTypes = value;
        } else {
            this.selectedCategory = value;
        }
        const subCat = [];
        this.props.subCategories.forEach(e => {
            let isIncludes = true;
            if (this.selectedDealTypes && this.selectedDealTypes.length === 1 && e.dealType && e.dealType.length > 0) {
                if (e.dealType.includes(this.selectedDealTypes[0]) === false) {
                    isIncludes = false;
                }
            }
            if (this.state.regularCatFlag && this.selectedCategory && e.categoryName && e.categoryName !== this.selectedCategory) {
                isIncludes = false;
            }
            if (isIncludes) {
                subCat.push({ label: e.subCategoryName, value: e.subCategoryName });
            }
        });
        this.props.categories.forEach(e => {
            if(value == e.value && e.isRegular){               
                this.setState({ regularCatFlag: true });
            }
        });
        this.setState({ subCategories: subCat });
    }

    onChangeRelevance = async (e) => {
        let filterCriteria = {}, docSubTypes = []
        filterCriteria['criteria'] = [{ key: 'RelevanceTypeId', value: e, type: 'eq' }];
        const docSubType = await documentsAPIs.getDocTypes(filterCriteria);
        docSubType.forEach(e => {
            docSubTypes.push({ label: e.name, value: e._id });
        });
        await this.setState({ docSubTypes: docSubTypes })
    }
    
    onChangeSubCategory = async (e) => {
        let filterCriteria = {}, docRelevances = []
        filterCriteria['criteria'] = [{ key: 'DocumentSubCategory', value: e, type: 'eq' }];
        const docRelevance = await documentsAPIs.getRelevenceTypes(filterCriteria);
        docRelevance.forEach(e => {
            docRelevances.push({ label: e.name, value: e._id });
        });
        await this.setState({ docRelevances: docRelevances })
    }

    getMandatoryStar = () => {
        return <FontAwesomeIcon icon={faStarOfLife}
            className='pl-1'
            color='red'
            data-toggle="tool-tip"
            title="Mandotary Field"
            style={{ width: 8 }}
        />
    }


    render() {
        const Checkbox = ({ input, meta: { touched, error } }) => (
            <div >
                <InputSwitch checked={input.value} onChange={(e) => input.onChange(e.value)} />

            </div>
        )
        // const  { DOM: { input, select, textarea } } = React
        return (
             
            <Modal
                isOpen={this.props.showSideBar}
                toggle={() => this.props.toggleSideBar(false)}
                backdrop={true}
                keyboard={false}
                id="document-upload-modal"
                className={` modal-dialog--header`}
                style={{ width: '1000px' }}
            > {(this.props.type === 'Add' || (this.props.type === 'Edit' && this.props.rowData)) &&
                <form className="form" name='State Attribute Form' onSubmit={this.props.handleSubmit(this.submit)}>
                    <ModalHeader toggle={() => this.props.toggleSideBar(false)} className={"modal__header doc-upload-Screen"} style={{ backgroundColor: '#F8F9FA !important', width: '100%' }}
                        ref={element => {
                            if (element) element.style.setProperty('backgroundColor', '#F8F9FA', 'important');
                        }}>
                        {`${this.props.type} State Attribute`}
                    </ModalHeader>
                    <ModalBody>
                        <>{this.props.errorMessage && <div className='text-danger config-error-message'>{this.props.errorMessage}</div>}</>
                        <div className="row h-100">
                            <div className="col-12 col-lg-6">
                                <label className='mb-0'>Category {this.getMandatoryStar()}</label>
                                <Field className='inputLogin'
                                    type={'dropDown'}
                                    name="Category"
                                    component={Select}
                                    options={this.props.categories}
                                    filter={true}
                                    validate={[required]}
                                    onChange={(e) => {this.setState({ selectedCategory: e }); this.setSubCategoryOptions('Category', e) }}

                                />
                            </div>
                            {this.props.type === 'Add' && <>
                                <div className="col-12 col-lg-6 ">
                                    <label className='mb-0'>Deal Type {this.getMandatoryStar()}</label>
                                    <Field className='inputLogin'
                                        type={'dropDown'}
                                        name="dealTypes"
                                        component={MultiSelect}
                                        filterElement={[{ label: 'BK', value: 'BK' }, { label: 'CR', value: 'CR' }]}
                                        filter={true}
                                        required={true}
                                        validate={[required, multiSelectValidation]}
                                        onChange={(e) => this.setSubCategoryOptions('dealTypes', e)}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 ">
                                    <label className='mb-0'>States {this.getMandatoryStar()}</label>
                                    <Field className='inputLogin'
                                        type={'dropDown'}
                                        name="states"
                                        component={MultiSelect}
                                        filterElement={[{ label: 'No State', value: 'No State' }, ...statesList.map((e) => { return { label: e.label, value: e.shortCode } })]}
                                        filter={true}
                                        validate={[required, multiSelectValidation]}

                                    />

                                </div>
                            </>}
                            <div className="col-12 col-lg-6 ">
                                <label className='mb-0'>Sub Category {this.getMandatoryStar()}</label>
                                <Field className='inputLogin'
                                    type={'dropDown'}
                                    name="SubCategory"
                                    component={Select}
                                    options={this.state.subCategories}
                                    onChange={(e) => this.onChangeSubCategory(e)}
                                    filter={true}
                                    validate={[required]}
                                />

                            </div>
                            {this.state.regularCatFlag &&   <>
                            <div className="col-12 col-lg-6 ">
                                <label className='mb-0'>Relevance {this.getMandatoryStar()}</label>
                                <Field className='inputLogin'
                                    type={'dropDown'}
                                    name="RelevanceTypeId"
                                    component={Select}
                                    onChange={(e) => this.onChangeRelevance(e)}
                                    options={this.state.docRelevances}
                                    filter={true}
                                    validate={[required]}
                                />

                            </div>
                            <div className="col-12 col-lg-6 ">
                                <label className='mb-0'>Document Type {this.getMandatoryStar()}</label>
                                <Field className='inputLogin'
                                    type={'dropDown'}
                                    name="DocumentTypeId"
                                    component={Select}
                                    options={this.state.docSubTypes}
                                    filter={true}
                                    validate={[required]}
                                />

                            </div>
                            <div className="col-12 col-lg-6 ">
                                <label className='mb-0'>Document Desc.</label>
                                <Field className='inputLogin form-control'
                                    name="DocumentDescription"
                                    component={DefaultInput}
                                />
                            </div>
                            </>}
                            <div className="col-12 col-lg-6 form__form-group">
                                <label className='mb-0'>Effective From {this.getMandatoryStar()}</label>
                                <Field className='inputLogin form__form-group-field'
                                    name="effectiveFromDate"
                                    component={DatePicker}
                                    type={"text"}
                                    validate={[required]}

                                />
                                {/* <input type='date' className='w-100 form-control' /> */}
                            </div>
                            <div className="col-12 col-lg-6 ">
                                <label className='mb-0'>Values {this.getMandatoryStar()}</label>
                                <Field className='inputLogin form-control'
                                    name="attributeValue"
                                    component={DefaultInput}
                                    validate={[required]}


                                />
                            </div>
                            {this.state.regularCatFlag &&   <>
                            {this.state.selectedCategory && this.state.selectedCategory === 'Claim Packet' && <>
                                <div className="col-12 col-lg-6 ">
                                    <label className='mb-0'>Claim Builder Chevron Group Sequence</label>
                                    <Field className='inputLogin form-control'
                                        name="claimBuilderChevronSequence"
                                        component={DefaultInput}
                                        type='number'


                                    />
                                </div>
                                <div className="col-12 col-lg-6 ">
                                    <label className='mb-0'>Claim Builder Chevron Group Sub-Sequence</label>
                                    <Field className='inputLogin form-control'
                                        name="docSequenceWithinChevron"
                                        component={DefaultInput}
                                        type='number'


                                    />
                                </div>
                                <div className="col-12 col-lg-6 ">
                                    <label className='mb-0'>Claim Packet Document Sequence</label>
                                    <Field className='inputLogin form-control'
                                        name="valueSequence"
                                        component={DefaultInput}
                                        type='number'


                                    />
                                </div>
                                </>}
                                </>}
                                <div className="col-6 col-lg-3 ">
                                    <label className='mb-0'>Mandatory</label>

                                    <Field
                                        id={'isMandatory'}
                                        name={'isMandatory'}
                                        // component={renderCheckBoxField}
                                        component={Checkbox}

                                    />


                                </div>
                            
                            <div className="col-6 col-lg-3">
                                <label className='mb-0'>Active</label>
                                <Field
                                    id={'activeIndicator'}
                                    name={'activeIndicator'}
                                    // component={renderCheckBoxField}
                                    component={Checkbox}

                                />
                            </div>
                       <div className="col-12 col-lg-6 ">
                                <label className='mb-0'>Comparison Indicator</label>
                                <Field className='ComparisonIndicator'
                                    type={'dropDown'}
                                    name="ComparisonIndicator"
                                    component={Select}
                                    options={this.state.comparisonDropDown}
                                   // onChange={(e) => this.onChangeSubCategory(e)}
                                    filter={true}
                                    
                                />

                                </div>
                            <div className="col-12">
                                <label className='mb-0'>Note</label>
                                <Field className='inputLogin'
                                    name="note"
                                    component={DefaultTextArea}
                                    row='2'
                                />
                            </div>
                            <div className="col-12 mt-4 text-center">
                                <Button
                                    outline
                                    color='primary'
                                    className="ml-auto mb-0"
                                    type="submit" 
                                    disabled={this.props.apiCallInProgress}                                   
                                >
                                    {this.props.type === 'Add' ? 'Save' : 'Update'}
                                </Button>
                                <Button
                                    outline
                                    color='primary'
                                    className="ml-auto mb-0"
                                    type="button"
                                    onClick={() => this.props.toggleSideBar(false)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>



                    </ModalBody>
                    {/* <ModalFooter className="modalFooter " >
                        <div>
                            <Button color="primary" outline
                                // onClick={this.props.saveClaimNotes(this.state.comment)}
                                // onClick={() => this.handleUsersData(this.state.recordData)}
                                className='deleteModalBtn marginRight'>Save</Button>

                        </div>
                        <Button color="primary" outline
                            // onClick={this.cancelReset}
                            className='deleteModalBtn marginRight'>Back</Button>
                    </ModalFooter> */}
                </form>
                }
            </Modal>
        )

    }
}

StateAttributeForm = reduxForm({
    form: "State Attribute Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(StateAttributeForm);

// You have to connect() to any reducers that you wish to connect to yourself
StateAttributeForm = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(StateAttributeForm);

export default withTranslation('common')(StateAttributeForm);
