
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Rnd } from "react-rnd";

import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
// fecth method from service.js file
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls'
// show message 

import ClaimDetail from '../ClaimDetails'
//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)
const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid gray"
};

class NewClaim extends React.Component {
  constructor(props) {
    super(props);
    this.buttonActionType = null;
    this.state = {
      isShown: false,
      isLoginSuccess: false,
      isLoading: false,
      isShowTable: false,
      progress: 0,
      fileForOptions: [],
      modifiedStates: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      width: 1200,
      height: 600,
      x: 885,
      y: -950,
      filter: {
        limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
        page: 1,
        criteria: [],
      }

    };

  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);

    }
    await this.centerElement()
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  /**
   * 
   * @param {Object} values 
   */
  submit = (values) => {
    this.getResponseforSelectedPropertyData()
    this.datatableref.isShowTable(true)

  }

  /**
   * 
   * @param {Object||Array} SelectedRecord 
   */
  sendSelctedRecordtoDb = (SelectedRecord) => {
    this.setState({
      SelectedRecord: SelectedRecord
    })
  }

  /**
   * Close the Modal
   */
  cancelReset() {
    this.props.isCloseModal()
    this.setState({
      showErrorMessage: ""
    })
  }

  /**
   * 
   * @param {Object} values 
   * @returns Save Claim API 
   */
  saveClaim = async (values) => {
    let body = values
    body.CompanyID = this.props.seller.EstateID
    body.connectedProperties = []
    for (var obj of this.props.selectedRows) {
      let data = {}
      data = {
        "statePropertyID": obj.statePropertyId,
        "propertyID": obj.propertyID,
        // "dealID": obj.dealID

      }
      body.connectedProperties.push(data)
    }
    // body.claimNotes = []
    // body.HASH__Claims = body.connectedProperties.length
    return fetchMethodRequest("POST", `${apiCalls.Claims}`, body).then(async (resp) => {
      if (resp) {
        await this.props.isCloseModal(resp)
        // await this.getDataFromServer()

      }
    });

  }

  showMessageinHeader = (data) => {
    this.setState({
      showErrorMessage: data
    })
  }

  centerElement = async (element) => {
    // Get the dimensions of the viewport
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.screen.height
    // Get the dimensions of the element
    const elementWidth = 1200;
    const elementHeight = 600;
    const leftPosition = (viewportWidth - elementWidth) / 2;
    const topPosition = (viewportHeight - elementHeight) / 2;
    await this.setState({ x: leftPosition, y: topPosition })

  }

  render() {
    const { handleSubmit, } = this.props;
    const modalClass = classNames({
      'modal-dialog--colored': this.state.colored,
      'modal-dialog--header': this.state.header,
    });
    return (
      <div className="rnd_Modal" style={{ all: "unset" }}>
        <Rnd
          className={` p-0 claim_creation_modal ${modalClass} rnd_modal`}
          style={style}
          size={{ width: this.state.width, height: this.state.height }}
          position={{ x: this.state.x, y: this.state.y }}
          onDragStop={(e, d) => {
            this.setState({ x: d.x, y: d.y });
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            this.setState({
              width: ref.style.width,
              height: ref.style.height,
              ...position
            });
          }}
        // enableResizing={false}
        >
          <Card className='pb-0 '>
            <CardHeader className={"modal__header"}>
              <div className="d-flex" style={{ textAlign: "center" }}>
                <button className="lnr lnr-cross modal__close-btn" type="button" style={{ right: 40 }}
                  onClick={() => this.cancelReset()}
                />
                <span />
                <h4 className='rangebuttontextstyle' style={{ textAlign: "center" }}>Claim Detail</h4>
                <p className='mt-0 ml-3 rangebuttontextstyle'>{this.state.showErrorMessage}</p>
              </div>
            </CardHeader>
            <CardBody className=' p-0'
            >
              <ClaimDetail fromPENewClaim={true} showMessageinHeader={this.showMessageinHeader} width={this.state.width} type="Property Editor" saveClaim={this.saveClaim} seller={this.props.seller} selectedRecords={this.props.selectedRows} />
            </CardBody>
          </Card>
        </Rnd>
      </div>




    );
  }
}


NewClaim = reduxForm({
  form: "New Claim", // a unique identifier for this form
  enableReinitialize: true,
})(NewClaim);

// You have to connect() to any reducers that you wish to connect to yourself
NewClaim = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(NewClaim);

export default withTranslation('common')(NewClaim);
