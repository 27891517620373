

import React from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Row, Button
} from 'reactstrap';
import classnames from 'classnames';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Loader from '../../containers/App/Loader'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import apiCalls from "../../config/apiCalls";
// import Loader from '../../../src/';
import { load as loadAccount } from './../../redux/reducers/commonReducer';
import { settings } from '../../redux/actions/settingsAction'
import { Field, reduxForm, FormSection } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import Select from '../../shared/components/form/Select';
import DefaultInput from '../../shared/components/form/DefaultInput';
import fetchMethodRequest from '../../config/service';
import { Redirect } from 'react-router-dom';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import RadioButton from '../../shared/components/form/RadioButton';
import { Checkbox } from 'primereact/checkbox'
// Toaster message
import validate from '../Validations/validate';
import CronData from '../Cruds/CronJobs'

// Loader
// import Loader from '../App/Loader';
// // Calendar
// //session expiry modal
// import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
// import store from '../App/store';
import configMessages from '../../config/configMessages'
import MemoryCache from '../Cruds/MemoryCache/index';
const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
// const normalizePhone = (value) => {
//   if (!value) {
//     return value
//   }
//   const onlyNums = value.replace(/[^\d]/g, '')
//   if (onlyNums.length <= 3) {
//     return onlyNums
//   }
//   if (onlyNums.length <= 7) {
//     return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
//   }
//   return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
// }
class SettingsForm extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        { label: 'ddMMYYY', value: 'DD/MM/YYYY' },
        { label: 'MMDDYYY', value: 'MM/DD/YYYY' },
        { label: 'MMM DD YYYY', value: 'MMM DD YYYY' },
        { label: 'DD MMM YYYY', value: 'DD MMM YYYY' },
        { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
        { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
        { label: 'MM/DD/YYYY HH:mm A', value: 'MM/DD/YYYY HH:mm A' },
        { label: 'MMM DD YYYY HH:mm A', value: 'MMM DD YYYY HH:mm A' },
        { label: 'hh:mm A, MM-DD-YYYY', value: 'hh:mm A, MM-DD-YYYY' },
        { label: 'MM/DD/YYYY HH:mm', value: 'MM/DD/YYYY HH:mm' },
        { label: 'YYYY-MM-DD HH:mm:ss', value: 'YYYY-MM-DD HH:mm:ss' },
        { label: 'YYYY-MM-DD[T]HH:mm:ss.SSS', value: 'YYYY-MM-DD[T]HH:mm:ss.SSS' },
        { label: 'YYYY-MM-DD[T]00:00:00Z', value: 'YYYY-MM-DD[T]00:00:00Z' },
        { label: 'MMM YYYY', value: 'MMM YYYY' },
        { label: 'MMM  DD, YYYY', value: 'MMM  DD, YYYY' },
      ],
      memoryCacheColumns: [
        { header: "Screen", field: "screen" },
        { header: "Memory Cache Name", field: "memoryCacheName" },
        {
          header: "Description",
          field: "description",
        }, {
          header: "Action",
          field: "Action",
          fieldType: "Action"
        },
      ],
      // memoryCacheData:[]
      activeTab: "1",
      isLoading: false,
      isUserUpdated: false,

    };
  }

  componentDidMount = async () => {
    // let apiUrl=''
    this.setState({
      isLoading: true,
      activeTab: "1"
    });
    await this.getInitialSettings()
  }

  componentDidUpdate() {
    if (this.state.errorMessage) {
      setTimeout(() => this.setState({ successMessage: false, errorMessage: '' }), 8000);
    }
  }


  getInitialSettings = async () => {
    let filterCriteria = {};
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchMethodRequest('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        settingsData.bk = response.settings[0].availableToFileThreshold.bk
        settingsData.cr = response.settings[0].availableToFileThreshold.cr
        settingsData.others = response.settings[0].availableToFileThreshold.others
        this.setState({
          isLoading: false,
          settingsData: settingsData,
          excludePossiblyDefaultEnable: settingsData.excludePossiblyDefaultEnable,
          reportRedirectToV2:settingsData.reportRedirectToV2,
        });
        this.props.load(settingsData)
        this.props.settingsLoad(settingsData)
      }
    })
  }
  //send data to server
  saveDataToServer = (formValues) => {
    let availableToFileThreshold = {}
    // let validationExists;
    formValues.availableToFileThreshold = { bk: parseInt(formValues.bk), cr: parseInt(formValues.cr), others: parseInt(formValues.others) }


    if (this.state.activeTab === "1" || this.state.activeTab === "2") {
      formValues.version = parseInt(formValues.version)
      formValues.excludePossiblyDefaultEnable = this.state.excludePossiblyDefaultEnable
      formValues.reportRedirectToV2=this.state.reportRedirectToV2
    }
    if (formValues && this.state.activeTab !== "4") {
      this.setState({
        isLoading: true
      });
      let apiUrl;
      apiUrl = `settings/${formValues._id}`
      return fetchMethodRequest('PUT', apiUrl, formValues)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired == "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          let settingsData = this.state.settingsData
          settingsData.dateFormat = formValues.dateFormat
          this.setState({ settingsData: settingsData })
          await this.props.settingsLoad(settingsData)
          this.getInitialSettings()
          if (response && response.respCode) {
            this.setState({
              errorMessage: response.respMessage,
              successMessage: true,
              isLoading: false
            })
            // showToasterMessage(response.respMessage, 'success');
          } else if (response && response.errorMessage) {
            this.setState({
              errorMessage: response.errorMessage,
              successMessage: true,
              isLoading: false
            })
          }

          this.setState({
            isLoading: false,
            isUserUpdated: true
          });
        }).catch((err) => {
          return err;
        });
    }
  }
  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }


  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  apple = () => {
    console.log('I am refreshed')
  }

  toggle = (tab) => {
    this.setState({
      activeTab: tab
    })
    if (tab === "1" || tab === "2") {
      this.getInitialSettings()
    } else {
      this.props.load({})
    }
    if (tab === "3")
      this.getMemoryCacheData()
  }
  /**
     * RFI History fetch call
     */
  getMemoryCacheData = async () => {
    await fetchMethodRequest(
      "GET",
      `${apiCalls.MemoryCache}`
    ).then(async (response) => {
      if (response) {
        let memoryCacheData = response["memoryCacheConfigurations"];
        await this.setState({ memoryCacheData: memoryCacheData });
      } else if (response && response["errorMessage"]) {
      }
    });
  };




  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    if (column.field === "Action") {
      return (
        <span style={{ textTransform: "capitalize" }}>
          <Button
            className="mr-1 mb-0"
            color="primary"
            outline
            onClick={() => this.resetCacheApi(item)}>
            {"Refresh"}
          </Button>
        </span>
      );
    } else {
      if (item[column.field] && typeof item[column.field] !== "object") {
        return item[column.field];
      }
    }
  };

  getMemoryCacheTable = () => {
    return (
      // <div className="disabledSelection w-80">
      //   <DataTable value={this.state.memoryCacheData}>
      //     {this.state.memoryCacheColumns.map((col, i) => (
      //       <Column
      //         key={col.field}
      //         field={col.field}
      //         header={col.header}
      //         fieldType={col.fieldType}
      //         body={this.changeFieldValues}
      //         emptyMessage={configMessages.noRecords}
      //         bodyStyle={{
      //           textOverflow: 'ellipsis',
      //           overflow: 'hidden',
      //           whiteSpace: 'nowrap',
      //           padding: "5px"
      //         }
      //         }
      //       />
      //     ))}
      //   </DataTable>
      // </div>
      <MemoryCache />
    );
  };

  restartServer = async () => {
    let url = `${apiCalls.restartServer}?test=true`;
    return fetchMethodRequest("GET", url).then(async (response) => {
      // this.datatableref.getErrorMessage("Scoring Server Restarted", "", "Success")
      this.setState({
        errorMessage: "Scoring application process has been restarted. ",
        successMessage: true,
        isLoading: false
      })
    });
  }


  getGeneralSettings = (handleSubmit) => {
    let isSubmitting = false
    return <div>
      <form className="form" onSubmit={handleSubmit(this.saveDataToServer)}>
        <Card className=' row px-5 col-lg-6'>
          <div className="col-sm-12">
            <label>Disable Multiple Login </label>
            <div className="">
              <div className="form-check form-check-inline custom-align mr-4">
                <Field
                  component={RadioButton}
                  name="disableMultipleLogin"
                  className="form-check-input mr-2"
                  label={'True'}
                  radioValue={true} disabled={isSubmitting} />

              </div>
              <div className="form-check form-check-inline custom-align">
                <Field
                  component={RadioButton}
                  name="disableMultipleLogin"
                  className="form-check-input mr-2"
                  radioValue={false}
                  label={'False'}
                  disabled={isSubmitting} />
              </div>
            </div>
          </div>
          <div className="col-sm-6 form-group pt-2">
            <label>Select Date Format </label>
            <div className="form__form-group-field">
              <Field
                name='dateFormat'
                component={Select}
                options={this.state.options}
                placeholder={'Select Date format'}
              />
            </div>
          </div>
          <div className="col-sm-6 form-group pt-2">
            <label>Choose Version</label>
            <div className="form__form-group-field">
              <Field
                name={'version'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'Version'}
              />
            </div>
          </div>
          <div className="form-group pt-2">
            <label>Excl.Possibly Default Enable/Disable</label>
            <div className="form__form-group-field">
              <Checkbox
                label='Unassigned Only'
                type='checkbox'
                // component={renderCheckBoxField}
                name={'validated'}
                className=" ml-3"
                checked={this.state.excludePossiblyDefaultEnable}
                onChange={(e) => this.setState({ excludePossiblyDefaultEnable: !this.state.excludePossiblyDefaultEnable })}
              />
            </div>
            <div className="form-group pt-2">
              <label>Redirect To Property Editor V2</label>
              <div className="form__form-group-field">
                <Checkbox
                  label='Unassigned Only'
                  type='checkbox'
                  // component={renderCheckBoxField}
                  name={'validated'}
                  className=" ml-3"
                  checked={this.state.reportRedirectToV2}
                  onChange={(e) => this.setState({ reportRedirectToV2: !this.state.reportRedirectToV2 })}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 pl-0">
            <label>Claim Status To Update</label>
            <div className="">
              <div className="form-check form-check-inline custom-align mr-4">
                <Field
                  component={RadioButton}
                  name="claimStatusToUpdate"
                  className="form-check-input mr-2"
                  label={'Active'}
                  radioValue={"Active"} disabled={isSubmitting} />

              </div>
              <div className="form-check form-check-inline custom-align">
                <Field
                  component={RadioButton}
                  name="claimStatusToUpdate"
                  className="form-check-input mr-2"
                  radioValue={"Active & Paid"}
                  label={'Active & Paid'}
                  disabled={isSubmitting} />
              </div>
            </div>
          </div>
        </Card>
        <Card className=' row px-5 col-lg-6'>
          <h4 className='ml-3'>Available to File Threshold Settings</h4>
          <div className="col-sm-6 form-group pt-2">
            <label>BK Deal Type Threshold</label>
            <div className="form__form-group-field">
              <Field
                name={'bk'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'BK Deal Type Threshold'}
              />
            </div>
          </div>
          <div className="col-sm-6 form-group pt-2">
            <label>CR Deal Type Threshold</label>
            <div className="form__form-group-field">
              <Field
                name={'cr'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'CR Deal Type Threshold'}
              />
            </div>
          </div>
          <div className="col-sm-6 form-group pt-2">
            <label>Other Deal Type Threshold</label>
            <div className="form__form-group-field">
              <Field
                name={'others'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'Other Deal Type Threshold'}
              />
            </div>
          </div>
          <div className="col-sm-6 form-group pt-2">
            <label>Results Tab Threshold</label>
            <div className="form__form-group-field">
              <Field
                name={'propertyEditorResultsThreshold'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'Results Tab Threshold'}
              />
            </div>
          </div>

        </Card>
      </form></div>
  }

  getTimeSettings = (handleSubmit) => {
    let isSubmitting = false
    return <div>
      <form className="form" onSubmit={handleSubmit(this.saveDataToServer)}>
        <Card className='row mx-1 mt-3 pl-0 pr-0'>
          <div className="col-sm-12 d-flex" >
            <div className="col-sm-6" >
              <div className="form-group pt-0">
                <label> Task Auto Off(In Min)
                </label>
                <div className="form__form-group-field">
                  <Field
                    component={DefaultInput}
                    name="taskTimeoutInMin"
                    className="form-control"
                    disabled={isSubmitting} />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <label> Task Timeout Warning(In Min)
              </label>
              <div className="form__form-group-field">
                <Field
                  component={DefaultInput}
                  name="taskTimeoutWarningInMin"
                  className="form-control"
                  disabled={isSubmitting} />
              </div>
            </div>
          </div>
          <div className="col-sm-12 d-flex" >
            <div className="col-sm-6" >
              <div className="form-group pt-0">
                <label> Expire Token Time (min)
                </label>
                <div className="form__form-group-field">
                  <Field
                    component={DefaultInput}
                    name="expireTokenTimeInMin"
                    className="form-control"
                    disabled={isSubmitting} />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <label> Force Logout Time (min)
              </label>
              <div className="form__form-group-field">
                <Field
                  component={DefaultInput}
                  name="forceLogoutTimeInMin"
                  className="form-control"
                  disabled={isSubmitting} />
              </div>
            </div>
          </div>
        </Card>
      </form></div >
  }


  getCronData() {
    return (
      <CronData />
    )
  }

  getScoringSettings = () => {
    return <div>
      <form className="form" >
        <div className="col-sm-6 form-group pt-2">
          <label>Restart Scoring Application </label>
          <div className="form__form-group-field">
            <Card className=' row px-5 col-lg-6'>
              < Button
                color="primary"
                size="sm"
                outline
                // disabled={true/*this.props.type === "crMatrix" && item.Answers > 0 ? true : false*/}
                className="mb-0 m-1 ml-0 p-1"
                style={{ width: "250px" }}
                onClick={() => this.restartServer()}
              >
                <div style={{ alignSelf: "center" }}>
                  {'Restart'}
                </div>
              </Button>
            </Card>
          </div>
        </div>

      </form></div>
  }

  render() {
    const { t, handleSubmit } = this.props
    return (
      <Container className="container">
        <div>
          <Card className="cardForListMargin m-0 card">
            <form className="form" onSubmit={handleSubmit(this.saveDataToServer)}>
              <CardBody className="tableCardBody">
                <Row className="col-sm-12">
                  <h4><span className='postionRelative pt-2 ml-1'>
                    <FontAwesomeIcon
                      className={'tableheadericon'}
                      color="#354f6e"
                      icon={faUsers} />
                    <b><Link to={"#"}
                      className="tableType pr-0" >Settings
                    </Link></b></span>

                  </h4>
                  <span className={this.state.successMessage ? 'successMessage vertical_middle mx-auto' : 'errorColor  mx-auto vertical_middle'}>
                    {this.state.errorMessage ? this.state.errorMessage : ""}</span>

                </Row>
                <div >
                  < div style={{ width: "100%", overflow: "hidden", display: "inline" }}>
                    <Nav tabs className="mb-2">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle('1'); }}
                        >
                          General Settings
                        </NavLink>
                      </NavItem>
                      <NavItem >
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }}
                        >
                          Time Token Settings
                        </NavLink>
                      </NavItem>
                      <NavItem >
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle('3'); }}
                        >
                          Memory Cache Settings
                        </NavLink>
                      </NavItem>
                      <NavItem >
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => { this.toggle('4'); }}
                        >
                          Cron Job Settings
                        </NavLink>
                      </NavItem>
                      <NavItem >
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '5' })}
                          onClick={() => { this.toggle('5'); }}
                        >
                          Scoring
                        </NavLink>
                      </NavItem>
                      <span className="topbar__centerresponse topBarImageAlignment" >{this.state.successResponse ? this.state.successMessage : null}</span>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />
                      <TabPane tabId="1">
                        {this.getGeneralSettings(handleSubmit)}
                      </TabPane>
                      <TabPane tabId="2">
                        {this.getTimeSettings(handleSubmit)}
                      </TabPane>
                      {this.state.activeTab === '3' ?
                        <TabPane tabId="3">
                          {this.getMemoryCacheTable()}
                        </TabPane> : null}
                      {this.state.activeTab === '4' ?
                        <TabPane tabId="4">
                          {this.getCronData()}
                        </TabPane> : null}
                      {this.state.activeTab === '5' ?
                        <TabPane tabId="5">
                          {this.getScoringSettings()}
                        </TabPane> : null}
                    </TabContent>

                  </div>
                  {this.state.isopenColumnModal ?
                    this.getConfirmaionModel() : null
                  }
                  {
                    this.state.isRedirecttoDeal ?
                      <Redirect to="/sellers" />
                      : null
                  }
                  {this.state.activeTab === "2" || this.state.activeTab === "1" ?
                    <div className='pl-5 mt-4'>
                      <button type='submit' className='btn btn-danger'>Update</button>
                    </div> : null}
                </div>
              </CardBody>
            </form>
          </Card>
        </div>
      </Container >
    );
  }


}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SettingsForm = reduxForm({
  form: "Common Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(SettingsForm);

// You have to connect() to any reducers that you wish to connect to yourself
SettingsForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings,
    // settingsData:auth
  }
)(SettingsForm);

export default SettingsForm;




