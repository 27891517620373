import React from 'react';
import { FileUpload } from 'primereact/fileupload';
import ExcelModel from '../Cruds/CommonModals/ExcelModal'
import ExcelReact from '../Cruds/CommonDataTable/ExcelReact';
import { Link } from 'react-router-dom';
import dateFormats from '../../containers/UI/FormatDate/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Message } from 'primereact/message';
import NameAddressImport from './Components/NameAddressImport'
import ExpiredSessionModal from '../Cruds/CommonModals/ExpiredSessionModal';

import {
  CardHeader, Card, CardBody, Table
} from 'reactstrap';
import { Button } from 'react-bootstrap';
import fetch from '../../config/service'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';



const tabledata_style = {
  border: "none"
}
let timeout
class NameAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadSheet: false,
      responseMessage: "",
      success: false,
      error: false
    };
  }



  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }

  }
  componentDidUpdate() {

    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }

  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }


  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }


  /**
   * 
   * @param {Object} namePreferences 
   * @param {Object} addressPreferences 
   */
  getExcelFile = async (namePreferences, addressPreferences) => {
    let namePreference = [], addressPreference = []
    namePreference.unshift("DealID")
    let index = addressPreferences.findIndex(a1 => a1.field === "BusinessLocationID")
    if (index > -1) {
      addressPreferences.splice(index, 1)
    }
    addressPreference.unshift("DealID", "BusinessLocationID")
    for (let name of namePreferences) {
      if (name.field !== "") {
        if(name.field==="LegacyConnectedNameLabel"){
          namePreference.push("LegacyConnectedName")
        }else{
          namePreference.push(name.field)
        }
      }
    }
    for (let address of addressPreferences) {
      if(address.field==="LegacyConnectedNameLabel"){
        addressPreference.push("LegacyConnectedName")
      } else if(address.field==="LocationNameLabel"){
        addressPreference.push("LocationName")
      }else{ 
        addressPreference.push(address.field)
      }
    }
    this.closeExcelModel()

    this.getExcelFileForDownload(namePreference, addressPreference)
  }

  /**
  * Download Excel for names and address based on preference
  */
  getExcelFileForDownload = async (namePreferences, addressPreferences, strArray) => {
    let url = 'seller/addressnames/export'
    fetch("GET", `${url}?str=${strArray}&type=exportToCsv&nameFilter={"criteria":[],"sortfield":"BusinessName","direction":"asc"}&addressFilter={"criteria":[],"sortfield":"CompanyID","direction":"asc"}`)
      .then(async (response) => {
        // let key = responsekey
        if (response) {
          let obj = [{
            name: "Names",
            headers: namePreferences,
            data: []
          },
          {
            name: "Addresses",
            headers: addressPreferences,
            data: []
          },

          ]
          await this.setState({
            sheets: obj,
            downloadSheet: true
          })
          document.getElementsByClassName("excel-React")[0].children[0].click()

        }
        else {
          this.setState({
            sheets: []
          })
        }
      }).catch((err) => {
        return err;
      });
  }




  /**
  * Open Excel Model
  */

  excelModel() {
    return (
      <ExcelModel
        saveOrderModal={this.state.openExcelModal}
        closeExcelModel={this.closeExcelModel}
        getExcelFile={this.getExcelFile}
        ActivePreset={this.state.active_Preset ? this.state.active_Preset : ""}

      // changeOrder={this.changeOrder}
      />
    )
  }

  /**
  * Clsoing Excel model
  */
  closeExcelModel = async () => {
    this.setState({ openExcelModal: false })
  }


  /**
   * 
   * @param {Object} e
   *  
   */
  onSelect = (e) => {
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    // document.getElementsByClassName("p-fileupload-choose")[0]
    let loginUser = loginCredentials.displayName
    let todaydate = dateFormats.formatDate(
      new Date(),
      "MM/DD/YYYY", "nhg")
    // dataTransfer ? event.dataTransfer.files : event.target.files;
    this.setState({
      fileName: e.target.files[0].name,
      fileSize: this.getSize(e.target.files[0].size),
      uploadedBy: loginUser,
      uploadedDate: todaydate,
      success: false,
      error: false,
      responseMessage: "",
      selectedFile: e.target.files[0]

    })
    // this.fileUpload.style.display = 'none';

  }

  /**
   * 
   * @param {Number} bytes 
   * @returns selected file size 
   */

  getSize = (bytes) => {
    if (bytes === 0) {
      return '0 B';
    }

    var k = 1000,
      dm = 3,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /*
       Component that is used for Download excel sheet
 */
  getExcelDownload = () => {
    let todaydate = dateFormats.formatDate(
      new Date(),
      "MM/DD/YYYY", "nhg")
    return (
      <span className='excel-React d-none'>
        <ExcelReact EstateName={"Names and Addresses"}
          sheets={this.state.sheets} type="Sellers"
        />
      </span>
    )
  }

  /**
   * clear selected data
   */
  clearSelectedFile() {

    this.setState({
      fileName: "",
      fileSize: 0,
      uploadedBy: "",
      uploadedDate: "",
      selectedFile: null
    })
  }
  /**
   * Api call to send selected file
   */
  uploadFileDataToServer = () => {
    fetch("POST", `uploads/bulkupload?type=NamesAddressesImport`, this.state.selectedFile, 'upload')
      .then(async (response) => {

        if (response && (response.addressesImportResponse && response.addressesImportResponse.respCode === 200) || (response.namesImportResponse && response.namesImportResponse.respCode === 200)) {
          let data = []
          if (response.namesImportResponse && response.namesImportResponse.respMessage) {
            data.push(response.namesImportResponse.respMessage)
          } else {
            data.push("No Response for Names")
          }
          if (response.addressesImportResponse && response.addressesImportResponse.respMessage) {
            data.push(response.addressesImportResponse.respMessage)
          } else {
            data.push("No Response for Address")
          }
          this.setState({
            fileName: "",
            fileSize: null,
            uploadedBy: "",
            uploadedDate: "",
            selectedFile: null,
            sheets: response,
            success: true,
            error: false,
            responseMessage: data
          })
          if (this.nameAddressImportref) {
            this.nameAddressImportref.refreshtogetUdatedValues()
          }
        } else if (response.errorMessage) {
          let array = []
          if (response.namesValidationResponse && response.namesValidationResponse.length>0) {
            array=[...array,...response.namesValidationResponse]
          }
          if (response.addressessValidationResponse && response.addressessValidationResponse.length>0) {
            array=[...array,...response.addressessValidationResponse]

          }
          if (response.errorMessage) {
            array.push(response.errorMessage)
          }
          this.setState({
            fileName: "",
            fileSize: null,
            uploadedBy: "",
            uploadedDate: "",
            selectedFile: null,
            success: false,
            error: true,
            sheets: response,
            responseMessage: array
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * clear Message after click cancel
   */
  clearMessage = () => {
    this.setState({
      success: false,
      error: false,
      responseMessage: ""
    })
    document.getElementById("myFile").value = null
  }



  render() {
    return (
      <>
        <Card>
          <CardHeader>
            <h4><b
            ><Link to={"names-addressImport"} className="tableType" >
                Import Data
              </Link>
            </b></h4>
          </CardHeader>
          <Card className="exported-card">
            <CardHeader>
              <span className='float-left'>
                <Button className="p-fileupload-choose" disabled={this.state.selectedFile ? true : false} >
                  <input type="file" id="myFile" onChange={(e) => this.onSelect(e)} onClick={this.clearMessage} />Select File
                </Button>
                <Button color="primary" className="import_upload ml-2 mb-4 mt-1" onClick={() => this.uploadFileDataToServer()} disabled={this.state.fileName ? false : true}  >Upload</Button>
              </span>
              <span className='float-right'>
                <Button color="primary" className="import_upload ml-2 mb-4 mt-1" onClick={() => this.setState({ openExcelModal: true })}>Generate Empty Sheet</Button>
              </span>
            </CardHeader>
            <CardBody className={this.state.responseMessage.length > 0 && this.state.success ? `p-message p-component p-message-success` : this.state.responseMessage.length > 0 && this.state.error ? "p-message p-component p-message-error" : null}>
              {this.state.fileName && this.state.fileName.length > 0 ? <Table>
                <tr>
                  <td className={tabledata_style}>{this.state.fileName}</td>
                  <td className={tabledata_style}>{this.state.fileSize}</td>
                  <td className={tabledata_style}>{this.state.uploadedBy}</td>
                  <td className={tabledata_style}>{this.state.uploadedDate}</td>
                  <td className={tabledata_style}>{<FontAwesomeIcon icon={faTimes}
                    className='pl-1 mt-1'
                    color='green'
                    data-toggle="tool-tip"
                    title="click to delete"
                    style={{ width: 30, float: "right" }}
                    onClick={() => this.clearSelectedFile()}
                  />}</td>
                </tr>
              </Table>
                : null}
              {this.state.responseMessage ? <div className="p-col-12 p-md-3" className="p-message-info">
                <>
                  <>
                    <div className='d-flex'>
                      <span className='p-message-icon my-auto mr-2 '>
                        <FontAwesomeIcon icon={this.state.success ? faCheck : faTimesCircle}
                          className='pl-1 mt-1'
                          color={this.state.success ? 'green' : 'red'}
                          style={{ width: 30, }}
                        // onClick={() => this.clearSelectedFile()}
                        />
                      </span>
                      <span className='p-message-text' style={{ width: "97%" }}>
                        {typeof this.state.responseMessage === "string" ? this.state.responseMessage :
                          <ul>
                            {this.state.responseMessage.map((number) =>
                              <li style={{ wordBreak: "break-word" }}>{number}</li>
                            )}
                          </ul>}
                      </span>
                      <span className='p-message-icon float-right my-auto ml-2 '>
                        <FontAwesomeIcon icon={faTimes}
                          className='pl-1 mt-1'
                          color={this.state.success ? 'green' : 'red'}
                          data-toggle="tool-tip"
                          title="click to delete"
                          style={{ width: 30, float: "right" }}
                          onClick={() => this.clearMessage()}
                        />
                      </span>
                    </div>


                  </>
                </>
              </div>
                : null}
            </CardBody>
          </Card>
          <div>
            <NameAddressImport onRef={(ref) => (this.nameAddressImportref = ref)} />
          </div>
        </Card>
        {this.state.openExcelModal ? this.excelModel() : null}
        {this.state.downloadSheet ? this.getExcelDownload() : null}
        {this.state.expiredSessionModal ? this.expiredSessionModal() : null}

      </>
    );
  }
};

export default NameAddress;
