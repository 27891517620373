import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OnlineClaimHistory from './components/ClaimHistory';
const onlineClaimHistory = ( props) => (
    <OnlineClaimHistory {...props} />
);

onlineClaimHistory.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(onlineClaimHistory);
