const getFileSize = (bytes) => {
    if (bytes === 0) {
        return '0 B';
    }
    var size = "";
    var k = 1000,
    dm = 3,
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
    size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    return size;
}

export default getFileSize;