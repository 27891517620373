import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import fetchRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
// config file
export default class PropertyUploadHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'File Upload History',
      selectedCR: true,
      tabDescriptionArray: [],
      selectedBK: true,
      criteria: [],
      apiUrl: ""
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.getSettings()


  }
  getSettings = async () => {
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    this.setState({
      isLoading: true
    });
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        this.setState({
          isLoading: false,
          settingsData: settingsData
        });
      }
    })
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  
  getFormFields = () => {
    return ([
    ]);
  }

  getTableFields = (tabType) => {
    let data = [
      {
        "show": true,
        textAlign: 'center',
        width: 80,
        field: 'dateAddedUtc',
        fieldType: 'dateTimeFormat',
        "dateFormat": "MM/DD/YYYY hh:mm a",
        "mobile": true,
        header: 'Date',
        "displayInSettings": true,
        "displayInFilter": true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 250,
        field: 'originalFileName',
        "mobile": true,
        header: 'Filename',
        "displayInSettings": true,
        "displayInFilter": true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'uploadedByUsername',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'User Name',
        "displayInSettings": true,
        "displayInFilter": true,
      },
      {
        "show": true,
        textAlign: 'center',
        width: 80,
        field: 'numberOfDuplicateProperties',
        fieldType: "Object",
        "mobile": true,
        header: 'Total Duplicate Properties',
        "displayInSettings": true,
        "displayInFilter": true,
      },
      {
        "show": true,
        textAlign: 'center',
        width: 80,
        field: 'numberOfPropertiesBeforeDedup',
        fieldType: "Object",
        "mobile": true,
        header: 'Total Properties',
        "displayInSettings": true,
        "displayInFilter": true,
      },
      {
        "show": true,
        textAlign: 'center',
        width: 80,
        field: 'status',
        "mobile": true,
        header: 'Status',
        "displayInSettings": true,
        "filterType": 'num',
        "displayInFilter": true,
      }

    ]
    return data;
  };

  submit = async (item) => {
  }




  render() {
    return (
      <span>
        {/* <Loader loader={this.state.isLoading} /> */}
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          criteria={this.props.criteria?this.props.criteria:[]}
          excelDownload={false}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          dontShowTitle={true}
          deleteRequired={false}
          viewRequired={true}
          customisedExport={true}
          settingsRequired={false}
          filterRequired={true}
          modifiedLabels={this.state.modifiedLabels}
          gridRequried={false}
          sample={false}
          isClearFilters={false}
          globalSearchFieldName='user'
          //globalSearch={""}
          type={"Upload History"}
          displayright={true}
          icon='menu-circle'
          settingsData={this.state.settingsData}
          sortField={"dateAddedUtc"}
          routeTo='sellers'
          displayViewOfForm='modal'
          apiResponseKey={"data"}
          className={true}
          apiUrl={apiCalls.getAllUIpropertyUploadHistory}
          entityType='employee'
        />
      </span>
    );
  }
}