import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { InputText } from "primereact/inputtext"
import configMessage from '../../../config/configMessages';

import { Checkbox } from "primereact/checkbox"
import apiCalls from '../../../config/apiCalls';
import { MultiSelect } from 'primereact/multiselect';

import fetchMethodRequest from '../../../config/service'

class SavePreset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      presetName: "",
      update_active_preset: false,
      errorResponse: false
    };
  }

  deleteSelectedRow = () => {
    this.props.closeOrder()
  }

  componentDidMount = async () => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    let roleName = loginCredentials.roleName
    this.setState({ roleName: roleName })
    this.getDepartmentData()
    if (this.props.ActivePreset) {
      this.loadAllPresets()
    }
  }

  componentDidUpdate() {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorResponse: '' }), 5000);
    }
  }
  /**
   * 
   * @returns loading all presets
   */
  loadAllPresets = async () => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'Screen', value: this.props.activeTab ? this.props.activeTab : "Names", type: 'eq' }, { key: 'name', value: this.props.ActivePreset, type: 'eq' }];
    await fetchMethodRequest('GET', `preset?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["presets"] && response["presets"].length > 0) {
        if (response && response["presets"] && response["presets"][0].isDefault) {
          // this.setState({ default_Preset: true })
        }
      }

    })
    return this.state.defaultPresetForUser;
  }

  /**
   * 
   * @returns Getting list of all departments
   */
  getDepartmentData = () => {
    let DepartmentArray = [];
    let filterCriteria = {}
    filterCriteria.sortField = "departmentName"
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    fetchMethodRequest('GET', `${apiCalls.Departments}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["departments"] && response["departments"].length > 0) {
        let departments = response["departments"]
        for (let department of departments) {
          DepartmentArray.push({ label: department.departmentName, value: department.departmentName })
        }
        this.setState({ DepartmentArray: DepartmentArray })
      }
    })
    return this.state.DepartmentArray;
  }
  /**
   * Sending the data to server for saving of Preset
   * @param {string} value
   */
  sendColumnDataToServer = async (value) => {
    if (!this.state.update_active_preset && this.state.presetName.length === 0) {
      this.setState({ errorResponse: true, errorMessage: "Preset Name cannot be empty" })
      return

    }
    let url = "", keyname;

    url = `preset/save`;

    let body = {}
    if (this.state.default_Preset) {
      body.isDefault = this.state.default_Preset
    }
    if (this.state.update_active_preset) {
      body.isUpdateActivePreset = this.state.update_active_preset
    }
    body.preferences = this.props.tablefieldsToShow && this.props.tablefieldsToShow.details ? this.props.tablefieldsToShow.details.Default : this.props.tablefieldsToShow
    body.departments = this.state.selectedDepartment
    body.screen = this.props.activeTab ? this.props.activeTab : "Names"
    body.name = this.state.update_active_preset && this.props.ActivePreset ? this.props.ActivePreset : this.state.presetName

    await fetchMethodRequest("POST", url, body).then(async (resp) => {
      if (resp) {
        if (resp.respCode) {
          let obj = {
            default_Preset: this.state.default_Preset,
            active_Preset: this.state.active_Preset,
            view: this.state.presetName
          }
          if (value) { } else {
            await this.props.changeOrder(obj, resp.respMessage, "Success", this.state.update_active_preset && this.props.ActivePreset ? this.props.ActivePreset : this.state.presetName)
          }
          this.props.showToasterMsg(resp.respMessage, "Success")
          // this.setState({ columnOrderError: true, columnOrderMsg: resp.respMessage })
          return;
        } else if (resp.errorMessage) {
          this.props.changeOrder('', resp.errorMessage, "Error")
          // this.props.showToasterMsg(resp.errorMessage, "Error")

          // showToasterMessage(resp.errorMessage, "error");
        }
      }
    });

  }

  /**

   * 

   * @param {{ 
      originalEvent: Event; 
      value: any;
    }} e
   */
  onChangeDepartment = async (e) => {
    await this.setState({
      selectedDepartment: e.value,
    })

  }

  /**
   * Handling the make default checkbox 
   */
  handleMakeDefault = async () => {
    this.setState({ default_Preset: this.state.default_Preset === true ? false : true })
    await this.sendColumnDataToServer("default")
  }

  /**
   * Conditions for Updating the active preset based on roles
   */
  updateActivePreset = async () => {
    if (this.state.roleName === "Admin" || this.state.roleName.includes("Manager")) {
      this.setState({ update_active_preset: this.state.update_active_preset === true ? false : true })
    } else {
      this.setState({ errorResponse: true, errorMessage: "Only Admins and Managers can update the Preset" })

    }
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Modal isOpen={this.props.saveOrderModal} fade={false} className='deleteModalHeight'>
          <ModalBody className='deleteModalBody' style={{ display: "grid" }}>
            <div style={{ marginBottom: 10 }}><h4 style={{ fontWeight: "bold" }}> {"Save & Edit Preset"}</h4>
              <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "#354f6e", right: 40 }}
                onClick={this.props.closeOrder} />
            </div>
            <div className='row'>
              <div className="col-sm-6">
                <label className='ml-3 d-flex'>New Preset Name</label>
                <InputText
                  value={this.state.presetName}
                  placeholder={'New Preset Name'}
                  className="mb-3"
                  size={25}
                  style={{ width: "90%" }}
                  disabled={this.state.update_active_preset}
                  onChange={(e) => this.setState({ presetName: e.target.value })}
                />
              </div>
              {this.props.EditorScreen === "Datatable" ? null : <div className="col-sm-6">
                <label className='ml-1 d-flex'>Departments</label>

                <MultiSelect
                  style={{ width: "97%" }}
                  name="Departments"
                  filter={true}
                  value={this.state.selectedDepartment}
                  options={this.state.DepartmentArray}
                  placeholder={"Departments"}
                  onChange={(e) => this.onChangeDepartment(e)}
                />

              </div>}

            </div>
            <div className='d-flex my-2'>
              <Checkbox
                label='Make Default Preset'
                type='checkbox'
                // component={renderCheckBoxField}
                name={'default_Preset'}
                className=" ml-1"
                checked={this.state.default_Preset}
                onChange={(e) => this.setState({ default_Preset: this.state.default_Preset === true ? false : true })}

              />
              <label className='ml-3'>Make Default Preset</label>
            </div>
            <div className='d-flex my-2'>
              <Checkbox
                label='Update Active Preset'
                type='checkbox'
                // component={renderCheckBoxField}
                name={'update_active_preset'}
                disabled={this.state.presetName || this.props.ActivePreset === "Original Grid" ? true : false}
                className=" ml-1"
                checked={this.state.update_active_preset}
                onChange={(e) => this.updateActivePreset(e)}

              />
              <label className='ml-3'>Update Active Preset</label>
            </div>
            <span className="topbar__centerresponse topBarImageAlignment" style={{ color: "red" }}>{this.state.errorResponse ? this.state.errorMessage : null}</span>

            <Button color="primary" outline size="sm" onClick={() => this.sendColumnDataToServer()}
              disabled={this.state.errorResponse ? true : false}
              className='deleteModalBtn m-auto'>{t('Submit')}</Button>
          </ModalBody>
        </Modal>
      </div >
    );
  }
}
export default withTranslation('common')(SavePreset);
