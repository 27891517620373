
import React, { PureComponent } from 'react';
import {
    Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../../App/Loader';
import { reduxForm, Field } from 'redux-form';
import { InputText } from 'primereact/inputtext';
import { setUserAction } from '../../../redux/actions/userActions';
import apiCalls from '../../../config/apiCalls';
import configMessages from '../../../config/configMessages';
import EditDataTable from './EditDatatable';
import fetchMethodRequest from '../../../config/service';
import NotesModal from '../../Cruds/CommonModals/NotesModal'
import DefaultTextArea from '../../../shared/components/form/DefaultTextArea'
import ConfirmationForRedirection from '../../Cruds/CommonDataTable/ConfirmationToRedirection'
import DefaultInput from '../../../shared/components/form/DefaultInput';
import ConfirmationModal from './ConfirmationModal';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import dateFormats from '../../UI/FormatDate/formatDate';
import { withTranslation } from 'react-i18next';
import DatePicker from '../../../shared/components/form/DatePicker';
import { faTimes, faSave, faExternalLinkAlt, faMinus, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from "../../../config/config";

const bodyStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 150,
    fontSize: 12,
    textAlign: "left",
    verticalAlign: "inherit"
}
const bodyStyleRight = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 150,
    fontSize: 12,
    textAlign: "right",
    verticalAlign: "inherit"
}
const filter = {
    criteria: [],
    sortfield: "statePropertyId",
    direction: "asc",
    limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10
};


const headerStyle = {
    width: 150,
    textAlign: "center",
}
const renderField = ({
    input, placeholder, type, isDisable, meta: { touched, error },
}) => (
    <div className="form__form-group-input-wrap">
        <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
        {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);


renderField.propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderField.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
    isDisable: false
};

class PropertyDetails extends PureComponent {

    static propTypes = {
        t: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            locationProps: '',
            hitGoButton: false,
            type: 'ClaimDetail',
            filterCriteria: {},
            SelectedStates: [],
            ClaimDetails: this.props.details,
            DealType: [],
            // DealTypeV2: ["BK", "CR"],
            DealTypeV2: [],
            isGreen25: true,
            isGreen10_25: false,
            isGreen5_10: false,
            isGreen1_5: false,
            isGreen1: false,
            isGreenAll: false,
            isBlue25: true,
            isBlue10_25: false,
            isBlue5_10: false,
            isBlue1_5: false,
            isBlue1: false,
            isBlueAll: false,
            isExcludeRoyalties: true,
            excludeCoOwners: false,
            isexcludePossiblyPaid: false,
            ranges: [">$25k"],
            selectedBK: true,
            selectedCR: true
        };
    }


    // static getDerivedStateFromProps(props, state) {
    //     let storeData = store.getState()
    //     let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    //     return { languageData: languageData };
    // }


    /**
     * 
     * @returns get reasons from propertyStatusandReasonField 
     */
    getNewStatusReasonsForEditor = async (val) => {
        var propertyStatusArray = [], filterCriteria = {}
        var propertyStatusReasonArray = [], defaultArray = []
        filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
        filterCriteria.direction = "asc"
        filterCriteria['criteria'] = [
            { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
            { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
            { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
        ]

        return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response["propertyStatusReasons"].length > 0) {
                    let propertyStatusReasons = response["propertyStatusReasons"]
                    for (let reason of propertyStatusReasons) {

                        let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
                        if (reasonIndex === -1) {
                            propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
                        }
                    }
                    await this.setState({ propertyStatusReasonArray: propertyStatusReasonArray, propertyStatusesandReasonsResponse: response["propertyStatusReasons"] })
                } else if (response && response["propertyStatusReasons"].length == 0) {
                    await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [], propertyStatusesandReasonsResponse: [] })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                        propertyStatusesandReasonsResponse: []
                    })
                }
            }).catch((err) => {
                return err;
            })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.isshowToasterMsg) {
            if (toasterMessage) {
                clearTimeout(toasterMessage);
            }
            var toasterMessage = setTimeout(() => this.setState({ isshowToasterMsg: false, showToasterMsg: '' }), 30000);

        }

    }
    componentDidMount = async () => {
        let PropertyIdArray = []
        // let obj = { estateName: this.props.details.estateName }

        if (this.props.load) {

            await this.props.load(this.props.details);
            // this.props.change("estateName", obj.estateName)
        }
        await this.getClaimRecord(this.props.Rowdetails.oppClaimID)

        for (let detail of this.state.dynamicConnectedProperties) {
            PropertyIdArray.push(detail.propertyID)
        }
        await this.setState({ PropertyIdArray: PropertyIdArray })
        await this.setState({ apiPropertyIdArray: this.state.PropertyIdArray.join(","), apiSelectedDealID: this.props.selectedDealID ? this.props.selectedDealID : this.props.Rowdetails ? this.props.Rowdetails.companyID : null })
        if (PropertyIdArray && PropertyIdArray.length > 0) {
            await this.getPropertyDetails("", filter)
            // await this.getPropertyDetails(this.state.PropertyIdArray.join(","), this.props.selectedDealID ? this.props.selectedDealID : this.props.Rowdetails ? this.props.Rowdetails.companyID : null)
        }
        //Screen permisions value can be edit,view, no view
        // let screenPermissions = RolePermissions.screenPermissions('Employees');
        // fetchMethodRequest("GET",'businessnames/execute?query=Select%20*%20%20from%20docs.DocumentRelevance & filter={%22limit%22:0,%22page%22:1,%22criteria%22:[],%22sortfield%22:%22sequence%22,%22direction%22:%22desc%22}')
        await this.getNewStatusReasonsForEditor()
        await this.getNewStatusForEditor()
    }
    setRowCount = async (count) => {
        await this.setState({ selectedRows: count })
    }
    getToggle = async () => {
        await this.props.toggle("close")
    }
    setPropertyIdAfterDelete = async (properties) => {
        await this.setState({ apiPropertyIdArray: properties.join(",") })
    }
    getClaimRecord = async (oppClaimID) => {
        let filterCriteria = {
            limit: 2,
            page: 1,
        }
        filterCriteria.criteria = []
        filterCriteria.criteria.push({
            key: "OppClaimID",
            value: oppClaimID,
            type: "eq",
            add_Filter: true
        })
        let url = `${apiCalls.claims}?filter=${JSON.stringify(filterCriteria)}`
        return fetchMethodRequest("GET", url)
            .then(async (response) => {
                if (response && response["claims"] && response["claims"].length > 0) {
                    this.setState({ dynamicConnectedProperties: response["claims"][0].connectedProperties ? response["claims"][0].connectedProperties : [] })
                }
                await this.setState({ isLoading: false })
                return this.state.dynamicConnectedProperties

            }).catch((err) => {
                return err;
            });


    }
    /**
 * 
 * @returns getting the property details based on the property ids
 */

    getPropertyDetails = async (activeTab, filterCriteria, type) => {
        await this.setState({ isLoading: true })
        if (type && type === "Clear Column" && filterCriteria) {
            filterCriteria.criteria = []
        }
        // filterCriteria.sortfield = "propertyID"
        // filterCriteria.direction = "desc"
        let url
        await this.setState({ propertyUrl: `${apiCalls.nsscoredproperties}?sellerID=${this.state.apiSelectedDealID}&propertyId=${this.state.apiPropertyIdArray}&type=exportToCsv&filter=${JSON.stringify(filterCriteria)}` })
        url = `${apiCalls.nsscoredproperties}?sellerID=${this.state.apiSelectedDealID}&propertyId=${this.state.apiPropertyIdArray}&filter=${JSON.stringify(filterCriteria)}`
        await fetchMethodRequest('GET', url).then(async (response) => {
            if (response && response["ns_scored_propertys"] && response["ns_scored_propertys"].length > 0) {
                let properties = response["ns_scored_propertys"]

                this.setState({ properties: properties })
                this.editdt.getValueforTable(properties, response.pagination.totalCount)
            } else if (response && response["ns_scored_propertys"] && response["ns_scored_propertys"].length === 0) {
                this.editdt.getValueforTable(
                    response["ns_scored_propertys"],
                    response.pagination.totalCount
                );
            }
        })
        await this.setState({ isLoading: false })
        return this.state.claimStatusArray;
    }

    getFormFields1 = () => {
        let data = [
            {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "estateName",
                "label": "Deal",
                "component": DefaultInput,
                "id": "deal",
                "placeholder": "Deal",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "sourceState",
                "label": "Source/State",
                "component": DefaultInput,
                "id": "state",
                "placeholder": "Source/State",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "date__Initiated",
                "component": DatePicker,
                "label": "Date Inititialized",
                "id": "dateInititialized",
                "placeholder": "Date Inititialized",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "component": DefaultInput,
                "name": "asseT_RECOVERY_TYPE",
                "label": "Asset Recovery Type",
                "id": "assetRecoveryType",
                "placeholder": "Asset Recovery Type",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "claimStatus",
                "label": "Claim Status",
                "component": DefaultInput,
                "id": "claimStatus",
                "placeholder": "Claim Status",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "claimReason",
                "label": "Claim Reason",
                "component": DefaultInput,
                "id": "claimReason",
                "placeholder": "Claim Reason",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "properties",
                "label": "# of Properties",
                "component": DefaultInput,
                "id": "properties",
                "placeholder": "# of Properties",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "value",
                "label": "Value",
                "component": DefaultInput,
                "id": "value",
                "placeholder": "Value",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "valueRange",
                "component": DefaultInput,
                "label": "Value Range",
                "id": "valueRange",
                "placeholder": "Value Range",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "statusChangeDate",
                "label": "Status Change Date",
                "component": DatePicker,
                "id": "statusChangeDate",
                "placeholder": "Status Change Date",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "nextActiveUser",
                "label": "Next Active User",
                "component": DefaultInput,
                "id": "nextActiveUser",
                "placeholder": "Next Active User",
                // "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "nextActiveDate",
                "label": "Next Active Date",
                "id": "nextActiveDate",
                "component": DatePicker,
                "placeholder": "Next Active Date",
                // "required": true
            },

        ]

        return data;
    }

    getFormButtons = () => {
        let data = [
            {
                label: '+Custom',
                className: '',
                onClick: () => { alert("Custom") },
                color: 'warning'
            },
            {
                label: 'Reset',
                className: '',
                onClick: () => { alert("Reset") },
                color: 'warning'
            },
            {
                label: '+1',
                className: '',
                onClick: () => { alert("1") },
                color: 'success'
            },
            {
                label: '+7',
                className: '',
                onClick: () => { alert("7") },
                color: 'success'
            },
            {
                label: 'Reset to Detault',
                className: 'col-7 btn btn-info',
                onClick: () => { alert("1") },
                color: 'primary'
            }, {
                label: '+14',
                className: 'col-2 ',
                onClick: () => { alert("14") },
                color: 'success'
            }, {
                label: '+28',
                className: 'col-2 ',
                onClick: () => { alert("28") },
                color: 'success'
            },
        ]

        return data
    }

    confirmMationModelforRedirection = () => {
        return (
            <ConfirmationForRedirection
                openConfirmationModal={this.state.confirmationForRedirection}
                closeConfirmationModal={this.closeConfirmationModalforredirection}
                setFlagforredirection={this.state.setFlagforredirection}
                closePopUpScreen={this.closePopUpScreen}

            />
        )
    }

    confirmtheredirection = (screen, item, clickedField) => {
        let obj = {
            screen: screen, item: item, clickedField: clickedField
        }
        this.setState({
            confirmationForRedirection: true,
            setFlagforredirection: obj
        })
    }

    closeConfirmationModalforredirection = (obj, screen) => {
        this.setState({
            confirmationForRedirection: false,
            setFlagforredirection: ""
        })
        this.getUrlbyParams("", screen)
    }
    closePopUpScreen = () => {
        this.setState({
            confirmationForRedirection: false,
        })
    }

    getInputField = (item) => {
        // if(){
        //     return 'TArea'
        // }
        return this.getInputText(item);

    }

    oneditValueChange = async (e, field) => {
        let ClaimDetails = this.state.ClaimDetails
        ClaimDetails[field] = e.target.value
        await this.setState({ ClaimDetails: ClaimDetails })
    }
    getInputText = (obj) => {

        return <Field
            name={obj.name}
            component={obj.component}
            type={"text"}
            id={obj.name}
            size={"18"}
        // onChange={(e) => this.handlekeyValueChange(e, `value${i}`, i)}
        />

    }

    submit = async (values) => {
        await this.props.saveClaim(values)
    }

    /**
     * 
     * @param {} type 
     * @param {*} item 
     * @param {*} clickedField 
     * fghtfhjghjhffg
     */
    getUrlbyParams = async (type, item, clickedField) => {
        let screen = item ? item : "nsproperties"
        let url;
        if (this.state.PropertyIdArray && this.state.PropertyIdArray.length > 0) {
            url = `${window.location.protocol}//${window.location.host}/${item}?sellerName=${this.props.Rowdetails.estateName ? this.props.Rowdetails.estateName : this.props.selectedDealID}&bucket=${this.state.activeTab ? this.state.activeTab : "Green"}&sellerId=${this.props.Rowdetails.companyID}&propertyId=${this.state.PropertyIdArray.join(",")}`
        } else {
            url = `${window.location.protocol}//${window.location.host}/${item}?sellerName=${this.props.Rowdetails.estateName ? this.props.Rowdetails.estateName : this.props.selectedDealID}&bucket=${this.state.activeTab ? this.state.activeTab : "Green"}&sellerId=${this.props.Rowdetails.companyID}&state=${this.props.Rowdetails && this.props.Rowdetails.sourceState ? this.props.Rowdetails.sourceState : null}&ClaimId=${this.props.Rowdetails && this.props.Rowdetails.stateClaimID ? this.props.Rowdetails.stateClaimID : null}`
        }
        // url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${this.props.Rowdetails.estateName ? this.props.Rowdetails.estateName : this.props.selectedDealID}&bucket=${this.state.activeTab ? this.state.activeTab : "Green"}&sellerId=${this.props.Rowdetails.companyID}&propertyId=${this.state.PropertyIdArray.join(",")}`
        await this.setState({
            PropertySharebody: url,
            // ProertyShareSubject: Subject,
            // customerEmail: ""
        })
        document.getElementsByClassName("redirecttoprop")[0].click()
    }

    /**
     * 
     * @param {*} type 
     * closing the model for debtors
     */
    cancelReset = async () => {
        await this.setState({
            isopenColumnModal: true
        })
    }
    /**
    * 
    * @param {*} type 
    * Clsoing the show hide model after confirmation
    */
    closeShowHideColumnsModal = async (type) => {
        await this.setState({
            isopenColumnModal: false
        })
        if (type === "close") {
            await this.getMctSource()
            // this.props.closeShowHideColumnsModal('close', null)
        }

    }

    /**
        * 
        * @returns Opening the confirmation model
        */
    getConfirmaionModel() {
        return (
            <ConfirmationModal
                openConfirmationModal={this.state.isopenColumnModal}
                closeConfirmationModal={this.closeShowHideColumnsModal}
                shownText={"Are you sure you want to remove the selected properties from their connected claim?"}
            />
        )
    }
    getNotesModal() {
        return (
            <NotesModal
                openNotesModal={this.state.isnotesModal}
                cancelReset={this.cancelNotes}
                saveClaimNotes={this.saveClaimNotes}
                claimNotesEdit={this.state.claimNotesEdit}
                notesSno={this.state.notesSno}
                Comments={this.state.Comments}
                type={"Notes"}
            />
        )
    }
    getMctSource = async () => {
        await this.editdt.getMctSource()
    }
    showMessage = async (msg, type) => {
        this.setState({
            isshowToasterMsg: true,
            showToasterMsg: msg.respMessage,
            backgroundColor: type === "errorView" ? "red" : "green",
            isLoading: false
        })
        // await this.props.showMessage(msg)

    }
    onEditorValueChange = async (props, value, item) => {
        // if (props) {
        let allUsersData = this.state.properties;

        allUsersData[value.rowIndex][props.field] = item ? item : ""
        if (allUsersData[value.rowIndex]["propertyMinValueAmount"] > allUsersData[value.rowIndex]["propertyMaxValueAmount"]) {
            if (document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`) && document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).parentElement && document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).parentElement.parentElement && document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).parentElement.parentElement.parentElement) {
                document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "red"
            } if (document.getElementById(`propertyMinValueAmount-${value.rowIndex}`) && document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).parentElement && document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).parentElement.parentElement && document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).parentElement.parentElement.parentElement) {
                document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "red"
            } if (document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`)) {
                document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).style.backgroundColor = "red"
            } if (document.getElementById(`propertyMinValueAmount-${value.rowIndex}`)) {
                document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).style.backgroundColor = "red"
            }

        } else {
            if (document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`) && document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).parentElement && document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).parentElement.parentElement && document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).parentElement.parentElement.parentElement) {
                document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "white"
            } if (document.getElementById(`propertyMinValueAmount-${value.rowIndex}`) && document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).parentElement && document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).parentElement.parentElement && document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).parentElement.parentElement.parentElement) {
                document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "white"
            } if (document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`)) {
                document.getElementById(`propertyMaxValueAmount-${value.rowIndex}`).style.backgroundColor = "white"
            } if (document.getElementById(`propertyMinValueAmount-${value.rowIndex}`)) {
                document.getElementById(`propertyMinValueAmount-${value.rowIndex}`).style.backgroundColor = "white"
            }

        }
        allUsersData[value.rowIndex]["isEdited"] = true
        await this.setState({ properties: allUsersData, showerrorfield: false });

    }
    clearFilters = async () => {
        await this.editdt.getTableFieldsforTableColumns([])
        await this.editdt.getTableFieldsforTableColumns(await this.getTableFields())
        await this.editdt.clearDropDownOptions()
        await this.getPropertyDetails("", filter, "Clear Column")
    }
    UpdateMinMaxValues = async () => {
        this.editdt.UpdateMinMaxValues()
    }
    setHitUpdateButtonFromPropertyDetails = async (value) => {
        await this.props.setHitUpdateButtonFromPropertyDetails(value)
    }
    //Copy Function
    clipToCopyBoard(data) {
        // if (data[0].created) {
        //   let arr = data.sort(function (a, b) {
        //     return new Date(a.created) - new Date(b.created);
        //   });
        //   data = arr[0].note
        // }
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(data)
        }
    }
    changeNote = (Comments) => {
        this.setState({
            isnotesModal: true,
            Comments: Comments
        })
    }
    getNotesDetails(val, column, data, index) {
        return (
            <div style={{ display: "flex", width: column.bodyStyle.width }}>
                <div style={val.length > 0 ? { display: "flex", width: column.bodyStyle.width - 50 } : {}}>
                    <span className="kanban_title" data-toggle="tool-tip"
                        title={val}
                    >
                        {val}
                    </ span >
                </div>
                <div className="d-flex" style={{ width: 50 }}>
                    {val.length > 0 ?
                        <span className="text-right" style={{ display: "flex" }}>
                            {/* <FontAwesomeIcon icon={faCopy}
                                className='pl-1'
                                data-toggle="tool-tip"
                                // validate={[required]}
                                title="click to copy this Notes"
                                style={{ width: 20, color: "red" }}
                                onClick={() => this.clipToCopyBoard(val[0].props.title)}
                            /> */}
                            <FontAwesomeIcon icon={faExternalLinkAlt}
                                className='pl-1'
                                data-toggle="tool-tip"
                                // validate={[required]}
                                title="click to see all Notes"
                                style={{ width: 20, color: "grey" }}
                                onClick={() => this.changeNote(data)}
                            />
                        </span>
                        : null}
                </div>
            </div>)
    }
    flattenArray = (arrayVal, item) => {
        let val = '';
        let arrLength = ""
        if (arrayVal) {
            val = JSON.stringify(arrayVal);
            if (val) {
                val = arrayVal.map((arrayItem) =>
                    arrayItem !== null ?
                        <p data-toggle="tool-tip"
                            title={arrayItem.note}>{arrayItem.note}</p>
                        : null
                );
            }
        }

        return val;
    }
    getTableFieldItem(field) {
        let tableField = this.getTableFields()
        for (let i = 0; i < tableField.length; i++) {
            if (tableField[i].field === field) {
                return tableField[i];
            }
            //condition to match fieldType
            if (tableField[i].fieldType === field) {
                return tableField[i]['field'];
            }
        }
        return null;
    }
    //  change table body values//
    //hari need to move to derived class or controller
    changeFieldValues = (item, column) => {
        var propType = this.props.type
        let self = this, tableItem;
        tableItem = self.getTableFieldItem(column.field);
        if (tableItem && tableItem.fieldType) {
            if (tableItem.fieldType === "Array") {
                let val;
                val = this.flattenArray(item[column.field]);
                if (tableItem.openNotes) {
                    let data = this.getNotesDetails(val, column, item[column.field])
                    return <>{data}</>
                } else {
                    return <span style={tableItem.style} data-toggle="tool-tip"
                        title={val}
                    >
                        {val}
                    </ span >
                }

            }
        } else {
            return <span data-toggle="tool-tip" title={item[column.field]} >{item[column.field]}</span>;
        }
    }

    /**
  * text field value
  * @param {Object} productKey 
  * @param {Object} props 
  * @param {Object} item 
  * @returns 
  */
    inputTextEditor(productKey, props, item) {
        return <div style={this.state.isShowError ? { height: "50px", display: "grid", backgroundColor: "red" } : { height: "32px", backgroundColor: "red" }}><InputText type={item.inputType ? item.inputType : "text"}
            ref={(ref) => { this.inputRef = ref }}
            id={`${props.field}-${props.rowIndex}`}
            defaultValue={props.rowData[item.field]}
            keyfilter={item.keyfilter ? item.keyfilter : null}
            disabled={this.props.disabled ? this.props.disabled : false}

            onChange={(e) => this.onEditorValueChange(item, props, e.target.value)} />
            <span style={{ fontSize: "10px", color: "red" }}>
                {this.state.isShowError && this.state.showFieldName === `${props.field}-${props.rowIndex}` ? `Please Enter the maximum ${item.maxLength} length ` : ""}
            </span>
        </div>;
    }
    cancelNotes = async (type, id) => {
        this.setState({
            isnotesModal: false,
            openpaymentsModal: false,
            selectedRowPayments: ""
        })

    }
    /**
     *   Change the datatype based on the editor field
     * @param {String} productKey 
     * @param {Object} options 
     * @param {Array} item 
     * @returns the required component based on edit field
     */
    editorbasedField = (productKey, options, item) => {
        return <div>{item.editField === "text" ?
            this.inputTextEditor("allTableValue", options, item) :
            item.fieldType === "Array" && options.rowData[item.field] && options.rowData[item.field][0] && options.rowData[item.field][0].note ? <span>{options.rowData[item.field][0].note}</span> :
                <span>{options.rowData[item.field]}</span>}
        </div>
    }
    getColumns(e, d) {
        const { t } = this.props
        const self = this;
        self.e = e;
        self.d = d;
        let tablefieldsToShow = this.getTableFields()
        if (tablefieldsToShow && tablefieldsToShow.length > 0) {
            return tablefieldsToShow.map((item, i) => {
                let column = (item.show &&
                    <Column
                        key={item.field + i}
                        id={item.field + i}
                        expander={item.field === "expander" ? true : false}
                        style={{
                            maxwidth: item.width,
                            padding: 2,
                        }}

                        editor={(props) => this.editorbasedField('allTableValue', props, item)}
                        onColReorder={this.onColReorder}
                        columnKey={item.field}
                        item={item}
                        bodyStyle={{
                            textOverflow: item.field === "expander" ? 'none' : 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textAlign: item.field === 'status' || item.field === 'role' ? 'center' : item.textAlign
                        }}
                        field={item.field}
                        header={(item.header)}
                        columnMandatory={item.required ? item.required : false}
                        activeTab={this.state.activeTab}
                        body={self.changeFieldValues}
                        headerStyle={{
                            padding: "6px 15px", fontWeight: 500, width: item.width, fontSize: 13,
                            // color: config.blackColor, backgroundColor: config.templateColor, 
                            // textAlign: 'left'
                        }}

                        shownField={this.state.activeTab}
                        dataChangeOptions={item.filter ? this.state.dataChangeOptionswithFilter : this.state.dataChangeOptionswithoutFilter}
                        filterMatchMode={item.filterType === "num" ? "num" : "string"}
                        filter={item.filter ? item.filter : false}

                        isAllowInt={item.allowInt ? true : false}
                        sortable={item.sortable ? true : false}
                        filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
                        selectionMode={item.selectionMode}
                    />
                )
                return column;
            })
        }
    }
    /**
       * 
       * @returns get reasons from propertyStatusandReasonField 
       */
    getNewStatusForEditor = async (val) => {
        var propertyStatusArray = [], filterCriteria = {}
        var propertyStatusReasonArray = [], defaultArray = []
        filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
        filterCriteria.direction = "asc"
        filterCriteria['criteria'] = [
            { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
            { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
            { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
        ]

        return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response["propertyStatusReasons"].length > 0) {
                    let propertyStatusReasons = response["propertyStatusReasons"]
                    for (let reason of propertyStatusReasons) {
                        let statusIndex = propertyStatusArray.findIndex(obj => obj.label === reason.status)
                        if (statusIndex === -1) {
                            if (reason.status.startsWith("MATCHING") || reason.status.startsWith("POST-FILING") || reason.status.startsWith("MAPPING")) {
                                defaultArray.push(reason.status)
                            }
                            propertyStatusArray.push({ label: reason.status, value: reason.status })
                        }
                        let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
                        if (reasonIndex === -1) {
                            propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
                        }
                    }
                    await this.setState({ propertyStatusArray: propertyStatusArray, propertyStatusReasonArray: propertyStatusReasonArray })

                } else if (response && response["propertyStatusReasons"].length == 0) {
                    await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [] })
                } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
                    await this.setState({
                        sessionWarning: true,

                    })
                }
            }).catch((err) => {
                return err;
            })
    }
    //Datatable properties sending to the Edit Datatable component(child component)
    getDataTable = () => {
        return (
            <div
                className={
                    this.state.updatedRecords && this.state.updatedRecords.length > 0
                        ? "disabledSelection disabledRow"
                        : ""
                }
            >
                <EditDataTable
                    onRef={(ref) => (this.editdt = ref)}
                    selectedDeal={this.state.selectedDeal}
                    selectedDealID={this.state.selectedDealID}

                    // exportToCSVCriteria={this.state.filterCriteria.criteria}
                    filteredURL={this.state.filteredURL}
                    metaKeySelection={false}
                    Object1={this.props.Object1}
                    hidePreset={true}
                    setRowCount={this.setRowCount}
                    filter={this.props.filter}
                    setHitUpdateButtonFromPropertyDetails={this.setHitUpdateButtonFromPropertyDetails}
                    propertyStatusArray={this.state.propertyStatusArray}
                    propertyStatusReasonArray={this.state.propertyStatusReasonArray}
                    isEditableGrid={false}
                    getDataFromServer={this.getDataFromServer}
                    defSortField={this.state.defSortField}
                    getTableFields={this.getTableFields}
                    Rowdetails={this.props.Rowdetails}
                    setPropertyIdAfterDelete={this.setPropertyIdAfterDelete}
                    getClaimRecord={this.getClaimRecord}
                    getToggle={this.getToggle}
                    setEditedRows={this.setEditedRows}
                    showMessage={this.showMessage}
                    setEditedRowsEmpty={this.setEditedRowsEmpty}
                    strArray={this.state.strArray}
                    showPreset={"true"}
                    scrollHeight={
                        (this.state.allSelectedRows &&
                            this.state.allSelectedRows.length > 0) ||
                            (this.state.activeTab &&
                                this.state.activeTab !== "close" &&
                                this.state.activeTab !== "closeByIcon")
                            ? "185px"
                            : "calc(100vh - 380px)"
                    }
                    disableInlineEdit={this.disableInlineEdit}
                    disabled={false}
                    tablefieldsToShow={this.getTableFields}
                    getTableFieldsforData={this.getTableFields}
                    deleArray={this.state.deleArray}
                    openClaimDetails={this.openClaimDetails}
                    shownFieldTab={this.state.shownFieldTab}
                    // reorderableColumns={"false"}
                    removeUtc={true}
                    anyOneField={
                        this.state.CrudInputs &&
                            this.state.CrudInputs.validationType === "or"
                            ? this.state.CrudInputs.anyOneField
                            : []
                    }
                    multiSaveUrl={
                        this.state.CrudInputs ? this.state.CrudInputs.multiSaveUrl : ""
                    } //multi save
                    multiDeleteUrl={
                        this.state.CrudInputs ? this.state.CrudInputs.multiDeleteUrl : ""
                    } //multi delete
                    newRecordEmptyObj={
                        this.state.CrudInputs ? this.state.CrudInputs.newRecordObj : {}
                    } //new record obj
                    requiredFields={
                        this.state.CrudInputs ? this.state.CrudInputs.requiredFields : []
                    } //required field in array of strings
                    uniqueDBField={
                        this.state.CrudInputs ? this.state.CrudInputs.uniqueDBField : ""
                    } //unique id for the rec
                    deleteRestrictionField={
                        this.state.CrudInputs
                            ? this.state.CrudInputs.deleteRestrictionField
                            : ""
                    }
                    deleteRestrictionFieldValues={
                        this.state.CrudInputs
                            ? this.state.CrudInputs.deleteRestrictionFieldValues
                            : []
                    }
                    dontShowBelowPage={true}
                    showAbovePage={true}
                    type="ClaimsManagementPropertyDetails"
                    activeTab="ClaimsManagementPropertyDetails" // onSort={this.sortChange}
                    filterCriteria={filter}
                    toggle={this.getPropertyDetails}
                    table_name={"ClaimsManagementPropertyDetails"}
                    noShowHideColOpt={true}
                    updateLimit={(limit) => { this.setState({ limit: limit }) }}
                />
            </div>
        );
    };
    getDataFromServer = async (Object1, filter, type) => {
        await this.props.getDataFromServer(Object1, filter, type)
    }
    getTableFields = () => {
        let data = [
            {
                textAlign: "center",
                width: 47,
                field: "",
                // fieldType: "multiple",
                header: "",
                selectionMode: "multiple",
                show: true,
                mobile: true,
                displayInSettings: false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "claimID",
                "mobile": true,
                // "fieldType": "Link",
                isFuzzy: true,
                "header": "St.Claim ID",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 80,
                "field": "connectedClaimID",
                "mobile": true,
                // "filterType": "text",
                // "fieldType": "ShowData",
                "header": "OPP ClaimID",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 120,
                "field": "statePropertyId",
                "mobile": true,
                "filterType": "num",
                // "editField": "text",
                "header": "St. Prop. ID",
                "displayInSettings": true,
                filter: true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 140,
                "field": "propertyID",
                "mobile": true,
                // "editField": "text"
                "filterType": "num",
                "header": "OPP Prop. ID",
                "displayInSettings": true,
                filter: true,
                "sortable": true,
            },

            {
                "show": true,
                "textAlign": "right",
                "width": 90,
                "field": "propertyMinValueAmount",
                "mobile": true,
                "allowInt": true,
                "editField": "text",
                keyfilter: "pnum",
                fieldType: "formatCurrencywithdec",
                filterType: "num",
                "header": "Min Amt",
                "displayInSettings": true,
                filter: true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "right",
                "width": 150,
                "header": "Max Amt",
                "mobile": true,
                "filterType": "num",
                keyfilter: "pnum",
                fieldType: "formatCurrencywithdec",
                "field": "propertyMaxValueAmount",
                "editField": "text",
                "displayInSettings": true,
                filter: true,
                "sortable": true,
            },


            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": 100,
            //     "header": "Property Status",
            //     "mobile": true,
            //     "field": "propertyStatus",
            //     // "editField": "text",
            //     "displayInSettings": true,
            //     filter: true,
            //     "sortable": true,
            // },
            {
                "show": true,
                "textAlign": "left",
                "width": 220,
                "field": "propertyStatus",
                "filterType": "multiSelect",
                isFuzzy: true,
                "mobile": true,
                "header": "Prop. Status",
                "filterOptions": this.state.propertyStatusArray ? this.state.propertyStatusArray : [],
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 215,
                "field": "propertyStatusReason",
                "mobile": true,
                "filterType": "multiSelect",
                "filterOptions": this.state.propertyStatusReasonArray ? this.state.propertyStatusReasonArray : [],
                isFuzzy: true,
                // "fieldType": "Link",
                "header": "Prop. Reason",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "right",
                "width": 60,
                "field": "sourceState",
                "mobile": true,
                "header": "Src. St",
                "filter": true,
                "isFuzzy": true,
                "sortable": true,
                "displayInSettings": true,
                // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "fileFor",
                "mobile": true,
                "header": "Mapped Name",
                // "isFuzzy": true,
                "filter": true,
                "displayInSettings": true,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "relevantSellerName",
                "mobile": true,
                "header": "Relevant Seller Name",
                // "isFuzzy": true,
                "filter": true,
                "displayInSettings": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                isFuzzy: true,
                "field": "propertyDescription",
                "mobile": true,
                "header": "Property Description",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "holderName",
                isFuzzy: true,
                "mobile": true,
                "header": "Holder Name",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "propertyName",
                "isFuzzy": true,
                "mobile": true,
                "header": "Reported Owner Name",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                isFuzzy: true,
                "field": "propertyAddressLine1",
                "mobile": true,
                "header": "Prop. Address Line1",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 80,
                "field": "propertyZipCode",
                "mobile": true,
                "header": "Prop. Zip",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "propertyCity",
                "mobile": true,
                "isFuzzy": true,

                "header": "Prop. City",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 60,
                "field": "propertyState",
                "mobile": true,
                "header": "Prop. St",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 115,
                "field": "filedBy",
                "mobile": true,
                "header": "Filed By",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": 100,
            //     "header": "Property Reason",
            //     "mobile": true,
            //     "field": "propertyStatusReason",
            //     // "editField": "text",
            //     "displayInSettings": true,
            //     filter: true,
            //     "sortable": true,
            // },
            {
                "show": true,
                "textAlign": "left",
                "width": 120,
                "allowInt": true,
                "header": "Mapped Note",
                "mobile": true,
                "fieldType": "NoteArray",
                filterType: "num",
                "openNotes": true,
                "field": "matchStatusNotes",
                "displayInSettings": true,

            },

        ]
        return data;
    };

    render() {
        const { handleSubmit } = this.props;
        return <div>
            <Loader loader={this.state.isLoading} />
            <div className='mt-3 justify-content-space-around'>
                <span className="px-4 my-2" style={this.state.isshowToasterMsg ? {
                    color: this.state.backgroundColor, display: "flex", justifyContent: "center", height: 25,
                    width: "fit-content", marginLeft: "auto", marginRight: "auto",
                } : { height: 40 }}>
                    {this.state.isshowToasterMsg ? this.state.showToasterMsg : ""}</span>
                <span  >
                    <span href={this.state.PropertySharebody} target="_blank" onClick={() => this.confirmtheredirection()} ><u>Redirect to Property Editor</u></span></span>
                {this.state.selectedRows && this.state.selectedRows.length > 0 ?
                    <span >
                        <FontAwesomeIcon
                            className={'tableheadericon mt-1'}
                            color="#354f6e"
                            style={{ marginRight: "10px", color: "red", float: "right" }}
                            onClick={() => { this.cancelReset("close"); }}
                            data-toggle="tool-tip"
                            title={"Delete Property"}
                            icon={faTimes} />
                    </span>
                    : null}
                {this.state.properties && this.state.properties.length > 0 ?
                    <>
                        <span>
                            {/* <Button style={{ float: "right" }} className="bg-warning text-white  p-button-rounded claim_detail_sub_navbar_buttons mr-2 mt-0" type="submit">{"Update"}</Button> */}
                            <Button color="primary"
                                outline
                                id={"submit"}
                                className="ml-2 mr-2 mt-0"
                                style={{ float: "right" }}
                                onClick={(e) => this.clearFilters()}>
                                Clear Column Filters
                            </Button>
                        </span>
                        <span>
                            {/* <Button style={{ float: "right" }} className="bg-warning text-white  p-button-rounded claim_detail_sub_navbar_buttons mr-2 mt-0" type="submit">{"Update"}</Button> */}
                            <Button color="primary"
                                outline
                                id={"submit"}
                                // className="bg-warning text-white  p-button-rounded claim_detail_sub_navbar_buttons mr-2 mt-0"
                                style={{ float: "right" }}
                                onClick={(e) => this.UpdateMinMaxValues()}>
                                Update
                            </Button>
                        </span>
                        <span>
                            <FontAwesomeIcon icon='download'
                                className={'tableheadericon mt-1'}
                                color='#354f6e'
                                data-toggle="tool-tip"
                                onClick={(e) => this.props.downLoadExcel(this.state.propertyUrl)}
                                title="Download Excel"
                                id="Download Excel"
                                style={{ marginRight: "10px", float: "right" }} />
                        </span></>
                    : null}
            </div>
            {/* <div className='child_Companie'> */}
            {/* <DataTable
                value={this.state.properties ? this.state.properties : []} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" className='mt-2'
                onSelectionChange={e => this.setState({ selectedProperties: e.value })} selection={this.state.selectedProperties}
            >
                {this.getColumns()}


            </DataTable>
          
            </DataTable> */}
            <a href={this.state.PropertySharebody} className='redirecttoprop' target="_blank"  ></a>
            {this.state.confirmationForRedirection ? this.confirmMationModelforRedirection() : null}
            {this.getDataTable()}
            {this.state.isnotesModal ? this.getNotesModal() : null}
            {this.state.isopenColumnModal ?
                this.getConfirmaionModel() : null
            }
        </div>;
    }
}




// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PropertyDetails = reduxForm({
    form: "PropertyDetails", // a unique identifier for this form
    enableReinitialize: true,
    // validate
})(PropertyDetails);

// You have to connect() to any reducers that you wish to connect to yourself
PropertyDetails = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    {
        load: loadAccount,
        setUserData: setUserAction
    } // bind account loading action creator
)(PropertyDetails);

export default withTranslation('common')(PropertyDetails);