import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetchMethodRequest from '../../../../config/service';
import store from '../../../App/store';

// config file
export default class AvailableToConfirmOnSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Grouped By Deal',
      ReadyToFileStatus: [],
      OnHoldStatus: [],
      ResourceNeededStatus: [],
      tabType: 'Grouped By Deal',
      apiUrl: `${apiCalls.confirmationOnsiteReport}`,
      responseKey: "results"
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions[this.state.type] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }

  }





  getGroupByDealFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        // "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "TotalProperties",
        "mobile": false,
        "header": "Total Pending Properties",
        "filterType": "text",
        "fieldType": 'redirect',
        //  "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "GreenBucketCount",
        "mobile": false,
        "header": "# Green Bucket",
        "filterType": "text",
        "fieldType": 'redirect',
        // "fieldType": 'number',
        // "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "right",
        "width": 180,
        "field": "GreenMinEstimatedValue",
        "mobile": false,
        "fieldType": 'formatCurrency',
        "header": "Green Bucket Min Est. Value",
        // "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Group1Count",
        "mobile": false,
        "header": "# Group 1",
        // "filter": true,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "field": "Group1MinEstimatedValue",
        "mobile": false,
        "header": "Group 1 Min Est. Value",
        "fieldType": 'formatCurrency',

        // "filter": true,

        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Group3Count",
        "mobile": false,
        "header": "# Group 3",
        "fieldType": 'redirect',
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "field": "Group3MinEstimatedValue",
        "mobile": false,
        "header": "Group 3 Min Est. Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };

  getTableFields = (tabType) => {
    if (tabType) {
      let apiUrl = this.state.apiUrl;
      let responseKey = this.state.responseKey;
      if (tabType === 'Grouped By Deal') {
        apiUrl = apiCalls.confirmationOnsiteReport;
        responseKey = "results"
      } else if (tabType === 'Grouped By Deal/State') {
        apiUrl = apiCalls.confirmationOnsiteReport;
        responseKey = "results"
      } else {
        apiUrl = apiCalls.confirmationOnsiteReport;
        responseKey = "results"
      }
      this.setState({
        tabType: tabType,
        apiUrl: apiUrl,
        type: tabType,
        responseKey: responseKey
      })
    }

    let data = [];
    if (tabType === 'Grouped By Deal') {
      data = this.getGroupByDealFields();
    } else if (tabType === 'Grouped By Deal/State') {
      data = this.getGroupByDealStateFields();
    } else {
      data = this.getGroupByDealFields();
    }
    return data;
  };
  getGroupByDealStateFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        // "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SourceState",
        "filterType": "text",
        "mobile": false,
        "header": "State",
        "filter": true,
        // "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "TotalProperties",
        "mobile": false,
        "header": "Total Pending Properties",
        "filterType": "text",
        "fieldType": 'redirect',
        //  "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "GreenBucketCount",
        "mobile": false,
        "header": "# Green Bucket",
        "filterType": "text",
        "fieldType": 'redirect',
        // "fieldType": 'number',
        // "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "GreenMinEstimatedValue",
        "mobile": false,
        "fieldType": 'formatCurrency',
        "header": "Green Bucket Min Est. Value",
        // "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Group1Count",
        "mobile": false,
        "header": "# Group 1",
        // "filter": true,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Group1MinEstimatedValue",
        "mobile": false,
        "header": "Group 1 Min Est. Value",
        "fieldType": 'formatCurrency',

        // "filter": true,

        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Group3Count",
        "mobile": false,
        "header": "# Group 3",
        "fieldType": 'redirect',
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Group3MinEstimatedValue",
        "mobile": false,
        "header": "Group 3 Min Est. Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };


  getFormFields = () => {

    return
  }



  submit = async (item) => {
  }

  render() {

    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          addRequried={this.state.tabType === "Ready To File" ? true : false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          // filterRequired={true}
          gridRequried={false}
          activeTabNow={this.state.tabType}
          fontAwesome={this.state.tabType === "Grouped By Deal" ? true : false}
          sample={false}
          isClearFilters={true}
          globalSearchFieldName='user'
          // globalSearch={this.state.tabType === "Ready To File" ? "Seller" : 'Seller'}
          type={this.state.type}
          dontShowTitle={true}
          displayright={true}
          icon={this.state.tabType === "Grouped By Deal" ? "users" : "line-spacing"}
          sortField={this.state.tabType === "Grouped By Deal" ? "TotalProperties" : ""}
          activeTab={"Grouped By Deal"}
          CANProperties={this.state.CANProperties ? this.state.CANProperties : []}
          ReadyToFileStatus={this.state.ReadyToFileStatus ? this.state.ReadyToFileStatus : []}
          SellerNegativeNoticeStatus={this.state.SellerNegativeNoticeStatus ? this.state.SellerNegativeNoticeStatus : []}
          OnHoldStatus={this.state.OnHoldStatus ? this.state.OnHoldStatus : []}
          ResourceNeededStatus={this.state.ResourceNeededStatus ? this.state.ResourceNeededStatus : []}
          isSelectMode={this.state.tabType === "Grouped By Deal" ? "multiple" : ""}
          dataKey={this.state.tabType === "Grouped By Deal" ? "TotalProperties" : ""}//for data key on selection
          isSql={this.state.tabType === "Grouped By Deal" ? "yes" : "no"}
          tabType={true}
          tabOptions={["Grouped By Deal", "Grouped By Deal/State"]}
          routeTo='AvailableToFile'
          displayViewOfForm='modal'
          apiResponseKey={this.state.responseKey}
          className={true}
          apiUrl={this.state.apiUrl}
          entityType='employee'
        />

      </span>
    );
  }
}
