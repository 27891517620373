
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Card, CardBody } from 'reactstrap';
import Moment from 'moment-timezone';


import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// fecth method from service.js file
//config
import apiCalls from '../../../config/apiCalls';
import ExpiredSessionModal from '../CommonModals/ExpiredSessionModal';

import fetch from '../../../config/service';
import configMessage from '../../../config/configMessages';
import configImages from '../../../config/configImages';
import fetchMethodRequest from '../../../config/service';


// show message 
import validate from '../../Form/validate';
import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';

import PaginatorComponent from '../CommonDataTable/PaginatorComponent';


import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import dateFormats from '../../UI/FormatDate/formatDate';

import EditDataTable from '../CommonModals/EditDatatable'
import SessionWarningModal from '../CommonModals/SessionWarningModal'
import BulkUploadModal from '../CommonModals/BulkUploadModal'

// import DefaultInput from 'shared/components/form/DefaultInput';

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)
let timeout

class DealManagement extends React.Component {
  constructor(props) {
    super(props);
    this.buttonActionType = null;
    this.state = {
      isLoginSuccess: false,
      isLoading: false,
      colored: false,
      selectedDealID: "",
      header: true,
      stayInAdd: false,
      namesArray: "",
      textAreaValue: true,
      editedRowNumber: [],
      redoArray: [],
      undoArray: [],
      disableRedoButton: true,
      disableUndoButton: true,
      editedFieldoptions: null,
      CrudInputs: [],
      isDownload: false,
      editedRowIndex: "",
      rows: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
      filterCriteria: {
        limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
        page: 1,
        criteria: [],
      },
      isExpanded: false,
      openDeleteModal: false,
      deleArray: [],
      nameTypeArray: "",
      keyNameChecked: false,
      editedField: "",
      isNotSuccess: false,
      keyNameCheckedState: false,
      rowData: {},
      sheets: [],
      loc: [
        { label: "Alabama - AL", value: "Alabama - AL", show: false },
        { label: "Alaska - AK", value: "Alaska - AK", show: false },
        { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
        { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
        { label: "California - CA", value: "California - CA", show: false },
        { label: "Colorado - CO", value: "Colorado - CO", show: false },
        { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
        { label: "Delaware - DE", value: "Delaware - DE", show: false },
        { label: "Florida - FL", value: "Florida - FL", show: false },
        { label: "Georgia - GA", value: "Georgia - GA", show: false },
        { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
        { label: "Idaho - ID", value: "Idaho - ID", show: false },
        { label: "Illinois - IL", value: "Illinois - IL", show: false },
        { label: "Indiana - IN", value: "Indiana - IN", show: false },
        { label: "Iowa - IA", value: "Iowa - IA", show: false },
        { label: "Kansas - KS", value: "Kansas - KS", show: false },
        { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
        { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
        { label: "Maine - ME", value: "Maine - ME", show: false },
        { label: "Maryland - MD", value: "Maryland - MD", show: false },
        { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
        { label: "Michigan - MI", value: "Michigan - MI", show: false },
        { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
        { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
        { label: "Missouri - MO", value: "Missouri - MO", show: false },
        { label: "Montana - MT", value: "Montana - MT", show: false },
        { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
        { label: "Nevada - NV", value: "Nevada - NV", show: false },
        { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
        { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
        { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
        { label: "New York - NY", value: "New York - NY", show: false },
        { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
        { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
        { label: "Ohio - OH", value: "Ohio - OH", show: false },
        { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
        { label: "Oregon - OR", value: "Oregon - OR", show: false },
        { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
        { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
        { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
        { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
        { label: "South Dakota - SD", value: "South Dakota - SD", show: false },
        { label: "Tennessee - TN", value: "Tennessee - TN", show: false },
        { label: "Texas - TX", value: "Texas - TX", show: false },
        { label: "Utah - UT", value: "Utah - UT", show: false },
        { label: "Vermont - VT", value: "Vermont - VT", show: false },
        { label: "Virginia - VA", value: "Virginia - VA", show: false },
        { label: "Washington - DC", value: "Washington - DC", show: false },
        { label: "Washington - WA", value: "Washington - WA", show: false },
        { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
        { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
        { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
      ],

      strArray: [],
      sellerViewType: false,
      selectedRows: [],
      subCatg: [],
      progress: 0,
      first: 0,
      dataSourceId: null,
      rows: 20,
      issueName: [
        { label: "No specific guidelines yet - 0", value: "0" },
        { label: "Date guidelines - 1.1", value: "1.1" },
        { label: "Geographic guidelines - 1.2", value: "1.2" },
        { label: "Other guidelines - 1.3", value: "1.3" },
        { label: "Research needed - 1.4", value: "1.4" },
        { label: "Code 7 Contingent - 1.7", value: "1.7" },
        { label: "Do not search or pursue - 2", value: "2" },
        { label: "Company employee - 3", value: "3" },
        { label: "CR OPP APA Modification - 7.1", value: "7.1" },
        { label: "Legal review - 7.2", value: "7.2" },
        { label: "Legal &/or Seller Consult - Signatory - 7.3", value: "7.3" },
        { label: "Legal &/or Seller Consult - Entity - 7.4", value: "7.4" },
        { label: "Legal &/or Seller Consult - JV - 7.5", value: "7.5" },
        { label: "Legal &/or Seller Consult - Foreign - 7.6", value: "7.6" },
        { label: "Blank", value: null }
      ],
      // issueName: [{ label: "No Issues Identified - 0", value: "0" }, { label: "Issue Identified - 1", value: "1" }, { label: "Unable to Pursue - 2", value: "2" }, { label: "Escalate if Assets - 7", value: "7" }, { label: "Blank", value: null }],
      Apa: [{ "label": "10-Full Remnant,subject to Exclusions", "value": '10' },
      { "label": "14-Full Remnant,includes RCS,subject to Exclusions", "value": '14' },
      { "label": "20-Limited scope,see Inclusions,subject to Exclusions", "value": '20' },
      { "label": "24-Limited scope,includes RCS,see Inclusions", "value": '24' },
      { "label": "30-UP only", "value": '30' },
      { "label": "40-RCS only", "value": '40' },
      { "label": "50-Judgment only", "value": '50' },
      { "label": "60-Other,limited to named inclusion(s)", "value": '60' },
      { "label": "64-Other,limited to named inclusion(s) with RCS", "value": '64' },
      { "label": "70-Full Remnant,Unable to pursue recoveries", "value": '70' },
      { "label": "75-Full Remnant,PWC portfolio restriction", "value": '75' },
      { "label": "80-Corporate Remnants", "value": '80' },
      { "label": "82-Corporate Remnants,limited scope", "value": '82' },
      { "label": "85-Corporate Remnants,NDA", "value": '85' },
      { "label": "87-Corporate Remnants,Sold", "value": '87' },
      ],


      bkAPA: [{ "label": "10-Full Remnant,subject to Exclusions", "value": '10' },
      { "label": "14-Full Remnant,includes RCS,subject to Exclusions", "value": '14' },
      { "label": "20-Limited scope,see Inclusions,subject to Exclusions", "value": '20' },
      { "label": "24-Limited scope,includes RCS,see Inclusions", "value": '24' },
      { "label": "30-UP only", "value": '30' },
      { "label": "40-RCS only", "value": '40' },
      { "label": "50-Judgment only", "value": '50' },
      { "label": "60-Other,limited to named inclusion(s)", "value": '60' },
      { "label": "64-Other,limited to named inclusion(s) with RCS", "value": '64' },
      { "label": "70-Full Remnant,Unable to pursue recoveries", "value": '70' },
      { "label": "75-Full Remnant,PWC portfolio restriction", "value": '75' },],


      crAPA: [{ "label": "80-Corporate Remnants", "value": '80' },
      { "label": "82-Corporate Remnants,limited scope", "value": '82' },
      { "label": "85-Corporate Remnants,NDA", "value": '85' },
      { "label": "87-Corporate Remnants,Sold", "value": '87' }],

      isOpenFormModal: false,
      isOpenAddressModal: false,
      hideSplitBtn: false,
      showCaseClosedDate: false,
      APAAddendumDate1: false,
      CaseTransfer1: false,
      CaseConversion1: false,
      setExhibitType: "",
      timer: false,
      formType: "",
      copiedA: false,
      subcategory: { "Sourcing": [{ label: "Valuation", value: "Valuation" }], "Research": [{ label: "Corporate Tree", value: "Corporate Tree" }, { label: "Entity Details", value: "Entity Details" }, { label: "Property Details", value: "Property Details" }], "Legal": [{ label: "APA", value: "APA" }, { label: "Document", value: "Document" }, { label: "Information", value: "Information" }], "Transition": [{ label: "Entity", value: "Entity" }], "Recovery": [{ label: "General Note", value: "General Note" }, { label: "State", value: "State" }] },
      copiedB: false,
      category: [],
      subCategory: [],
      enteredBy: [],
      EntityName: null,
      SellerNickName: null,
      AzureStorageDirectory: null,
      OpraId: null,
      LeadSeller: null,
      LeadCaseNumber: null,
      filterSelect: "",
      CategoryFilter: false,
      SubCategoryFilter: false,
      EnteredByFilter: false,
      searchFilterCriteria: [],
      exhibit1Array: [],
      exhibit2Array: [],
      exhibit2String: [],
      exhibit1String: [],

      showErrorMessage: false,
      sessionExpiryModal: false,
      matrixQstns: [],
      checkedListPurpose: "",

      checklists: [],
      key: 0,
      selectedStatus: [],
      tempCheckList: [],
      isSelectedData: [],
      activeTab: this.props.activeTab ? this.props.activeTab : "1",
      showNames: true,
      errorResponse: "",
      isShowChecklistError: false,
      isSplittedStates: [],
      ismoveAll: false,
      additionalselectedStates: [],
      statestab2Data: [],
      selectedValues2: [],
      downtoleft: [],
      getValues1: [],
      ismoveAlltoleft: false,
      upCount: 0,
      downCount: 0,
      leftCount: 0,
      rightCount: 0,
      actions: [],
      isSelectAll: false,
      nameConnection: [
        { label: "Acquisition", value: "Acquisition" },
        { label: "Conversion", value: "Conversion" },
        { label: "Division", value: "Division" },
        { label: "Former Name", value: "Former Name" },
        { label: "Joint Debtor", value: "Joint Debtor" },
        { label: "Judgment Debtor", value: "Judgment Debtor" },
        { label: "Lead Debtor", value: "Lead Debtor" },
        { label: "Name Variation", value: "Name Variation" },
        { label: "Oak Point APA Lead Seller", value: "Oak Point APA Lead Seller" },
        { label: "Oak Point APA Seller Firm", value: "Oak Point APA Seller Firm" },
        { label: "Oak Point APA Seller Signatory", value: "Oak Point APA Seller Signatory" },
        { label: "Parent Company", value: "Parent Company" },
        { label: "Subsidiary", value: "Subsidiary" },
        { label: "Trade Name", value: "Trade Name" },
        { label: "Unconfirmed", value: "Unconfirmed" },
        { label: "Unrelated", value: "Unrelated" },
      ],
      issues: [
        { label: "Unknown", value: "Unknown" },
        { label: "0-No Issues Identified", value: "0-No Issues Identified" },
        { label: "1-Issue Identified", value: "1-Issue Identified" },
        { label: "2-Unable to Pursue", value: "2-Unable to Pursue" },
        { label: "3-Connection to case", value: "3-Connection to case" },
        { label: "7-Escalate if Assets", value: "7-Escalate if Assets" },

      ],
      priority: [
        { label: "Critical", value: "Critical" },
        { label: "High", value: "High" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" }],
      states: [
        { label: "Alabama - AL", value: "Alabama - AL", show: false },
        { label: "Alaska - AK", value: "Alaska - AK", show: false },
        { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
        { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
        { label: "California - CA", value: "California - CA", show: false },
        { label: "Colorado - CO", value: "Colorado - CO", show: false },
        { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
        { label: "Delaware - DE", value: "Delaware - DE", show: false },
        { label: "Florida - FL", value: "Florida - FL", show: false },
        { label: "Georgia - GA", value: "Georgia - GA", show: false },
        { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
        { label: "Idaho - ID", value: "Idaho - ID", show: false },
        { label: "Illinois - IL", value: "Illinois - IL", show: false },
        { label: "Indiana - IN", value: "Indiana - IN", show: false },
        { label: "Iowa - IA", value: "Iowa - IA", show: false },
        { label: "Kansas - KS", value: "Kansas - KS", show: false },
        { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
        { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
        { label: "Maine - ME", value: "Maine - ME", show: false },
        { label: "Maryland - MD", value: "Maryland - MD", show: false },
        { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
        { label: "Michigan - MI", value: "Michigan - MI", show: false },
        { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
        { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
        { label: "Missouri - MO", value: "Missouri - MO", show: false },
        { label: "Montana - MT", value: "Montana - MT", show: false },
        { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
        { label: "Nevada - NV", value: "Nevada - NV", show: false },
        { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
        { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
        { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
        { label: "New York - NY", value: "New York - NY", show: false },
        { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
        { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
        { label: "Ohio - OH", value: "Ohio - OH", show: false },
        { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
        { label: "Oregon - OR", value: "Oregon - OR", show: false },
        { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
        { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
        { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
        { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
        { label: "south Dakota - SD", value: "South Dakota - SD", show: false },
        { label: "tennessee - TN", value: "Tennessee - TN", show: false },
        { label: "Texas - TX", value: "Texas - TX", show: false },
        { label: "Utah - UT", value: "Utah - UT", show: false },
        { label: "Vermont - VT", value: "Vermont - VT", show: false },
        { label: "Virginia - VA", value: "Virginia - VA", show: false },
        { label: "Washington - DC", value: "Washington - DCPuerto Rico", show: false },
        { label: "Washington - WA", value: "Washington - WA", show: false },
        { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
        { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
        { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
      ],
      comments: [],
      isSplitData: [],
      lengthNote: 0,
      taskNote: "",
      last2Notes: [],
      sicCodes: [],
      showFields: [],
      otherStates: [],
      issplitAll: false,
      tableFields: '',
      selectedValues: [],
      totalFields: [],
      exportData: [],
      apierrorResponse: false,
      disabledField: false,
      assignedValue: false,
      issplitbyStatus: false,
    };
  }

  componentDidUpdate() {
    if (this.state.isshowToasterMsg) {
      setTimeout(() => this.setState({ isshowToasterMsg: false, showToasterMsg: "" }), 10000);
    }

    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }
    // this.getTab2Body()
  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }


    let sessionexpired = await localStorage.getItem('sessionexpired')
    let role = await JSON.parse(localStorage.getItem('loginCredentials'))
    if (role && role.roleName) {
      let filterCriteria = this.state.filterCriteria
      filterCriteria["limit"] = role.pageLimit ? role.pageLimit : 10
      this.setState({ role: role.roleName, filterCriteria: filterCriteria })
    }
    if (this.props && this.props.location && window.location.pathname === "/names") {
      this.setState({ activeTab: "3", tablefieldsToShow: tablefieldsToShow })

    }
    if (this.props && this.props.location && window.location.pathname === "/Address") {
      this.setState({ activeTab: "4", tablefieldsToShow: tablefieldsToShow })

    }



    if (sessionexpired === "true") {
      await this.setState({ sessionExpiryModal: true })
    }

    if (window.location.href.includes("sellersView")) {
      await this.setState({ sellerViewType: true })
    }
    if (window.location.href.includes("isRedirect")) {
      this.props.load({})
      await this.setState({ redirectToCRMatrix: true, key2: "CR Matrix" })
    }
    let tablefieldsToShow = this.getTableFields()
  }


  static getDerivedStateFromProps(props, state) {
    if (props && props.location) {
      if (props.location.pathname === "/names" && state.activeTab !== "3") {
        return { activeTab: "3", selectedDeal: "", selectedDealID: "" };
      } else if (props.location.pathname === "/Address" && state.activeTab !== "4") {

        return { activeTab: "4", selectedDeal: "", selectedDealID: "" };
      } else if (props.location.pathname === "/deal" && state.activeTab !== "1") {
        return { activeTab: "1", selectedDeal: "", selectedDealID: "" };
      }
    }
  }

  refreshData=async() =>{
    if (this.state.CrudInputs && this.state.CrudInputs.refreshData) {
      this.state.CrudInputs.refreshData()
    }
  }

  /**
   *   invokes when the tab change or any filter change 
   * @param {String} tab 
   * @param {String} type 
   * @param {Boolean} DontHitPreset 
   * @param {Boolean} refresh 
   */
  toggle = async (tab, type, DontHitPreset, refresh) => {
    if (this.state.activeTab !== tab || type) {
      if (!DontHitPreset) {
        var tablefieldsToShow = await this.editdt.loadAllPresets()
      }
      await this.setState({
        activeTab: tab,
        Loading: true,
        apierrorResponse: false,
        Category: "",
        apisuccResponse: false,
        SubCategory: "",
        selectedRows: "",
        EnteredBy: "",
        EnteredByFilter: false,
        CategoryFilter: false,
        SubCategoryFilter: false,
        // tablefieldsToShow: this.getTableFields(),
        addressnames: [],
        addresses: [],
        isLoading: true
      });
      this.handlePercentage("1")
      let filter = typeof type !== "string" ? type : this.state.filterCriteria
      let url, responsekey, defSortField;
      if (tab === "3") {
        // TypeError.sortfield === "CompanyID" ? "" : filter.sortfield
        filter['limit'] = filter && filter.limit ? filter.limit : this.state.rows
        filter['sortfield'] = filter && filter.sortfield && filter.sortfield !== "CompanyID" ? filter.sortfield : "BusinessName"
        filter['direction'] = filter && filter.direction ? filter.direction : "asc"
        filter['criteria'] = filter && filter.criteria ? filter.criteria : []
        defSortField = "BusinessName"

        url = `${apiCalls.AddressNames}?filter=${JSON.stringify(filter)}&str=${this.state.strArray}`
        responsekey = "addressnames"
      } else if (tab === "4") {
        filter['limit'] = filter && filter.limit ? filter.limit : this.state.rows
        filter['sortfield'] = filter && filter.sortfield && filter.sortfield !== "BusinessName" ? filter.sortfield : "CompanyID"
        filter['direction'] = filter && filter.direction ? filter.direction : "asc"
        filter['criteria'] = filter && filter.criteria ? filter.criteria : []
        url = `${apiCalls.addresses}?filter=${JSON.stringify(filter)}&str=${this.state.strArray}`
        responsekey = "addresses"
        defSortField = "CompanyID"
      } else if (tab === "5") {
        filter['limit'] = filter && filter.limit ? filter.limit : this.state.rows
        filter['sortfield'] = filter && filter.sortfield ? filter.sortfield : "DWDateInserted"
        filter['direction'] = filter && filter.direction ? filter.direction : "desc"
        filter["criteria"] = filter && filter.criteria ? filter.criteria : []
        url = `${apiCalls.SellerNotes}?filter=${JSON.stringify(filter)}&str=${this.state.dimSellerId}`
        responsekey = "notes"
        defSortField = "DWDateInserted"
      }

      if (tab === "3" || tab === "4" || tab === "5") {
        // await this.setState({
        //   first: 0,
        //   rows: 20,
        // })
        await this.setState({ exportNamesUrl: url, isLoading: true, filterCriteria: filter })
        fetch("GET", url)
          .then(async (response) => {
            if (response && response[responsekey] && response[responsekey].length > 0) {
              this.editdt.getValueforTable([], 0)
              var updatedData = this.updateDealIDToData(response[responsekey])
              // updatedData = await this.editdt.updateDateFormat(updatedData, this.getTableFields(), "MM-DD-YYYY");
              await this.setState({
                defSortField, defSortField, historyAddressNames: updatedData, [responsekey]: updatedData, totalRecordsLength: response.pagination.totalCount, dataSourceId: response.dataSourceId, totalRecordsLength: 0, responseaddressnames: JSON.parse(JSON.stringify(updatedData)), updatedRecords: [], editedFieldoptions: null
              })
              if (this.editdt) {
                if (!DontHitPreset) {
                  await this.editdt.loadAllPresets()
                }
                this.editdt.getValueforTable(updatedData, response.pagination.totalCount)
                this.editdt.setActiveTab(tab)
                if (!refresh) {
                  this.editdt.clearHistory()
                }
              }
              await this.setState({ isLoading: false })
            } else {
              var updatedData = this.updateDealIDToData(response[responsekey])
              await this.setState({ [responsekey]: [], totalRecordsLength: 0, totalRecordsLength: 0 })
              await this.setState({ isLoading: false })
              if (this.editdt) {
                this.editdt.getValueforTable(updatedData, response.pagination.totalCount)
                if (!DontHitPreset) {
                  await this.editdt.loadAllPresets()
                }
                this.editdt.setActiveTab(tab)
                if (!refresh) {
                  this.editdt.clearHistory()
                }
              }
            }
            this.setState({
              isLoading: false
            })
          }).catch((err) => {
            return err;
          });
      }
      if (tab === "5") {
        let urlUsers = `${apiCalls.Users}?filter={"limit":50,"page":1,"criteria":[],"sortfield":"created","direction":"desc"}`
        fetch("GET", urlUsers)
          .then(async (response) => {
            if (response && response.users && response.users.length > 0) {
              let users = []
              for (let user of response.users) {
                users.push({ label: user.display_name, value: user.display_name })
              }
              await this.setState({ users: users })
            } else {
              await this.setState({ users: [] })
            }
          }).catch((err) => {
            return err;
          });
      }
    }

  }

  /**
    * 
    * @param {Array} itemdata 
    * @returns updated values
    */
  updateDealIDToData(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {

      if (this.state.first) {
        item.Sno = (index + 1) + this.state.first;
      } else {
        item.Sno = index + 1;
      }
      if (item.IssueName) {
        item.IssueName = item.IssueName.split(",")
      }
      item.DealID = this.state.selectedDealID

      // Date formate
      this.getTableFields().map((obj) => {
        if ("dateTime" == obj.fieldType && item[obj.field]) {
          item[obj.field] = Moment(item[obj.field]).format("MM/DD/YYYY");
        }
      })

      modifiedData.push(item);

    });
    return modifiedData;
  }
  /**
     * Get all companies based on deal id
     * @param {String} y 
     */
  getCompanies = async (y) => {
    let str = []; let strArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [
      { key: "DealID", value: `${y}`, type: "eq" }
    ];
    let url1 = `${apiCalls.Companies}?filter=${JSON.stringify(filterCriteria)}`;

    await fetch("GET", url1)
      .then(async (response) => {
        let arr = this.state.isSelectedData
        if (response && response.companies) {

          for (let comp of response.companies) {
            arr.push({ label: comp.CompanyName, value: comp.CompanyID, show: true, BusinessID: comp.BusinessID })
          }
          await this.setState({ isSelectedData: arr, hideSplitBtn: true, hideUpdatebtn: false })
          for (let ar of this.state.isSelectedData) {
            str.push(ar.value)
          }
          strArray = str.join(",")
          await this.setState({ strArray: strArray })
        }
      }).catch((err) => {
        return err;
      });
  }

  //for getting names and address without tab click
  handleLoadforTabsasScreens = async (data, fromSidebar) => {
    // this.props.load({})
    let str = []; let strArray;

    var url
    await this.setState({ isLoading: true })
    this.props.load({})
    this.handlePercentage('1')
    if (fromSidebar) {
      var y = data.DealID
      url = `${apiCalls.Seller}/${data.DealID}`;
    }

    await this.setState({ dealType: data.DealType })
    this.props.change("dealType", data.DealType)
    let z = this.state.Apa.find(elem => elem.value === data.APAType)
    if (z) {
      //with z values hit the api call
      this.props.change("APAType", z.label)
      this.setState({ APAType: z.label })
    }
    await this.setState({ showEntityName: data.EntityName, selectedDeal: data.EntityName })
    await this.setState({ headDimSellerID: data.DealID })
    setTimeout(() => {
      this.props.load(data)
      this.props.change("dealType", data.dealType)
      this.setState({ dealType: data.DealType })
      let z = this.state.Apa.find(elem => elem.value === data.APAType)
      if (z) {
        //with z values hit the api call
        this.props.change("APAType", z.label)
        this.setState({ APAType: z.label })
      }
    }, 200);
    await this.getCompanies(y)
  }


  /*
    cancel Session modal
    */
  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }
  /**
   * 
   * @param {Deal Id} y 
   * Getting the companies associated with specific deal
   */
  getCompanies = async (y) => {
    let str = []; let strArray;
    let filterCriteria = {}
    filterCriteria['criteria'] = [
      { key: "DealID", value: `${y}`, type: "eq" }
    ];
    let url1 = `${apiCalls.Companies}?filter=${JSON.stringify(filterCriteria)}`;

    await fetch("GET", url1)
      .then(async (response) => {
        let arr = this.state.isSelectedData
        if (response && response.companies) {

          for (let comp of response.companies) {
            arr.push({ label: comp.CompanyName, value: comp.CompanyID, show: true, BusinessID: comp.BusinessID })
          }
          await this.setState({ isSelectedData: arr, hideSplitBtn: true, hideUpdatebtn: false })
          for (let ar of this.state.isSelectedData) {
            str.push(ar.value)
          }

          strArray = str.join(",")

          await this.setState({ strArray: strArray })

        }
      }).catch((err) => {
        return err;
      });
  }

  /*
  Bulk Upload Modal calling
  */
  bulkUpload = () => {
    return (
      <BulkUploadModal
        type={"seller/names"}
        sample={this.state.activeTab === "3" ? configImages.namesSampleXL : this.state.activeTab === "4" ? configImages.addressSampleXL : this.state.key2 === "debtors" ? configImages.DebtorsSampleXL : configImages.ApaNamesSampleXL}
        toggleNames={(this.state.activeTab === "3" || this.state.activeTab === "4") ? this.toggleNames : this.refreshApaNames}
        activeTab={this.state.key2 === "debtors" ? "debtors" : this.state.activeTab}
        dimSellerID={this.state.dimSellerId}
        namesArray={this.state.namesArray}
        nameTypeArray={this.state.nameTypeArray}
        IssueCodeArray={this.state.IssueCodeArray}
        setResponse={this.setResponse}
        openBulkUploadModal={this.state.openBulkUploadModal}
        closeBulkModal={this.closeBulkModal} />
    )
  }

  // clear input data
  clearInputFields = () => {
    this.props.reset();
  }

  submit = (values) => {

  }

  //Showing Error Messege from child components or parent components
  getErrorMessage = async (errorMessage, type, color) => {
    await this.setState({
      apierrorMessage: errorMessage.replace("\r\n", ""),
      apierrorResponse: true,
      apierrorcolor: color === "error" ? "red" : "green"
    })

  }


  //copy clipboard function  (for now not in using)
  copyToClipboard = async (type) => {
    navigator.clipboard.writeText()
  }

  //for showing progress change in horizontal scroll
  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }

  //open bulk upload(not using for now)
  bulkUploadMoadal = () => {
    this.setState({ openBulkUploadModal: true })
  }

  //close Bulk Modal(not using for now)
  closeBulkModal = () => {
    this.setState({ openBulkUploadModal: false })
  }



  // get the Table fields from the DealMgmtFields folder 
  getTableFields = () => {
    let data
    data = this.state.CrudInputs ? this.state.CrudInputs.tableFields : []
    return data;
  };

  /**
   * 
   * @param {Object} e 
   * @param {String} tab 
   * @param {Object} CrudInputs 
   */

  //selected Deal in Estate dropdown
  handlegetDeal = async (e, tab, CrudInputs) => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    await this.setState({
      selectedDeal: e.value,
      selectedDealID: e.value.DealID,
      dimSellerId: e.value.DealID,
      CrudInputs: CrudInputs,
      strArray: [],
      filterCriteria: {
        limit: loginCredentials.pageLimit ? loginCredentials.pageLimit :
          localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
        page: 1,
        criteria: [],
      },
      // tablefieldsToShow: CrudInputs.tableFields
    })

    this.editdt.getTableFieldsforTableColumns([])
    this.editdt.getValueforTable([], 0)
    if (tab) {
      this.setState({
        activeTab: tab
      })
    }
    if (e.value.DealID) {
      await this.setState({ isLoading: true })
      await this.handleLoadforTabsasScreens(e.value, 'sidebar')
      if (window.location.pathname.includes("name")) {
        this.toggle("3", this.state.filterCriteria)

      } else if (window.location.pathname.includes("address") || window.location.pathname.includes("Address")) {
        this.toggle("4", this.state.filterCriteria)
      }
      else {
        this.toggle("5", this.state.filterCriteria)
      }
    }
    // this.props.load(e.value)
  }

  //Estate names from api
  EstateItems = (event) => {
    let filterCriteria = { limit: 5, sortfield: "EntityName" };
    filterCriteria["criteria"] = [{
      key: "EntityName",
      value: event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : "",
      type: "regexOr"
    }]
    let apiUrl = `seller?filter=${JSON.stringify(filterCriteria)}`
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            // this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
        }
      }).catch((err) => {
        return err;
      });
  }


  //Export functionality for csv
  getDataToExport = async () => {

    await this.setState({ isLoading: true, isexported: true })
    let filterCriteriaData = this.state.filterCriteria;
    // delete filterCriteriaData['limit'];
    // delete filterCriteriaData['page'];
    let url;
    url = this.state.activeTab === "3" ? "addressnames" : "addresses"
    let apiUrl;

    apiUrl = `${this.state.exportNamesUrl}&type=exportToCsv`;
    this.getErrorMessage("The file will be downloaded in few minutes", "", "Success")

    if (apiUrl) {
      return fetchMethodRequest('GET', apiUrl)
        .then(async (response) => {
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
          }
          if (response && response[url] && response[url].length && response[url].length > 0) {
            let exportData = response[url]
            let item = exportData[0]
            exportData[0] = item
            for (let exportId of exportData) {
              if (exportId.BusinessNamesID) delete exportId.BusinessNamesID
              if (exportId.CompanyID) delete exportId.CompanyID
              if (exportId.DataSourceID) delete exportId.DataSourceID
              if (exportId.BusinessID) delete exportId.BusinessID
              if (exportId.BusinessLocationID) delete exportId.BusinessLocationID
              if (exportId.LastUpdated) delete exportId.LastUpdated
              if (exportId.DWDateInserted) delete exportId.DWDateInserted
              if (exportId.DWDateLastModified) delete exportId.DWDateLastModified
              if (exportId.DWDelete) delete exportId.DWDelete
              if (exportId.DWLastModifiedBy) delete exportId.DWLastModifiedBy
              if (exportId.DWLastOperation) delete exportId.DWLastOperation
              if (exportId.DWRowIsCurrent) delete exportId.DWRowIsCurrent
            }
            this.setState({
              exportData: exportData,
              // exportHeaders: headers,
              isLoading: false,
            }, () => {
              // click the CSVLink component to trigger the CSV download
              this.csvLinkRef.link.click();
              this.setState({
                exportData: [],
                isexported: false
              })
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        }).catch((err) => {
          this.setState({
            isLoading: false
          });
          return err;
        });
    }

  }

  //format for dates
  updateDateFormat(itemdata, dateFormat) {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < this.state.tablefieldsToShow.length; k++) {
        if ("Date" === this.state.tablefieldsToShow[k]['fieldType']) {
          if (this.props.type !== "Property Editor") {
            if (itemdata[i][this.state.tablefieldsToShow[k]['field']] !== null) {
              let time = moment(itemdata[i][this.state.tablefieldsToShow[k]['field']]).utcOffset(dateFormats.isDstObserved(itemdata[i][this.state.tablefieldsToShow[k]['field']]) ? '-05:00' : '-06:00');

              itemdata[i][this.state.tablefieldsToShow[k]['field']] =
                this.props.type === "Company" ?
                  dateFormats.formatDate(
                    itemdata[i][this.state.tablefieldsToShow[k]['field']],
                    this.state.tablefieldsToShow[k]['dateFormat'] ? this.state.tablefieldsToShow[k]['dateFormat'] : dateFormat, "nhg")
                  :
                  dateFormats.formatDate(
                    time,
                    this.state.tablefieldsToShow[k]['dateFormat'] ? this.state.tablefieldsToShow[k]['dateFormat'] : dateFormat);
            }
          } else {
            if (itemdata[i][this.state.tablefieldsToShow[k]['field']] !== null) {
              itemdata[i][this.state.tablefieldsToShow[k]['field']] =
                dateFormats.formatDate(
                  itemdata[i][this.state.tablefieldsToShow[k]['field']],
                  this.state.tablefieldsToShow[k]['dateFormat'], 'NS');
            }
          }
        } else if ("Time" === this.state.tablefieldsToShow[k]["fieldType"]) {
          let val = itemdata[i][this.state.tablefieldsToShow[k]["field"]];
          if (val) {
            let time = moment(val).utcOffset(dateFormats.isDstObserved(val) ? '-05:00' : '-06:00');
            let dd = dateFormats.formatDate(time, "MM/DD/YY hh:mm a");
            itemdata[i][this.state.tablefieldsToShow[k]["field"]] = dd;
          }

        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  //Datatable properties sending to the Edit Datatable component(child component)
  getDataTable = () => {
    return (
      <div className={this.state.updatedRecords && this.state.updatedRecords.length > 0 ? "disabledSelection disabledRow" : ""}>
        <EditDataTable
          onRef={(ref) => this.editdt = ref}
          selectedDeal={this.state.selectedDeal}
          getDatbasedonComponentSelected={this.props.getDatbasedonComponentSelected}
          selectedDealID={this.state.selectedDealID}
          metaKeySelection={false}
          isEditableGrid={this.state.CrudInputs ? this.state.CrudInputs.isEditableGrid : false}//screen name
          defSortField={this.state.defSortField}
          getTableFieldsforData={this.getTableFields}
          refreshData={this.refreshData}
          strArray={this.state.strArray}
          disableInlineEdit={this.disableInlineEdit}
          // tablefieldsToShow={this.state.tablefieldsToShow}
          deleArray={this.state.deleArray}
          activeTab={this.state.activeTab}
          shownFieldTab={this.state.shownFieldTab}
          refreshColumnDropdownApis={this.state.CrudInputs ? this.state.CrudInputs.refreshData : ""}
          multiSaveUrl={this.state.CrudInputs ? this.state.CrudInputs.multiSaveUrl : ""}//multi save
          multiDeleteUrl={this.state.CrudInputs ? this.state.CrudInputs.multiDeleteUrl : ""}//multi delete
          newRecordEmptyObj={this.state.CrudInputs ? this.state.CrudInputs.newRecordObj : {}}//new record obj
          requiredFields={this.state.CrudInputs ? this.state.CrudInputs.requiredFields : []}//required field in array of strings
          uniqueDBField={this.state.CrudInputs ? this.state.CrudInputs.uniqueDBField : ""}//unique id for the rec
          deleteRestrictionField={this.state.CrudInputs ? this.state.CrudInputs.deleteRestrictionField : ""}
          deleteRestrictionFieldValues={this.state.CrudInputs ? this.state.CrudInputs.deleteRestrictionFieldValues : []}
          validationType={this.state.CrudInputs ? this.state.CrudInputs.validationType : "and"}
          anyOneField={this.state.CrudInputs && this.state.CrudInputs.validationType === "or" ? this.state.CrudInputs.anyOneField : []}
          type={this.state.CrudInputs ? this.state.CrudInputs.type : {}}//screen name
          // onSort={this.sortChange}
          filterCriteria={this.state.filterCriteria}
          toggle={this.toggle}
        />


      </div >
    )
  }

  //closing modals if it is opened
  closeOrderModal = async () => {
    await this.setState({
      saveOrderModal: false,
      showOrderModal: false,
      openDeleteModal: false,
      selectedRows: [],
      deleArray: [],
    })
  }

  //Paginator component
  getPaginator() {
    return (
      <PaginatorComponent
        totalRecords={this.state.activeTab === '4' ? this.state.totalRecordsLength : this.state.totalRecordsLengthNames}
        first={this.state.first}
        rows={this.state.rows ? this.state.rows : this.state.filterCriteria["limit"]}
        onPageChange={this.onPageChange}
        isWeb={true}
      />
    )
  }

  /**
   * On page change when the paginator changes
   * @param {Object} event 
   */
  onPageChange = async (event) => {
    let page
    let filterCriteria = this.state.filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      page = event.page + 1
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      await this.setState({
        rows: event.rows,
        page: event.page,
        first: event.first,
        // expandAll: false,
        expandedRows: [],
        filterCriteria: filterCriteria
      })
      await this.toggle(this.state.activeTab, filterCriteria)
    }
  }

  //return the paginator component
  getActivitiesHeader() {
    // const {t} = this.props
    return (
      <div className='col-12  pb-1'>
        <span className='float-right pt-2'>
          {this.state.totalRecordsLength > 20 || this.state.totalRecordsLengthNames > 20 ?
            this.getPaginator()
            : null}
        </span>
      </div>
    )
  }

  //get the values from datatable child component
  updatedEditField = (value) => {
    this.setState({
      editedRowIndex: value.editedRowIndex ? value.editedRowIndex : this.state.editedRowIndex,
      editedRowField: value.editedRowField ? value.editedRowField : this.state.editedRowField,
      editedField: value.editField ? value.editField : this.state.editField,
      // editedFieldoptions: value.editedFieldoptions ? value.editedFieldoptions : this.state.editedFieldoptions,
    })
  }

  render() {
    return (
      <Card className="cardForListMargin mx-2 card">
        <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />

        <CardBody className="tableCardBody">
          <form className="form" style={{ width: "100%" }}>
            {this.state.selectedDealID ?
              <div style={{ width: "inherit" }}>
                {this.getDataTable()}
                {/* {this.getActivitiesHeader()} */}
              </div>
              : null}

          </form >
          {this.state.isopenColumnModal ?
            this.getConfirmaionModel() : null
          }
          {this.state.isnotesModal ? this.getNotesModal() : null}
          {
            this.state.sessionExpiryModal ?
              <Redirect to="/log_in" />
              : null
          }
          {this.state.forceLogout ? <Redirect to="/log_in" /> : ""}
          {this.state.isKanbanModelClose ? <Redirect to="/sellers" /> : ""}
          {this.state.openBulkUploadModal ? this.bulkUpload() : null}
          {this.state.sessionWarning ? this.getSessionWraningModal() : null}
          {this.state.isOpenFormModal ? this.AddNamesModal() : null}
          {this.state.openSignatoryModal ? this.AddSignatoryModal() : null}
          {this.state.isOpenAddressModal ? this.AddAddressModal() : null}
          {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
        </CardBody >
      </Card >
    )
  }
}

DealManagement = reduxForm({
  form: "Deal Mgmt Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(DealManagement);

// You have to connect() to any reducers that you wish to connect to yourself
DealManagement = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(DealManagement);

export default withTranslation('common')(DealManagement);