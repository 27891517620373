const configImages = {
    defaultImg: require(`../assets/img/profile/defaultImg.png`),
    defaultUserImage: require(`../assets/img/profile/default_user_image.png`),
    employeesSampleXL: require(`../assets/files/scheduleSampleUpload.xlsx`),
    // loginImage: require(`../assets/img/logo/1620807546967_logojayeesha.png`),
    loginImage: require(`../assets/img/logo/OPP-Logo.png`),
    projectTitle: require(`../assets/img/logo/OPRA 2.svg`),
    TopLogo: require(`../assets/img/logo/1632906563489_flower.jpeg`),
    splitIcon: require(`../assets/img/split-icon-20.jpg`),
    leftIcon: require(`../assets/img/60771.png`),
    upIcon: require(`../assets/img/upArrow.png`),
    sellectAllIcon: require(`../assets/img/check-square .png`),
    rightIcon: require(`../assets/img/right.png`),
    SortIcon:require('../assets/img/sortIcon.png'),
    downIcon: require(`../assets/img/downArrow.png`),
    userRequest: require(`../assets/img/UserWithBell.png`),
    // loginLogo: require(`../assets/img/logo/loginLogo.jpg`),
    loaderImage: require(`../assets/loader.gif`),
    spinnerImage: require(`../assets/Spinner-1s-200px.gif`),
    namesSampleXL: require('../assets/files/Names.xlsx'),
    addressSampleXL: require('../assets/files/Address.xlsx'),
    ApaNamesSampleXL: require('../assets/files/ApaNames.xlsx'),
    DebtorsSampleXL: require('../assets/files/Debtors.xlsx'),
    ResearchIcon: require('../assets/img/research.png'),
    ValidatedPropertyUpdaterIcon: require('../assets/img/migrateCloud.png'),
    OpenCorporate: require('../assets/img/open_corporates_icon.jpg'),
    addpropertySamplefile : require('../assets/files/addpropertySamplefile.xlsx')
};
export default configImages;
