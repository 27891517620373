import { useMsal } from "@azure/msal-react";
import React from 'react';
import { loginRequest } from "../../../config/authConfig";
import configImages from '../../../config/configImages';
import LogInButton from './components/LogInButton';
import LogInForm from './components/LoginInForm';
import config from "../../../config/config";


const LogIn = (props) => {
  const { instance } = useMsal();
  const handleLogin = async () => {
    const response = await instance.loginPopup(loginRequest)
    if (!response || !response.accessToken) {
      console.warning(response)
    }
    return response.accessToken;
  }

  
  console.log(config.environment);

  return (
    <div className="account">
      <div className="account__wrapper four-sides-with-same-color ">
        <div className="account__card">
          <div className="account__title row mb-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
            {/* <b className='pt-2' style={{ fontSize: 25 }}>Welcome to</b> */}
            <span >
              <span >
                <img src={configImages.loginImage} style={{ width: 253, borderRadius: 0 }} alt="" />
              </span>
            </span>
          </div>
          <div className="my-3 py-1" />
          <div className="mt-3">
            {/* Conditionally render based on environment */}          
            {config.environment === 'development' ? <LogInForm onSubmit /> : <LogInButton handleSubmit={handleLogin} />}
            
          </div>
        </div>
      </div>
    </div>
  )
};
export default LogIn;

