import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";

// config file
export default class SiteConfirmationEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      type: "Site Confirmation Editor"
    };
  }
  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions[this.state.type] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }
  getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        frozen: true,
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "pendingConfirmation",
        "editField": "dropDown",
        options: [{ label: "Confirmed", value: "Confirmed" }, { label: "Missing", value: "Missing" }],
        "mobile": true,
        "header": "Pending Confirmation",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 100,
        "field": "sourceState",
        "mobile": true,
        // "getField": "Property",
        "header": "Source State",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "statePropertyId",
        "allowInt": true,
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "St. Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 100,
        "field": "propertyName",
        "mobile": true,
        // "getField": "Property",
        "header": "Property Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyAddressLine1",
        "mobile": true,
        "header": "Prop. Address Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "field": "propertyState",
        "mobile": true,
        "header": "Prop. St",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyCity",
        "mobile": true,
        "header": "Prop. City",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "propertyZipCode",
        "mobile": true,
        "header": "Prop. Zip",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyDescription",
        "mobile": true,
        "header": "Property Description",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "holderName",
        isFuzzy: true,
        "mobile": true,
        "header": "Holder Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "escheatmentDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Escheatment",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateOfLastContact",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Of Last Contact",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMinValueAmount",
        "allowInt": true,
        "mobile": true,
        "header": "Min Amt",
        "filterType": "num",
        "fieldType": "formatCurrencywithdec",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMaxValueAmount",
        "allowInt": true,
        "mobile": true,
        "header": "Max Amt",
        "filterType": "num",
        "fieldType": "formatCurrencywithdec",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },





    ]
    return data;
  };
  getTableFieldsbasedonRole = () => {
    let data = this.getTableFields();
    if (!this.state.readOnly) {
    } else {
      data.push(
        {
          "show": true,
          "textAlign": "left",
          "width": 70,
          "fieldType": "rowEditor",
          "header": "Edit",
          "filter": true,
          "sortable": false
        },
      )
    }
    return data;
  }
  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "user_name",
        label: "User Name",
        id: "user_name",
        placeholder: "User Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },
    ];
  };


  render() {

    return (
      <DataTables
        onRef={(ref) => (this.datatableref = ref)}
        getTableFields={this.getTableFieldsbasedonRole}
        formFields={this.getFormFields}
        className={true}
        exportRequried={false}
        // criteria={[{ "key": "PendingConfirmation", "value": "Pending", "type": "eq", "add_Filter": true }]}
        printRequried={false}
        addRequried={false}
        editRequired={false}
        isEdit={this.state.readOnly ? true : false}
        multiApi={`NsScoredProperties/siteconfirmationupdate`}
        multiMethod="POST"
        multiApiKey="models"
        onlyIdswithchangedValues={true}
        isOnlyEditted={true}
        deleteRequired={false}
        selectedStatusFilter={"Pending"}
        viewRequired={false}
        dontShowTitle={true}
        inlineSave={true}
        settingsRequired={false}
        filterRequired={false}
        gridRequried={false}
        sample={false}
        globalSearchFieldName='user'
        // globalSearch={'Search'}
        loading={true}
        statusFilter={true}
        // SelectedForOptions={SelectedForOptions}
        // noLimit={true}
        type='Site Confirmation Editor'
        isClearFilters={true}
        displayright={true}
        icon='drop'
        routeTo='sellerNegativeNotice'
        displayViewOfForm='modal'
        apiResponseKey='ns_scored_propertys'
        apiUrl={`nsscoredproperties`
        }
        entityType='employee'
      />
    );
  }
}
