import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PropertyUploadHistory from './components/PropertyUploadHistory';
const propertyUploadHistory = ( props) => (
    <PropertyUploadHistory {...props} />
);

propertyUploadHistory.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(propertyUploadHistory);
