import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReportBugNew from './components/ReportBugNew';
import ViewReportedBugs from './components/ViewReportedBugs';
import ViewReportedFilters from './ReportBugFilters';

import classnames from 'classnames';

class ReportBugPageNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };
    }

    getFiltercriteriafromtable = async () => {
        let data = await this.viewReportedBugs.getFiltercriteria()
        return data
    };

    getCall = async(filterCriteria) => {
        await this.viewReportedBugs.getCall(filterCriteria);
    }

    render() {
        return (
            <>
                <ViewReportedBugs
                    onRef={(ref) => (this.viewReportedBugs = ref)}
                /></>




        );
    }
}

ReportBugPageNew.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ReportBugPageNew);
