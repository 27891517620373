import React from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Button, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import moment from 'moment-timezone';
import { load as loadAccount } from "../../../redux/reducers/commonReducer";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import classNames from "classnames";
// fecth method from service.js file
import fetch from "../../../config/service";
import configMessages from "../../../config/configMessages";
import DatePicker from "../../../shared/components/form/DatePicker";
// show message
import DefaultInput from "../../../shared/components/form/DefaultInput";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CustomSelect from "../../../shared/components/form/MakeValueSelect";
import validate from "../../Validations/validate";
import Loader from "../../App/Loader";
import { withTranslation } from "react-i18next";
import Select from "../../../shared/components/form/Select";
import AutoComplete from "../../Form/components/AutoComplete";
import { faStarOfLife, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import apiCalls from "../../../config/apiCalls";
import dateFormats from "../../../containers/UI/FormatDate/formatDate"
import DefaultTextArea from "../../../shared/components/form/DefaultTextArea";
import config from "../../../config/config";
import UploadFiles from "./TaskModal/UploadFiles";
import MultiSelect from "../../Form/components/MultiSelect";
import NotesModal from "./NotesModal";
const required = (value) =>
  value || typeof value === "string" ? undefined : configMessages.fillField;
//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)
const theme = createTheme({
  overrides: {
    MuiSwitch: {
      thumb: {
        height: "17px!important",
        width: 17,
      },
      track: {
        left: "51%!important",
      },
      root: {
        "&$checked": {
          color: "white!important",
          "& + $track": {
            backgroundColor: "green !important",
            color: "white!important",
            height: 15,
            left: "46%!important",
          },
        },
      },
    },
  },
});
class RFIForm extends React.Component {
  constructor(props) {
    super(props);
    this.buttonActionType = null;
    this.state = {
      priority: [
        { label: "Critical", value: "Critical" },
        { label: "High", value: "High" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
      ],
      documents: [],
      statuses: [],
      isSaved: false
    };
  }
  componentDidMount = async () => {
    console.log("this.props.detailssssssssss111111s", this.props.selectedObj)
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.selectedObj) {
      let getDetailsFromTaskId = this.props.selectedObj && this.props.selectedObj._id ? await this.getDetailsFromTaskId(this.props.selectedObj._id) : this.props.selectedObj

      this.initialLoading(getDetailsFromTaskId)
      this.setState({ getDetailsFromTaskId: getDetailsFromTaskId })
    }

    this.getUsersOptions();
    this.getReasons();
    this.getStatusOptions()
    this.getFileForEditor()
    this.getFileForEditorForSellerRelevantName()


  };

  getDetailsFromTaskId = async (taskId) => {
    this.setState({
      isLoading: true,
    });
    this.handlePercentage("1");
    return fetch("GET", `tasks/${taskId}`)
      .then(async (response) => {
        if (response && response.details) {
          this.setState({ isLoading: false });
          return response.details
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        return err;
      });
  }

  checkConnectedName = async (value, type, data) => {
    let relevantSellerConnectedName
    if (type === "mappedName") {
      relevantSellerConnectedName = data.find(elem => elem.value === value)
    } else {
      relevantSellerConnectedName = data.find(elem => elem.value === value)
    }
    if (relevantSellerConnectedName) {
      return relevantSellerConnectedName.label
    }
  }


  getFileForEditor = async (val) => {
    let fileForOptions = [];
    let mappedNames = [];
    return fetch('GET', `seller/sellerRelevanceNames?estateId=${this.state.getDetailsFromTaskId && this.state.getDetailsFromTaskId.opraEstateID ? this.state.getDetailsFromTaskId.opraEstateID : this.props.selectedObj.opraEstateID}&nameType=mappedName`)
      .then(async (response) => {
        if (response && response["sellerRelevanceNames"].length > 0) {
          let propertyReasons = response["sellerRelevanceNames"]
          for (let reason of propertyReasons) {
            // fileForOptions.push({ label: reason.BusinessName, value: reason.BusinessName, nameID: reason.BusinessNamesID });
            mappedNames.push({ label: reason.CombinedName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
          }
          await this.setState({ mappedNames: mappedNames });
          let checkMappedName = await this.checkConnectedName(this.state.getDetailsFromTaskId && this.state.getDetailsFromTaskId.mappedName ? this.state.getDetailsFromTaskId.mappedName : this.props.selectedObj.mappedName, "mappedName", mappedNames)
          await this.props.change("mappedName", checkMappedName)
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getFileForEditorForSellerRelevantName = async (val) => {
    let fileForOptions = [];
    let mappedNames = [];
    return fetch('GET', `seller/sellerRelevanceNames?estateId=${this.state.getDetailsFromTaskId && this.state.getDetailsFromTaskId.opraEstateID ? this.state.getDetailsFromTaskId.opraEstateID : this.props.selectedObj.opraEstateID}&nameType=sellerRelevanceName`)
      .then(async (response) => {
        if (response && response["sellerRelevanceNames"].length > 0) {
          let propertyReasons = response["sellerRelevanceNames"]
          for (let reason of propertyReasons) {
            fileForOptions.push({ label: reason.CombinedName, value: reason.BusinessName, nameID: reason.BusinessNamesID });
            // mappedNames.push({ label: reason.CombinedName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
          }
          await this.setState({ fileForOptions: fileForOptions });
          let checkConnectedName = await this.checkConnectedName(this.state.getDetailsFromTaskId && this.state.getDetailsFromTaskId.relevantSellerName ? this.state.getDetailsFromTaskId.relevantSellerName : this.props.selectedObj.relevantSellerName, "RelevantSellerName", fileForOptions)
          await this.props.change("relevantSellerName", checkConnectedName)
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }


  componentDidUpdate() {
    if (this.state.errorMessage) {
      setTimeout(() => this.setState({ successMessage: false, errorMessage: '' }), 8000);
    }
  }

  initialLoading = async (data) => {
    let obj = data
    obj.oPPClaimID = obj.oppClaimID
    if (this.props.formType === "add") {
      obj.rfiNextActionDate = dateFormats.formatDate(moment(new Date()).add(14, "days"), config.dbcstOnlyDateFormat)
      obj.rfiReceivedDate = dateFormats.formatDate(new Date(), config.dbcstOnlyDateFormat)
    }
    await this.setState({
      documents: obj.documents ? obj.documents : [],
      timer2: data.timer,
      Comments: data.notes,
      rowData: obj,
      isSaved: false
    })
    this.setNotesToChange(data.notes)
    this.props.load(obj);
  }

  getStatusOptions() {
    let filterCriteria = {}
    fetch("GET", `${apiCalls.Status}`)
      .then(async (response) => {
        if (response && response.statuss) {
          let statuses = response.statuss;
          let modifiedStatus = [];
          for (let status of statuses) {
            let index = modifiedStatus.findIndex(obj => obj.label === status.statusName)
            if (index === -1 && status.statusName) {
              modifiedStatus.push({
                label: status.statusName,
                value: status.statusName,
              });
            }
          }
          await this.setState({
            statuses: modifiedStatus,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  }

  //user field options
  getUsersOptions = () => {
    this.setState({
      isLoading: true,
    });
    this.handlePercentage("1");
    fetch("GET", apiCalls.Users)
      .then(async (response) => {
        if (response && response.users) {
          let apps = response.users;
          let modifiedApps = [];
          let respondedByUser = [];
          let textAutoAreaSuggestions = [];
          for (let app of apps) {
            modifiedApps.push({
              label: app.display_name,
              value: [app.display_name, app._id, app.image],
              app_id: app._id,
            });
            textAutoAreaSuggestions.push(app.name);
            respondedByUser.push({
              label: app.display_name,
              value: app.display_name,
            });
          }
          const sorted = modifiedApps.sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          modifiedApps.unshift({
            label: "Unassigned",
            value: ["Unassigned"],
          });

          // this.setState({ sorted: sorted })
          await this.setState({
            assignedUsers: sorted,
            respondedByUser: respondedByUser,
            textAutoAreaSuggestions: textAutoAreaSuggestions,
            textAutoAreaSuggestionsLength: response.pagination.totalCount + 1,
            isLoading: false,
          });
        } else if (
          response &&
          response["errorMessage"] === configMessages.warningMessage
        ) {
          await this.setState({
            sessionWarning: true,
            assignedUsers: [],
            respondedByUser: "",
            textAutoAreaSuggestions: [],
            textAutoAreaSuggestionsLength: 0,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };
  // clear input data
  clearInputFields = () => {
    this.props.reset();
  };

  getReasons = () => {
    let StatusArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq" },
    ];
    fetch(
      "GET",
      `${apiCalls.RfiReasons}?filter=${JSON.stringify(
        filterCriteria
      )}&type=exportToCsv`
    ).then(async (response) => {
      if (
        response &&
        response["rfiReasons"] &&
        response["rfiReasons"].length > 0
      ) {
        let propertyStatuses = response["rfiReasons"];
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.reason, value: status.reason });
        }
        this.setState({ Reasons: StatusArray });
      }
    });
    return this.state.settingsData;
  };

  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        });
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  };


  saveDataFormat = async (formValues, responsesdocuments) => {
    let documents = []
    if (formValues.states) {
      if (typeof formValues.states === "string") {
        let state = config.statesUnique.find(function (element) {
          return element.shortCode === formValues.states;
        });
        formValues.states = state.label.split(",");
      }
    }
    if (responsesdocuments && responsesdocuments.length > 0) {
      if (this.state.documents === null) {
        this.state.documents = [];
      }
      documents = [...this.state.documents, ...responsesdocuments];
    } else if (this.state.documents && this.state.documents.length > 0) {
      documents = this.state.documents;
    } else {
      documents = [];
    }
    formValues.documents = documents;
    if (formValues.assignTo) {
      formValues.assignImage = typeof formValues.assignTo === "string" ? formValues.assignImage : formValues.assignTo[2];
      formValues.assignToId = typeof formValues.assignTo === "string" ? formValues.assignToId : formValues.assignTo[1];
      formValues.assignTo = typeof formValues.assignTo === "string" ? formValues.assignTo : formValues.assignTo[0];
    }

    formValues.notes = this.state.Comments
    let url, method
    if (this.props.formType !== "add") {
      method = "PUT"
      url = `${apiCalls.Tasks}/${formValues._id}`
    } else {
      method = "POST"
      url = `${apiCalls.Tasks}`
      formValues.dealType = this.props.dealType
    }
    return fetch(method, url, formValues)
      .then(async (response) => {
        if (response) {
          if (response && response.respMessage) {
            localStorage.setItem("isTaskUpdated", true)
            window.localStorage.setItem("isTaskUpdated", true)
            window.dispatchEvent(new Event('storage'))
            if (this.props.isCloseRFIModal) {
              this.props.isCloseRFIModal("Open", response.taskId)
              this.props.getErrorResponsefromServer(response)
            } else {
              this.setState({
                errorMessage: response.respMessage,
                successMessage: true,
                isSaved: false
              })
              if (this.props.getRFIRecentconnectedTask) {
                let updatedData = await this.props.getRFIRecentconnectedTask()
                this.initialLoading(formValues)
                if (
                  formValues.title &&
                  document.getElementsByClassName(
                    "p-button p-component p-button-text-icon-left"
                  )[2]
                ) {
                  document
                    .getElementsByClassName(
                      "p-button p-component p-button-text-icon-left"
                    )[2]
                    .click();
                }
              }
            }
          } else {
            if (this.props.isCloseRFIModal) {
              this.props.getErrorResponsefromServer(response)
            }
            this.setState({
              errorMessage: response.errorMessage,
              successMessage: false,
              isLoading: false,
              isSaved: false
            })
          }

        }
      })
      .catch((err) => {
        return err;
      });
  }

  changeNoteClaims = () => {
    let Comments = this.state.Comments ? this.state.Comments : []
    this.setState({
      isnotesModal: true,
      Comments: Comments,
      // notesSno:             // notesSno: item

    })
  }
  cancelNotes = async (type, id) => {
    this.setState({
      isnotesModal: false,
      openpaymentsModal: false,
      selectedRowPayments: ""
    })
    if (this.props.noteSavedStatusFunc) {
      await this.props.noteSavedStatusFunc(false)
    }
    if (this.props.noteSavedStatusFunCheck) {
      await this.props.noteSavedStatusFunCheck("no")
    }


  }
  saveClaimNotes = async (comment, item, Data, editComment, edited) => {
    let claimNotes = Data && Data.length > 0 ? Data : []
    if (Data && Data.length === 0) {
      if (editComment !== undefined) {
        // claimNotes.shift()
        // claimNotes.unshift({ note: editComment })
        claimNotes[0].note = editComment.note
      }
      if (comment !== undefined) {
        claimNotes.unshift({ note: comment })
      }
    }
    await this.setNotesToChange(claimNotes)
    if (this.props.noteSavedStatusFunc) {
      await this.props.noteSavedStatusFunc(false)
    }
    if (this.props.noteSavedStatusFunCheck) {
      await this.props.noteSavedStatusFunCheck("no")
    } this.setState({
      isnotesModal: false,
      claimNotesEdit: false,
      Comments: claimNotes
    })
  }

  setNotesToChange = async (notes) => {
    let note1 = ""

    if (notes && notes.length > 0) {
      await notes.sort(function (a, b) {
        return new Date(b.created) - new Date(a.created);
      });
      for (let note of notes) {
        note1 = note1 + `Date: ${note.updated ? dateFormats.formatDate(note.updated, "MM/DD/YY") : note.created ? dateFormats.formatDate(note.created, "MM/DD/YY") : dateFormats.formatDate(new Date(), "MM/DD/YY")} Created By:${note.updatedBy ? note.updatedBy : note.createdBy ? note.createdBy : this.state.loginRole} Note: ${note.note}\n`
      }
    }
    await this.props.change("notes", note1)
  }

  getNotesModal() {
    return (
      <NotesModal
        openNotesModal={this.state.isnotesModal}
        cancelReset={this.cancelNotes}
        saveClaimNotes={this.saveClaimNotes}
        claimNotesEdit={true}
        notesSno={this.state.notesSno}
        screenType={"rfiNotes"}
        rfiValues={this.props.rfiValues}
        formType={this.props.formType}
        Comments={this.state.multiselectRows && this.state.multiselectRows.length > 1 ? [] : this.state.Comments}
        type={"Notes1"}
      />
    )
  }
  submit = async (formValues) => {
    formValues.timer = this.state.timer2
    await this.setState({
      submitedValues: formValues,
      isLoading: true,
      isSaved: true
    })
    //Formating dates to prevent the timezone
    let formatForDateFields = ["rfiLastActionDate",
      "rfiNextActionDate",
      "rfiReceivedDate",
      "rfiResponseDate",
      "rfiResponseDueDate",
      "priorRFIResponseDate",
      "paymentApproveEmailReceivedDate",
      "dateOnRFI"
    ]
    for (const property in formValues) {
      if (formatForDateFields.includes(property) && formValues[property] !== null && formValues[property] !== "") {
        formValues[property] = dateFormats.formatDate(new Date(formValues[property]), config.dbcstOnlyDateFormat)
      }
    }
    if (formValues.relevantSellerName) {
      let z = this.state.fileForOptions.find(elem => elem.value === formValues.relevantSellerName)
      if (z) {
        formValues.relevantSellerNameID = z.nameID
        formValues.relevantSellerName = z.value

      } else if (formValues.relevantSellerName.includes("CN:")) {
        let zCN = this.state.fileForOptions.find(elem => elem.label === formValues.relevantSellerName)
        if (zCN) {
          formValues.relevantSellerNameID = zCN.nameID
          formValues.relevantSellerName = zCN.value

        }
      }
    }
    if (formValues.mappedName) {
      let z = this.state.mappedNames.find(elem => elem.value === formValues.mappedName)
      if (z) {
        formValues.mappedNameID = z.nameID
        formValues.mappedName = z.value
      } else if (formValues.mappedName.includes("CN:")) {
        let zCN = this.state.mappedNames.find(elem => elem.label === formValues.mappedName)
        if (zCN) {
          formValues.mappedNameID = zCN.nameID
          formValues.mappedName = zCN.value

        }
      }
    }
    if (this.props.noteSavedStatusFunc) {
      await this.props.noteSavedStatusFunc(false)
    }

    if (this.props.noteSavedStatusFunCheck) {
      await this.props.noteSavedStatusFunCheck("no")
    }
    if (
      formValues.title &&
      document.getElementsByClassName(
        "p-button p-component p-button-text-icon-left p-disabled"
      ).length > 0
    ) {
      if (this.state.removedFiles && this.state.removedFiles.length > 0) {
        this.uploadref.deleteImagesCall(formValues);
      } else {
        this.saveDataFormat(formValues);
      }
    } else if (
      formValues.title &&
      document.getElementsByClassName(
        "p-button p-component p-button-text-icon-left"
      )[1]
    ) {
      document
        .getElementsByClassName(
          "p-button p-component p-button-text-icon-left"
        )[1]
        .click();
    }

  };
  getForm = async () => {
    console.log("::::::::::123333", this.props.rfiValues)
    await this.submit(this.props.rfiValues)
  }
  handleTextAreaData = (value, existedData, newData) => {
    let notesArr = [],
      mailedUsers = [];
    if (
      value &&
      this.state.textAutoAreaSuggestions &&
      this.state.textAutoAreaSuggestions.length
    ) {
      for (var i = 0; i < this.state.textAutoAreaSuggestions.length; i++) {
        let result = value.includes(this.state.textAutoAreaSuggestions[i]);
        if (result === true) {
          mailedUsers.push(this.state.textAutoAreaSuggestions[i]);
        }
      }
    }
    if (this.props.type === "edit") {
      notesArr = existedData;
    } else {
      notesArr = [];
    }
    if (newData !== undefined) {
      let notesObj = {
        note: newData,
        mentions: mailedUsers,
      };
      notesArr.push(notesObj);
    }
    return notesArr;
  };


  setRemovedFiles = (documents, removedFiles) => {
    this.setState({
      documents: documents,
      removedFiles: removedFiles,
    });
  };
  textAreaFieldsChange = async (e, field) => {
    await this.setState({
      [field]: e,
    })
    if (this.props.noteSavedStatusFunc) {
      await this.props.noteSavedStatusFunc(true)
    }
    if (this.props.noteSavedStatusFunCheck) {
      await this.props.noteSavedStatusFunCheck("yes")
    }
  }

  onChangeevent = async (value) => {
    await this.setState((prevState) => ({
      timer: !prevState.timer,
      timer2: this.state.timer2 === "start" ? "stop" : "start",
    }));

    if (
      this.state.rowData &&
      localStorage.getItem("recentTaskID") === this.state.rowData.taskID &&
      this.state.timer2 === "stop"
    ) {
      localStorage.setItem("ManuallyOFF", true);
    }
  };

  render() {
    const { handleSubmit, t, type, screen } = this.props;
    const modalClass = classNames({
      "modal-dialog--colored": this.state.colored,
      "modal-dialog--header": this.state.header,
    });
    return (
      <form className="form" onSubmit={handleSubmit(this.submit)}>
        <Card className="pb-0 ">
          {/* <Loader loader={this.state.isLoading} /> */}
          <CardBody className=" p-0 mt-3">
            <Row className="ml-0 mr-0 mb-2 justify-content-between">
              {this.state.rowData && this.state.rowData.taskID ?
                <div className="d-flex align-items-center">
                  <span className="taskIDclaims" >RFI Task Number :</span>
                  <a className={`mr-auto ${screen === "RFI View" ? 'disabled-link' : ' '}`} target="_blank" href={`/rfiView?taskID=${this.state.rowData && this.state.rowData.taskID ? this.state.rowData.taskID : null}`}  >{this.state.rowData && this.state.rowData.taskID ? this.state.rowData.taskID : null}</a>
                  <span className="taskIDclaims" >Created By :</span>
                  <span className={`mr-auto ${screen === "RFI View" ? 'disabled-link' : ' '}`} >{this.state.rowData && this.state.rowData.createdByUser ? this.state.rowData.createdByUser : null}</span>
                  <span className="taskIDclaims" >Created :</span>
                  <span className={`mr-auto ${screen === "RFI View" ? 'disabled-link' : ' '}`} >{this.state.rowData && this.state.rowData.created ? dateFormats.formatDate(this.state.rowData.created, "MM/DD/YY") : null}</span>

                </div>

                : null}
              <Button
                type="submit"
                className={`bg-primary p-2 mb-0 ml-auto text-white p-button-rounded claim_detail_sub_navbar_buttons ${this.props.type === "Property Editor" ? "mb-0" : ""
                  }`}
                style={{ fontSize: 16, height: 34, marginRight: 30 }}
                disabled={this.state.isSaved}
              >
                {this.props.formType !== "add" ? "Update" : "Save"}
              </Button>
            </Row>
            <span className={this.state.successMessage ? 'successbgColor successTextColor mx-auto d-flex' : 'errorbgColor errorTextColor d-flex mx-auto'} style={{ width: "fit-content" }}>
              {this.state.errorMessage ? this.state.errorMessage : ""}</span>
            <span
              className="formError mt-2 mb-2"
              style={{ textAlign: "center", top: -14, color: "red" }}
            >
              {this.state.apierrorResponse ? (
                <span className={`${this.state.msgType === "error" ? "errorbgColor errorTextColor" : "successbgColor successTextColor"} px-3`} style={{ height: 30 }}>{this.state.apierrorMessage}</span>
              ) : null}
            </span>
            <Loader
              loader={this.state.isLoading}
              progress={this.state.progress}
              className="screen-loader"
            />

            <Row className="ml-0 mr-0 mb-4">

              <div className={!this.props.type ? "col-sm-4" : "col-sm-3"}>
                <div className="col-sm-12">
                  <label className="form__form-group-label">Deal</label>
                  <Field
                    className={
                      this.props.type !== "edit" ? "EstateName" : ""
                    }
                    type={"text"}
                    name="company"
                    component={AutoComplete}
                    // onChange={(e) => this.handleEstateNames(e)}
                    // handleEstateNames={this.handleEstateNames}
                    placeholder="Deal"
                    disabled={this.state.rowData && this.state.rowData.company ? true : false} />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">State</label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="states"
                    component={DefaultInput}
                    isDisable={true}
                    placeholder="State"
                    maxLength={50}

                  // options={this.state.company}
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    State Claim ID
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="stateClaimID"
                    component={DefaultInput}
                    maxLength={1000}
                    isDisable={true}
                    placeholder="State Claim ID"

                  // options={this.state.company}
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">Opp Claim ID</label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="oPPClaimID"
                    component={DefaultInput}
                    maxLength={1000}
                    isDisable={true}
                    placeholder="Opp Claim ID"
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    RFI Prop. Count
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="propertyCount"
                    component={DefaultInput}
                    maxLength={1000}
                    placeholder="RFI Prop. Count"
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">RFI Value</label>
                  <Field
                    className="inputLogin"
                    type={"number"}
                    // type="number"
                    name="valueRFI"
                    component={DefaultInput}
                    maxLength={1000}
                    placeholder="RFI Value"
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">Status</label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="status"
                    component={Select}
                    isDisable={this.props.formType === "add" ? true : false}
                    options={this.state.statuses}
                    maxLength={1000}
                    placeholder="RFI Value"
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    Relevant Seller Name
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="relevantSellerName"
                    component={DefaultInput}
                    maxLength={1000}
                    isDisable={true}
                    placeholder="Relevant Seller Name"

                  // options={this.state.company}
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">Mapped Name</label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="mappedName"
                    component={DefaultInput}
                    maxLength={1000}
                    isDisable={true}
                    placeholder="Mapped Name"
                  />
                </div></div>
              <div className={!this.props.type ? "col-sm-4" : "col-sm-3"}>
                <div className="col-sm-12">
                  <label className="form__form-group-label">Task Label</label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="title"
                    component={DefaultInput}
                    maxLength={50}
                    isDisable={true}
                    placeholder="Task Label"
                  />
                </div>

                <div className="col-sm-12">
                  <label className="form__form-group-label">Purpose</label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="purpose"
                    component={DefaultInput}
                    isDisable={true}
                    placeholder="Purpose"
                    maxLength={50}

                  // options={this.state.company}
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    Priority
                    <FontAwesomeIcon
                      icon={faStarOfLife}
                      className="pl-1"
                      color="red"
                      data-toggle="tool-tip"
                      title="Mandotary Field"
                      style={{ width: 8 }}
                    />{" "}
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="priority"
                    component={Select}
                    maxLength={1000}
                    options={this.state.priority}
                    placeholder="Priority"
                    validate={[required]}
                  // options={this.state.company}
                  />
                </div>

                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    Payment Approved Email Received Date
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="paymentApproveEmailReceivedDate"
                    ignoreTime={true}
                    component={DatePicker}
                    maxLength={50}
                    placeholder="Payment Approved Email Received"

                  // options={this.state.company}
                  />
                </div>
                {/*add the date field name if in the "formatForDateFields" Array to format */}
                <div className="col-sm-12 d-flex p-0">
                  <div className="col-sm-6">
                    <label className="form__form-group-label">
                      RFI Received Date
                    </label>
                    <Field
                      className="inputLogin"
                      type={"text"}
                      ignoreTime={true}
                      name="rfiReceivedDate"
                      component={DatePicker}
                      maxLength={50}
                      placeholder="RFI Received Date"
                      currentDate={this.props.formType === "add" ? dateFormats.formatDate(new Date(), config.dbOnlyDateFormat) : null}
                    // options={this.state.company}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form__form-group-label">
                      RFI Response Due Date
                    </label>
                    <Field
                      className="inputLogin"
                      type={"text"}
                      name="rfiResponseDueDate"
                      ignoreTime={true}
                      component={DatePicker}
                      maxLength={50}
                      placeholder="RFI Response Due Date"

                    // options={this.state.company}
                    />
                  </div>


                </div>
                <div className="col-sm-12 d-flex p-0">
                  <div className="col-sm-6">
                    <label className="form__form-group-label">
                      RFI Response Date
                    </label>
                    <Field
                      className="inputLogin"
                      type={"text"}
                      name="rfiResponseDate"
                      ignoreTime={true}
                      component={DatePicker}
                      maxLength={50}
                      // isDisable={this.state.type === "edit" ? true : false}
                      // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                      placeholder="RFI Response Date"

                    // options={this.state.company}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form__form-group-label">
                      RFI Responded By
                    </label>
                    <Field
                      className="inputLogin"
                      type={"text"}
                      name="rfiRespondedBy"
                      component={Select}
                      options={this.state.respondedByUser}
                      maxLength={50}
                      placeholder="RFI Responded By"

                    // options={this.state.company}
                    />
                  </div>
                </div>
                <div className="col-sm-12 d-flex p-0">
                  <div className="col-sm-6">
                    <label className="form__form-group-label">
                      Prior RFI Response Date
                    </label>
                    <Field
                      className="inputLogin"
                      type={"text"}
                      name="priorRFIResponseDate"
                      ignoreTime={true}
                      component={DatePicker}
                      maxLength={50}
                      placeholder="Prior RFI Response Date"

                    // options={this.state.company}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form__form-group-label">
                      Date on RFI
                      <FontAwesomeIcon
                        icon={faStarOfLife}
                        className="pl-1"
                        color="red"
                        data-toggle="tool-tip"
                        title="Mandotary Field"
                        style={{ width: 8 }}
                      />
                    </label>
                    <Field
                      className="inputLogin"
                      type={"text"}
                      name="dateOnRFI"
                      ignoreTime={true}
                      component={DatePicker}
                      maxLength={50}
                      placeholder="Date on RFI"
                      validate={[required]}                      // options={this.state.company}
                    />
                  </div>
                </div>
                <div className="col-sm-12 d-flex p-0">
                  <div className="col-sm-6">
                    <label className="form__form-group-label">
                      RFI Last Action Date
                    </label>
                    <Field
                      className="inputLogin"
                      type={"text"}
                      name="rfiLastActionDate"
                      ignoreTime={true}
                      component={DatePicker}
                      maxLength={50}
                      placeholder="RFI Last Action Date"

                    // options={this.state.company}
                    />
                  </div>
                  <div className="col-sm-6">

                    <label className="form__form-group-label">RFI Next Action Date</label>
                    <Field
                      className="inputLogin"
                      type={"text"}
                      name="rfiNextActionDate"
                      ignoreTime={true}
                      component={DatePicker}
                      maxLength={1000}
                      placeholder="Due Date"
                    // currentDate={currentDateforNextAction}
                    // options={this.state.company}
                    />
                  </div>
                </div>
                {!this.props.type ? (
                  <div>
                    <UploadFiles
                      onRef={(ref) => (this.uploadref = ref)}
                      view={this.state.view}
                      saveDataFormat={this.saveDataFormat}
                      {...this.state}
                      submitedValues={this.state.submitedValues}
                      setRemovedFiles={this.setRemovedFiles}
                      type={this.props.type}
                    />
                  </div>
                ) : null}
              </div>
              <div className={!this.props.type ? "col-sm-4" : "col-sm-3"}>
                <div className="col-sm-12">
                  <label className="form__form-group-label">RFI Reason Code(s)
                    <FontAwesomeIcon
                      icon={faStarOfLife}
                      className="pl-1"
                      color="red"
                      data-toggle="tool-tip"
                      title="Mandotary Field"
                      style={{ width: 8 }}
                    /></label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="reasons"
                    filter={true}
                    component={MultiSelect}
                    filterElement={this.state.Reasons}
                    maxLength={50}
                    placeholder="RFI Reason Code(s)"
                    style={{ textAlign: "left" }}
                    options={this.state.company}
                    validate={[required]}
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    Assign To
                    <FontAwesomeIcon
                      icon={faStarOfLife}
                      className="pl-1"
                      color="red"
                      data-toggle="tool-tip"
                      title="Mandotary Field"
                      style={{ width: 8 }}
                    />
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="assignTo"
                    component={Select}
                    options={this.state.assignedUsers}
                    placeholder="Assign To"
                    maxLength={50}
                    validate={[required]}                    // options={this.state.company}
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    RFI Request Description
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="rfiRequestDescription"
                    options={this.state.textAutoAreaSuggestions}
                    component={DefaultTextArea}
                    maxOptions={this.state.textAutoAreaSuggestionsLength}
                    // onChange={(e) =>
                    //   this.setState({
                    //     rfiRequestDescription: e,
                    //   })
                    // }
                    onChange={(e) => this.textAreaFieldsChange(e, "rfiRequestDescription")}
                    placeholder="RFI Request Description"
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    RFI Response Text
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="rfiTextFromUpSource"
                    id="rfiTextFromUpSource"
                    options={this.state.textAutoAreaSuggestions}
                    component={DefaultTextArea}
                    maxOptions={this.state.textAutoAreaSuggestionsLength}
                    placeholder="RFI Response Text"
                    // onChange={(e) =>
                    //   this.setState({
                    //     rfiTextFromUpSource: e,
                    //   })}
                    onChange={(e) => this.textAreaFieldsChange(e, "rfiTextFromUpSource")}


                  // isDisable={type === 'edit' ? true : false}
                  />
                </div>
                <div className="col-sm-12">
                  <label className="form__form-group-label">RFI OPP Note
                    <FontAwesomeIcon icon={faExternalLinkAlt}
                      className='pl-1'
                      data-toggle="tool-tip"
                      // validate={[required]}
                      title="click to see all Notes"
                      style={{ width: 20, color: "grey" }}
                      onClick={() => this.changeNoteClaims()}
                    /></label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="notes"
                    isDisable={true}
                    component={DefaultTextArea}
                    maxOptions={this.state.textAutoAreaSuggestionsLength}
                    onChange={(e) =>
                      this.setState({
                        rfioppNote: e,
                      })
                    }
                    placeholder="RFI OPP Note"
                  />
                </div>
                <div className={!this.props.type ? "col-sm-12 text1" : "col-sm-3 d-none"} >
                  <label className="form__form-group-label">RFI Task Template
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="rfiTaskTemplate"
                    component={DefaultTextArea}
                    // onChange={(e) =>
                    //   this.setState({
                    //     rfiActiveInactiveNotes: e,
                    //   })
                    // }
                    onChange={(e) => this.textAreaFieldsChange(e, "rfiActiveInactiveNotes")}
                    placeholder="RFI Task Template"

                  // options={this.state.company}
                  />
                </div>
                <div style={{
                  display: "block",
                  float: "right",
                  marginTop: 8,
                }}>
                  <label className="form__form-group-label"></label>
                  <MuiThemeProvider theme={theme}>
                    <FormGroup
                      style={{ marginLeft: "auto" }}
                      onClick={(e) => this.onChangeevent(e)}
                    >
                      <Switch
                        className="kanban_Switch"
                        checked={
                          this.state.timer2 === "start" ? true : false
                        }
                        style={{ height: "0px", width: 45 }}
                      // onChange={(e) => this.onChangeevent(e)}
                      />
                    </FormGroup>
                  </MuiThemeProvider>
                </div>

              </div>
              <div className={!this.props.type ? "col-sm-4 d-none" : "col-sm-3 text2"}>
                <div className="col-sm-12">
                  <label className="form__form-group-label">
                    RFI Task Template
                  </label>
                  <Field
                    className="inputLogin"
                    type={"text"}
                    name="rfiTaskTemplate"
                    component={DefaultTextArea}
                    // onChange={(e) =>
                    //   this.setState({
                    //     rfiActiveInactiveNotes: e,
                    //   })
                    // }
                    onChange={(e) => this.textAreaFieldsChange(e, "rfiActiveInactiveNotes")}
                    placeholder="RFI Task Template"

                  // options={this.state.company}
                  />
                </div>
                {this.props.type ? (
                  <div>
                    <UploadFiles
                      onRef={(ref) => (this.uploadref = ref)}
                      view={this.state.view}
                      saveDataFormat={this.saveDataFormat}
                      submitedValues={this.state.submitedValues}
                      {...this.state}
                      setRemovedFiles={this.setRemovedFiles}
                      type={this.props.type}
                    />
                  </div>
                ) : null}
              </div>
            </Row>
            {this.state.isnotesModal ? this.getNotesModal() : null}
          </CardBody>
        </Card>
      </form>
    );
  }
}

RFIForm = reduxForm({
  form: "RFI Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(RFIForm);

// You have to connect() to any reducers that you wish to connect to yourself
RFIForm = connect(
  (state) => ({
    initialValues: state.commonData.data,
    rfiValues: getFormValues('RFI Form')(state)// pull initial values from account reducer// pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(RFIForm);

export default withTranslation("common")(RFIForm);
