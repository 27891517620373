
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
// fecth method from service.js file
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls'
// show message 
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import Select from '../../../../shared/components/form/Select';
import validate from '../../../Form/validate';
import DataTables from '../../../Cruds/CommonDataTable/DataTable';
import Loader from '../../../App/Loader'
//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class AddToClaim extends React.Component {
  constructor(props) {
    super(props);
    this.buttonActionType = null;
    this.state = {
      isShown: false,
      isLoginSuccess: false,
      isLoading: false,
      isShowTable: false,
      progress: 0,
      fileForOptions: [],
      SelectedRecord: null,
      filter: {
        limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
        page: 1,
        criteria: [],
      },
      selectedstate: [],
      OppClaimID: "",
      StateClaimID: "",
      isClickedAdd:false

    };

  }


  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (this.props.selectedRows && this.props.selectedRows[0]) {
      let state = this.props.selectedRows[0].sourceState
      this.setState({
        selectedstate: [{ label: state, value: state }]
      })
      this.props.change("SourceState", state)
      this.showSave(state, "SourceState")
    }
    this.getClaimStatusReason()
 }


  /**
 *
 * @returns the claim status reason array for claims filter dropdown
 */
  getClaimStatusReason = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
      { key: "claimStatusFlag", value: true, type: "eq", add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetchMethodRequest(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {
          if (
            !claimStatusArray.some(
              (obj) => obj.value == propertyStatusReason.status
            )
          ) {
            await claimStatusArray.push({
              label: propertyStatusReason.status,
              value: propertyStatusReason.status,
            });
          }
          await claimReasonArray.push({
            label: propertyStatusReason.reason,
            value: propertyStatusReason.reason,
          });
          // }
        }

        await this.setState({
          claimStatusArray: claimStatusArray,
          claimReasonArray: claimReasonArray,
          propertyStatusReasons: propertyStatusReasons,
        });
      } else if (response && response["errorMessage"]) {
        this.getErrorResponsefromServer(response);
      }
    });
    return this.state.claimStatusArray;
  };

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }


  submit = (values) => {
    let filter = this.state.filter
    this.setState({
      isShowTable: true,
      filter: filter,
      isClicked: true,

    })
    this.getResponseforSelectedPropertyData(filter)
  }

  /**
   * 
   * @param {Object} filtercriteria 
   * @returns  Claims getcall with criteria
   */
  getResponseforSelectedPropertyData = async (filtercriteria) => {
    this.setState({ isLoading: true })
    let filter
    if (filtercriteria) {
      filter = this.state.filter
      filter["page"] = filtercriteria.page
      filter["limit"] = filtercriteria.limit
      filter["criteria"] = filtercriteria.criteria
      filter["sortfield"] = filtercriteria.sortfield
      filter["direction"] = filtercriteria.direction
    } else {
      let loginCredentials = localStorage.loginCredentials;
      filter = this.state.filter
      filter["limit"] = loginCredentials.pageLimit ? loginCredentials.pageLimit : this.state.filter["limit"]
    }

    let shownindex = filter["criteria"].findIndex(obj => obj.key === "CompanyID")
    if (shownindex === -1) {
      filter.criteria.push({ key: "CompanyID", value: this.props.seller.EstateID, type: "eq" })
    }
    let url = `${apiCalls.Claims}?filter=${JSON.stringify(filter)}`;
    return fetchMethodRequest("GET", url).then(async (response) => {
      if (response && response.claims) {
        this.datatableref.isShowTable(true)
        this.datatableref.showMatchedResponses(response)
        this.setState({ isLoading: false })
      } else if (response && response.errorMessage) {
        this.datatableref.getErrorMessage(response.errorMessage, "", "error")
        this.setState({ isLoading: false })

      }
    });
  }

  //to Close Modal
  cancelReset = (response) => {
    this.props.isCloseModal();
  }

  /**
   * 
   * @param {Array||Object} SelectedRecord 
   */
  sendSelctedRecordtoDb = (SelectedRecord) => {
    this.setState({
      SelectedRecord: SelectedRecord
    })
    console.log("sendSelctedRecordtoDb",this.state.SelectedRecord)
  }

  /**
   * 
   * @returns Array tablefields to show in grid
   */
  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "single",
        show: true,
        mobile: true,
        displayInSettings: false,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "oppClaimID",
        "mobile": true,
        "header": "Opp Claim ID",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "stateClaimID",
        "mobile": true,
        "header": "St.Claim ID",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Source State",
        "mobile": true,
        "field": "sourceState",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "dateFormat": 'MM-DD-yyyy',
        "fieldType": "Date",
        "filterType": "num",
        "header": "Date Initiated",
        "mobile": true,
        "field": "date__Initiated",
        "displayInSettings": true,
        filter: false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "allowInt": true,
        "filterType": "num",
        "header": "# of Properties",
        "mobile": true,
        "field": "hasH__Claims",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 150,
        // "allowInt": true,
        "filterType": "num",
        fieldType: "formatCurrencywithdec",
        "header": "Claim Amount",
        "mobile": true,
        "field": "claimAmountPaid",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "header": "Claim Status",
        filterOptions: this.state.claimStatusArray
          ? this.state.claimStatusArray
          : [],
        filterType: "multiSelect",
        "mobile": true,
        "field": "claimStatus",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 150,
        filterType: "multiSelect",
        filterOptions: this.state.claimReasonArray
          ? this.state.claimReasonArray
          : [],
        "header": "Claim Reason",
        "mobile": true,
        "field": "claimReason",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "dateFormat": 'MM-DD-yyyy',
        "fieldType": "Date",
        "filterType": "num",
        "header": "Last Status Update Date",
        "mobile": true,
        "field": "updatedAtUtc",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 150,
        // "allowInt": true,
        // "filterType": "num",
        "header": "Next Action User",
        "mobile": true,
        "field": "next__Action__Person",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }
      , {
        "show": true,
        "textAlign": "right",
        "width": 150,
        "filterType": "num",
        "dateFormat": 'MM-DD-yyyy',
        "fieldType": "Date",
        "header": "Next Action Date",
        "mobile": true,
        "field": "next_Action_Date",
        "displayInSettings": true,
        filter: false,
        "sortable": true,
      }






    ]
    return data;
  };

  /**
   * 
   * @param {String} e 
   * @param {String} name 
   */
  showSave = async (e, name) => {
    let filter = this.state.filter
    let index = filter["criteria"].findIndex(obj => obj.key === name)
    if (index !== -1) filter["criteria"].splice(index, 1)
    if (e && e.length > 0) {
      filter.criteria.push({ key: name, value: e, type: "eq" },)
    } else {
      let removeatIndex = filter["criteria"].findIndex(obj => obj.key === name)
      if (removeatIndex !== -1) {
        filter["criteria"].splice(removeatIndex, 1)
      }
    }
    await this.setState({
      [name]: e
    })
  }

  /**
   * 
   * @returns API to connect the properties to claim
   */
  addtoExistingClaim = () => {
    let selectedRows = this.state.SelectedRecord
    this.setState({ isClickedAdd: true, isLoading: true })
    let body = {}
    body.OppClaimID = selectedRows.oppClaimID;
    body.connectedProperties = [];
    for (var obj of this.props.selectedRows) {
      let data = {}
      data = {
        "statePropertyID": obj.statePropertyId,
        "propertyID": obj.propertyID,
        "dealID": obj.dealID

      }
      body.connectedProperties.push(data)
    }
    body.hasH__Claims = this.props.selectedRows.length
    return fetchMethodRequest("POST", `${apiCalls.existingClaimfromPE}`, body).then(async (resp) => {
      if (resp) {
        await this.props.isCloseModal(resp)
        this.setState({ isLoading: false })

      }
    });

  }

  render() {
    const { handleSubmit } = this.props;
    const modalClass = classNames({
      'modal-dialog--colored': this.state.colored,
      'modal-dialog--header': this.state.header,
    });
    return (
      <Modal
        fade={false}
        isOpen={this.props.isOpenMatchmodel}
        // style={{ width: 1000 }}
        className={`right modal-dialog-centered modal-dialog--primary p-0 claim_creation_modal ${modalClass}`}
      >
        <ModalHeader className={"modal__header"}>
          <div className="d-flex" style={{ textAlign: "center" }}>
            <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "white", right: 40 }}
              onClick={() => this.props.isCloseModal()}
            />
            <span />
            <h4 className='' style={{ textAlign: "center" }}>Add to Existing Claim -{`${this.props.seller.EstateName}`}</h4>
          </div>
        </ModalHeader>
        <ModalBody className={"modal__body mb-0 pt-0 "} style={{ height: "100%", textAlign: "left" }}
        >
          <Card className='pb-0 '>
            {/* <Loader loader={this.state.isLoading} /> */}
            <CardBody className=' p-0 mt-3'
            >
              <form className="form row" onSubmit={handleSubmit(this.submit)}>
                <div className="form__form-group pb-1 col-sm-3">
                  <label className="form__form-group-label">State
                  </label>
                  <div className='form__form-group-field'>
                    <Field className='inputLogin'
                      name="SourceState"
                      component={Select}
                      placeholder='State'
                      // validate={[required]}
                      onChange={(e) => this.showSave(e, "SourceState")}
                      options={this.state.selectedstate}
                    />
                  </div>
                </div><div className="form__form-group pb-1 col-sm-3">
                  <label className="form__form-group-label">OPP Claim ID
                  </label>
                  <div className='form__form-group-field'>
                    <Field className='inputLogin'
                      name="OppClaimID"
                      component={DefaultInput}
                      placeholder=' OppClaimID'
                      onChange={(e) => this.showSave(e, "OppClaimID")}
                    />
                  </div>
                </div>
                <div className="form__form-group pb-1 col-sm-3">
                  <label className="form__form-group-label">State Claim ID
                  </label>
                  <div className='form__form-group-field'>
                    <Field className='inputLogin'
                      name="StateClaimID"
                      component={DefaultInput}
                      placeholder='State Claim ID'
                      onChange={(e) => this.showSave(e, "StateClaimID")}
                    />
                  </div>
                </div>
                <div className={"col-sm-3 mx-auto "} >
                  <Button
                    style={{ height: "34px" }}
                    color='primary'
                    className={"mb-0 mt-3"}
                    outline
                    type="submit"
                  // disabled={this.state.OppClaimID.length > 0 || this.state.StateClaimID.length > 0 ? false : true}
                  >
                    <FontAwesomeIcon
                      style={{ width: "15px" }}
                      icon={faSave}
                    />
                    {'Go'}
                  </Button></div>
              </form >
              {this.state.filter.criteria.length > 0 && this.state.isShowTable ?
                <>
                  <Loader loader={this.state.isLoading} />
                  <DataTables
                    onRef={(ref) => (this.datatableref = ref)}
                    isFormTable={true}
                    dontShowTitle={true}
                    isClearFilters={true}
                    getTableFields={this.getTableFields}
                    loading={true}
                    formFields={[]}
                    exportRequried={false}
                    printRequried={false}
                    filter={this.state.filter}
                    scrollHeight={"350px"}
                    sortField={"oppClaimID"}
                    sortDirection={"asc"}
                    headerColumnOptions={[{ "label": "Unsort", "value": 'unsort' },
                    { "label": "Sort by ASC", "value": 'asc' },
                    { "label": "Sort by DSC", "value": 'desc' },
                    { "label": "Filter", "value": 'filter' },
                    { "label": "Clear Filter", "value": 'clear_filter' }]}
                    isMatchproperties={true}
                    getResponseforSelectedPropertyData={this.getResponseforSelectedPropertyData}
                    criteria={this.state.filter.criteria}
                    statsUrl={"NsScoredProperties/ValidatedStats"}
                    // sellerID={this.props.EstateID}
                    addRequried={false}
                    editRequired={false}
                    deleteRequired={false}
                    viewRequired={true}
                    settingsRequired={false}
                    filterRequired={true}
                    gridRequried={false}
                    activeTab={"1"}
                    sample={false}
                    isSql={"yes"}
                    dataKey={"oppClaimID"}
                    sendSelctedRecordtoDb={this.sendSelctedRecordtoDb}
                    type='existingClaim'
                    displayright={true}
                    icon='user'
                    routeTo='properties'
                    displayViewOfForm='modal'
                    apiResponseKey='result'
                    apiUrl={""}
                    entityType='employee'
                  />
                </>
                : null}
              <div className={"col-sm-3 mx-auto "} >
                <Button
                  style={{ height: "34px" }}
                  color='primary'
                  className={"mb-0 mt-3"}
                  outline
                  onClick={() => this.addtoExistingClaim()}
                  disabled={this.state.SelectedRecord && !this.state.isClickedAdd ? false : true}
                >
                  <FontAwesomeIcon
                    style={{ width: "15px" }}
                    icon={faSave}
                  />
                  Add to Claim
                </Button></div>

            </CardBody>
          </Card>
        </ModalBody >
      </Modal >
    );
  }
}


AddToClaim = reduxForm({
  form: "Rulemodel", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(AddToClaim);

// You have to connect() to any reducers that you wish to connect to yourself
AddToClaim = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(AddToClaim);

export default withTranslation('common')(AddToClaim);
