
const apiCalls = {
    "loginEmail": "auth/loginemail",
    "loginUser": "auth/login",
    "Employees": "employees",
    "Tasks": "tasks",
    "Seller": "seller",
    "GetDocumentsData": "GetDocumentsData",
    "ResearchRequestType": "researchRequestType",
    "DataSource": "dataSource",
    "RemoveFromConnectedTasks": "tasks/RemoveFromConnectedTasks",
    "RfiReasons": "rfiReason",
    "nextActionDateProjections": "nextActionDateProjections",
    "SellerNotes": "seller/notes",
    "SellerCRMatrix": "seller/CrMatrixVersion",
    "mctSources": "claimSources",
    "RemoveFromConnectedClaim": "nsClaim/RemoveFromConnectedClaim",
    "UpdatePropertyMinMaxValue": "nsClaim/UpdatePropertyMinMaxValue",
    "ConnectedTasks": "tasks/ConnectedTasks",
    "AddToConnectedTasks": "tasks/AddToConnectedTasks",
    "SellerAPAName": "seller/SellerApaName",
    "followUps": "followUps",
    "Debtor": "seller/SellerDebtor",
    "SellerApaExhibit": "seller/SellerApaExhibit",
    "CrMatrixQuestions": "CrMatrixQuestions",
    "crMatrix": "seller/CrMatrix",
    "addNames": "seller/names",
    "execute": "businessnames/execute",
    "distinctNameTypes": "seller/distinctNameTypes",
    "addAddress": "seller/addresses",
    "propertyStatusReason": "propertyStatusReason",
    "sellerRelevanceNames": "seller/sellerRelevanceNames",
    "updateCrMatrix": "seller/SellerCrMatrix",
    "versionList": "seller/CrMatrixVersionsList",
    "TasksGrid": "tasks?view=grid",
    "loginChangePassword": "auth/loginChangePassword",
    "MenuList": "menus",
    "Users": "users",
    "Roles": "roles",
    "Labels": "labels",
    "Departments": "departments",
    "Status": "statuses",
    "Purposes": "purposes",
    "sankeyReport": "propertyHistory/sankeyreport",
    "PropertyStatuses": "propertyStatus",
    "ClaimStatuses": "claimStatus",
    "AvailableToFile": "statusConfiguration",
    "dealType": "dealType",
    "Apatypeconfigurations": "apaTypeConfiguration",
    "DealStage": "DealStage",
    "nsscoredproperties": "nsscoredproperties",
    "RequiredEvidence": "NsScoredProperties/requiredEvidenceReport",
    "HighValueProperties": "NsScoredProperties/reports/ListHighValueProperties",
    "dealStatus": "dealStatus",
    "ClaimReasons": "claimReason",
    "PropertyReasons": "propertyReason",
    "Settings": "settings",
    "LocationImagePath": "uploads?uploadWhileCreate=true&uploadPath=location",
    "ProfileImagePath": "uploads?uploadWhileCreate=true&uploadPath=employee",
    "reportBugUpload": "uploads/UploadMultiple?uploadPath=userRequests",
    "reportBugDelete": "uploads/delete?uploadPath=userRequests",
    "userRequestConfiguration": "userRequestConfiguration",
    "dashboard": "dashboard/counts", "Employee": "employees",
    "Attributes": "attributes",
    "Activities": "activities",
    "OpenCorporates": "openCorporates",
    "Rules": "rules",
    "Reports": "reports",
    "getAll": "attributes/getAll",
    "Companies": "businessNames/companies",
    "companiesdropdown": "businessNames/companiesdropdown",
    "BusinessSeller": "seller/business",
    "publicCompanies": "businessNames/publiccompanies",
    "Counts": "businessNames/counts",
    "ChildCompanies": "businessNames/childcompanies",
    "Investors": "businessNames/investors",
    "sicCodes": "seller/siccodes",
    "TaskTemplates": "tasktemplates",
    "logout": "auth/logout",
    "uniqueInvestors": "businessnames/uniqueinvestors",
    "ReportsDashboard": "utilizationreports",
    "affiliates": "businessNames/affiliates",
    "HeirarchyCompany": "seller/companyIds",
    "nameAddressList": "",
    "News": "businessNames/news",
    "Leaders": "businessNames/leaders",
    "TaskHistory": "activities/taskhistory",
    "TaskHistoryEffort": "tasks/taskhistory",
    "ProcessDocuments": "ProcessDocs",
    "AddressNames": "businessnames/addressnames",
    "signatories": "seller/signatories",
    "zipcode": "seller/zipcodes",
    "addresses": "businessnames/addresses",
    "PlanningGrid": "planninggrid",
    "PreAPA": "businessnames/apa",
    "publicAffiliates": "businessnames/publicAffiliates",
    "properties": "properties",
    // "propertysellers": "properties/sellers",
    "propertysellers": "properties/DealsDropdown",
    "propertySellersWithDealType": "properties/DealsDropdown",
    "propertynames": "NsScoredProperties/propertySellerNames",
    "AvailableFile": "NsScoredProperties/filingplanning",
    "AvailableFileV2": "NsScoredProperties/filingplanningV2",
    "confirmationOnsiteReport": "NsScoredProperties/GetSiteconfirmationSummary",
    "ResourceNeeded": "NsScoredProperties/resourceNeededReport",
    "ResourceNeededV2": "NsScoredProperties/resourceNeededReportV2",
    "dealSizingNames": "NsScoredProperties/DealSizingPropertySellerNames",
    "keyNames": "seller/keynames",
    "keyAddresses": "seller/keyaddresses",
    "connectBusiness": "seller/connectBusiness",
    "Deals": "businessnames/dealSizing",
    "Dealsizing": "NsScoredProperties/NsScoringTrackers",
    "PropertySummary": "NsScoredProperties/GetSummary",
    "SellerSummary": "NsScoredProperties/sellerSummaryReport",
    "businessnameissuecodeconfiguration": "businessnameissuecodeconfiguration",
    "nameIssueCodes": "seller/NameIssueCodes",
    "NameIssueCodeHistory": "seller/NameIssueCodeHistory",
    "Claims": "nsClaim",
    "ChildClaims": "nsClaim/childClaims",
    "claims": "nsclaim",
    "ClaimSummary": "nsClaim/claimSummary",
    "SearchTerms": "dealSearchTerm",
    "GetStatePropertyIDSummary": "NsScoredProperties/GetStatePropertyIDSummary",
    "ReferencePropertyName": "ReferencePropertyName",
    "ClaimStatus": "claimStatus",
    "ScoredCounts": "NsScoredProperties/Counts",
    "DealCounts": "NsScoredProperties/DealSizingCounts",
    "MatchPropertyGet": "properties/PNAAMProperties",
    "OverrideClaimStatus": "nsClaim/OverrideClaimStatus",
    "ManualMatch": "properties/MultiManualMatch",
    "Schedular": "NSScoredSeller",
    "AddtoQueue": "nsscoredseller/addToQueue",
    "Queue": "queue",
    "StateUpFiling": "StateUpFiling",
    "UpdatedScheduledDate": "nsscoredseller/UpdateScheduledDate",
    "Category": "category",
    "SubCategory": "subcategory",
    "ScoringBatch": "settings/Scoring",
    "Checklist": "checklist",
    "RecoveryPlanningV2": "tasks/RecoveryPlanV2",
    "SPLogs": "splog",
    "Search": "propertySearches/search",
    "propertySearches": "propertySearches",
    "scoringBucketName": "scoringBucketName",
    "getnameConnectionArrays": "seller/distinctNameConnections",
    "propertyStatusReason": "propertyStatusReason",
    "openCorporateBranch": "openCorporatesSearch/branchData",
    "openCorporateOCReport": "openCorporatesSearch/getOCReports",
    "openCorporateFileDownload": "openCorporatesSearch/fileDownload",
    "getLegacyIssueNames": "seller/distinctLegacyIssueCodes",
    "OpportunitySizeQuery": "businessNames/EstateSize",
    "existingClaimfromPE": "nsClaim/AddToConnectedClaim",
    "openCorporateSearch": "openCorporatesSearch/searchData",
    "FactSet": "businessNames/factSet",
    "FactSetDetails": "businessNames/factSetDetails",
    "FactSetNamesAddress": "businessNames/factSetNameAddress",
    "nsscoredproperties": "nsscoredproperties",
    "followUpHistories": "followUps",
    "stateUPOnlineClaimStatuss": "stateUPOnlineClaimStatuss",
    "nextActionDateProjection": "nextActionDateProjections",
    "OwnedNamesAndAddresses": "OwnedNamesAndAddresses",
    "reportsnewNames": "seller/newNames",
    "BusinessIssueChangesHistory": "seller/BusinessIssueChangesHistory",
    "topBarMenulists": "topBarMenulists",
    "summarySheet": "seller/ChangeReportSummary",
    "getMostRecentTask": "tasks/GetMostRecentTask",
    "MemoryCache": "memoryCacheConfigurations",
    "ReportNewBug": "reportNewBug",
    "resetCacheApi": "businessNames/resetCache",
    "OnGoing": "common/queueList",
    "restartServer": "common/restartServer",
    "Cronlist": "cronJobSchedularConfigurations",
    "AddProperties": "AddProperties",
    "readyToReviewReport": "NsScoredProperties/ListReportReadyToReview",
    "followUpReport": "nsClaim/GetAvailableToFollowUpSummary",
    "getAllUIpropertyUploadHistory": "fileImportQueue/getAll",
    "stateOnlineClaimStatussHistory": "stateUPOnlineClaimStatuss",
    "stateOnlineClaimStatussCreate": "nsClaim/AddStateUPOnlineClaimStatus"
}

export default apiCalls;
