import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { color } from 'highcharts';

// config file
class DeleteRowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    deleteSelectedRow = () => {
        this.props.deleteSelectedRow();
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal isOpen={this.props.openDeleteModal} fade={false}
                    className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'
                >
                    <ModalBody className='deleteModalBody' style={{ padding: 10 }}>
                        {this.props.unSaved ?
                            <div style={{ marginBottom: 10, color: "red" }}>{this.props.confirmModalText}</div>
                            :
                            <div style={{ marginBottom: 10 }}>{`Are you sure you want to ${this.props.type === "Queue" ? "Cancel" : this.props.type === "propertySearch" ? "Clear" : "Delete"}?`}</div>
                        }
                        <Button color="primary" outline
                            onClick={this.props.closeDeleteModal}
                            style={this.props.notesUnsaved ? { backgroundColor: "#4ea250", color: "white" } : {}}
                            className='deleteModalBtn marginRight'>{this.props.notesUnsaved ? "Save" : "No"}</Button>
                        <Button color="primary" outline onClick={this.deleteSelectedRow}
                            className='deleteModalBtn'>{this.props.notesUnsaved ? "Discard" : "Yes"}</Button>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
export default withTranslation('common')(DeleteRowModal);
