import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button, Modal } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Loader from '../../../App/Loader';

import { Rnd } from "react-rnd";
import classNames from 'classnames';
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)
const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid gray"
};
const grid = 8;


const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // paddingTop: 2,
    // paddingBottom: 2,
    paddingLeft: 2,
    margin: `0 ${grid}px 0 0`,
    display: isDragging ? "flex" : "",
    justifyContent: isDragging ? "space-between" : "",
    // change background colour if dragging
    background: isDragging ? 'lightblue' : 'white',
    border: '1px solid white',
    borderRadius: 10,
    // styles we need to apply on draggables
    ...draggableStyle,

});
const getListStyle = isDraggingOver => ({
    background: 'white',
    // display: 'flex',
    // flexWrap: 'wrap',
    // width: '100%',
    padding: grid,
    // overflow: 'auto',
    // border: '1px solid black',
    // borderRadius: 10,
    // wordBreak: 'break-word',

    // textOverflow: 'ellipsis',
});

// config file
export default class MultiSortModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.selectedfieldsforSort,
            storedrows: JSON.parse(JSON.stringify(this.props.selectedfieldsforSort)),
            isShowError: false,
            selectedRowIndex: "",
            width: 800,
            isLoading:false,
            height: 600,
            x: 885,
            y: -950,
            isdisable: false,
            multiSortFields: this.props.multiSortFields,
            stringOptions: [{ label: "A to Z", value: "asc" }, { label: "Z to A", value: "desc" }],
            numOptions: [ { label: "Largest to Smallest", value: "desc" },{ label: "Smallest to Largest", value: "asc" }],
        };
    }
    componentDidMount = async () => {
        const { multiSortFields } = this.props

        const { rows } = this.state
        console.log("1234 rows",rows)
        let updatedrowsArray = rows.map(function (element, index, array) {
            element.index = index.toString()
            return element;
        });
        console.log("1234 rows",updatedrowsArray)

        let updatedArray = multiSortFields.map(obj => {
            obj.disabled = rows && rows.length && rows.find(item => (item.value.value === obj.value)) ? true : false
            return obj;
        });
        // await this.centerElement()
        this.setState({
            multiSortFields: updatedArray,
            rows: JSON.parse(JSON.stringify(updatedrowsArray))
        })
    }
    /**
     * Creats new Row when click on Add Level
     */
    addRow = async () => {
        const { rows } = this.state
        await this.setState((prevState) => ({
            rows: [...prevState.rows, { value: '', index: (rows.length + 1).toString() }],
        }));
        console.log("1234 rows",this.state.rows)
    };
    /**
     * 
     * @param {Object} e 
     * @param {number} index 
     * @param {string} type 
     */
    //On change Dropdown Value
    handleInputChange = async (e, index, type) => {
        const { multiSortFields } = this.state
        await this.setState((prevState) => {
            const updatedRows = [...prevState.rows];
            if (!type) {
                updatedRows[index].value = e.target.value;
                updatedRows[index].fieldType = e.target.value.type;
                updatedRows[index].type = "";
            }
            if (type) {
                updatedRows[index].type = e.target.value;
            }
            const updatedArray1 = this.addKeyIfExists(multiSortFields, updatedRows, 'disabled', true);
            return { rows: updatedRows, isShowError: false, multiSortFields: updatedArray1 };
        });
    };
    /**
     * 
     * @param {Array} multiSortFields 
     * @param {Array} updatedRows 
     * @param {String} newKey 
     * @param {boolean} newValue 
     * @returns Array after checking the value
     */
    addKeyIfExists = (multiSortFields, updatedRows, newKey, newValue) => {
        const map = new Map(updatedRows.map(item => [item.value.label, item]));
        multiSortFields.forEach(item => {
            if (map.has(item.label)) {
                item[newKey] = newValue;
            } else {
                item[newKey] = false;
            }
        });
        return multiSortFields;
    };
    /**
     * Row Selection
     */
    handleRowClick = async (e, index) => {
        const { selectedRowIndex } = this.state
        if (e.target.className.includes("onrowclick")) {
            this.setState({
                selectedRowIndex: selectedRowIndex === index ? "" : index
            })
        }
    }
    /**
     * Deleting Function
     */
    deleteRow = async () => {
        const { selectedRowIndex, rows, multiSortFields } = this.state
        rows.splice(selectedRowIndex, 1);
        const updatedArray1 = this.addKeyIfExists(multiSortFields, rows, 'disabled', true);
        let updatedrowsArray = rows.map(function (element, index, array) {
            element.index = index.toString()
            return element;
        });
        console.log("1234",updatedArray1)
        this.setState({
            rows: updatedrowsArray,
            selectedRowIndex: "",
            multiSortFields: updatedArray1
        })
    }
    /**
     * Change the sorting order in downside
     */
    moveItemDown = () => {
        const { selectedRowIndex } = this.state
        this.setState((prevState) => {
            const itemsCopy = [...prevState.rows];
            const [selectedItem] = itemsCopy.splice(selectedRowIndex, 1);
            itemsCopy.splice(selectedRowIndex + 1, 0, selectedItem);
            return { rows: itemsCopy, selectedRowIndex: "" };
        });
    };
    /**
     * Change the sorting order in upside
     */
    moveItemUp = () => {
        const { selectedRowIndex } = this.state
        this.setState((prevState) => {
            const itemsCopy = [...prevState.rows];
            const [selectedItem] = itemsCopy.splice(selectedRowIndex, 1);
            itemsCopy.splice(selectedRowIndex - 1, 0, selectedItem);
            return { rows: itemsCopy, selectedRowIndex: "" };
        });
    };
    /**
     * Upload
     */
    upload = async () => {
        const { rows, stringOptions, numOptions,isLoading } = this.state
        const activeFields = rows.filter(user => user.value === "");
        if (activeFields.length > 0) {
            this.setState({ isShowError: true })
        } else {
            await this.props.cancelMultiSortModel(sortArray, rows)
            this.setState({ isShowError: false, isdisable: true,isLoading:true })
            const sortArray = rows.map(user => {
                if (user && user.value) {
                    return {
                        key: user.value.value,
                        direction: user.type ? user.type : user.value.type === "string" ? stringOptions[0].value : numOptions[0].value,
                    };
                }
            });
console.log("sortArray",sortArray)
            setTimeout(()=>this.props.applyMultisort(sortArray, rows),1000)
            // this.props.getFunction(sortArray, rows)
        }
    }
    getHeaderButtons() {
        const { rows, selectedRowIndex, isShowError, multiSortFields } = this.state
        return (
            <>
                <div className='row col-sm-12 mx-auto mb-1 my-3'>
                    <div className='col-sm-12 ' style={{ textAlign: "center", alignSelf: 'center' }}>
                        <Button className="mb-0" outline color="primary" onClick={this.addRow} disabled={rows.length === multiSortFields.length ? true : false}>Add Level</Button>
                        <Button className="mb-0" outline color="primary" onClick={this.deleteRow} disabled={selectedRowIndex !== "" && rows.length > 0 ? false : true}>Delete Level</Button>
                        {/* <Button className="mb-0" outline color="primary" onClick={() => this.moveItemDown("down")} disabled={selectedRowIndex !== "" && selectedRowIndex !== rows.length - 1 ? false : true} >
                        Move Down
                    </Button>
                    <Button outline color="primary" className="mb-0" onClick={() => this.moveItemUp("Up")} disabled={selectedRowIndex !== "" && selectedRowIndex !== 0 ? false : true}>
                        Move Up
                    </Button> */}
                    </div>
                </div>
                <p className='d-flex justify-content-center' style={{ color: "#354f6e" }}>Please drag and drop to change the order.</p>
            </>
        )
    }
    getFooterSection() {
        const { rows, isShowError } = this.state
        return (
            <div className='mt-4 d-flex justify-content-center'>
                <Button type="button" outline color="danger"
                    onClick={() => this.props.cancelMultiSortModel("close", this.state.storedrows)}>Cancel</Button>
                <Button type="button" outline color="primary" disabled={!isShowError && rows.length === 0 || this.state.isdisable ? true : false}
                    onClick={() => this.upload()}>Sort</Button> </div>
        )
    }
    centerElement = async (element) => {
        // Get the dimensions of the viewport
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.screen.height
        // Get the dimensions of the element
        const elementWidth = 1200;
        const elementHeight = 600;
        const leftPosition = (viewportWidth - elementWidth) / 2;
        const topPosition = (viewportHeight - elementHeight) / 2;
        await this.setState({ x: leftPosition, y: topPosition })
    }

    onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }
        const rows = Array.from(this.state.rows);
        const [movedRow] = rows.splice(source.index, 1);
        rows.splice(destination.index, 0, movedRow);
        this.setState({ rows });
    };

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        const { isOpenMultiSortModal } = this.props
        const { stringOptions, numOptions, rows, selectedRowIndex, isShowError, multiSortFields } = this.state
        return (
            <div className="rnd_Modal multisortModal" style={{ all: "unset" }}>
                <Modal
                    isOpen={isOpenMultiSortModal}
                    fade={false}
                    className={` p-0 claim_creation_modal multi_sort_Modal ${modalClass} rnd_modal`}
                    style={style}
                    size={{ width: this.state.width, height: this.state.height }}
                    position={{ x: this.state.x, y: this.state.y }}
                    onDragStop={(e, d) => {
                        this.setState({ x: d.x, y: d.y });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        this.setState({
                            width: ref.style.width,
                            height: ref.style.height,
                            ...position
                        });
                    }}
                // enableResizing={false}
                >
                    <Loader loader={this.state.isLoading} />
                    <Card className='pb-0 '>
                        <CardHeader className={"modal__header"}>
                            <div className="d-flex" style={{ textAlign: "center" }}>
                                <button className="lnr lnr-cross modal__close-btn" type="button" style={{ right: 40 }}
                                    onClick={() => this.props.cancelMultiSortModel("close")}
                                />
                                <span />
                                <h4 className='rangebuttontextstyle' >Multi Sort</h4>
                            </div>
                        </CardHeader>
                        <CardBody className=' p-0'
                        >
                            {this.getHeaderButtons()}
                            <div className="row mx-0 col-sm-12" style={{ justifyContent: "center", height: 35 }}><span className={`${isShowError ? "errorColor d-flex py-1 text-center" : "d-none py-1"}`} >Please fill all the fields</span></div>
                            <div className='row m-0 justify-content-center d-flex multisortModal'>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <table ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                                                <thead>
                                                    <tr>
                                                        <th>Column</th>
                                                        <th>Order</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rows.map((row, index) => (
                                                        <Draggable key={row.index} draggableId={row.index} index={index}>
                                                            {(provided, snapshot) => (

                                                                <tr
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                    key={index}
                                                                    className={selectedRowIndex === index ? 'selected' : ''}
                                                                    onClick={(e) => this.handleRowClick(e, index)}
                                                                >
                                                                    <td className='d-flex onrowclick' style={{ justifyContent: "space-between" }}>
                                                                        <span className='px-2 onrowclick'>{index === 0 ? "Sort By" : "Then By"}</span>
                                                                        <Dropdown value={row.value} style={{ width: 250 }} dataKey='value' optionLabel='label' onChange={(e) => this.handleInputChange(e, index)} options={multiSortFields}
                                                                            filterMatchMode='startsWith' filterBy="label" />
                                                                    </td>
                                                                    <td className='onrowclick'><Dropdown
                                                                        options={row.fieldType&&row.fieldType !== "string" ? numOptions : stringOptions}
                                                                        value={row.type ? row.type : row.fieldType && row.fieldType !== "string" ? numOptions[0].value : row.fieldType && row.fieldType === "string" ? stringOptions[0].value : ""}
                                                                        className='ml-3'
                                                                        onChange={(e) => this.handleInputChange(e, index, "type")}
                                                                    /></td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </tbody>
                                            </table>)}</Droppable>
                                </DragDropContext>
                            </div>
                            {this.getFooterSection()}
                        </CardBody></Card>
                </Modal></div>
        );
    }
}